import React, { Suspense } from 'react';
import './App.css';
import Index from './pages/index'
import { initializeIcons } from '@uifabric/icons';
initializeIcons();

function App() {
  return (
    <Suspense fallback="loading">
    <div className="App">
      <Index/>
    </div>
    </Suspense>
  );
}

export default App;
