import React from 'react';
import '../App.css';
import '../styles/styleFinal.css'
import '../styles/styleblack.css'
import 'office-ui-fabric-react/dist/css/fabric.css';

//ErrorBadge

import { createTheme, loadTheme} from '@fluentui/react';

import * as idb from 'idb';
import {data} from '../data/data'

import { withTranslation } from 'react-i18next'


const myTheme = createTheme({
    palette: {
        themePrimary: '#804099',
        themeLighterAlt: '#050306',
        themeLighter: '#140a18',
        themeLight: '#26132e',
        themeTertiary: '#4d275c',
        themeSecondary: '#713987',
        themeDarkAlt: '#8b4ea3',
        themeDark: '#9b63b1',
        themeDarker: '#b486c6',
        neutralLighterAlt: '#3c3c3c',
        neutralLighter: '#444444',
        neutralLight: '#515151',
        neutralQuaternaryAlt: '#595959',
        neutralQuaternary: '#5f5f5f',
        neutralTertiaryAlt: '#7a7a7a',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#333333',
    }});
let dbPromise = ''
if (!('indexedDB' in window)) {
    console.info('This browser doesn\'t support IndexedDB');
}else{
    dbPromise = idb.openDB('registros', 3, {
        upgrade(db){
            db.createObjectStore('logs', {keyPath: 'id', autoIncrement: false});
        }
    });
}
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        // eslint-disable-next-line
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const id = uuidv4()

class ConversorMetronicRecuento extends React.Component {

    constructor(props) {
        super(props);
        //console.log(props)
        this.state = {
            left:{
                amplitude: '',
                rate: '',
                pulse: '',
                soft: '',
                leads:{
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance: {
                    l0: '',
                    l1: '',
                    l2: '',
                    l3: ''
                }
            },
            leftG2:{
                amplitude: '',
                rate: '',
                pulse: '',
                soft: '',
                leads:{
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance: {
                    l0: '',
                    l1: '',
                    l2: '',
                    l3: ''
                }
            },
            right:{
                amplitude: '',
                rate: '',
                pulse: '',
                soft: '',
                leads:{
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance: {
                    l0: '',
                    l1: '',
                    l2: '',
                    l3: ''
                },
            },
            rightG2:{
                amplitude: '',
                rate: '',
                pulse: '',
                soft: '',
                leads:{
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance: {
                    l0: '',
                    l1: '',
                    l2: '',
                    l3: ''
                },
            },
            estadoAnt: {},
            fusion:{
                left:{
                    amplitude: '',
                    rate: '',
                    pulse: '',
                    soft: '',
                    leads:{
                        lcase: 'neutral',
                        l0: 'neutral',
                        l1: 'neutral',
                        l2: 'neutral',
                        l3: 'neutral',
                    },
                    impedance: {
                        lcase: '',
                        l0: '',
                        l1: '',
                        l2: '',
                        l3: ''
                    },
                    toleranciasSel: {},
                },
                right:{
                    amplitude: '',
                    rate: '',
                    pulse: '',
                    soft: '',
                    leads:{
                        rcase: 'neutral',
                        r0: 'neutral',
                        r1: 'neutral',
                        r2: 'neutral',
                        r3: 'neutral',
                    },
                    impedance: {
                        rcase: '',
                        r0: '',
                        r1: '',
                        r2: '',
                        r3: ''
                    },
                    toleranciasSel: {},
                },
            },
            intensidadLeft: {},
            toleranciasLeft: {},
            intensidadLeftPorcent: {},

            intensidadLeft2: {},
            toleranciasLeft2: {},
            intensidadLeftPorcent2: {},

            toleranciasLeftten: {},
            toleranciasLeftPorcentten: {},
            toleranciasLeftfive: {},
            toleranciasLeftPorcentfive: {},
            toleranciasLeftPorcent: {},

            toleranciasLeftten2: {},
            toleranciasLeftPorcentten2: {},
            toleranciasLeftfive2: {},
            toleranciasLeftPorcentfive2: {},
            toleranciasLeftPorcent2: {},

            intensidadRight: {},
            intensidadRightPorcent: {},
            tipoProgramacionSel: {},
            ladoImpUniSel: {},

            intensidadRight2: {},
            intensidadRightPorcent2: {},
            tipoProgramacionSel2: {},
            ladoImpUniSel2: {},

            tolerancias: [
                {key: 'five', text: '5%', type: 'toleranciasSel', valor: '5'},
                {key: 'ten', text: '10%', type: 'toleranciasSel', valor: '10'},
                {key: 'fifteen', text: '15%', type: 'toleranciasSel', valor: '15'},
                {key: 'twenty', text: '20%', type: 'toleranciasSel', valor: '20'},
                {key: 'twentyfive', text: '25%', type: 'toleranciasSel', valor: '25'},
                {key: 'cero', text: props.t('sinmargen'), type: 'toleranciasSel', valor: '0'},
            ],
            toleranciasSel: {},
            toleranciasSelL: {},
            toleranciasSelR: {},
            toleranciaApplicada: '',
            mostrarFinalLeft: false,
            mostrarFinalRigth: false,
            graficoSelL: 'mA',
            graficoSelR: 'mA',
            contPL: 0,
            contNL: 0,
            contPR: 0,
            contNR: 0,

            toleranciasSel2: {},
            toleranciasSelL2: {},
            toleranciasSelR2: {},
            toleranciaApplicada2: '',
            mostrarFinalLeft2: false,
            mostrarFinalRigth2: false,
            graficoSelL2: 'mA',
            graficoSelR2: 'mA',
            contPL2: 0,
            contNL2: 0,
            contPR2: 0,
            contNR2: 0
        };
        this.btn1 = 'btnSel' 
        this.btn2 = 'btnNoSel'
        this.onInputchange = this.onInputchange.bind(this);
        this.toggleOnChange = this.toggleOnChange.bind(this)
        this.toggleOnChange2 = this.toggleOnChange2.bind(this)
        this.changeRateLeft = this.changeRateLeft.bind(this)
        this.changeRateRigth = this.changeRateRigth.bind(this)
        this.changeRateLeft2 = this.changeRateLeft2.bind(this)
        this.changeRateRigth2 = this.changeRateRigth2.bind(this)
        this.returnMDT = this.returnMDT.bind(this)
    }
    sum( obj , porcent = 0) {
        var sum = 0;
        for( var el in obj ) {
            if( obj.hasOwnProperty( el ) ) {
                sum += parseFloat( (obj[el] - (obj[el] * (porcent / 100))) );
            }
        }
        return sum;
    }
    returnMDT() {
        alert('aaaaa')
        //console.log('demo')
        //return this.state
    }

    componentDidMount () {
        window.scrollTo(0, 0)
        document.body.style = 'background: #000;';
        loadTheme(myTheme);
        console.log(this.props.datos)
        this.setState(this.props.datos,() => {
            let auxArray = JSON.parse(JSON.stringify(this.state))
            if(auxArray.left.programador === 'interleaving') {
                let auxLeft = {
                    amplitude: this.state.left.amplitude,
                    rate: this.state.left.rate,
                    pulse: this.state.left.pulse2,
                    soft: this.state.left.soft,
                    leads:{
                        lcase: this.state.left.leads2.lcase,
                        l0: this.state.left.leads2.l0,
                        l1: this.state.left.leads2.l1,
                        l2: this.state.left.leads2.l2,
                        l3: this.state.left.leads2.l3,
                    },
                    impedance: {
                        lcase: this.state.configAnt.left.impedance2.lcase,
                        l0: this.state.configAnt.left.impedance2.l0,
                        l1: this.state.configAnt.left.impedance2.l1,
                        l2: this.state.configAnt.left.impedance2.l2,
                        l3: this.state.configAnt.left.impedance2.l3,
                    }
                }
                this.setState({leftG2: auxLeft}, () => {
                    this.primeraFuncion(); 
                    //this.props.change(this.state)
                })
            }
            if(this.state.right.programador === 'interleaving') {
                let auxRight = {
                    amplitude: this.state.right.amplitude,
                    rate: this.state.right.rate,
                    pulse: this.state.right.pulse2,
                    soft: this.state.right.soft,
                    leads:{
                        rcase: this.state.right.leads2.rcase,
                        r0: this.state.right.leads2.r0,
                        r1: this.state.right.leads2.r1,
                        r2: this.state.right.leads2.r2,
                        r3: this.state.right.leads2.r3,
                    },
                    impedance: {
                        rcase: this.state.configAnt.right.impedance2.rcase,
                        r0: this.state.configAnt.right.impedance2.r0,
                        r1: this.state.configAnt.right.impedance2.r1,
                        r2: this.state.configAnt.right.impedance2.r2,
                        r3: this.state.configAnt.right.impedance2.r3,
                    }
                }
                this.setState({rightG2: auxRight}, () => {
                    this.primeraFuncion(); 
                    //this.props.change(this.state)
                })
            }
            if(auxArray.left.programador !== 'interleaving' && auxArray.right.programador !== 'interleaving') {
                this.primeraFuncion()
                //this.props.change(this.state)
            }
        })
    }

    primeraFuncion() {
        const stado = this.state
        if(dbPromise){
            try {
                dbPromise.then(function(db,) {
                    
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        var item = {
                            id: id,
                            left: stado.left,
                            right: stado.right,
                            left2: stado.leftG2,
                            right2: stado.rightG2,
                            configAnt: stado.configAnt,
                            fechaSel: stado.fechaSel,
                            formulaL: stado.formulaL,
                            formulaR: stado.formulaR,
                            formulaL2: stado.formulaL2,
                            formulaR2: stado.formulaR2,
                            resultadoL: stado.resultadoL,
                            resultadoR: stado.resultadoR,
                            resultadoL2: stado.resultadoL2,
                            resultadoR2: stado.resultadoR2,

                            intensidadLeft: stado.intensidadLeft,
                            intensidadLeftPorcent: stado.intensidadLeftPorcent,
                            intensidadLeft2: stado.intensidadLeft2,
                            intensidadLeftPorcent2: stado.intensidadLeftPorcent2,
                            intensidadRight: stado.intensidadRight,
                            intensidadRightPorcent: stado.intensidadRightPorcent,
                            intensidadRight2: stado.intensidadRight2,
                            intensidadRightPorcent2: stado.intensidadRightPorcent2,

                            generadorSel: stado.generadorSel,
                            ladoImpUniSel: stado.ladoImpUniSel,
                            patologiaSel: stado.patologiaSel,
                            tipoImplanteSel: stado.tipoImplanteSel,
                            tipoProgramacionSel: stado.tipoProgramacionSel,
                            zonestumulaSel: stado.zonestumulaSel,
                            toleranciasSelL: stado.toleranciasSelL,
                            toleranciasSelR: stado.toleranciasSelR,

                            generadorSel2: stado.generadorSel2,
                            ladoImpUniSel2: stado.ladoImpUniSel2,
                            patologiaSel2: stado.patologiaSel2,
                            tipoImplanteSel2: stado.tipoImplanteSel2,
                            tipoProgramacionSel2: stado.tipoProgramacionSel2,
                            zonestumulaSel2: stado.zonestumulaSel2,
                            toleranciasSelL2: stado.toleranciasSelL2,
                            toleranciasSelR2: stado.toleranciasSelR2,
                        };
                        store.add(item);
                        return tx.complete;
                    
                }).then(function() {
                    dbPromise.then(function(db) { 
                        var tx = db.transaction('logs', 'readonly');
                        var store = tx.objectStore('logs');
                        return store.getAll();
                    })
                    .then(function(log){
                        // eslint-disable-next-line
                        return log.map(function(registro) {
                            data.guardarDatos(registro)
                            .then(() => {
                                dbPromise.then(function(db) { 
                                    var tx = db.transaction('logs', 'readwrite');
                                    var store = tx.objectStore('logs');
                                    return store.delete(registro.id);
                                })
                            })
                            .catch(() => console.warn('err'))
                        })
                    })
                    
                })
            }catch (error) {
                console.info(error)
            }
        }

        this.setState({resultadoL: 0})
        this.setState({resultadoR: 0})
        this.setState({resultadoL2: 0})
        this.setState({resultadoR2: 0})
        this.setState({
            mostrarFinalLeft: false,
            mostrarFinalRigth: false
        })
        this.setState({
            mostrarFinalLeft2: false,
            mostrarFinalRigth2: false
        })
        this.calcularLeft()
        this.calcularRigth()
        Object.filter = (obj, predicate) => 
        Object.keys(obj)
            .filter( key => predicate(obj[key]) )
            // eslint-disable-next-line
            .reduce( (res, key) => (res[key] = obj[key], res), {} );
        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.left.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.left.leads, lead => lead === 'positive'); 
        delete positivos.lcase
        delete negativos.lcase
        let negativosr = Object.filter(this.state.right.leads, lead => lead === 'negative'); 
        let positivosr = Object.filter(this.state.right.leads, lead => lead === 'positive'); 
        delete positivosr.rcase
        delete negativosr.rcase
        this.setState({
            contPL: Object.size(positivos),
            contNL: Object.size(negativos),
            contPR: Object.size(positivosr),
            contNR: Object.size(negativosr),
        })

        if(this.state.left.programador === 'interleaving' && (this.state.left.pulse !== this.state.left.pulse2)) {
            this.calcularLeftG2()
            let negativos = Object.filter(this.state.leftG2.leads, lead => lead === 'negative'); 
            let positivos = Object.filter(this.state.leftG2.leads, lead => lead === 'positive'); 
            delete positivos.lcase
            delete negativos.lcase
            this.setState({
                contPL2: Object.size(positivos),
                contNL2: Object.size(negativos)
            })
        }
        if(this.state.right.programador === 'interleaving' && (this.state.right.pulse !== this.state.right.pulse2)) {
            this.calcularRigthG2()
            let negativosr = Object.filter(this.state.rightG2.leads, lead => lead === 'negative'); 
            let positivosr = Object.filter(this.state.rightG2.leads, lead => lead === 'positive'); 
            delete positivosr.rcase
            delete negativosr.rcase
            this.setState({
                contPR2: Object.size(positivosr),
                contNR2: Object.size(negativosr),
            })
        }
        //console.error('aaaaaaaaaaaaaaaa')
        //this.props.change(this.state)
    }

    calcularLeft(){
        /*Maquinaria para filtar en objetos*/
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.left.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.left.leads, lead => lead === 'positive'); 
        delete positivos.lcase
        delete negativos.lcase
        //Obtenemos el voltaje
        let amplitude = this.state.left.amplitude
        //Sacamos las intensidades de cada uno de los leads agrupados
        //Negativos
        let sumaNegativos = 0
        let objNegativos = {}
        let intensidadNeg = {}
        let rEquivaNeg = {}
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.configAnt.left.impedance[key]
            objNegativos[key] = impedance
            intensidadNeg[key] = ((amplitude/impedance)*1000)
            rEquivaNeg[key] = (1/impedance)
        });
        sumaNegativos = this.sum(objNegativos)
        //Positivos
        let sumaPositivos = 0
        let objPositivos = {}
        let intensidadPos = {}
        let rEquivaPos = {}
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.configAnt.left.impedance[key]
            objPositivos[key] = impedance
            intensidadPos[key] = ((amplitude/impedance)*1000)
            rEquivaPos[key] = (1/impedance)
        });
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.configAnt.left.impedance[key]
            intensidadNeg[key] = ((amplitude/impedance)*1000)
        });
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.configAnt.left.impedance[key]
            intensidadPos[key] = ((amplitude/impedance)*1000)
        });
        /*
            m
            Rp + RN * Ma
        */

        sumaPositivos = this.sum(objPositivos)
        //Obtenemos el total
        //let total = 0
        //let formula = ''
        if(Object.size(negativos) >= 2 && Object.size(positivos) === 0){// Negativos SOLO en paralelo Scenario 3
            this.calcParalelos('left', negativos, intensidadNeg, amplitude)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 0){// POSITIVOS SOLO en paralelo Scenario 3
            this.calcParalelos('left', positivos, intensidadPos, amplitude)
        }else if(Object.size(negativos) >= 2 && Object.size(positivos) === 1){// Scenario 4 Negativos
            this.calcScnFourDes('left', amplitude, negativos, sumaPositivos, rEquivaNeg, positivos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 1){// Scenario 4 Positivos
            this.calcScnFourDes('left', amplitude, positivos, sumaNegativos, rEquivaPos, negativos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) >= 2){// Scenario 4 Positivos
            this.calcScnFourFav('left', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
        }else{
            this.calcScnFourFav('left', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
            /*
            formula = 'It = '+amplitude +' / (' + sumaNegativos + ' + ' + sumaPositivos+ ')'
            total = ((amplitude/(sumaNegativos+sumaPositivos))*1000)
            this.setState({
                resultadoL: Math.round((total + Number.EPSILON) * 100) / 100,
                formulaL: formula
            }, () => {
                //Tolerancias
                this.porcentajes(5, this.state.resultadoL, this.state.formulaL, 'resultadoLTfive', 'formulaLTfive')
                this.porcentajes(10, this.state.resultadoL, this.state.formulaL, 'resultadoLTten', 'formulaLTten')
                this.porcentajes(0, this.state.resultadoL, this.state.formulaL, 'resultadoLSinT', 'formulaLSinT')
            })
            */
        }
        

    }

    calcularLeftG2(){
        /*Maquinaria para filtar en objetos*/
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //console.log(this.state.leftG2)
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.leftG2.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.leftG2.leads, lead => lead === 'positive'); 
        delete positivos.lcase
        delete negativos.lcase
        //Obtenemos el voltaje
        let amplitude = this.state.leftG2.amplitude
        //Sacamos las intensidades de cada uno de los leads agrupados
        //Negativos
        let sumaNegativos = 0
        let objNegativos = {}
        let intensidadNeg = {}
        let rEquivaNeg = {}
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.leftG2.impedance[key]
            objNegativos[key] = impedance
            intensidadNeg[key] = ((amplitude/impedance)*1000)
            rEquivaNeg[key] = (1/impedance)
        });
        sumaNegativos = this.sum(objNegativos)
        //Positivos
        let sumaPositivos = 0
        let objPositivos = {}
        let intensidadPos = {}
        let rEquivaPos = {}
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.leftG2.impedance[key]
            objPositivos[key] = impedance
            intensidadPos[key] = ((amplitude/impedance)*1000)
            rEquivaPos[key] = (1/impedance)
        });
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        if (this.state.tipoProgramacionSel2.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.leftG2.impedance[key]
            intensidadNeg[key] = ((amplitude/impedance)*1000)
        });
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.leftG2.impedance[key]
            intensidadPos[key] = ((amplitude/impedance)*1000)
        });
        /*
            m
            Rp + RN * Ma
        */

        sumaPositivos = this.sum(objPositivos)
        //Obtenemos el total
        //let total = 0
        //let formula = ''
        if(Object.size(negativos) >= 2 && Object.size(positivos) === 0){// Negativos SOLO en paralelo Scenario 3
            this.calcParalelos2('leftG2', negativos, intensidadNeg, amplitude)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 0){// POSITIVOS SOLO en paralelo Scenario 3
            this.calcParalelos2('leftG2', positivos, intensidadPos, amplitude)
        }else if(Object.size(negativos) >= 2 && Object.size(positivos) === 1){// Scenario 4 Negativos
            this.calcScnFourDes2('leftG2', amplitude, negativos, sumaPositivos, rEquivaNeg, positivos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 1){// Scenario 4 Positivos
            this.calcScnFourDes2('leftG2', amplitude, positivos, sumaNegativos, rEquivaPos, negativos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) >= 2){// Scenario 4 Positivos
            this.calcScnFourFav2('leftG2', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
        }else{
            this.calcScnFourFav2('leftG2', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
            /*
            formula = 'It = '+amplitude +' / (' + sumaNegativos + ' + ' + sumaPositivos+ ')'
            total = ((amplitude/(sumaNegativos+sumaPositivos))*1000)
            this.setState({
                resultadoL: Math.round((total + Number.EPSILON) * 100) / 100,
                formulaL: formula
            }, () => {
                //Tolerancias
                this.porcentajes(5, this.state.resultadoL, this.state.formulaL, 'resultadoLTfive', 'formulaLTfive')
                this.porcentajes(10, this.state.resultadoL, this.state.formulaL, 'resultadoLTten', 'formulaLTten')
                this.porcentajes(0, this.state.resultadoL, this.state.formulaL, 'resultadoLSinT', 'formulaLSinT')
            })
            */
        }
        

    }

    calcularRigth(){
        /*Maquinaria para filtar en objetos*/
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.right.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.right.leads, lead => lead === 'positive'); 
        delete positivos.rcase
        delete negativos.rcase
        //Obtenemos el voltaje
        let amplitude = this.state.right.amplitude
        //Sacamos las intensidades de cada uno de los leads agrupados
            //Scenarios
        //Negativos
        let sumaNegativos = 0
        let objNegativos = {}
        let intensidadNeg = {}
        let rEquivaNeg = {}
            Object.entries(negativos).forEach(([key]) => {
                const impedance = this.state.configAnt.right.impedance[key]
                objNegativos[key] = impedance
                intensidadNeg[key] = ((amplitude/impedance)*1000)
                rEquivaNeg[key] = (1/impedance)
        });
        sumaNegativos = this.sum(objNegativos)
        //Positivos
        let sumaPositivos = 0
        let objPositivos = {}
        let intensidadPos = {}
        let rEquivaPos = {}
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.configAnt.right.impedance[key]
            objPositivos[key] = impedance
            intensidadPos[key] = ((amplitude/impedance)*1000)
            rEquivaPos[key] = (1/impedance)
        });
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        sumaPositivos = this.sum(objPositivos)
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.configAnt.right.impedance[key]
            intensidadNeg[key] = ((amplitude/impedance)*1000)
        });
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.configAnt.right.impedance[key]
            intensidadPos[key] = ((amplitude/impedance)*1000)
        });
        //Obtenemos el total
        //let total = 0
        //let formula = ''
        if(Object.size(negativos) >= 2 && Object.size(positivos) === 0){// Negativos SOLO en paralelo Scenario 3
            this.calcParalelos('right', negativos, intensidadNeg, amplitude)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 0){// POSITIVOS SOLO en paralelo Scenario 3
            this.calcParalelos('right', positivos, intensidadPos, amplitude)
        }else if(Object.size(negativos) >= 2 && Object.size(positivos) === 1){// Scenario 4 Negativos
            this.calcScnFourDes('right', amplitude, negativos, sumaPositivos, rEquivaNeg, positivos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 1){// Scenario 4 Positivos
            this.calcScnFourDes('right', amplitude, positivos, sumaNegativos, rEquivaPos, negativos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) >= 2){// Scenario 4 Positivos
            this.calcScnFourFav('right', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
        }else{

            this.calcScnFourFav('right', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
            /*
            this.setState({
                intensidadRight: ObjTodos
            })
            formula = 'It = '+amplitude +' / (' + sumaNegativos + ' + ' + sumaPositivos+ ')'
            total = ((amplitude/(sumaNegativos+sumaPositivos))*1000)
            this.setState({})
            this.setState({
                resultadoR: Math.round((total + Number.EPSILON) * 100) / 100,
                formulaR: formula,
            }, () => {
                this.porcentajes(5, this.state.resultadoR, this.state.formulaR, 'resultadoRTfive', 'formulaRTfive')
                this.porcentajes(10, this.state.resultadoR, this.state.formulaR, 'resultadoRTten', 'formulaRTten')
                this.porcentajes(0, this.state.resultadoR, this.state.formulaR, 'resultadoRSinT', 'formulaRSinT')
            })
            */
        }
        //Tolerancias
        
    }

    calcularRigthG2(){
        /*Maquinaria para filtar en objetos*/
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.rightG2.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.rightG2.leads, lead => lead === 'positive'); 
        delete positivos.rcase
        delete negativos.rcase
        //Obtenemos el voltaje
        let amplitude = this.state.rightG2.amplitude
        //Sacamos las intensidades de cada uno de los leads agrupados
            //Scenarios
        //Negativos
        let sumaNegativos = 0
        let objNegativos = {}
        let intensidadNeg = {}
        let rEquivaNeg = {}
            Object.entries(negativos).forEach(([key]) => {
                const impedance = this.state.rightG2.impedance[key]
                objNegativos[key] = impedance
                intensidadNeg[key] = ((amplitude/impedance)*1000)
                rEquivaNeg[key] = (1/impedance)
        });
        sumaNegativos = this.sum(objNegativos)
        //Positivos
        let sumaPositivos = 0
        let objPositivos = {}
        let intensidadPos = {}
        let rEquivaPos = {}
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.rightG2.impedance[key]
            objPositivos[key] = impedance
            intensidadPos[key] = ((amplitude/impedance)*1000)
            rEquivaPos[key] = (1/impedance)
        });
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        sumaPositivos = this.sum(objPositivos)
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.rightG2.impedance[key]
            intensidadNeg[key] = ((amplitude/impedance)*1000)
        });
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.rightG2.impedance[key]
            intensidadPos[key] = ((amplitude/impedance)*1000)
        });
        //Obtenemos el total
        //let total = 0
        //let formula = ''
        if(Object.size(negativos) >= 2 && Object.size(positivos) === 0){// Negativos SOLO en paralelo Scenario 3
            this.calcParalelos2('rightG2', negativos, intensidadNeg, amplitude)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 0){// POSITIVOS SOLO en paralelo Scenario 3
            this.calcParalelos2('rightG2', positivos, intensidadPos, amplitude)
        }else if(Object.size(negativos) >= 2 && Object.size(positivos) === 1){// Scenario 4 Negativos
            this.calcScnFourDes2('rightG2', amplitude, negativos, sumaPositivos, rEquivaNeg, positivos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 1){// Scenario 4 Positivos
            this.calcScnFourDes2('rightG2', amplitude, positivos, sumaNegativos, rEquivaPos, negativos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) >= 2){// Scenario 4 Positivos
            this.calcScnFourFav2('rightG2', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
        }else{

            this.calcScnFourFav2('rightG2', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
            /*
            this.setState({
                intensidadRight: ObjTodos
            })
            formula = 'It = '+amplitude +' / (' + sumaNegativos + ' + ' + sumaPositivos+ ')'
            total = ((amplitude/(sumaNegativos+sumaPositivos))*1000)
            this.setState({})
            this.setState({
                resultadoR: Math.round((total + Number.EPSILON) * 100) / 100,
                formulaR: formula,
            }, () => {
                this.porcentajes(5, this.state.resultadoR, this.state.formulaR, 'resultadoRTfive', 'formulaRTfive')
                this.porcentajes(10, this.state.resultadoR, this.state.formulaR, 'resultadoRTten', 'formulaRTten')
                this.porcentajes(0, this.state.resultadoR, this.state.formulaR, 'resultadoRSinT', 'formulaRSinT')
            })
            */
        }
        //Tolerancias
        
    }

    calcParalelos(tipo, arrTipo, intensidad, amplitude){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let cont = 0
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'left'){
            letra = 'L'
            intensidadLado = 'intensidadLeft'
            intensidadLadoPorcent = 'intensidadLeftPorcent'
            resultado = 'resultadoL'
            formulaApp = 'formulaL'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight'
            intensidadLadoPorcent = 'intensidadRightPorcent'
            resultado = 'resultadoR'
            formulaApp = 'formulaR'
        }
        let total = this.sum(intensidad)
        let formula = 'It = '
        Object.entries(arrTipo).forEach(([key]) => {
            cont ++
            const impedance = this.state.configAnt[tipo].impedance[key]
            formula = formula + '(' + amplitude +' / '+ impedance+ ')'
            if(cont < Object.size(arrTipo))
                formula = formula + ' + '
        })
        this.setState({
            [intensidadLado]: intensidad
        }, () => {
            if(dbPromise){
                const stado = this.state
                dbPromise.then(function(db) {
                    var tx = db.transaction('logs', 'readwrite');
                    var store = tx.objectStore('logs');
                    return store.openCursor(IDBKeyRange.only(id));
                }).then(function logItems(cursor) {
                    if (!cursor) {
                        return;
                    }
                    const updateData = cursor.value;
                    updateData[intensidadLado] = stado[intensidadLado]
                    cursor.update(updateData)
                    return cursor.continue().then(logItems);
                });
            }
        })
        Object.entries(intensidad).forEach(([key]) => {
            this.setState(prevState => ({
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad[key]*100)/total)
                },
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        this.setState({
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }, () =>{
            this.porcentajes(5, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfive', 'formula'+letra+'Tfive', 'intensidad'+letra+'Tfive', tipo)
            this.porcentajes(10, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tten', 'formula'+letra+'Tten', 'intensidad'+letra+'Tten', tipo)
            this.porcentajes(15, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfifteen', 'formula'+letra+'Tfifteen', 'intensidad'+letra+'Tfifteen', tipo)
            this.porcentajes(20, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwenty', 'formula'+letra+'Ttwenty', 'intensidad'+letra+'Ttwenty', tipo)
            this.porcentajes(25, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwentyfive', 'formula'+letra+'Ttwentyfive', 'intensidad'+letra+'Ttwentyfive', tipo)
            this.porcentajes(0, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'SinT', 'formula'+letra+'SinT', 'intensidad'+letra+'SinT', tipo)
        })
    }

    calcParalelos2(tipo, arrTipo, intensidad, amplitude){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let cont = 0
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'leftG2'){
            letra = 'L'
            intensidadLado = 'intensidadLeft2'
            intensidadLadoPorcent = 'intensidadLeftPorcent2'
            resultado = 'resultadoL2'
            formulaApp = 'formulaL2'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight2'
            intensidadLadoPorcent = 'intensidadRightPorcent2'
            resultado = 'resultadoR2'
            formulaApp = 'formulaR2'
        }
        let total = this.sum(intensidad)
        let formula = 'It = '
        Object.entries(arrTipo).forEach(([key]) => {
            cont ++
            const impedance = this.state[tipo].impedance[key]
            formula = formula + '(' + amplitude +' / '+ impedance+ ')'
            if(cont < Object.size(arrTipo))
                formula = formula + ' + '
        })
        this.setState({
            [intensidadLado]: intensidad
        }, () => {
            if(dbPromise){
                const stado = this.state
                dbPromise.then(function(db) {
                    var tx = db.transaction('logs', 'readwrite');
                    var store = tx.objectStore('logs');
                    return store.openCursor(IDBKeyRange.only(id));
                }).then(function logItems(cursor) {
                    if (!cursor) {
                        return;
                    }
                    const updateData = cursor.value;
                    updateData[intensidadLado] = stado[intensidadLado]
                    cursor.update(updateData)
                    return cursor.continue().then(logItems);
                });
            }
        })
        Object.entries(intensidad).forEach(([key]) => {
            this.setState(prevState => ({
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad[key]*100)/total)
                },
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        this.setState({
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }, () =>{
            this.porcentajes2(5, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfive2', 'formula'+letra+'Tfive2', 'intensidad'+letra+'Tfive2', tipo)
            this.porcentajes2(10, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tten2', 'formula'+letra+'Tten2', 'intensidad'+letra+'Tten2', tipo)
            this.porcentajes2(15, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfifteen2', 'formula'+letra+'Tfifteen2', 'intensidad'+letra+'Tfifteen2', tipo)
            this.porcentajes2(20, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwenty2', 'formula'+letra+'Ttwenty2', 'intensidad'+letra+'Ttwenty2', tipo)
            this.porcentajes2(25, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwentyfive2', 'formula'+letra+'Ttwentyfive2', 'intensidad'+letra+'Ttwentyfive2', tipo)
            this.porcentajes2(0, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'SinT2', 'formula'+letra+'SinT2', 'intensidad'+letra+'SinT2', tipo)
        })
    }

    calcScnFourDes(tipo, amplitude, arrFav, sumFav, rEquivaFav, arrDes){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'left'){
            letra = 'L'
            intensidadLado = 'intensidadLeft'
            intensidadLadoPorcent = 'intensidadLeftPorcent'
            resultado = 'resultadoL'
            formulaApp = 'formulaL'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight'
            intensidadLadoPorcent = 'intensidadRightPorcent'
            resultado = 'resultadoR'
            formulaApp = 'formulaR'
        }
        const rEq = this.sum(rEquivaFav) > 0 ? (1/this.sum(rEquivaFav)) : 0
        /*
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEq + sumFav) * amplitude)/1000
        }
        */
        let formula = 'It = '+Math.round((amplitude + Number.EPSILON) * 100) / 100  +' / (' + Math.round((rEq + Number.EPSILON) * 100) / 100 + ' + ' + sumFav + ')'
        let total = ((amplitude/(rEq+sumFav))*1000)


        const vEq = (rEq*total)/1000
        Object.entries(arrFav).forEach(([key]) => {
            const impedance = this.state.configAnt[tipo].impedance[key]
            const intensidad = (vEq/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: intensidad
                }, 
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                },
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        Object.entries(arrDes).forEach(([key]) => {
            //const impedance = this.props.location.state[tipo].impedance[key]
            //const intensidad = (vEq/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: total
                },
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((total*100)/total)
                },
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        //this.setState({[resultado]: Math.round((total + Number.EPSILON) * 100) / 100})
        this.setState({
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }, () =>{
            this.porcentajes(5, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfive', 'formula'+letra+'Tfive', 'intensidad'+letra+'Tfive', tipo)
            this.porcentajes(10, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tten', 'formula'+letra+'Tten', 'intensidad'+letra+'Tten', tipo)
            this.porcentajes(15, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfifteen', 'formula'+letra+'Tfifteen', 'intensidad'+letra+'Tfifteen', tipo)
            this.porcentajes(20, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwenty', 'formula'+letra+'Ttwenty', 'intensidad'+letra+'Ttwenty', tipo)
            this.porcentajes(25, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwentyfive', 'formula'+letra+'Ttwentyfive', 'intensidad'+letra+'Ttwentyfive', tipo)
            this.porcentajes(0, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'SinT', 'formula'+letra+'SinT', 'intensidad'+letra+'SinT', tipo)
        })
    }

    calcScnFourDes2(tipo, amplitude, arrFav, sumFav, rEquivaFav, arrDes){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'leftG2'){
            letra = 'L'
            intensidadLado = 'intensidadLeft2'
            intensidadLadoPorcent = 'intensidadLeftPorcent2'
            resultado = 'resultadoL2'
            formulaApp = 'formulaL2'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight2'
            intensidadLadoPorcent = 'intensidadRightPorcent2'
            resultado = 'resultadoR2'
            formulaApp = 'formulaR2'
        }
        const rEq = this.sum(rEquivaFav) > 0 ? (1/this.sum(rEquivaFav)) : 0
        /*
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEq + sumFav) * amplitude)/1000
        }
        */
        let formula = 'It = '+Math.round((amplitude + Number.EPSILON) * 100) / 100  +' / (' + Math.round((rEq + Number.EPSILON) * 100) / 100 + ' + ' + sumFav + ')'
        let total = ((amplitude/(rEq+sumFav))*1000)


        const vEq = (rEq*total)/1000
        Object.entries(arrFav).forEach(([key]) => {
            const impedance = this.state[tipo].impedance[key]
            const intensidad = (vEq/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: intensidad
                }, 
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                },
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        Object.entries(arrDes).forEach(([key]) => {
            //const impedance = this.props.location.state[tipo].impedance[key]
            //const intensidad = (vEq/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: total
                },
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((total*100)/total)
                },
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        //this.setState({[resultado]: Math.round((total + Number.EPSILON) * 100) / 100})
        this.setState({
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }, () =>{
            this.porcentajes2(5, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfive2', 'formula'+letra+'Tfive2', 'intensidad'+letra+'Tfive2', tipo)
            this.porcentajes2(10, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tten2', 'formula'+letra+'Tten2', 'intensidad'+letra+'Tten2', tipo)
            this.porcentajes2(15, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfifteen2', 'formula'+letra+'Tfifteen2', 'intensidad'+letra+'Tfifteen2', tipo)
            this.porcentajes2(20, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwenty2', 'formula'+letra+'Ttwenty2', 'intensidad'+letra+'Ttwenty2', tipo)
            this.porcentajes2(25, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwentyfive2', 'formula'+letra+'Ttwentyfive2', 'intensidad'+letra+'Ttwentyfive2', tipo)
            this.porcentajes2(0, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'SinT2', 'formula'+letra+'SinT2', 'intensidad'+letra+'SinT2', tipo)
        })
    }

    calcScnFourFav(tipo, amplitude, negativos, rEquivaNeg, positivos, rEquivaPos){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'left'){
            letra = 'L'
            intensidadLado = 'intensidadLeft'
            intensidadLadoPorcent = 'intensidadLeftPorcent'
            resultado = 'resultadoL'
            formulaApp = 'formulaL'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight'
            intensidadLadoPorcent = 'intensidadRightPorcent'
            resultado = 'resultadoR'
            formulaApp = 'formulaR'
        }
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        /*
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        */
        /*
            m
            Rp + RN * Ma
        */
        let formula = 'It = '+Math.round((amplitude + Number.EPSILON) * 100) / 100 +' / (' + Math.round((rEqN + Number.EPSILON) * 100) / 100 + ' + ' + Math.round((rEqP + Number.EPSILON) * 100) / 100 + ')'
        let total = ((amplitude/(rEqN+rEqP))*1000)

        const vEqP = (rEqP*total)/1000
        const vEqN = (rEqN*total)/1000
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.configAnt[tipo].impedance[key]
            const intensidad = (vEqN/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: intensidad
                },
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                }
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
            
        })
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.configAnt[tipo].impedance[key]
            const intensidad = (vEqP/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: intensidad
                },
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                }
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        //this.setState({[resultado]: Math.round((total + Number.EPSILON) * 100) / 100})
        this.setState({
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }, () =>{
            this.porcentajes(5, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfive', 'formula'+letra+'Tfive', 'intensidad'+letra+'Tfive', tipo)
            this.porcentajes(10, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tten', 'formula'+letra+'Tten', 'intensidad'+letra+'Tten', tipo)
            this.porcentajes(15, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfifteen', 'formula'+letra+'Tfifteen', 'intensidad'+letra+'Tfifteen', tipo)
            this.porcentajes(20, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwenty', 'formula'+letra+'Ttwenty', 'intensidad'+letra+'Ttwenty', tipo)
            this.porcentajes(25, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwentyfive', 'formula'+letra+'Ttwentyfive', 'intensidad'+letra+'Ttwentyfive', tipo)
            this.porcentajes(0, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'SinT', 'formula'+letra+'SinT', 'intensidad'+letra+'SinT', tipo)
        })
    }

    calcScnFourFav2(tipo, amplitude, negativos, rEquivaNeg, positivos, rEquivaPos){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'leftG2'){
            letra = 'L'
            intensidadLado = 'intensidadLeft2'
            intensidadLadoPorcent = 'intensidadLeftPorcent2'
            resultado = 'resultadoL2'
            formulaApp = 'formulaL2'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight2'
            intensidadLadoPorcent = 'intensidadRightPorcent2'
            resultado = 'resultadoR2'
            formulaApp = 'formulaR2'
        }
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        /*
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        */
        /*
            m
            Rp + RN * Ma
        */
        let formula = 'It = '+Math.round((amplitude + Number.EPSILON) * 100) / 100 +' / (' + Math.round((rEqN + Number.EPSILON) * 100) / 100 + ' + ' + Math.round((rEqP + Number.EPSILON) * 100) / 100 + ')'
        let total = ((amplitude/(rEqN+rEqP))*1000)

        const vEqP = (rEqP*total)/1000
        const vEqN = (rEqN*total)/1000
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state[tipo].impedance[key]
            const intensidad = (vEqN/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: intensidad
                },
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                }
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
            
        })
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state[tipo].impedance[key]
            const intensidad = (vEqP/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: intensidad
                },
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                }
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        //this.setState({[resultado]: Math.round((total + Number.EPSILON) * 100) / 100})
        this.setState({
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }, () =>{
            this.porcentajes2(5, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfive2', 'formula'+letra+'Tfive2', 'intensidad'+letra+'Tfive2', tipo)
            this.porcentajes2(10, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tten2', 'formula'+letra+'Tten2', 'intensidad'+letra+'Tten2', tipo)
            this.porcentajes2(15, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfifteen2', 'formula'+letra+'Tfifteen2', 'intensidad'+letra+'Tfifteen2', tipo)
            this.porcentajes2(20, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwenty2', 'formula'+letra+'Ttwenty2', 'intensidad'+letra+'Ttwenty2', tipo)
            this.porcentajes2(25, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwentyfive2', 'formula'+letra+'Ttwentyfive2', 'intensidad'+letra+'Ttwentyfive2', tipo)
            this.porcentajes2(0, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'SinT2', 'formula'+letra+'SinT2', 'intensidad'+letra+'SinT2', tipo)
        })
    }

    porcentajes(porciento, total, formula, nombre, nombreF, intensidadF, tipo){
        const descuento = (1-(1*(porciento/100)))
        let formu = formula.replace('It = ', '')
        if(porciento !== 0){
            formu = 'It = '+descuento+ ' * ['+formu+']'
        }else{
            formu = formula
        }
        const calculo = descuento*(total)

        this.setState({
            [nombre]: Math.round((calculo + Number.EPSILON) * 100) / 100,
            [nombreF]: formu
        }, () => {
            if(dbPromise){
                dbPromise.then(function(db) {
                    var tx = db.transaction('logs', 'readwrite');
                    var store = tx.objectStore('logs');
                    return store.openCursor(IDBKeyRange.only(id));
                }).then(function logItems(cursor) {
                    if (!cursor) {
                        return;
                    }
                    const updateData = cursor.value;
                    updateData[nombre] = Math.round((calculo + Number.EPSILON) * 100) / 100
                    updateData[nombreF] = formu
                    cursor.update(updateData)
                    return cursor.continue().then(logItems);
                });
            }
            
        })

        
        if(parseInt(this.state.toleranciasSelL.valor) === porciento){
            if(tipo === 'left'){
                this.setState({
                    resultadoL: Math.round((calculo + Number.EPSILON) * 100) / 100,
                    formulaL: formu
                }, () => {
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoL = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaL = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }
            /*else{
                this.setState({resultadoR: Math.round((calculo + Number.EPSILON) * 100) / 100, formulaR: formu}, () =>{
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoR = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaR = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }*/
        }else if(parseInt(this.state.toleranciasSelR.valor) === porciento){
            if(tipo === 'right'){ 
                this.setState({resultadoR: Math.round((calculo + Number.EPSILON) * 100) / 100, formulaR: formu}, () =>{
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoR = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaR = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }
        }

        if(tipo === 'left'){
            Object.entries(this.state.intensidadLeft).forEach(([key, value]) => {
                this.setState(prevState => ({
                    [intensidadF]:{
                        ...prevState[intensidadF],
                        [key]: descuento*(value)
                    }
                }), () => {
                    if(dbPromise){
                        const stado = this.state
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData[intensidadF] = stado[intensidadF]
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
                if(parseInt(this.state.toleranciasSelL.valor) === porciento){
                    this.setState(prevState => ({
                        intensidadLeft:{
                            ...prevState.intensidadLeft,
                            [key]: descuento*(value)
                        }
                    }), () => {
                        if(dbPromise){
                            const stado = this.state
                            dbPromise.then(function(db) {
                                var tx = db.transaction('logs', 'readwrite');
                                var store = tx.objectStore('logs');
                                return store.openCursor(IDBKeyRange.only(id));
                            }).then(function logItems(cursor) {
                                if (!cursor) {
                                    return;
                                }
                                const updateData = cursor.value;
                                updateData.intensidadLeft = stado.intensidadLeft
                                cursor.update(updateData)
                                return cursor.continue().then(logItems);
                            });
                        }
                    })
                }
            })
        }else{
            Object.entries(this.state.intensidadRight).forEach(([key, value]) => {
                this.setState(prevState => ({
                    [intensidadF]:{
                        ...prevState[intensidadF],
                        [key]: descuento*(value)
                    }
                }), () => {
                    if(dbPromise){
                        const stado = this.state
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData[intensidadF] = stado[intensidadF]
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
                if(parseInt(this.state.toleranciasSelR.valor) === porciento){
                    this.setState(prevState => ({
                        intensidadRight:{
                            ...prevState.intensidadRight,
                            [key]: descuento*(value)
                        }
                    }), () => {
                        if(dbPromise){
                            const stado = this.state
                            dbPromise.then(function(db) {
                                var tx = db.transaction('logs', 'readwrite');
                                var store = tx.objectStore('logs');
                                return store.openCursor(IDBKeyRange.only(id));
                            }).then(function logItems(cursor) {
                                if (!cursor) {
                                    return;
                                }
                                const updateData = cursor.value;
                                updateData.intensidadRight = stado.intensidadRight
                                cursor.update(updateData)
                                return cursor.continue().then(logItems);
                            });
                        }
                    })
                }
            })
        }
    }
    porcentajes2(porciento, total, formula, nombre, nombreF, intensidadF, tipo){
        const descuento = (1-(1*(porciento/100)))
        let formu = formula.replace('It = ', '')
        if(porciento !== 0){
            formu = 'It = '+descuento+ ' * ['+formu+']'
        }else{
            formu = formula
        }
        const calculo = descuento*(total)

        this.setState({
            [nombre]: Math.round((calculo + Number.EPSILON) * 100) / 100,
            [nombreF]: formu
        }, () => {
            if(dbPromise){
                dbPromise.then(function(db) {
                    var tx = db.transaction('logs', 'readwrite');
                    var store = tx.objectStore('logs');
                    return store.openCursor(IDBKeyRange.only(id));
                }).then(function logItems(cursor) {
                    if (!cursor) {
                        return;
                    }
                    const updateData = cursor.value;
                    updateData[nombre] = Math.round((calculo + Number.EPSILON) * 100) / 100
                    updateData[nombreF] = formu
                    cursor.update(updateData)
                    return cursor.continue().then(logItems);
                });
            }
            
        })

        
        if(parseInt(this.state.toleranciasSelL2.valor) === porciento){
            if(tipo === 'leftG2'){
                this.setState({
                    resultadoL2: Math.round((calculo + Number.EPSILON) * 100) / 100,
                    formulaL2: formu
                }, () => {
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoL = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaL = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }
            /*else{
                this.setState({resultadoR: Math.round((calculo + Number.EPSILON) * 100) / 100, formulaR: formu}, () =>{
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoR = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaR = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }*/
        }else if(parseInt(this.state.toleranciasSelR2.valor) === porciento){
            if(tipo === 'rightG2'){ 
                this.setState({resultadoR2: Math.round((calculo + Number.EPSILON) * 100) / 100, formulaR2: formu}, () =>{
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoR = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaR = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }
        }

        if(tipo === 'leftG2'){
            Object.entries(this.state.intensidadLeft2).forEach(([key, value]) => {
                this.setState(prevState => ({
                    [intensidadF]:{
                        ...prevState[intensidadF],
                        [key]: descuento*(value)
                    }
                }), () => {
                    if(dbPromise){
                        const stado = this.state
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData[intensidadF] = stado[intensidadF]
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
                if(parseInt(this.state.toleranciasSelL2.valor) === porciento){
                    this.setState(prevState => ({
                        intensidadLeft2:{
                            ...prevState.intensidadLeft2,
                            [key]: descuento*(value)
                        }
                    }), () => {
                        if(dbPromise){
                            const stado = this.state
                            dbPromise.then(function(db) {
                                var tx = db.transaction('logs', 'readwrite');
                                var store = tx.objectStore('logs');
                                return store.openCursor(IDBKeyRange.only(id));
                            }).then(function logItems(cursor) {
                                if (!cursor) {
                                    return;
                                }
                                const updateData = cursor.value;
                                updateData.intensidadLeft2 = stado.intensidadLeft2
                                cursor.update(updateData)
                                return cursor.continue().then(logItems);
                            });
                        }
                    })
                }
            })
        }else{
            Object.entries(this.state.intensidadRight).forEach(([key, value]) => {
                this.setState(prevState => ({
                    [intensidadF]:{
                        ...prevState[intensidadF],
                        [key]: descuento*(value)
                    }
                }), () => {
                    if(dbPromise){
                        const stado = this.state
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData[intensidadF] = stado[intensidadF]
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
                if(parseInt(this.state.toleranciasSelR2.valor) === porciento){
                    this.setState(prevState => ({
                        intensidadRight2:{
                            ...prevState.intensidadRight2,
                            [key]: descuento*(value)
                        }
                    }), () => {
                        if(dbPromise){
                            const stado = this.state
                            dbPromise.then(function(db) {
                                var tx = db.transaction('logs', 'readwrite');
                                var store = tx.objectStore('logs');
                                return store.openCursor(IDBKeyRange.only(id));
                            }).then(function logItems(cursor) {
                                if (!cursor) {
                                    return;
                                }
                                const updateData = cursor.value;
                                updateData.intensidadRight2 = stado.intensidadRight2
                                cursor.update(updateData)
                                return cursor.continue().then(logItems);
                            });
                        }
                    })
                }
            })
        }
    }

    onInputchange(event, item) {
        this.setState({toleranciasSel: item})
        const parts = event.target.attributes.name.value.split('.')
        let lado = ''
        if(parts[1] === 'left'){
            lado = 'L'
        }else{
            lado = 'R'
        }
        const ladoCap = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);   
        if(item.key === 'five'){
            this.setState({['resultado'+lado]: this.state['resultado'+lado+'Tfive']})
            this.setState({['formula'+lado]: this.state['formula'+lado+'Tfive']})
            this.setState({['intensidad'+ladoCap]: this.state['intensidad'+lado+'Tfive']})
        }else if(item.key === 'ten'){
            this.setState({['resultado'+lado]: this.state['resultado'+lado+'Tten']})
            this.setState({['formula'+lado]: this.state['formula'+lado+'Tten']})
            this.setState({['intensidad'+ladoCap]: this.state['intensidad'+lado+'Tten']})
        }else{
            this.setState({['resultado'+lado]: this.state['resultado'+lado+'SinT']})
            this.setState({['formula'+lado]: this.state['formula'+lado+'SinT']})
            this.setState({['intensidad'+ladoCap]: this.state['intensidad'+lado+'SinT']})
        }
    }

    toggleOnChange(event, checked){
        let lado = ''
        if(event.target.attributes.name){
            lado = event.target.attributes.name.value
        }else{
            lado = event.target.parentElement.attributes.name.value
        }
        if(lado === 'toggleLeft'){
            this.setState({mostrarFinalLeft: checked})
        }else if(lado === 'toggleRight'){
            this.setState({mostrarFinalRigth : checked})
        }
    }

    toggleOnChange2(event, checked){
        let lado = ''
        if(event.target.attributes.name){
            lado = event.target.attributes.name.value
        }else{
            lado = event.target.parentElement.attributes.name.value
        }
        if(lado === 'toggleLeft2'){
            this.setState({mostrarFinalLeft2: checked})
        }else if(lado === 'toggleRight2'){
            this.setState({mostrarFinalRigth2: checked})
        }
    }

    changeRateLeft(event){
		this.setState({
            graficoSelL: event.target.textContent
        })
    }
    changeRateLeft2(event){
		this.setState({
            graficoSelL2: event.target.textContent
        })
    }
    
    changeRateRigth(event){
		this.setState({
            graficoSelR: event.target.textContent
        })
    }
    changeRateRigth2(event){
		this.setState({
            graficoSelR2: event.target.textContent
        })
    }
    render() {
        return (<></>);
    }
}

export default withTranslation()(ConversorMetronicRecuento);