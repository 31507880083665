import React, {Fragment} from 'react';
import '../App.css';
import '../styles/styleFinal.css'
import '../styles/style.css'
import 'office-ui-fabric-react/dist/css/fabric.css';

import { Link } from 'react-router-dom';
import { PrimaryButton, DefaultButton, TextField} from '@fluentui/react'

import { Separator } from '@fluentui/react';
import { Label } from '@fluentui/react';

import { createTheme, loadTheme} from '@fluentui/react';

import * as idb from 'idb';

import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import {Stack, IconButton, Callout} from '@fluentui/react';
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'


const myThemeAnt = createTheme({
    palette: {
        themePrimary: '#303030',
        themeLighterAlt: '#e6e6e6',
        themeLighter: '#cfcfcf',
        themeLight: '#b9b9b9',
        themeTertiary: '#a2a2a2',
        themeSecondary: '#8b8b8b',
        themeDarkAlt: '#757575',
        themeDark: '#5e5e5e',
        themeDarker: '#474747',
        neutralLighterAlt: '#e1e2d2',
        neutralLighter: '#dddecf',
        neutralLight: '#d4d5c6',
        neutralQuaternaryAlt: '#c5c6b9',
        neutralQuaternary: '#bcbdb0',
        neutralTertiaryAlt: '#b5b6a9',
        neutralTertiary: '#594949',
        neutralSecondary: '#372b2b',
        neutralPrimaryAlt: '#2f2424',
        neutralPrimary: '#000000',
        neutralDark: '#151010',
        black: '#0b0808',
        white: '#e8e9d8',
    }});

loadTheme(myThemeAnt);

let dbPromise = ''
if (!('indexedDB' in window)) {
    console.info('This browser doesn\'t support IndexedDB');
}else{
    dbPromise = idb.openDB('registros', 3, {
        upgrade(db){
            db.createObjectStore('logs', {keyPath: 'id', autoIncrement: false});
        }
    });
}
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        // eslint-disable-next-line
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const id = uuidv4()


class VerInforme extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            left:{
                amplitude: '',
                rate: '',
                pulse: '',
                soft: '',
                leads:{
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance: {
                    l0: '',
                    l1: '',
                    l2: '',
                    l3: ''
                },
                leads2:{
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance2: {
                    l0: '',
                    l1: '',
                    l2: '',
                    l3: ''
                }
            },
            leftG2:{
                amplitude: '',
                rate: '',
                pulse: '',
                soft: '',
                leads:{
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance: {
                    l0: '',
                    l1: '',
                    l2: '',
                    l3: ''
                }
            },
            right:{
                amplitude: '',
                rate: '',
                pulse: '',
                soft: '',
                leads:{
                    r0: 'neutral',
                    r1: 'neutral',
                    r2: 'neutral',
                    r3: 'neutral',
                },
                impedance: {
                    r0: '',
                    r1: '',
                    r2: '',
                    r3: ''
                },
                leads2:{
                    r0: 'neutral',
                    r1: 'neutral',
                    r2: 'neutral',
                    r3: 'neutral',
                },
                impedance2: {
                    r0: '',
                    r1: '',
                    r2: '',
                    r3: ''
                },
            },
            rightG2:{
                amplitude: '',
                rate: '',
                pulse: '',
                soft: '',
                leads:{
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance: {
                    l0: '',
                    l1: '',
                    l2: '',
                    l3: ''
                },
            },
            mdt: {},
            estadoAnt: {
                left:{
                    amplitude: '',
                    rate: '',
                    pulse: '',
                    soft: '',
                    leads:{
                        l0: 'neutral',
                        l1: 'neutral',
                        l2: 'neutral',
                        l3: 'neutral',
                    },
                    impedance: {
                        l0: '',
                        l1: '',
                        l2: '',
                        l3: ''
                    },
                    leads2:{
                        l0: 'neutral',
                        l1: 'neutral',
                        l2: 'neutral',
                        l3: 'neutral',
                    },
                    impedance2: {
                        l0: '',
                        l1: '',
                        l2: '',
                        l3: ''
                    }
                },
                leftG2:{
                    amplitude: '',
                    rate: '',
                    pulse: '',
                    soft: '',
                    leads:{
                        l0: 'neutral',
                        l1: 'neutral',
                        l2: 'neutral',
                        l3: 'neutral',
                    },
                    impedance: {
                        l0: '',
                        l1: '',
                        l2: '',
                        l3: ''
                    }
                },
                right:{
                    amplitude: '',
                    rate: '',
                    pulse: '',
                    soft: '',
                    leads:{
                        l0: 'neutral',
                        l1: 'neutral',
                        l2: 'neutral',
                        l3: 'neutral',
                    },
                    impedance: {
                        l0: '',
                        l1: '',
                        l2: '',
                        l3: ''
                    },
                    leads2:{
                        l0: 'neutral',
                        l1: 'neutral',
                        l2: 'neutral',
                        l3: 'neutral',
                    },
                    impedance2: {
                        l0: '',
                        l1: '',
                        l2: '',
                        l3: ''
                    },
                },
                rightG2:{
                    amplitude: '',
                    rate: '',
                    pulse: '',
                    soft: '',
                    leads:{
                        l0: 'neutral',
                        l1: 'neutral',
                        l2: 'neutral',
                        l3: 'neutral',
                    },
                    impedance: {
                        l0: '',
                        l1: '',
                        l2: '',
                        l3: ''
                    },
                },
                formulaL: '',
                formulaR: '',
                formulaL2: '',
                formulaR2: '',
                resultadoL: '',
                resultadoR: '',
                resultadoL2: '',
                resultadoR2: '',

                intensidadLeft: '',
                intensidadLeftPorcent: '',
                intensidadLeft2: '',
                intensidadLeftPorcent2: '',
                intensidadRight: '',
                intensidadRightPorcent: '',
                intensidadRight2: '',
                intensidadRightPorcent2: '',

                generadorSel: '',
                ladoImpUniSel: '',
                patologiaSel: '',
                tipoImplanteSel: '',
                tipoProgramacionSel: '',
                zonestumulaSel: '',
                toleranciasSelL: '',
                toleranciasSelR: '',

                generadorSel2: '',
                ladoImpUniSel2: '',
                patologiaSel2: '',
                tipoImplanteSel2: '',
                tipoProgramacionSel2: '',
                zonestumulaSel2: '',
                toleranciasSelL2: '',
                toleranciasSelR2: '',
            },
            fusion:{
                left:{
                    amplitude: '',
                    rate: '',
                    pulse: '',
                    soft: '',
                    leads:{
                        lcase: 'neutral',
                        l0: 'neutral',
                        l1: 'neutral',
                        l2: 'neutral',
                        l3: 'neutral',
                    },
                    impedance: {
                        lcase: '',
                        l0: '',
                        l1: '',
                        l2: '',
                        l3: ''
                    },
                    toleranciasSel: {},
                },
                right:{
                    amplitude: '',
                    rate: '',
                    pulse: '',
                    soft: '',
                    leads:{
                        rcase: 'neutral',
                        r0: 'neutral',
                        r1: 'neutral',
                        r2: 'neutral',
                        r3: 'neutral',
                    },
                    impedance: {
                        rcase: '',
                        r0: '',
                        r1: '',
                        r2: '',
                        r3: ''
                    },
                    toleranciasSel: {},
                },
            },
            intensidadLeft: {},
            toleranciasLeft: {},
            intensidadLeftPorcent: {},

            intensidadLeft2: {},
            toleranciasLeft2: {},
            intensidadLeftPorcent2: {},

            toleranciasLeftten: {},
            toleranciasLeftPorcentten: {},
            toleranciasLeftfive: {},
            toleranciasLeftPorcentfive: {},
            toleranciasLeftPorcent: {},

            toleranciasLeftten2: {},
            toleranciasLeftPorcentten2: {},
            toleranciasLeftfive2: {},
            toleranciasLeftPorcentfive2: {},
            toleranciasLeftPorcent2: {},

            intensidadRight: {},
            intensidadRightPorcent: {},
            tipoProgramacionSel: {},
            ladoImpUniSel: {},

            intensidadRight2: {},
            intensidadRightPorcent2: {},
            tipoProgramacionSel2: {},
            ladoImpUniSel2: {},

            tolerancias: [
                {key: 'five', text: '5%', type: 'toleranciasSel', valor: '5'},
                {key: 'ten', text: '10%', type: 'toleranciasSel', valor: '10'},
                {key: 'fifteen', text: '15%', type: 'toleranciasSel', valor: '15'},
                {key: 'twenty', text: '20%', type: 'toleranciasSel', valor: '20'},
                {key: 'twentyfive', text: '25%', type: 'toleranciasSel', valor: '25'},
                {key: 'cero', text: props.t('sinmargen'), type: 'toleranciasSel', valor: '0'},
            ],
            toleranciasSel: {},
            toleranciasSelL: {},
            toleranciasSelR: {},
            toleranciaApplicada: '',
            mostrarFinalLeft: false,
            mostrarFinalRigth: false,
            isCalloutVisibleInfoPrinci: false,
            isCalloutVisibleInfoPrinci1:false,
            isCalloutVisibleInfoPrinci2:false,
            graficoSelL: 'mA',
            graficoSelR: 'mA',
            contPL: 0,
            contNL: 0,
            contPR: 0,
            contNR: 0,

            toleranciasSel2: {},
            toleranciasSelL2: {},
            toleranciasSelR2: {},
            toleranciaApplicada2: '',
            mostrarFinalLeft2: false,
            mostrarFinalRigth2: false,
            graficoSelL2: 'mA',
            graficoSelR2: 'mA',
            contPL2: 0,
            contNL2: 0,
            contPR2: 0,
            contNR2: 0
        };

        this.visibleCalloutInfoPrinci = this.visibleCalloutInfoPrinci.bind(this)
        this.invisibleCalloutInfoPrinci =  this.invisibleCalloutInfoPrinci.bind(this)
        this.visibleCalloutInfoPrinci1 = this.visibleCalloutInfoPrinci1.bind(this)
        this.invisibleCalloutInfoPrinci1 =  this.invisibleCalloutInfoPrinci1.bind(this)
        this.visibleCalloutInfoPrinci2 = this.visibleCalloutInfoPrinci2.bind(this)
        this.invisibleCalloutInfoPrinci2 =  this.invisibleCalloutInfoPrinci2.bind(this)
    }
    sum( obj , porcent = 0) {
        var sum = 0;
        for( var el in obj ) {
            if( obj.hasOwnProperty( el ) ) {
                sum += parseFloat( (obj[el] - (obj[el] * (porcent / 100))) );
            }
        }
        return sum;
    }

    componentDidMount () {
        window.scrollTo(0, 0)
        document.body.style = 'background: #e8e9d9;';
        loadTheme(myThemeAnt)
        if(!this.props.location.state){
            window.location.href = '/'
        }
        //console.log(this.props.location.state)
            //console.log(this.state)
        this.setState(this.props.location.state,() => {
            
            //console.log(this.state)
            let auxArray = JSON.parse(JSON.stringify(this.state.estadoAnt))
            if(auxArray.left.programador === 'interleaving') {
                let auxLeft = {
                    amplitude: this.state.estadoAnt.left.amplitude,
                    rate: this.state.estadoAnt.left.rate,
                    pulse: this.state.estadoAnt.left.pulse2,
                    soft: this.state.estadoAnt.left.soft,
                    leads:{
                        lcase: this.state.estadoAnt.left.leads2.lcase,
                        l0: this.state.estadoAnt.left.leads2.l0,
                        l1: this.state.estadoAnt.left.leads2.l1,
                        l2: this.state.estadoAnt.left.leads2.l2,
                        l3: this.state.estadoAnt.left.leads2.l3,
                    },
                    impedance: {
                        lcase: this.state.estadoAnt.configAnt.left.impedance2.lcase,
                        l0: this.state.estadoAnt.configAnt.left.impedance2.l0,
                        l1: this.state.estadoAnt.configAnt.left.impedance2.l1,
                        l2: this.state.estadoAnt.configAnt.left.impedance2.l2,
                        l3: this.state.estadoAnt.configAnt.left.impedance2.l3,
                    }
                }
                this.setState(prevState => ({
                    estadoAnt:{
                        ...prevState.estadoAnt,
                        leftG2: auxLeft
                    },
                }), () => {this.primeraFuncion()})
            }
            if(this.state.right.programador === 'interleaving') {
                let auxRight = {
                    amplitude: this.state.estadoAnt.right.amplitude,
                    rate: this.state.estadoAnt.right.rate,
                    pulse: this.state.estadoAnt.right.pulse2,
                    soft: this.state.estadoAnt.right.soft,
                    leads:{
                        rcase: this.state.estadoAnt.right.leads2.rcase,
                        r0: this.state.estadoAnt.right.leads2.r0,
                        r1: this.state.estadoAnt.right.leads2.r1,
                        r2: this.state.estadoAnt.right.leads2.r2,
                        r3: this.state.estadoAnt.right.leads2.r3,
                    },
                    impedance: {
                        rcase: this.state.configAnt.right.impedance2.rcase,
                        r0: this.state.estadoAnt.configAnt.right.impedance2.r0,
                        r1: this.state.estadoAnt.configAnt.right.impedance2.r1,
                        r2: this.state.estadoAnt.configAnt.right.impedance2.r2,
                        r3: this.state.estadoAnt.configAnt.right.impedance2.r3,
                    }
                }
                this.setState(prevState => ({
                    estadoAnt:{
                        ...prevState.estadoAnt,
                        rightG2: auxRight
                    },
                }), () => {this.primeraFuncion()})
            }
            if(auxArray.left.programador !== 'interleaving' && auxArray.right.programador !== 'interleaving') {
            }
                this.primeraFuncion()
        })        
    }
    visibleCalloutInfoPrinci(){
        this.setState({isCalloutVisibleInfoPrinci:  true})
    }
    invisibleCalloutInfoPrinci(){
        this.setState({isCalloutVisibleInfoPrinci:  false})
    }

    visibleCalloutInfoPrinci1(){
        this.setState({isCalloutVisibleInfoPrinci1:  true})
    }
    invisibleCalloutInfoPrinci1(){
        this.setState({isCalloutVisibleInfoPrinci1:  false})
    }

    visibleCalloutInfoPrinci2(){
        this.setState({isCalloutVisibleInfoPrinci2:  true})
    }
    invisibleCalloutInfoPrinci2(){
        this.setState({isCalloutVisibleInfoPrinci2:  false})
    }
    primeraFuncion() {
        this.setState(prevState => ({
            estadoAnt:{
                ...prevState.estadoAnt,
                resultadoL: 0
            },
        }))
        this.setState(prevState => ({
            estadoAnt:{
                ...prevState.estadoAnt,
                resultadoR: 0
            },
        }))
        this.setState(prevState => ({
            estadoAnt:{
                ...prevState.estadoAnt,
                resultadoL2: 0
            },
        }))
        this.setState(prevState => ({
            estadoAnt:{
                ...prevState.estadoAnt,
                resultadoR2: 0
            },
        }))
        this.setState(prevState => ({
            estadoAnt:{
                ...prevState.estadoAnt,
                mostrarFinalLeft: false,
                mostrarFinalRigth: false
            },
        }))
        this.setState(prevState => ({
            estadoAnt:{
                ...prevState.estadoAnt,
                mostrarFinalLeft2: false,
                mostrarFinalRigth2: false
            },
        }))
        this.calcularLeft()
        this.calcularRigth()
        Object.filter = (obj, predicate) => 
        Object.keys(obj)
            .filter( key => predicate(obj[key]) )
            // eslint-disable-next-line
            .reduce( (res, key) => (res[key] = obj[key], res), {} );
        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.estadoAnt.left.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.estadoAnt.left.leads, lead => lead === 'positive'); 
        delete positivos.lcase
        delete negativos.lcase
        let negativosr = Object.filter(this.state.estadoAnt.right.leads, lead => lead === 'negative'); 
        let positivosr = Object.filter(this.state.estadoAnt.right.leads, lead => lead === 'positive'); 
        delete positivosr.rcase
        delete negativosr.rcase
        this.setState(prevState => ({
            estadoAnt:{
                ...prevState.estadoAnt,
                contPL: Object.size(positivos),
                contNL: Object.size(negativos),
                contPR: Object.size(positivosr),
                contNR: Object.size(negativosr),
            },
        }))

        if(this.state.left.programador === 'interleaving') {
            this.calcularLeftG2()
            let negativos = Object.filter(this.state.estadoAnt.leftG2.leads, lead => lead === 'negative'); 
            let positivos = Object.filter(this.state.estadoAnt.leftG2.leads, lead => lead === 'positive'); 
            delete positivos.lcase
            delete negativos.lcase
            this.setState(prevState => ({
                estadoAnt:{
                    ...prevState.estadoAnt,
                    contPL2: Object.size(positivos),
                    contNL2: Object.size(negativos)
                },
            }))
        }
        if(this.state.right.programador === 'interleaving') {
            this.calcularRigthG2()
            let negativosr = Object.filter(this.state.estadoAnt.rightG2.leads, lead => lead === 'negative'); 
            let positivosr = Object.filter(this.state.estadoAnt.rightG2.leads, lead => lead === 'positive'); 
            delete positivosr.rcase
            delete negativosr.rcase
            this.setState(prevState => ({
                estadoAnt:{
                    ...prevState.estadoAnt,
                    contPR2: Object.size(positivosr),
                    contNR2: Object.size(negativosr),
                },
            }))
        }
        setTimeout(function() {
            console.log(this.state)
        }.bind(this), 2000)
        
        //this.props.change(this.state)
    }

    calcularLeft(){
        /*Maquinaria para filtar en objetos*/
        //console.log(this.state.estadoAnt)
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.estadoAnt.left.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.estadoAnt.left.leads, lead => lead === 'positive'); 
        delete positivos.lcase
        delete negativos.lcase
        //Obtenemos el voltaje
        let amplitude = this.state.estadoAnt.left.amplitude
        //Sacamos las intensidades de cada uno de los leads agrupados
        //Negativos
        let sumaNegativos = 0
        let objNegativos = {}
        let intensidadNeg = {}
        let rEquivaNeg = {}
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.configAnt.left.impedance[key]
            objNegativos[key] = impedance
            intensidadNeg[key] = ((amplitude/impedance)*1000)
            rEquivaNeg[key] = (1/impedance)
        });
        sumaNegativos = this.sum(objNegativos)
        //Positivos
        let sumaPositivos = 0
        let objPositivos = {}
        let intensidadPos = {}
        let rEquivaPos = {}
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.configAnt.left.impedance[key]
            objPositivos[key] = impedance
            intensidadPos[key] = ((amplitude/impedance)*1000)
            rEquivaPos[key] = (1/impedance)
        });
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.configAnt.left.impedance[key]
            intensidadNeg[key] = ((amplitude/impedance)*1000)
        });
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.configAnt.left.impedance[key]
            intensidadPos[key] = ((amplitude/impedance)*1000)
        });
        /*
            m
            Rp + RN * Ma
        */

        sumaPositivos = this.sum(objPositivos)
        //Obtenemos el total
        //let total = 0
        //let formula = ''
        if(Object.size(negativos) >= 2 && Object.size(positivos) === 0){// Negativos SOLO en paralelo Scenario 3
            this.calcParalelos('left', negativos, intensidadNeg, amplitude)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 0){// POSITIVOS SOLO en paralelo Scenario 3
            this.calcParalelos('left', positivos, intensidadPos, amplitude)
        }else if(Object.size(negativos) >= 2 && Object.size(positivos) === 1){// Scenario 4 Negativos
            this.calcScnFourDes('left', amplitude, negativos, sumaPositivos, rEquivaNeg, positivos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 1){// Scenario 4 Positivos
            this.calcScnFourDes('left', amplitude, positivos, sumaNegativos, rEquivaPos, negativos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) >= 2){// Scenario 4 Positivos
            this.calcScnFourFav('left', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
        }else{
            this.calcScnFourFav('left', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
            /*
            formula = 'It = '+amplitude +' / (' + sumaNegativos + ' + ' + sumaPositivos+ ')'
            total = ((amplitude/(sumaNegativos+sumaPositivos))*1000)
            this.setState({
                resultadoL: Math.round((total + Number.EPSILON) * 100) / 100,
                formulaL: formula
            }, () => {
                //Tolerancias
                this.porcentajes(5, this.state.resultadoL, this.state.formulaL, 'resultadoLTfive', 'formulaLTfive')
                this.porcentajes(10, this.state.resultadoL, this.state.formulaL, 'resultadoLTten', 'formulaLTten')
                this.porcentajes(0, this.state.resultadoL, this.state.formulaL, 'resultadoLSinT', 'formulaLSinT')
            })
            */
        }
        

    }

    calcularLeftG2(){
        //console.log(this.state)
        /*Maquinaria para filtar en objetos*/
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //console.log(this.state.leftG2)
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.estadoAnt.leftG2.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.estadoAnt.leftG2.leads, lead => lead === 'positive'); 
        delete positivos.lcase
        delete negativos.lcase
        //Obtenemos el voltaje
        let amplitude = this.state.estadoAnt.leftG2.amplitude
        //Sacamos las intensidades de cada uno de los leads agrupados
        //Negativos
        let sumaNegativos = 0
        let objNegativos = {}
        let intensidadNeg = {}
        let rEquivaNeg = {}
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.leftG2.impedance[key]
            objNegativos[key] = impedance
            intensidadNeg[key] = ((amplitude/impedance)*1000)
            rEquivaNeg[key] = (1/impedance)
        });
        sumaNegativos = this.sum(objNegativos)
        //Positivos
        let sumaPositivos = 0
        let objPositivos = {}
        let intensidadPos = {}
        let rEquivaPos = {}
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.leftG2.impedance[key]
            objPositivos[key] = impedance
            intensidadPos[key] = ((amplitude/impedance)*1000)
            rEquivaPos[key] = (1/impedance)
        });
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        if (this.state.tipoProgramacionSel2.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.leftG2.impedance[key]
            intensidadNeg[key] = ((amplitude/impedance)*1000)
        });
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.leftG2.impedance[key]
            intensidadPos[key] = ((amplitude/impedance)*1000)
        });
        /*
            m
            Rp + RN * Ma
        */

        sumaPositivos = this.sum(objPositivos)
        //Obtenemos el total
        //let total = 0
        //let formula = ''
        if(Object.size(negativos) >= 2 && Object.size(positivos) === 0){// Negativos SOLO en paralelo Scenario 3
            this.calcParalelos2('leftG2', negativos, intensidadNeg, amplitude)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 0){// POSITIVOS SOLO en paralelo Scenario 3
            this.calcParalelos2('leftG2', positivos, intensidadPos, amplitude)
        }else if(Object.size(negativos) >= 2 && Object.size(positivos) === 1){// Scenario 4 Negativos
            this.calcScnFourDes2('leftG2', amplitude, negativos, sumaPositivos, rEquivaNeg, positivos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 1){// Scenario 4 Positivos
            this.calcScnFourDes2('leftG2', amplitude, positivos, sumaNegativos, rEquivaPos, negativos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) >= 2){// Scenario 4 Positivos
            this.calcScnFourFav2('leftG2', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
        }else{
            this.calcScnFourFav2('leftG2', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
            /*
            formula = 'It = '+amplitude +' / (' + sumaNegativos + ' + ' + sumaPositivos+ ')'
            total = ((amplitude/(sumaNegativos+sumaPositivos))*1000)
            this.setState({
                resultadoL: Math.round((total + Number.EPSILON) * 100) / 100,
                formulaL: formula
            }, () => {
                //Tolerancias
                this.porcentajes(5, this.state.resultadoL, this.state.formulaL, 'resultadoLTfive', 'formulaLTfive')
                this.porcentajes(10, this.state.resultadoL, this.state.formulaL, 'resultadoLTten', 'formulaLTten')
                this.porcentajes(0, this.state.resultadoL, this.state.formulaL, 'resultadoLSinT', 'formulaLSinT')
            })
            */
        }
        

    }

    calcularRigth(){
        /*Maquinaria para filtar en objetos*/
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.estadoAnt.right.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.estadoAnt.right.leads, lead => lead === 'positive'); 
        delete positivos.rcase
        delete negativos.rcase
        //Obtenemos el voltaje
        let amplitude = this.state.estadoAnt.right.amplitude
        //Sacamos las intensidades de cada uno de los leads agrupados
            //Scenarios
        //Negativos
        let sumaNegativos = 0
        let objNegativos = {}
        let intensidadNeg = {}
        let rEquivaNeg = {}
            Object.entries(negativos).forEach(([key]) => {
                const impedance = this.state.estadoAnt.configAnt.right.impedance[key]
                objNegativos[key] = impedance
                intensidadNeg[key] = ((amplitude/impedance)*1000)
                rEquivaNeg[key] = (1/impedance)
        });
        sumaNegativos = this.sum(objNegativos)
        //Positivos
        let sumaPositivos = 0
        let objPositivos = {}
        let intensidadPos = {}
        let rEquivaPos = {}
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.configAnt.right.impedance[key]
            objPositivos[key] = impedance
            intensidadPos[key] = ((amplitude/impedance)*1000)
            rEquivaPos[key] = (1/impedance)
        });
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        sumaPositivos = this.sum(objPositivos)
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.configAnt.right.impedance[key]
            intensidadNeg[key] = ((amplitude/impedance)*1000)
        });
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.configAnt.right.impedance[key]
            intensidadPos[key] = ((amplitude/impedance)*1000)
        });
        //Obtenemos el total
        //let total = 0
        //let formula = ''
        if(Object.size(negativos) >= 2 && Object.size(positivos) === 0){// Negativos SOLO en paralelo Scenario 3
            this.calcParalelos('right', negativos, intensidadNeg, amplitude)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 0){// POSITIVOS SOLO en paralelo Scenario 3
            this.calcParalelos('right', positivos, intensidadPos, amplitude)
        }else if(Object.size(negativos) >= 2 && Object.size(positivos) === 1){// Scenario 4 Negativos
            this.calcScnFourDes('right', amplitude, negativos, sumaPositivos, rEquivaNeg, positivos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 1){// Scenario 4 Positivos
            this.calcScnFourDes('right', amplitude, positivos, sumaNegativos, rEquivaPos, negativos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) >= 2){// Scenario 4 Positivos
            this.calcScnFourFav('right', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
        }else{

            this.calcScnFourFav('right', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
            /*
            this.setState({
                intensidadRight: ObjTodos
            })
            formula = 'It = '+amplitude +' / (' + sumaNegativos + ' + ' + sumaPositivos+ ')'
            total = ((amplitude/(sumaNegativos+sumaPositivos))*1000)
            this.setState({})
            this.setState({
                resultadoR: Math.round((total + Number.EPSILON) * 100) / 100,
                formulaR: formula,
            }, () => {
                this.porcentajes(5, this.state.resultadoR, this.state.formulaR, 'resultadoRTfive', 'formulaRTfive')
                this.porcentajes(10, this.state.resultadoR, this.state.formulaR, 'resultadoRTten', 'formulaRTten')
                this.porcentajes(0, this.state.resultadoR, this.state.formulaR, 'resultadoRSinT', 'formulaRSinT')
            })
            */
        }
        //Tolerancias
        
    }

    calcularRigthG2(){
        /*Maquinaria para filtar en objetos*/
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.estadoAnt.rightG2.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.estadoAnt.rightG2.leads, lead => lead === 'positive'); 
        delete positivos.rcase
        delete negativos.rcase
        //Obtenemos el voltaje
        let amplitude = this.state.estadoAnt.rightG2.amplitude
        //Sacamos las intensidades de cada uno de los leads agrupados
            //Scenarios
        //Negativos
        let sumaNegativos = 0
        let objNegativos = {}
        let intensidadNeg = {}
        let rEquivaNeg = {}
            Object.entries(negativos).forEach(([key]) => {
                const impedance = this.state.estadoAnt.rightG2.impedance[key]
                objNegativos[key] = impedance
                intensidadNeg[key] = ((amplitude/impedance)*1000)
                rEquivaNeg[key] = (1/impedance)
        });
        sumaNegativos = this.sum(objNegativos)
        //Positivos
        let sumaPositivos = 0
        let objPositivos = {}
        let intensidadPos = {}
        let rEquivaPos = {}
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.rightG2.impedance[key]
            objPositivos[key] = impedance
            intensidadPos[key] = ((amplitude/impedance)*1000)
            rEquivaPos[key] = (1/impedance)
        });
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        sumaPositivos = this.sum(objPositivos)
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.rightG2.impedance[key]
            intensidadNeg[key] = ((amplitude/impedance)*1000)
        });
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.rightG2.impedance[key]
            intensidadPos[key] = ((amplitude/impedance)*1000)
        });
        //Obtenemos el total
        //let total = 0
        //let formula = ''
        if(Object.size(negativos) >= 2 && Object.size(positivos) === 0){// Negativos SOLO en paralelo Scenario 3
            this.calcParalelos2('rightG2', negativos, intensidadNeg, amplitude)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 0){// POSITIVOS SOLO en paralelo Scenario 3
            this.calcParalelos2('rightG2', positivos, intensidadPos, amplitude)
        }else if(Object.size(negativos) >= 2 && Object.size(positivos) === 1){// Scenario 4 Negativos
            this.calcScnFourDes2('rightG2', amplitude, negativos, sumaPositivos, rEquivaNeg, positivos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 1){// Scenario 4 Positivos
            this.calcScnFourDes2('rightG2', amplitude, positivos, sumaNegativos, rEquivaPos, negativos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) >= 2){// Scenario 4 Positivos
            this.calcScnFourFav2('rightG2', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
        }else{

            this.calcScnFourFav2('rightG2', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
            /*
            this.setState({
                intensidadRight: ObjTodos
            })
            formula = 'It = '+amplitude +' / (' + sumaNegativos + ' + ' + sumaPositivos+ ')'
            total = ((amplitude/(sumaNegativos+sumaPositivos))*1000)
            this.setState({})
            this.setState({
                resultadoR: Math.round((total + Number.EPSILON) * 100) / 100,
                formulaR: formula,
            }, () => {
                this.porcentajes(5, this.state.resultadoR, this.state.formulaR, 'resultadoRTfive', 'formulaRTfive')
                this.porcentajes(10, this.state.resultadoR, this.state.formulaR, 'resultadoRTten', 'formulaRTten')
                this.porcentajes(0, this.state.resultadoR, this.state.formulaR, 'resultadoRSinT', 'formulaRSinT')
            })
            */
        }
        //Tolerancias
        
    }

    calcParalelos(tipo, arrTipo, intensidad, amplitude){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let cont = 0
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'left'){
            letra = 'L'
            intensidadLado = 'intensidadLeft'
            intensidadLadoPorcent = 'intensidadLeftPorcent'
            resultado = 'resultadoL'
            formulaApp = 'formulaL'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight'
            intensidadLadoPorcent = 'intensidadRightPorcent'
            resultado = 'resultadoR'
            formulaApp = 'formulaR'
        }
        let total = this.sum(intensidad)
        let formula = 'It = '
        Object.entries(arrTipo).forEach(([key]) => {
            cont ++
            const impedance = this.state.estadoAnt.configAnt[tipo].impedance[key]
            formula = formula + '(' + amplitude +' / '+ impedance+ ')'
            if(cont < Object.size(arrTipo))
                formula = formula + ' + '
        })
        this.setState(prevState => ({
            estadoAnt:{
                ...prevState.estadoAnt,
                [intensidadLado]: intensidad
            },
        }), () => {
            if(dbPromise){
                const stado = this.state.estadoAnt
                dbPromise.then(function(db) {
                    var tx = db.transaction('logs', 'readwrite');
                    var store = tx.objectStore('logs');
                    return store.openCursor(IDBKeyRange.only(id));
                }).then(function logItems(cursor) {
                    if (!cursor) {
                        return;
                    }
                    const updateData = cursor.value;
                    updateData[intensidadLado] = stado[intensidadLado]
                    cursor.update(updateData)
                    return cursor.continue().then(logItems);
                });
            }
        })
        Object.entries(intensidad).forEach(([key]) => {
            this.setState(prevState => ({estadoAnt: {
                ...prevState.estadoAnt,
                [intensidadLadoPorcent]:{
                    ...prevState.estadoAnt[intensidadLadoPorcent],
                    [key]: ((intensidad[key]*100)/total)
                },
            }}), () => {
                if(dbPromise){
                    const stado = this.state.estadoAnt
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        this.setState(prevState => ({
            estadoAnt:{
                ...prevState.estadoAnt,
                [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
                [formulaApp]: formula
            },
        }), () =>{
            this.porcentajes(5, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tfive', 'formula'+letra+'Tfive', 'intensidad'+letra+'Tfive', tipo)
            this.porcentajes(10, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tten', 'formula'+letra+'Tten', 'intensidad'+letra+'Tten', tipo)
            this.porcentajes(15, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tfifteen', 'formula'+letra+'Tfifteen', 'intensidad'+letra+'Tfifteen', tipo)
            this.porcentajes(20, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Ttwenty', 'formula'+letra+'Ttwenty', 'intensidad'+letra+'Ttwenty', tipo)
            this.porcentajes(25, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Ttwentyfive', 'formula'+letra+'Ttwentyfive', 'intensidad'+letra+'Ttwentyfive', tipo)
            this.porcentajes(0, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'SinT', 'formula'+letra+'SinT', 'intensidad'+letra+'SinT', tipo)
        })
    }

    calcParalelos2(tipo, arrTipo, intensidad, amplitude){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let cont = 0
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'leftG2'){
            letra = 'L'
            intensidadLado = 'intensidadLeft2'
            intensidadLadoPorcent = 'intensidadLeftPorcent2'
            resultado = 'resultadoL2'
            formulaApp = 'formulaL2'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight2'
            intensidadLadoPorcent = 'intensidadRightPorcent2'
            resultado = 'resultadoR2'
            formulaApp = 'formulaR2'
        }
        let total = this.sum(intensidad)
        let formula = 'It = '
        Object.entries(arrTipo).forEach(([key]) => {
            cont ++
            const impedance = this.state.estadoAnt[tipo].impedance[key]
            formula = formula + '(' + amplitude +' / '+ impedance+ ')'
            if(cont < Object.size(arrTipo))
                formula = formula + ' + '
        })
        this.setState(prevState => ({
            estadoAnt:{
                ...prevState.estadoAnt,
                [intensidadLado]: intensidad
            },
        }), () => {
            if(dbPromise){
                const stado = this.state.estadoAnt
                dbPromise.then(function(db) {
                    var tx = db.transaction('logs', 'readwrite');
                    var store = tx.objectStore('logs');
                    return store.openCursor(IDBKeyRange.only(id));
                }).then(function logItems(cursor) {
                    if (!cursor) {
                        return;
                    }
                    const updateData = cursor.value;
                    updateData[intensidadLado] = stado[intensidadLado]
                    cursor.update(updateData)
                    return cursor.continue().then(logItems);
                });
            }
        })
        Object.entries(intensidad).forEach(([key]) => {
            this.setState(prevState => ({estadoAnt: {
                ...prevState.estadoAnt,
                [intensidadLadoPorcent]:{
                    ...prevState.estadoAnt[intensidadLadoPorcent],
                    [key]: ((intensidad[key]*100)/total)
                },
            }}), () => {
                if(dbPromise){
                    const stado = this.state.estadoAnt
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        this.setState(prevState => ({
            estadoAnt:{
                ...prevState.estadoAnt,
                [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
                [formulaApp]: formula
            },
        }), () =>{
            this.porcentajes2(5, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tfive2', 'formula'+letra+'Tfive2', 'intensidad'+letra+'Tfive2', tipo)
            this.porcentajes2(10, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tten2', 'formula'+letra+'Tten2', 'intensidad'+letra+'Tten2', tipo)
            this.porcentajes2(15, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tfifteen2', 'formula'+letra+'Tfifteen2', 'intensidad'+letra+'Tfifteen2', tipo)
            this.porcentajes2(20, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Ttwenty2', 'formula'+letra+'Ttwenty2', 'intensidad'+letra+'Ttwenty2', tipo)
            this.porcentajes2(25, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Ttwentyfive2', 'formula'+letra+'Ttwentyfive2', 'intensidad'+letra+'Ttwentyfive2', tipo)
            this.porcentajes2(0, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'SinT2', 'formula'+letra+'SinT2', 'intensidad'+letra+'SinT2', tipo)
        })
    }

    calcScnFourDes(tipo, amplitude, arrFav, sumFav, rEquivaFav, arrDes){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'left'){
            letra = 'L'
            intensidadLado = 'intensidadLeft'
            intensidadLadoPorcent = 'intensidadLeftPorcent'
            resultado = 'resultadoL'
            formulaApp = 'formulaL'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight'
            intensidadLadoPorcent = 'intensidadRightPorcent'
            resultado = 'resultadoR'
            formulaApp = 'formulaR'
        }
        const rEq = this.sum(rEquivaFav) > 0 ? (1/this.sum(rEquivaFav)) : 0
        /*
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEq + sumFav) * amplitude)/1000
        }
        */
        let formula = 'It = '+Math.round((amplitude + Number.EPSILON) * 100) / 100  +' / (' + Math.round((rEq + Number.EPSILON) * 100) / 100 + ' + ' + sumFav + ')'
        let total = ((amplitude/(rEq+sumFav))*1000)


        const vEq = (rEq*total)/1000
        Object.entries(arrFav).forEach(([key]) => {
            const impedance = this.state.estadoAnt.configAnt[tipo].impedance[key]
            const intensidad = (vEq/impedance)*1000
            this.setState(prevState => ({estadoAnt: {
                ...prevState.estadoAnt,
                [intensidadLado]:{
                    ...prevState.estadoAnt[intensidadLado],
                    [key]: intensidad
                }, 
                [intensidadLadoPorcent]:{
                    ...prevState.estadoAnt[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                },
            }}), () => {
                if(dbPromise){
                    const stado = this.state.estadoAnt
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        Object.entries(arrDes).forEach(([key]) => {
            //const impedance = this.props.location.state[tipo].impedance[key]
            //const intensidad = (vEq/impedance)*1000
            this.setState(prevState => ({estadoAnt: {
                ...prevState.estadoAnt,
                [intensidadLado]:{
                    ...prevState.estadoAnt[intensidadLado],
                    [key]: total
                },
                [intensidadLadoPorcent]:{
                    ...prevState.estadoAnt[intensidadLadoPorcent],
                    [key]: ((total*100)/total)
                },
            }}), () => {
                if(dbPromise){
                    const stado = this.state.estadoAnt
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        //this.setState({[resultado]: Math.round((total + Number.EPSILON) * 100) / 100})
        this.setState(prevState => ({estadoAnt: {
            ...prevState.estadoAnt,
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }}), () =>{
            this.porcentajes(5, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tfive', 'formula'+letra+'Tfive', 'intensidad'+letra+'Tfive', tipo)
            this.porcentajes(10, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tten', 'formula'+letra+'Tten', 'intensidad'+letra+'Tten', tipo)
            this.porcentajes(15, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tfifteen', 'formula'+letra+'Tfifteen', 'intensidad'+letra+'Tfifteen', tipo)
            this.porcentajes(20, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Ttwenty', 'formula'+letra+'Ttwenty', 'intensidad'+letra+'Ttwenty', tipo)
            this.porcentajes(25, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Ttwentyfive', 'formula'+letra+'Ttwentyfive', 'intensidad'+letra+'Ttwentyfive', tipo)
            this.porcentajes(0, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'SinT', 'formula'+letra+'SinT', 'intensidad'+letra+'SinT', tipo)
        })
    }

    calcScnFourDes2(tipo, amplitude, arrFav, sumFav, rEquivaFav, arrDes){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'leftG2'){
            letra = 'L'
            intensidadLado = 'intensidadLeft2'
            intensidadLadoPorcent = 'intensidadLeftPorcent2'
            resultado = 'resultadoL2'
            formulaApp = 'formulaL2'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight2'
            intensidadLadoPorcent = 'intensidadRightPorcent2'
            resultado = 'resultadoR2'
            formulaApp = 'formulaR2'
        }
        const rEq = this.sum(rEquivaFav) > 0 ? (1/this.sum(rEquivaFav)) : 0
        /*
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEq + sumFav) * amplitude)/1000
        }
        */
        let formula = 'It = '+Math.round((amplitude + Number.EPSILON) * 100) / 100  +' / (' + Math.round((rEq + Number.EPSILON) * 100) / 100 + ' + ' + sumFav + ')'
        let total = ((amplitude/(rEq+sumFav))*1000)


        const vEq = (rEq*total)/1000
        Object.entries(arrFav).forEach(([key]) => {
            const impedance = this.state.estadoAnt[tipo].impedance[key]
            const intensidad = (vEq/impedance)*1000
            this.setState(prevState => ({estadoAnt: {
                ...prevState.estadoAnt,
                [intensidadLado]:{
                    ...prevState.estadoAnt[intensidadLado],
                    [key]: intensidad
                }, 
                [intensidadLadoPorcent]:{
                    ...prevState.estadoAnt[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                },
            }}), () => {
                if(dbPromise){
                    const stado = this.state.estadoAnt
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        Object.entries(arrDes).forEach(([key]) => {
            //const impedance = this.props.location.state[tipo].impedance[key]
            //const intensidad = (vEq/impedance)*1000
            this.setState(prevState => ({estadoAnt: {
                ...prevState.estadoAnt,
                [intensidadLado]:{
                    ...prevState.estadoAnt[intensidadLado],
                    [key]: total
                },
                [intensidadLadoPorcent]:{
                    ...prevState.estadoAnt[intensidadLadoPorcent],
                    [key]: ((total*100)/total)
                },
            }}), () => {
                if(dbPromise){
                    const stado = this.state.estadoAnt
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        //this.setState({[resultado]: Math.round((total + Number.EPSILON) * 100) / 100})
        this.setState(prevState => ({estadoAnt: {
            ...prevState.estadoAnt,
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }}), () =>{
            this.porcentajes2(5, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tfive2', 'formula'+letra+'Tfive2', 'intensidad'+letra+'Tfive2', tipo)
            this.porcentajes2(10, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tten2', 'formula'+letra+'Tten2', 'intensidad'+letra+'Tten2', tipo)
            this.porcentajes2(15, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tfifteen2', 'formula'+letra+'Tfifteen2', 'intensidad'+letra+'Tfifteen2', tipo)
            this.porcentajes2(20, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Ttwenty2', 'formula'+letra+'Ttwenty2', 'intensidad'+letra+'Ttwenty2', tipo)
            this.porcentajes2(25, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Ttwentyfive2', 'formula'+letra+'Ttwentyfive2', 'intensidad'+letra+'Ttwentyfive2', tipo)
            this.porcentajes2(0, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'SinT2', 'formula'+letra+'SinT2', 'intensidad'+letra+'SinT2', tipo)
        })
    }

    calcScnFourFav(tipo, amplitude, negativos, rEquivaNeg, positivos, rEquivaPos){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'left'){
            letra = 'L'
            intensidadLado = 'intensidadLeft'
            intensidadLadoPorcent = 'intensidadLeftPorcent'
            resultado = 'resultadoL'
            formulaApp = 'formulaL'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight'
            intensidadLadoPorcent = 'intensidadRightPorcent'
            resultado = 'resultadoR'
            formulaApp = 'formulaR'
        }
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        /*
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        */
        /*
            m
            Rp + RN * Ma
        */
        let formula = 'It = '+Math.round((amplitude + Number.EPSILON) * 100) / 100 +' / (' + Math.round((rEqN + Number.EPSILON) * 100) / 100 + ' + ' + Math.round((rEqP + Number.EPSILON) * 100) / 100 + ')'
        let total = ((amplitude/(rEqN+rEqP))*1000)

        const vEqP = (rEqP*total)/1000
        const vEqN = (rEqN*total)/1000
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.configAnt[tipo].impedance[key]
            const intensidad = (vEqN/impedance)*1000
            this.setState(prevState => ({estadoAnt: {
                ...prevState.estadoAnt,
                [intensidadLado]:{
                    ...prevState.estadoAnt[intensidadLado],
                    [key]: intensidad
                },
                [intensidadLadoPorcent]:{
                    ...prevState.estadoAnt[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                }
            }}), () => {
                if(dbPromise){
                    const stado = this.state.estadoAnt
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
            
        })
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.estadoAnt.configAnt[tipo].impedance[key]
            const intensidad = (vEqP/impedance)*1000
            this.setState(prevState => ({estadoAnt: {
                ...prevState.estadoAnt,
                [intensidadLado]:{
                    ...prevState.estadoAnt[intensidadLado],
                    [key]: intensidad
                },
                [intensidadLadoPorcent]:{
                    ...prevState.estadoAnt[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                }
            }}), () => {
                if(dbPromise){
                    const stado = this.state.estadoAnt
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        //this.setState({[resultado]: Math.round((total + Number.EPSILON) * 100) / 100})
        this.setState(prevState => ({estadoAnt: {
            ...prevState.estadoAnt,
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }}), () =>{
            this.porcentajes(5, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tfive', 'formula'+letra+'Tfive', 'intensidad'+letra+'Tfive', tipo)
            this.porcentajes(10, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tten', 'formula'+letra+'Tten', 'intensidad'+letra+'Tten', tipo)
            this.porcentajes(15, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tfifteen', 'formula'+letra+'Tfifteen', 'intensidad'+letra+'Tfifteen', tipo)
            this.porcentajes(20, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Ttwenty', 'formula'+letra+'Ttwenty', 'intensidad'+letra+'Ttwenty', tipo)
            this.porcentajes(25, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Ttwentyfive', 'formula'+letra+'Ttwentyfive', 'intensidad'+letra+'Ttwentyfive', tipo)
            this.porcentajes(0, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'SinT', 'formula'+letra+'SinT', 'intensidad'+letra+'SinT', tipo)
        })
    }

    calcScnFourFav2(tipo, amplitude, negativos, rEquivaNeg, positivos, rEquivaPos){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'leftG2'){
            letra = 'L'
            intensidadLado = 'intensidadLeft2'
            intensidadLadoPorcent = 'intensidadLeftPorcent2'
            resultado = 'resultadoL2'
            formulaApp = 'formulaL2'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight2'
            intensidadLadoPorcent = 'intensidadRightPorcent2'
            resultado = 'resultadoR2'
            formulaApp = 'formulaR2'
        }
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        /*
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        */
        /*
            m
            Rp + RN * Ma
        */
        let formula = 'It = '+Math.round((amplitude + Number.EPSILON) * 100) / 100 +' / (' + Math.round((rEqN + Number.EPSILON) * 100) / 100 + ' + ' + Math.round((rEqP + Number.EPSILON) * 100) / 100 + ')'
        let total = ((amplitude/(rEqN+rEqP))*1000)

        const vEqP = (rEqP*total)/1000
        const vEqN = (rEqN*total)/1000
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.estadoAnt[tipo].impedance[key]
            const intensidad = (vEqN/impedance)*1000
            this.setState(prevState => ({estadoAnt: {
                ...prevState.estadoAnt,
                [intensidadLado]:{
                    ...prevState.estadoAnt[intensidadLado],
                    [key]: intensidad
                },
                [intensidadLadoPorcent]:{
                    ...prevState.estadoAnt[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                }
            }}), () => {
                if(dbPromise){
                    const stado = this.state.estadoAnt
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
            
        })
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.estadoAnt[tipo].impedance[key]
            const intensidad = (vEqP/impedance)*1000
            this.setState(prevState => ({estadoAnt: {
                ...prevState.estadoAnt,
                [intensidadLado]:{
                    ...prevState.estadoAnt[intensidadLado],
                    [key]: intensidad
                },
                [intensidadLadoPorcent]:{
                    ...prevState.estadoAnt[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                }
            }}), () => {
                if(dbPromise){
                    const stado = this.state.estadoAnt
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        //this.setState({[resultado]: Math.round((total + Number.EPSILON) * 100) / 100})
        this.setState(prevState => ({estadoAnt: {
            ...prevState.estadoAnt,
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }}), () =>{
            this.porcentajes2(5, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tfive2', 'formula'+letra+'Tfive2', 'intensidad'+letra+'Tfive2', tipo)
            this.porcentajes2(10, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tten2', 'formula'+letra+'Tten2', 'intensidad'+letra+'Tten2', tipo)
            this.porcentajes2(15, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Tfifteen2', 'formula'+letra+'Tfifteen2', 'intensidad'+letra+'Tfifteen2', tipo)
            this.porcentajes2(20, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Ttwenty2', 'formula'+letra+'Ttwenty2', 'intensidad'+letra+'Ttwenty2', tipo)
            this.porcentajes2(25, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'Ttwentyfive2', 'formula'+letra+'Ttwentyfive2', 'intensidad'+letra+'Ttwentyfive2', tipo)
            this.porcentajes2(0, this.state.estadoAnt[resultado], this.state.estadoAnt[formulaApp], 'resultado'+letra+'SinT2', 'formula'+letra+'SinT2', 'intensidad'+letra+'SinT2', tipo)
        })
    }

    porcentajes(porciento, total, formula, nombre, nombreF, intensidadF, tipo){
        const descuento = (1-(1*(porciento/100)))
        let formu = formula.replace('It = ', '')
        if(porciento !== 0){
            formu = 'It = '+descuento+ ' * ['+formu+']'
        }else{
            formu = formula
        }
        const calculo = descuento*(total)

        this.setState(prevState => ({estadoAnt: {
            ...prevState.estadoAnt,
            [nombre]: Math.round((calculo + Number.EPSILON) * 100) / 100,
            [nombreF]: formu
        }}), () => {
            if(dbPromise){
                dbPromise.then(function(db) {
                    var tx = db.transaction('logs', 'readwrite');
                    var store = tx.objectStore('logs');
                    return store.openCursor(IDBKeyRange.only(id));
                }).then(function logItems(cursor) {
                    if (!cursor) {
                        return;
                    }
                    const updateData = cursor.value;
                    updateData[nombre] = Math.round((calculo + Number.EPSILON) * 100) / 100
                    updateData[nombreF] = formu
                    cursor.update(updateData)
                    return cursor.continue().then(logItems);
                });
            }
            
        })

        
        if(parseInt(this.state.estadoAnt.toleranciasSelL.valor) === porciento){
            if(tipo === 'left'){
                this.setState(prevState => ({estadoAnt: {
                    ...prevState.estadoAnt,
                    resultadoL: Math.round((calculo + Number.EPSILON) * 100) / 100,
                    formulaL: formu
                }}), () => {
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoL = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaL = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }
            /*else{
                this.setState({resultadoR: Math.round((calculo + Number.EPSILON) * 100) / 100, formulaR: formu}, () =>{
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoR = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaR = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }*/
        }else if(parseInt(this.state.estadoAnt.toleranciasSelR.valor) === porciento){
            if(tipo === 'right'){ 
                this.setState(prevState => ({estadoAnt: {
                    ...prevState.estadoAnt,
                    resultadoR: Math.round((calculo + Number.EPSILON) * 100) / 100, 
                    formulaR: formu
                }}), () =>{
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoR = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaR = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }
        }

        if(tipo === 'left'){
            Object.entries(this.state.estadoAnt.intensidadLeft).forEach(([key, value]) => {
                this.setState(prevState => ({estadoAnt: {
                    ...prevState.estadoAnt,
                    [intensidadF]:{
                        ...prevState.estadoAnt[intensidadF],
                        [key]: descuento*(value)
                    }
                }}), () => {
                    if(dbPromise){
                        const stado = this.state.estadoAnt
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData[intensidadF] = stado[intensidadF]
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
                if(parseInt(this.state.estadoAnt.toleranciasSelL.valor) === porciento){
                    this.setState(prevState => ({estadoAnt: {
                        ...prevState.estadoAnt,
                        intensidadLeft:{
                            ...prevState.estadoAnt.intensidadLeft,
                            [key]: descuento*(value)
                        }
                    }}), () => {
                        if(dbPromise){
                            const stado = this.state.estadoAnt
                            dbPromise.then(function(db) {
                                var tx = db.transaction('logs', 'readwrite');
                                var store = tx.objectStore('logs');
                                return store.openCursor(IDBKeyRange.only(id));
                            }).then(function logItems(cursor) {
                                if (!cursor) {
                                    return;
                                }
                                const updateData = cursor.value;
                                updateData.intensidadLeft = stado.intensidadLeft
                                cursor.update(updateData)
                                return cursor.continue().then(logItems);
                            });
                        }
                    })
                }
            })
        }else{
            Object.entries(this.state.estadoAnt.intensidadRight).forEach(([key, value]) => {
                this.setState(prevState => ({estadoAnt: {
                    ...prevState.estadoAnt,
                    [intensidadF]:{
                        ...prevState.estadoAnt[intensidadF],
                        [key]: descuento*(value)
                    }
                }}), () => {
                    if(dbPromise){
                        const stado = this.state.estadoAnt
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData[intensidadF] = stado[intensidadF]
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
                if(parseInt(this.state.estadoAnt.toleranciasSelR.valor) === porciento){
                    this.setState(prevState => ({estadoAnt: {
                        ...prevState.estadoAnt,
                        intensidadRight:{
                            ...prevState.estadoAnt.intensidadRight,
                            [key]: descuento*(value)
                        }
                    }}), () => {
                        if(dbPromise){
                            const stado = this.state.estadoAnt
                            dbPromise.then(function(db) {
                                var tx = db.transaction('logs', 'readwrite');
                                var store = tx.objectStore('logs');
                                return store.openCursor(IDBKeyRange.only(id));
                            }).then(function logItems(cursor) {
                                if (!cursor) {
                                    return;
                                }
                                const updateData = cursor.value;
                                updateData.intensidadRight = stado.intensidadRight
                                cursor.update(updateData)
                                return cursor.continue().then(logItems);
                            });
                        }
                    })
                }
            })
        }
    }
    porcentajes2(porciento, total, formula, nombre, nombreF, intensidadF, tipo){
        const descuento = (1-(1*(porciento/100)))
        let formu = formula.replace('It = ', '')
        if(porciento !== 0){
            formu = 'It = '+descuento+ ' * ['+formu+']'
        }else{
            formu = formula
        }
        const calculo = descuento*(total)

        this.setState(prevState => ({estadoAnt: {
            ...prevState.estadoAnt,
            [nombre]: Math.round((calculo + Number.EPSILON) * 100) / 100,
            [nombreF]: formu
        }}), () => {
            if(dbPromise){
                dbPromise.then(function(db) {
                    var tx = db.transaction('logs', 'readwrite');
                    var store = tx.objectStore('logs');
                    return store.openCursor(IDBKeyRange.only(id));
                }).then(function logItems(cursor) {
                    if (!cursor) {
                        return;
                    }
                    const updateData = cursor.value;
                    updateData[nombre] = Math.round((calculo + Number.EPSILON) * 100) / 100
                    updateData[nombreF] = formu
                    cursor.update(updateData)
                    return cursor.continue().then(logItems);
                });
            }
            
        })

        
        if(parseInt(this.state.estadoAnt.toleranciasSelL2.valor) === porciento){
            if(tipo === 'leftG2'){
                this.setState(prevState => ({estadoAnt: {
                    ...prevState.estadoAnt,
                    resultadoL2: Math.round((calculo + Number.EPSILON) * 100) / 100,
                    formulaL2: formu
                }}), () => {
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoL = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaL = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }
            /*else{
                this.setState({resultadoR: Math.round((calculo + Number.EPSILON) * 100) / 100, formulaR: formu}, () =>{
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoR = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaR = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }*/
        }else if(parseInt(this.state.estadoAnt.toleranciasSelR2.valor) === porciento){
            if(tipo === 'rightG2'){ 
                this.setState(prevState => ({estadoAnt: {
                    ...prevState.estadoAnt,
                    resultadoR2: Math.round((calculo + Number.EPSILON) * 100) / 100, 
                    formulaR2: formu
                }}), () =>{
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoR = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaR = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }
        }

        if(tipo === 'leftG2'){
            Object.entries(this.state.estadoAnt.intensidadLeft2).forEach(([key, value]) => {
                this.setState(prevState => ({estadoAnt: {
                    ...prevState.estadoAnt,
                    [intensidadF]:{
                        ...prevState.estadoAnt[intensidadF],
                        [key]: descuento*(value)
                    }
                }}), () => {
                    if(dbPromise){
                        const stado = this.state.estadoAnt
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData[intensidadF] = stado[intensidadF]
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
                if(parseInt(this.state.estadoAnt.toleranciasSelL2.valor) === porciento){
                    this.setState(prevState => ({estadoAnt: {
                        ...prevState.estadoAnt,
                        intensidadLeft2:{
                            ...prevState.estadoAnt.intensidadLeft2,
                            [key]: descuento*(value)
                        }
                    }}), () => {
                        if(dbPromise){
                            const stado = this.state.estadoAnt
                            dbPromise.then(function(db) {
                                var tx = db.transaction('logs', 'readwrite');
                                var store = tx.objectStore('logs');
                                return store.openCursor(IDBKeyRange.only(id));
                            }).then(function logItems(cursor) {
                                if (!cursor) {
                                    return;
                                }
                                const updateData = cursor.value;
                                updateData.intensidadLeft2 = stado.intensidadLeft2
                                cursor.update(updateData)
                                return cursor.continue().then(logItems);
                            });
                        }
                    })
                }
            })
        }else{
            Object.entries(this.state.estadoAnt.intensidadRight).forEach(([key, value]) => {
                this.setState(prevState => ({estadoAnt: {
                    ...prevState.estadoAnt,
                    [intensidadF]:{
                        ...prevState.estadoAnt[intensidadF],
                        [key]: descuento*(value)
                    }
                }}), () => {
                    if(dbPromise){
                        const stado = this.state.estadoAnt
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData[intensidadF] = stado[intensidadF]
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
                if(parseInt(this.state.estadoAnt.toleranciasSelR2.valor) === porciento){
                    this.setState(prevState => ({estadoAnt: {
                        ...prevState.estadoAnt,
                        intensidadRight2:{
                            ...prevState.estadoAnt.intensidadRight2,
                            [key]: descuento*(value)
                        }
                    }}), () => {
                        if(dbPromise){
                            const stado = this.state.estadoAnt
                            dbPromise.then(function(db) {
                                var tx = db.transaction('logs', 'readwrite');
                                var store = tx.objectStore('logs');
                                return store.openCursor(IDBKeyRange.only(id));
                            }).then(function logItems(cursor) {
                                if (!cursor) {
                                    return;
                                }
                                const updateData = cursor.value;
                                updateData.intensidadRight2 = stado.intensidadRight2
                                cursor.update(updateData)
                                return cursor.continue().then(logItems);
                            });
                        }
                    })
                }
            })
        }
    }

	formatear(date) {
        var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        var mesespt = [
		'Janeiro',
		'Fevereiro',
		'Março',
		'Abril',
		'Maio',
		'Junho',
		'Julho',
		'Agosto',
		'Setembro',
		'Outubro',
		'Novembro',
		'Dezembro']
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        let mesLetter = meses[month - 1] 
        if(i18next.language === 'pt'){
            mesLetter = mesespt[month - 1] 
        }else{
            mesLetter = meses[month - 1] 
        }
        
        return [day, mesLetter, year].join('-');
    }
    descargarImg(){
        
        /*
        html2canvas(document.querySelector("#root"),{
            onrendered: function (canvas) {
                var a = document.createElement('a');
                // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
                a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
                a.download = 'somefilename.jpg';
                a.click();
            }
        });
        */

        
        var svgElements = document.body.querySelectorAll('svg');
        svgElements.forEach(function(item) {
            item.setAttribute("width", item.getBoundingClientRect().width);
            item.setAttribute("height", item.getBoundingClientRect().height);
            item.style.width = null;
            item.style.height= null;
        });
        html2canvas(document.querySelector("#informeFinal"), {
            scrollX: 0,
            scrollY: -window.scrollY
        }).then(canvas => {
            //var a = document.createElement('a');
            // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
            /**
            a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
            a.download = 'somefilename.jpg';
            a.click();
            */
                
            const imgData = canvas.toDataURL("image/jpg")
            console.info(canvas.width, canvas.height)
            const pdf = new jsPDF({
                orientation: 'p', 
                unit: 'mm', 
                format: [((canvas.width+4)*0.2645833333), ((canvas.height-54)*0.2645833333)]
            });
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save("informe.pdf");
        });

    }
	

    render() {
        const {t} = this.props
        const iconProps = { iconName: 'Info' };
        //return(<></>)
        return (
            <>
		<div className="ms-Grid-row"  style={{display:'flex',justifyContent:'center'}}>
			<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg7 ms-xl5" id="informeFinal">
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label className="ms-fontSize-32 ms-fontWeight-regular"  style={{textAlign: 'center'}}>{t('report.title')}</Label>
                        <Separator></Separator>
                        <Label className="ms-fontSize-24 ms-fontWeight-regular">{t('interest.title')}</Label>
                        <Label className="ms-fontSize-14 ms-fontWeight-bold">{t('pathology.type.label')}: </Label>
                        <Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.patologiaSel?this.state.patologiaSel.text:''}</Label>
                        <Label className="ms-fontSize-14 ms-fontWeight-bold">{t('stimulation.zone.label')}: </Label>
                        <Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.zonestumulaSel?this.state.zonestumulaSel.text:''}</Label>
                        <Label className="ms-fontSize-14 ms-fontWeight-bold">{t('implant.type.label')}: </Label>
                        <Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.tipoImplanteSel?this.state.tipoImplanteSel.text:''}</Label>
						{this.state.tipoImplanteSel && this.state.tipoImplanteSel.key === 'uni' &&
						<>
							<Label className="ms-fontSize-14 ms-fontWeight-bold">{t('unilateral.implant.side.label')}: </Label>
							<Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.ladoImpUniSel.text}</Label>
						</>
						}
                    </div>
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        <Label className="ms-fontSize-14 ms-fontWeight-bold">{t('generator.model.label')}: </Label>
                        <Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.generadorSel ? this.state.generadorSel.text : ''}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.generadorSel ?
                        <img src={this.state.generadorSel.adaptadorimg} id="adaptador" alt="Adaptador" style={{width: '100%', height: '100%', maxWidth:375, maxHeight: 275}} />
                        : <></>}
                    </div>
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label className="ms-fontSize-14 ms-fontWeight-bold">{t('date.last.replacement.label')}: </Label>
                        <Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.fechaSel ? this.formatear(this.state.fechaSel) : 'Sin fecha seleccionada'}</Label>
						<Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.diferenciaFecha} </Label>
                    </div>
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Separator></Separator>
                        <Label className="ms-fontSize-24 ms-fontWeight-regular">{t('programmingant.text')}:</Label>
                        <Label className="ms-fontSize-14 ms-fontWeight-bold">{t('typeprogramming.label')}: </Label>
                        <Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.tipoProgramacionSel.text}</Label>
                    </div>
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
					{this.state.ladoImpUniSel.key !== 'right' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<Label className="ms-fontSize-20 ms-fontWeight-regular"><u>{t('left.text')}</u></Label>
						</div>
					}
					{this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<Label className="ms-fontSize-20 ms-fontWeight-regular"><u>{t('right.text')}</u></Label>
						</div>
					}
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
					{this.state.ladoImpUniSel.key !== 'right' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<Label className="ms-fontSize-14 ms-fontWeight-bold">{t('programador.label')}: </Label>
							<Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.left.programador ? this.state.left.programador === 'normal' ? t('normal') : this.state.left.programado :  ''}</Label>
						</div>
					}
					{this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<Label className="ms-fontSize-14 ms-fontWeight-bold">{t('programador.label')}: </Label>
							<Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.right.programador ? this.state.right.programador === 'normal' ? t('normal') : this.state.right.programado :  ''}</Label>
						</div>
					}
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
					{this.state.ladoImpUniSel.key !== 'right' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<Label className="ms-fontSize-14 ms-fontWeight-bold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')} </Label>
							<Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.left.amplitude ? this.state.left.amplitude + (this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V') :  ''}</Label>
						</div>
					}
					{this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<Label className="ms-fontSize-14 ms-fontWeight-bold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')} </Label>
							<Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.right.amplitude ? this.state.right.amplitude + (this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V') :  ''}</Label>
						</div>
					}
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
					{this.state.ladoImpUniSel.key !== 'right' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<Label className="ms-fontSize-14 ms-fontWeight-bold">{t('frequency.label')}: </Label>
							<Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.left.rate ? this.state.left.rate + ' Hz' :  ''}</Label>
						</div>
					}
					{this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<Label className="ms-fontSize-14 ms-fontWeight-bold">{t('frequency.label')}: </Label>
							<Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.right.rate ? this.state.right.rate + ' Hz' :  ''}</Label>
						</div>
					}
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
					{(this.state.ladoImpUniSel.key !== 'right' && this.state.left.programador !== 'interleaving') &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<Label className="ms-fontSize-14 ms-fontWeight-bold">{t('pulse.label')}: </Label>
							<Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.left.pulse ? this.state.left.pulse + ' µs' :  ''}</Label>
						</div>
					}
					{(this.state.ladoImpUniSel.key !== 'left' && this.state.right.programador !== 'interleaving') &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<Label className="ms-fontSize-14 ms-fontWeight-bold">{t('pulse.label')}: </Label>
							<Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.right.pulse ? this.state.right.pulse + ' µs' :  ''}</Label>
						</div>
					}
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label className="ms-fontSize-14 ms-fontWeight-bold">{t('contactsconf.text')}: </Label>
                    </div>
                </div>
                {(this.state.left.programador === 'interleaving') || (this.state.right.programador === 'interleaving') ?
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    {(this.state.ladoImpUniSel.key !== 'right' && this.state.left.programador === 'interleaving') &&
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <Label className="ms-fontSize-14 ms-fontWeight-bold">{t('pulse.label')}: </Label>
                            <Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.left.pulse ? this.state.left.pulse + ' µs' :  ''}</Label>
                        </div>
                    }
                    {(this.state.ladoImpUniSel.key !== 'left' && this.state.right.programador === 'interleaving') &&
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <Label className="ms-fontSize-14 ms-fontWeight-bold">{t('pulse.label')}: </Label>
                            <Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.right.pulse ? this.state.right.pulse + ' µs' :  ''}</Label>
                        </div>
                    }
                </div>
                : <></>}
                {/** CASE */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                        {this.state.ladoImpUniSel.key !== 'right' &&
					<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>IPG</span>
                                        <span className={'checkbox '+this.state.left.leads.lcase} title="lcase">
                                            { this.state.left.leads.lcase === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads.lcase === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        
                                    </div>
                                </div>
                        
					</div>}
                        {this.state.ladoImpUniSel.key !== 'left' &&
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>IPG</span>
                                        <span className={'checkbox '+this.state.right.leads.rcase} title="lcase">
                                            { this.state.right.leads.rcase === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads.rcase === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                </div>
                        
                    </div>}
                </div>
                {/** 3 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                        {this.state.ladoImpUniSel.key !== 'right' &&
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>3</span>
                                        {this.state.left.leads.l3 ? <span className={'checkbox '+this.state.left.leads.l3} title="lcase">
                                            {this.state.left.leads2.l3 && this.state.left.leads2.l3 === 'neutral' ? this.state.left.leads.l3 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads.l3 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            : <svg  width="10" height="10"></svg>
                                            }
                                        </span> : <></>}
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.left.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads2.l3 === 'neutral' ? this.state.left.leads.l3 !== 'neutral' ? this.state.configAnt.left.impedance.l3 + ' Ω': '' : '' }</span>
                                    }
                                    </div>
                                </div>
                        
                    </div>}
                        {this.state.ladoImpUniSel.key !== 'left' &&
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>3</span>
                                        <span className={'checkbox '+this.state.right.leads.r3} title="lcase">
                                            {this.state.right.leads2.r3 === 'neutral' ?  this.state.right.leads.r3 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads.r3 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.right.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads2.r3 === 'neutral' ? this.state.right.leads.r3 !== 'neutral' ? this.state.configAnt.right.impedance.r3 + ' Ω': '' : '' }</span>
                                    }
                                    </div>
                                </div>
                        
                    </div>}
                </div>
                {/** 2 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                        {this.state.ladoImpUniSel.key !== 'right' &&
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>2</span>
                                        <span className={'checkbox '+this.state.left.leads.l2} title="lcase">
                                            {this.state.left.leads2.l2 === 'neutral' ?  this.state.left.leads.l2 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads.l2 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.left.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads2.l2 === 'neutral' ? this.state.left.leads.l2 !== 'neutral' ? this.state.configAnt.left.impedance.l2 + ' Ω': '' : '' }</span>
                                    }
                                    </div>
                                </div>
                        
                    </div>}
                        {this.state.ladoImpUniSel.key !== 'left' &&
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>2</span>
                                        <span className={'checkbox '+this.state.right.leads.r1} title="lcase">
                                            {this.state.right.leads2.r2 === 'neutral' ?  this.state.right.leads.r2 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads.r2 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.right.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads2.r2 === 'neutral' ? this.state.right.leads.r2!== 'neutral' ? this.state.configAnt.right.impedance.r2 + ' Ω': '' : '' }</span>
                                    }
                                    </div>
                                </div>
                        
                    </div>}
                </div>
                {/** 1 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                        {this.state.ladoImpUniSel.key !== 'right' &&
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>1</span>
                                        <span className={'checkbox '+this.state.left.leads.l1} title="lcase">
                                            {this.state.left.leads2.l1 === 'neutral' ?  this.state.left.leads.l1 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads.l1 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.left.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads2.l1 === 'neutral' ? this.state.left.leads.l1 !== 'neutral' ? this.state.configAnt.left.impedance.l1 + ' Ω': '' : '' }</span>
                                    }
                                    </div>
                                </div>
                        
                    </div>}
                        {this.state.ladoImpUniSel.key !== 'left' &&
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>1</span>
                                        <span className={'checkbox '+this.state.right.leads.r1} title="lcase">
                                            {this.state.right.leads2.r1 === 'neutral' ?  this.state.right.leads.r1 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads.r1 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.right.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads2.r1 === 'neutral' ? this.state.right.leads.r1 !== 'neutral' ? this.state.configAnt.right.impedance.r1 + ' Ω': '' : '' }</span>
                                    }
                                    </div>
                                </div>
                        
                    </div>}
                </div>
                {/** 0 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                        {this.state.ladoImpUniSel.key !== 'right' &&
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>0</span>
                                        <span className={'checkbox '+this.state.left.leads.l0} title="lcase">
                                            {this.state.left.leads2.l0 === 'neutral' ?  this.state.left.leads.l0 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads.l0 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.left.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads2.l0 === 'neutral' ? this.state.left.leads.l0 !== 'neutral' ? this.state.configAnt.left.impedance.l0 + ' Ω': '' : '' }</span>
                                    }
                                    </div>
                                </div>
                        
                    </div>}
                        {this.state.ladoImpUniSel.key !== 'left' &&
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>0</span>
                                        <span className={'checkbox '+this.state.right.leads.r0} title="lcase">
                                            {this.state.right.leads2.r0 === 'neutral' ?  this.state.right.leads.r0 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads.r0 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.right.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads2.r0 === 'neutral' ? this.state.right.leads.r0 !== 'neutral' ? this.state.configAnt.right.impedance.r0 + ' Ω': '' : '' }</span>
                                    }
                                    </div>
                                </div>
                        
                    </div>}
                </div>
                {(this.state.left.programador === 'interleaving') || (this.state.right.programador === 'interleaving') ?
                <>
               
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    {(this.state.ladoImpUniSel.key !== 'right' && this.state.left.programador === 'interleaving') &&
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <Label className="ms-fontSize-14 ms-fontWeight-bold">{t('pulse.label')}: </Label>
                            <Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.left.pulse2 ? this.state.left.pulse2 + ' µs' :  ''}</Label>
                        </div>
                    }
                    {(this.state.ladoImpUniSel.key !== 'left' && this.state.right.programador === 'interleaving') &&
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <Label className="ms-fontSize-14 ms-fontWeight-bold">{t('pulse.label')}: </Label>
                            <Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.right.pulse2 ? this.state.right.pulse2 + ' µs' :  ''}</Label>
                        </div>
                    }
                </div>

                {/** CASE */}
                <div className="ms-Grid-row" style={{textAlign: 'left', marginTop:'15px'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {(this.state.ladoImpUniSel.key !== 'right' && (this.state.left.programador === 'interleaving')) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>IPG</span>
                                        <span className={'checkbox '+this.state.left.leads2.lcase} title="lcase">
                                            { this.state.left.leads2.lcase === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads2.lcase === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {(this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving')) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>IPG</span>
                                        <span className={'checkbox '+this.state.right.leads2.rcase} title="lcase">
                                            { this.state.right.leads2.rcase === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads2.rcase === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                {/** 3 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {(this.state.ladoImpUniSel.key !== 'right' && (this.state.left.programador === 'interleaving')) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>3</span>
                                        <span className={'checkbox '+this.state.left.leads2.l3} title="lcase">
                                            { this.state.left.leads2.l3 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads2.l3 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.left.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads2.l3 !== 'neutral' ? this.state.configAnt.left.impedance2.l3 + ' Ω': '' }</span>
                                    }
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {(this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving')) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>3</span>
                                        <span className={'checkbox '+this.state.right.leads2.r3} title="lcase">
                                            { this.state.right.leads2.r3 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads2.r3 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.right.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads2.r3 !== 'neutral' ? this.state.configAnt.right.impedance2.r3 + ' Ω': '' }</span>
                                    }
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                {/** 2 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {(this.state.ladoImpUniSel.key !== 'right' && (this.state.left.programador === 'interleaving')) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>2</span>
                                        <span className={'checkbox '+this.state.left.leads2.l2} title="lcase">
                                            { this.state.left.leads2.l2 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads2.l2 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.left.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads2.l2 !== 'neutral' ? this.state.configAnt.left.impedance2.l2 + ' Ω': '' }</span>
                                    }
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {(this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving')) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>2</span>
                                        <span className={'checkbox '+this.state.right.leads2.r1} title="lcase">
                                            { this.state.right.leads2.r2 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads2.r2 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.right.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads2.r2!== 'neutral' ? this.state.configAnt.right.impedance2.r2 + ' Ω': '' }</span>
                                    }
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                {/** 1 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {(this.state.ladoImpUniSel.key !== 'right' && (this.state.left.programador === 'interleaving')) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>1</span>
                                        <span className={'checkbox '+this.state.left.leads2.l1} title="lcase">
                                            { this.state.left.leads2.l1 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads2.l1 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.left.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads2.l1 !== 'neutral' ? this.state.configAnt.left.impedance2.l1 + ' Ω': '' }</span>
                                    }
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {(this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving')) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>1</span>
                                        <span className={'checkbox '+this.state.right.leads2.r1} title="lcase">
                                            { this.state.right.leads2.r1 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads2.r1 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.right.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads2.r1 !== 'neutral' ? this.state.configAnt.right.impedance2.r1 + ' Ω': '' }</span>
                                    }
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                {/** 0 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {(this.state.ladoImpUniSel.key !== 'right' && (this.state.left.programador === 'interleaving')) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>0</span>
                                        <span className={'checkbox '+this.state.left.leads2.l0} title="lcase">
                                            { this.state.left.leads2.l0 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads2.l0 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.left.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads2.l0 !== 'neutral' ? this.state.configAnt.left.impedance2.l0 + ' Ω': '' }</span>
                                    }
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {(this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving')) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>0</span>
                                        <span className={'checkbox '+this.state.right.leads2.r0} title="lcase">
                                            { this.state.right.leads2.r0 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads2.r0 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {this.state.configAnt && this.state.configAnt.right.medirImpedancias ? <></> :
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads2.r0 !== 'neutral' ? this.state.configAnt.right.impedance2.r0 + ' Ω': '' }</span>
                                    }
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                </>
                : <></>
                }
                {/** DATOS ANTERIORES SVG */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                     <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                         <Separator></Separator>
                         <Label className="ms-fontSize-24 ms-fontWeight-regular" >
                            {t('finalcoversionmdt.text')}
                            <IconButton
                                title="Info"
                                ariaLabel="Info"
                                id={'infoPrinci'}
                                onClick={this.visibleCalloutInfoPrinci}
                                iconProps={iconProps}
                            />
                        </Label>
                        {this.state.isCalloutVisibleInfoPrinci && (
                            <Callout
                            target={'#infoPrinci'}
                            setInitialFocus
                            ariaDescribedBy={'Teat'}
                            role="alertdialog"
                            style={{padding: '3%'}}
                            >
                                <Stack horizontalAlign="start" >
                                    <span>{t('pFin.alert')}</span>
                                    <br></br>
                                    <DefaultButton onClick={this.invisibleCalloutInfoPrinci}>{t('p2.btn')}</DefaultButton>
                                </Stack>
                            </Callout>
                        )}
                     </div>
                 </div>
                 <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    {this.state.ladoImpUniSel && this.state.ladoImpUniSel.key !== 'right' &&
                        /** */
                        
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <Label className="ms-fontSize-20 ms-fontWeight-regular"><u>{t('left.text')}</u></Label>
                            <br></br>
                        {this.state.configAnt && this.state.configAnt.left.medirImpedancias ? <div className="ms-Grid-row "><div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>{t('sinmedidas.text')}</div></div> :
                            <>
                            <div className="ms-Grid-row ">
                                {
                                    this.state.tipoProgramacionSel !== 'ma'
                                    ?
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('stream.label')}:</Label></div>
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={this.state.estadoAnt.resultadoL} /></div>
                                            </div>
                                        </div>
                                    : <></>
                                }
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={this.state.estadoAnt.left.amplitude} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.estadoAnt.left.rateNew ? this.state.estadoAnt.left.rateNew : this.state.estadoAnt.left.rate} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.estadoAnt.left.pulse} /></div>
                                    </div>
                                </div>
                            </div>
                            <Separator></Separator>
							<svg version="1.1" id="Layer_1" width="100%" height="650px" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="25 0 130.49 471.64">
                                <g id="Polo_1">
                                    { this.state.estadoAnt.left.leads.l0 === 'negative' &&
                                        <g id="Polo_1_negativo">
                                            <g id="Fondo_negativo_7_">
                                                <path className="st0" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.estadoAnt.graficoSelL === 'mA'
                                            ? this.state.estadoAnt.intensidadLeft.l0
                                                ? (Math.round((this.state.estadoAnt.intensidadLeft.l0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.estadoAnt.intensidadLeftPorcent.l0
                                                ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent.l0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_negativo_7_" className="st1" d="M109.81,441.65c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86 c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,439.79,109.81,440.62,109.81,441.65z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads.l0 === 'positive' &&
                                        <g id="Polo_1_positivo" className="st2">
                                            <g id="Fondo_positivo_7_" className="st3">
                                                <path className="st4" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.estadoAnt.graficoSelL === 'mA'
                                            ? this.state.estadoAnt.intensidadLeft.l0
                                                ? (Math.round((this.state.estadoAnt.intensidadLeft.l0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.estadoAnt.intensidadLeftPorcent.l0
                                                ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent.l0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_positivo_7_" className="st1" d="M108.76,440.16h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,440.89,109.34,440.16,108.76,440.16z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads.l0 === 'neutral' &&
                                        <g id="Polo_1_off" className="st2">
                                            <path className="st6" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                            <path className="st5 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 434.3762)" className="st1 st7 st8">1</text>
                                    <g id="Tapa_14_">
                                        <ellipse className="st9" cx="102.8" cy="418.83" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,412.48c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,414.66,89.74,412.48,102.8,412.48 M102.8,411.48c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,411.48,102.8,411.48L102.8,411.48z"/>
                                    </g>
                                </g>
                                <g id="Polo_2">
                                    { this.state.estadoAnt.left.leads.l1 === 'negative' &&
                                        <g id="Polo_2_negativo">
                                            <g id="Fondo_negativo_6_">
                                                <path className="st0" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.estadoAnt.graficoSelL === 'mA'
                                            ? this.state.estadoAnt.intensidadLeft.l1
                                                ? (Math.round((this.state.estadoAnt.intensidadLeft.l1 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.estadoAnt.intensidadLeftPorcent.l1
                                                ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent.l1 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_negativo_6_" className="st1" d="M109.81,386.6c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,384.74,109.81,385.58,109.81,386.6z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads.l1 === 'positive' &&
                                        <g id="Polo_2_positivo" className="st2">
                                            <g id="Fondo_positivo_6_" className="st3">
                                                <path className="st4" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.estadoAnt.graficoSelL === 'mA'
                                            ? this.state.estadoAnt.intensidadLeft.l1
                                                ? (Math.round((this.state.estadoAnt.intensidadLeft.l1 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.estadoAnt.intensidadLeftPorcent.l1
                                                ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent.l1 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_positivo_6_" className="st1" d="M108.76,385.12h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,385.84,109.34,385.12,108.76,385.12z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads.l1 === 'neutral' &&
                                        <g id="Polo_2_off" className="st2">
                                            <path className="st6" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                            <path className="st5 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 379.3322)" className="st1 st7 st8">2</text>
                                    <g id="Tapa_12_">
                                        <path className="st9" d="M102.8,370.64c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,368.5,115.7,370.64,102.8,370.64z"/>
                                        <path className="st1 strokeBlack" d="M102.8,357.44c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,357.44,102.8,357.44 M102.8,356.44c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,356.44,102.8,356.44L102.8,356.44z"/>
                                    </g>
                                </g>
                                <g id="Polo_3">
                                    { this.state.estadoAnt.left.leads.l2 === 'negative' &&
                                        <g id="Polo_3_negativo" className="st2">
                                            <g id="Fondo_negativo_5_" className="st3">
                                                <path className="st0" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelL === 'mA'
                                                ? this.state.estadoAnt.intensidadLeft.l2
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeft.l2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadLeftPorcent.l2
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent.l2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_5_" className="st1" d="M109.81,331.56c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,329.7,109.81,330.53,109.81,331.56z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads.l2 === 'positive' &&
                                        <g id="Polo_3_positivo">
                                            <g id="Fondo_positivo_5_">
                                                <path className="st4" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelL === 'mA'
                                                ? this.state.intensidadLeft.l2
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeft.l2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadLeftPorcent.l2
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent.l2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_5_" className="st1" d="M108.76,330.07h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,330.8,109.34,330.07,108.76,330.07z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads.l2 === 'neutral' &&
                                        <g id="Polo_3_off" className="st2">
                                            <path className="st6" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                            <path className="st5 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 324.2883)" className="st1 st7 st8">3</text>
                                    <g id="Tapa_10_">
                                        <path className="st9" d="M102.8,315.6c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,313.45,115.7,315.6,102.8,315.6z"/>
                                        <path className="st1 strokeBlack" d="M102.8,302.39c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,304.57,89.74,302.39,102.8,302.39 M102.8,301.39c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,301.39,102.8,301.39L102.8,301.39z"/>
                                    </g>
                                </g>
                                <g id="Polo_4">
                                    { this.state.estadoAnt.left.leads.l3 === 'negative' &&
                                        <g id="Polo_4_negativo" className="st2">
                                            <g id="Fondo_negativo_4_" className="st3">
                                                <path className="st0" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelL === 'mA'
                                                ? this.state.estadoAnt.intensidadLeft.l3
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeft.l3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadLeftPorcent.l3
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent.l3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_4_" className="st1" d="M109.81,276.52c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,274.65,109.81,275.49,109.81,276.52z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads.l3 === 'positive' &&
                                        <g id="Polo_4_positivo" className="st2">
                                            <g id="Fondo_positivo_4_" className="st3">
                                                <path className="st4" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelL === 'mA'
                                                ? this.state.estadoAnt.intensidadLeft.l3
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeft.l3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadLeftPorcent.l3
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent.l3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_4_" className="st1" d="M108.76,275.03h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,275.75,109.34,275.03,108.76,275.03z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads.l3 === 'neutral' &&
                                        <g id="Polo_4_off">
                                            <path className="st10" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                            <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"/>
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 269.2434)" className="st1 st7 st8">4</text>
                                    <g id="Tapa_8_">
                                        <path className="st9" d="M102.8,260.55c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,258.41,115.7,260.55,102.8,260.55z"/>
                                        <path className="st1 strokeBlack" d="M102.8,247.35c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,249.53,89.74,247.35,102.8,247.35 M102.8,246.35c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,246.35,102.8,246.35L102.8,246.35z"/>
                                    </g>
                                </g>
                                <g id="Polo_5">
                                    <g id="Polo_5_negativo" className="st2">
                                        <g id="Fondo_negativo_3_" className="st3">
                                            <path className="st0" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_3_" className="st5" d="M109.81,226.21c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,224.35,109.81,225.18,109.81,226.21z"/>
                                    </g>
                                    <g id="Polo_5_positivo" className="st2">
                                        <g id="Fondo_positivo_3_" className="st3">
                                            <path className="st4" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_3_" className="st5" d="M108.76,224.72h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,225.45,109.34,224.72,108.76,224.72z"/>
                                    </g>
                                    <g id="Polo_5_off">
                                        <path className="st10" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                        <path className="st1 strokeBlack" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 214.1994)" className="st1 st7 st8">5</text>
                                    <g id="Tapa_6_">
                                        <path className="st9" d="M102.8,205.51c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,203.36,115.7,205.51,102.8,205.51z"/>
                                        <path className="st1 strokeBlack" d="M102.8,192.31c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,192.31,102.8,192.31 M102.8,191.31c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,191.31,102.8,191.31L102.8,191.31z"/>
                                    </g>
                                </g>
                                <g id="Polo_6">
                                    <g id="Polo_6_negativo" className="st2">
                                        <g id="Fondo_negativo_2_" className="st3">
                                            <path className="st0" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_2_" className="st5" d="M109.81,171.16c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,169.3,109.81,170.14,109.81,171.16z"/>
                                    </g>
                                    <g id="Polo_6_positivo" className="st2">
                                        <g id="Fondo_positivo_2_" className="st3">
                                            <path className="st4" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_2_" className="st5" d="M108.76,169.68h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,170.4,109.34,169.68,108.76,169.68z"/>
                                    </g>
                                    <g id="Polo_6_off">
                                        <path className="st10" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                        <path className="st1 strokeBlack" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 159.156)" className="st1 st7 st8">6</text>
                                    <g id="Tapa_1_">
                                        <path className="st9" d="M102.8,150.46c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,148.32,115.7,150.46,102.8,150.46z"/>
                                        <path className="st1 strokeBlack" d="M102.8,137.26c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,139.44,89.74,137.26,102.8,137.26 M102.8,136.26c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,136.47,105.59,136.26,102.8,136.26L102.8,136.26z"/>
                                    </g>
                                </g>
                                <g id="Polo_7">
                                    <g id="Polo_7_negativo" className="st2">
                                        <g id="Fondo_negativo_1_" className="st3">
                                            <path className="st0" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_negativo_1_" className="st5" d="M109.81,116.12c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,114.26,109.81,115.09,109.81,116.12z"/>
                                    </g>
                                    <g id="Polo_7_positivo" className="st2">
                                        <g id="Fondo_positivo_1_" className="st3">
                                            <path className="st4" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_positivo_1_" className="st5" d="M108.76,114.63h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,115.36,109.34,114.63,108.76,114.63z"/>
                                    </g>
                                    <g id="Polo_7_off">
                                        <path className="st10" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                        <path className="st1 strokeBlack" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 104.1111)" className="st1 st7 st8">7</text>
                                    <g id="Tapa_4_">
                                        <path className="st9" d="M102.8,95.42c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,93.28,115.7,95.42,102.8,95.42z"/>
                                        <path className="st1 strokeBlack" d="M102.8,82.22c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,84.4,89.74,82.22,102.8,82.22 M102.8,81.22c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,81.42,105.59,81.22,102.8,81.22L102.8,81.22z"/>
                                    </g>
                                </g>
                                <g id="Polo_8">
                                    <g id="Polo_8_negativo" className="st2">
                                        <g id="Fondo_negativo" className="st3">
                                            <path className="st0" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_negativo" className="st5" d="M109.81,61.08c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,59.21,109.81,60.05,109.81,61.08z"/>
                                    </g>
                                    <g id="Polo_8_positivo" className="st2">
                                        <g id="Fondo_positivo" className="st3">
                                            <path className="st4" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_positivo" className="st5" d="M108.76,59.59h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74
                                            h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04
                                            v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,60.31,109.34,59.59,108.76,59.59z"/>
                                    </g>
                                    <g id="Polo_8_off">
                                        <path className="st10" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                        <path className="st1 strokeBlack" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 49.0671)" className="st1 st7 st8">8</text>
                                    <g id="Tapa">
                                        <ellipse className="st9" cx="102.8" cy="33.52" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,27.17c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,27.17,102.8,27.17 M102.8,26.17c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,26.17,102.8,26.17L102.8,26.17z"/>
                                    </g>
                                </g>
                                <g id="Carcasa">
                                    <line className="st11 strokeBlack" x1="41.4" y1="10.25" x2="103.14" y2="10.25"/>
                                    <line className="st11 strokeBlack" x1="102.8" y1="27.17" x2="102.8" y2="10.25"/>
                                    {this.state.estadoAnt.left.leads.lcase === 'positive' &&
                                        <>
                                            <path id="Carcasa_positivo" className="st12" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                            <path id="Icono_positivo_8_" className="st1 strokeBlack" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                        </>
                                    }
                                    {this.state.estadoAnt.left.leads.lcase === 'neutral' &&
                                        <path id="Carcasa_off" className="st13 strokeBlack" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                    }
                                    <path id="Tapa_2_" className="st14 strokeBlack" d="M2.7,16.54c-1.22-20.84,39.46-20.84,39.45,0C23.01,16.54,2.7,16.54,2.7,16.54z"/>
                                    <text transform="matrix(1 0 0 1 14.4977 12.9934)" className="st1 st7 st8">IPG</text>
                                </g>
                            </svg>
                            </>
                        }
						</div>
					}
					{this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <Label className="ms-fontSize-20 ms-fontWeight-regular"><u>{t('right.text')}</u></Label>
                            <br></br>
                            {this.state.configAnt && this.state.configAnt.right.medirImpedancias ? <div className="ms-Grid-row "><div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>{t('sinmedidas.text')}</div></div> :
                            <><div className="ms-Grid-row">
                                {
                                    this.state.tipoProgramacionSel !== 'ma'
                                    ?
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('stream.label')}:</Label></div>
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={this.state.estadoAnt.resultadoR} /></div>
                                            </div>
                                        </div>
                                    : <></>
                                }
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={this.state.estadoAnt.right.amplitude} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.estadoAnt.right.rateNew ? this.state.estadoAnt.right.rateNew : this.state.estadoAnt.right.rate} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.estadoAnt.right.pulse} /></div>
                                    </div>
                                </div>
                            </div>
                            <Separator></Separator>
							<svg version="1.1" id="Layer_1" width="100%" height="650px" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="25 0 130.49 471.64">
                                <g id="Polo_1">
                                    { this.state.estadoAnt.right.leads.r0 === 'negative' &&
                                        <g id="Polo_1_negativo">
                                            <g id="Fondo_negativo_7_">
                                                <path className="st0" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.estadoAnt.graficoSelL === 'mA'
                                            ? this.state.estadoAnt.intensidadRight.r0
                                                ? (Math.round((this.state.estadoAnt.intensidadRight.r0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.estadoAnt.intensidadRightPorcent.r0
                                                ? (Math.round((this.state.estadoAnt.intensidadRightPorcent.r0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_negativo_7_" className="st1" d="M109.81,441.65c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86 c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,439.79,109.81,440.62,109.81,441.65z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads.r0 === 'positive' &&
                                        <g id="Polo_1_positivo" className="st2">
                                            <g id="Fondo_positivo_7_" className="st3">
                                                <path className="st4" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.estadoAnt.graficoSelR === 'mA'
                                            ? this.state.estadoAnt.intensidadRight.r0
                                                ? (Math.round((this.state.estadoAnt.intensidadRight.r0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.estadoAnt.intensidadRightPorcent.r0
                                                ? (Math.round((this.state.estadoAnt.intensidadRightPorcent.r0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_positivo_7_" className="st1" d="M108.76,440.16h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,440.89,109.34,440.16,108.76,440.16z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads.r0 === 'neutral' &&
                                        <g id="Polo_1_off" className="st2">
                                            <path className="st6" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                            <path className="st5 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 434.3762)" className="st1 st7 st8">1</text>
                                    <g id="Tapa_14_">
                                        <ellipse className="st9" cx="102.8" cy="418.83" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,412.48c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,414.66,89.74,412.48,102.8,412.48 M102.8,411.48c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,411.48,102.8,411.48L102.8,411.48z"/>
                                    </g>
                                </g>
                                <g id="Polo_2">
                                    { this.state.estadoAnt.right.leads.r1 === 'negative' &&
                                        <g id="Polo_2_negativo">
                                            <g id="Fondo_negativo_6_">
                                                <path className="st0" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelR === 'mA'
                                                ? this.state.estadoAnt.intensidadRight.r1
                                                    ? (Math.round((this.state.estadoAnt.intensidadRight.r1 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadRightPorcent.r1
                                                    ? (Math.round((this.state.estadoAnt.intensidadRightPorcent.r1 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_6_" className="st1" d="M109.81,386.6c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,384.74,109.81,385.58,109.81,386.6z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads.r1 === 'positive' &&
                                        <g id="Polo_2_positivo" className="st2">
                                            <g id="Fondo_positivo_6_" className="st3">
                                                <path className="st4" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelR === 'mA'
                                                ? this.state.estadoAnt.intensidadRight.r1
                                                    ? (Math.round((this.state.estadoAnt.intensidadRight.r1 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadRightPorcent.r1
                                                    ? (Math.round((this.state.estadoAnt.intensidadRightPorcent.r1 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_6_" className="st1" d="M108.76,385.12h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,385.84,109.34,385.12,108.76,385.12z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads.r1 === 'neutral' &&
                                        <g id="Polo_2_off" className="st2">
                                            <path className="st6" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                            <path className="st5 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 379.3322)" className="st1 st7 st8">2</text>
                                    <g id="Tapa_12_">
                                        <path className="st9" d="M102.8,370.64c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,368.5,115.7,370.64,102.8,370.64z"/>
                                        <path className="st1 strokeBlack" d="M102.8,357.44c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,357.44,102.8,357.44 M102.8,356.44c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,356.44,102.8,356.44L102.8,356.44z"/>
                                    </g>
                                </g>
                                <g id="Polo_3">
                                    { this.state.estadoAnt.right.leads.r2 === 'negative' &&
                                        <g id="Polo_3_negativo" className="st2">
                                            <g id="Fondo_negativo_5_" className="st3">
                                                <path className="st0" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelR === 'mA'
                                                ? this.state.estadoAnt.intensidadRight.r2
                                                    ? (Math.round((this.state.estadoAnt.intensidadRight.r2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadRightPorcent.r2
                                                    ? (Math.round((this.state.estadoAnt.intensidadRightPorcent.r2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_5_" className="st1" d="M109.81,331.56c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,329.7,109.81,330.53,109.81,331.56z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads.r2 === 'positive' &&
                                        <g id="Polo_3_positivo">
                                            <g id="Fondo_positivo_5_">
                                                <path className="st4" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelR === 'mA'
                                                ? this.state.estadoAnt.intensidadRight.r2
                                                    ? (Math.round((this.state.estadoAnt.intensidadRight.r2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadRightPorcent.r2
                                                    ? (Math.round((this.state.estadoAnt.intensidadRightPorcent.r2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_5_" className="st1" d="M108.76,330.07h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,330.8,109.34,330.07,108.76,330.07z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads.r2 === 'neutral' &&
                                        <g id="Polo_3_off" className="st2">
                                            <path className="st6" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                            <path className="st5 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 324.2883)" className="st1 st7 st8">3</text>
                                    <g id="Tapa_10_">
                                        <path className="st9" d="M102.8,315.6c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,313.45,115.7,315.6,102.8,315.6z"/>
                                        <path className="st1 strokeBlack" d="M102.8,302.39c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,304.57,89.74,302.39,102.8,302.39 M102.8,301.39c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,301.39,102.8,301.39L102.8,301.39z"/>
                                    </g>
                                </g>
                                <g id="Polo_4">
                                    { this.state.estadoAnt.right.leads.r3 === 'negative' &&
                                        <g id="Polo_4_negativo" className="st2">
                                            <g id="Fondo_negativo_4_" className="st3">
                                                <path className="st0" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelR === 'mA'
                                                ? this.state.estadoAnt.intensidadRight.r3
                                                    ? (Math.round((this.state.estadoAnt.intensidadRight.r3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadRightPorcent.r3
                                                    ? (Math.round((this.state.estadoAnt.intensidadRightPorcent.r3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_4_" className="st1" d="M109.81,276.52c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,274.65,109.81,275.49,109.81,276.52z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads.r3 === 'positive' &&
                                        <g id="Polo_4_positivo" className="st2">
                                            <g id="Fondo_positivo_4_" className="st3">
                                                <path className="st4" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelR === 'mA'
                                                ? this.state.estadoAnt.intensidadRight.r3
                                                    ? (Math.round((this.state.estadoAnt.intensidadRight.r3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadRightPorcent.r3
                                                    ? (Math.round((this.state.estadoAnt.intensidadRightPorcent.r3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_4_" className="st1" d="M108.76,275.03h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,275.75,109.34,275.03,108.76,275.03z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads.r3 === 'neutral' &&
                                        <g id="Polo_4_off">
                                            <path className="st10" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                            <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"/>
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 269.2434)" className="st1 st7 st8">4</text>
                                    <g id="Tapa_8_">
                                        <path className="st9" d="M102.8,260.55c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,258.41,115.7,260.55,102.8,260.55z"/>
                                        <path className="st1 strokeBlack" d="M102.8,247.35c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,249.53,89.74,247.35,102.8,247.35 M102.8,246.35c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,246.35,102.8,246.35L102.8,246.35z"/>
                                    </g>
                                </g>
                                <g id="Polo_5">
                                    <g id="Polo_5_negativo" className="st2">
                                        <g id="Fondo_negativo_3_" className="st3">
                                            <path className="st0" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_3_" className="st5" d="M109.81,226.21c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,224.35,109.81,225.18,109.81,226.21z"/>
                                    </g>
                                    <g id="Polo_5_positivo" className="st2">
                                        <g id="Fondo_positivo_3_" className="st3">
                                            <path className="st4" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_3_" className="st5" d="M108.76,224.72h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,225.45,109.34,224.72,108.76,224.72z"/>
                                    </g>
                                    <g id="Polo_5_off">
                                        <path className="st10" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                        <path className="st1 strokeBlack" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 214.1994)" className="st1 st7 st8">5</text>
                                    <g id="Tapa_6_">
                                        <path className="st9" d="M102.8,205.51c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,203.36,115.7,205.51,102.8,205.51z"/>
                                        <path className="st1 strokeBlack" d="M102.8,192.31c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,192.31,102.8,192.31 M102.8,191.31c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,191.31,102.8,191.31L102.8,191.31z"/>
                                    </g>
                                </g>
                                <g id="Polo_6">
                                    <g id="Polo_6_negativo" className="st2">
                                        <g id="Fondo_negativo_2_" className="st3">
                                            <path className="st0" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_2_" className="st5" d="M109.81,171.16c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,169.3,109.81,170.14,109.81,171.16z"/>
                                    </g>
                                    <g id="Polo_6_positivo" className="st2">
                                        <g id="Fondo_positivo_2_" className="st3">
                                            <path className="st4" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_2_" className="st5" d="M108.76,169.68h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,170.4,109.34,169.68,108.76,169.68z"/>
                                    </g>
                                    <g id="Polo_6_off">
                                        <path className="st10" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                        <path className="st1 strokeBlack" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 159.156)" className="st1 st7 st8">6</text>
                                    <g id="Tapa_1_">
                                        <path className="st9" d="M102.8,150.46c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,148.32,115.7,150.46,102.8,150.46z"/>
                                        <path className="st1 strokeBlack" d="M102.8,137.26c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,139.44,89.74,137.26,102.8,137.26 M102.8,136.26c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,136.47,105.59,136.26,102.8,136.26L102.8,136.26z"/>
                                    </g>
                                </g>
                                <g id="Polo_7">
                                    <g id="Polo_7_negativo" className="st2">
                                        <g id="Fondo_negativo_1_" className="st3">
                                            <path className="st0" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_negativo_1_" className="st5" d="M109.81,116.12c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,114.26,109.81,115.09,109.81,116.12z"/>
                                    </g>
                                    <g id="Polo_7_positivo" className="st2">
                                        <g id="Fondo_positivo_1_" className="st3">
                                            <path className="st4" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_positivo_1_" className="st5" d="M108.76,114.63h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,115.36,109.34,114.63,108.76,114.63z"/>
                                    </g>
                                    <g id="Polo_7_off">
                                        <path className="st10" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                        <path className="st1 strokeBlack" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 104.1111)" className="st1 st7 st8">7</text>
                                    <g id="Tapa_4_">
                                        <path className="st9" d="M102.8,95.42c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,93.28,115.7,95.42,102.8,95.42z"/>
                                        <path className="st1 strokeBlack" d="M102.8,82.22c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,84.4,89.74,82.22,102.8,82.22 M102.8,81.22c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,81.42,105.59,81.22,102.8,81.22L102.8,81.22z"/>
                                    </g>
                                </g>
                                <g id="Polo_8">
                                    <g id="Polo_8_negativo" className="st2">
                                        <g id="Fondo_negativo" className="st3">
                                            <path className="st0" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_negativo" className="st5" d="M109.81,61.08c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,59.21,109.81,60.05,109.81,61.08z"/>
                                    </g>
                                    <g id="Polo_8_positivo" className="st2">
                                        <g id="Fondo_positivo" className="st3">
                                            <path className="st4" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_positivo" className="st5" d="M108.76,59.59h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74
                                            h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04
                                            v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,60.31,109.34,59.59,108.76,59.59z"/>
                                    </g>
                                    <g id="Polo_8_off">
                                        <path className="st10" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                        <path className="st1 strokeBlack" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 49.0671)" className="st1 st7 st8">8</text>
                                    <g id="Tapa">
                                        <ellipse className="st9" cx="102.8" cy="33.52" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,27.17c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,27.17,102.8,27.17 M102.8,26.17c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,26.17,102.8,26.17L102.8,26.17z"/>
                                    </g>
                                </g>
                                <g id="Carcasa">
                                    <line className="st11 strokeBlack" x1="41.4" y1="10.25" x2="103.14" y2="10.25"/>
                                    <line className="st11 strokeBlack" x1="102.8" y1="27.17" x2="102.8" y2="10.25"/>
                                    {this.state.estadoAnt.right.leads.rcase === 'positive' &&
                                        <>
                                            <path id="Carcasa_positivo" className="st12 strokeBlack" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                            <path id="Icono_positivo_8_" className="st1" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                        </>
                                    }
                                    {this.state.estadoAnt.right.leads.rcase === 'neutral' &&
                                        <path id="Carcasa_off" className="st13 strokeBlack" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                    }
                                    <path id="Tapa_2_" className="st14 strokeBlack" d="M2.7,16.54c-1.22-20.84,39.46-20.84,39.45,0C23.01,16.54,2.7,16.54,2.7,16.54z"/>
                                    <text transform="matrix(1 0 0 1 14.4977 12.9934)" className="st1 st7 st8">IPG</text>
                                </g>
                            </svg>
                            </>}
						</div>
					}                                
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
					{this.state.ladoImpUniSel.key !== 'right' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            {this.state.configAnt && this.state.configAnt.left.medirImpedancias ? <></> :
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
								<Label className="ms-fontSize-16 ms-fontWeight-bold">{t('equivalentamplitude.text')}: <span className="ms-fontWeight-regular">{this.state.estadoAnt.resultadoL} mA</span></Label>
								<Label>{t('appliedformula.text')}: {this.state.estadoAnt.formulaL}</Label>
							</div>
                            }
						</div>
					}
					{this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            {this.state.configAnt && this.state.configAnt.left.medirImpedancias ? <></> :
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
								<Label className="ms-fontSize-16 ms-fontWeight-bold">{t('equivalentamplitude.text')}: <span className="ms-fontWeight-regular">{this.state.estadoAnt.resultadoR} mA</span></Label>
								<Label>{t('appliedformula.text')}: {this.state.estadoAnt.formulaR}</Label>
							</div>
                            }
						</div>
					}
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    {this.state.ladoImpUniSel.key !== 'right' &&
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            {this.state.configAnt && this.state.configAnt.left.medirImpedancias ? <></> :
                            <div>
                                <Fragment>
                                    <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                        <g id="Resistencias_positivas">
                                            { this.state.estadoAnt.contPL === 4 &&
                                                <g id="R5_positivo">
                                                    <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>
                                            }
                                            { this.state.estadoAnt.contPL >= 2 &&
                                                <g id="R4_positivo">
                                                    <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43 " className="strokeBlack"/>
                                                </g>
                                            }
                                            { this.state.estadoAnt.contPL === 1 || this.state.estadoAnt.contPL === 3 ?
                                                <g id="R3_positivo">
                                                    <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                </g>
                                                : ''
                                            }
                                            
                                            { this.state.estadoAnt.contPL >= 2 &&
                                                <g id="R2_positivo">
                                                    <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>
                                            }

                                            { this.state.estadoAnt.contPL === 4 &&
                                                <g id="R1_positivo">
                                                    <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>
                                            }

                                            { this.state.estadoAnt.contPL < 1 &&
                                                <>
                                                    <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlack"/>
                                                </>
                                            }
                                            <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
    h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlack"/>
                                            
                                        </g>
                                        <g id="Resistencias_negativas">
                                            {this.state.estadoAnt.contNL === 4 &&
                                                <g id="R5_negativo">
                                                <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43" className="strokeBlack"/>
                                                <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlack"/>
                                            </g>}
                                            {this.state.estadoAnt.contNL >= 2 &&
                                                <g id="R4_negativo">
                                                    <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                            </g>}
                                            {this.state.estadoAnt.contNL === 1 || this.state.estadoAnt.contNL === 3 ?
                                                <g id="R3_negativo">
                                                <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                            </g>:''}
                                            {this.state.estadoAnt.contNL >= 2 &&
                                                <g id="R2_negativo">
                                                    <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                            </g>}
                                            {this.state.estadoAnt.contNL === 4 &&
                                                <g id="R1_negativo">
                                                    <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                                <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43"  className="strokeBlack"/>
                                            </g>}
                                            { this.state.estadoAnt.contNL < 1 &&
                                                <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlack"/>
                                            }
                                            <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
    c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlack"/>
                                            
                                        </g>
                                        <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlack"/>
                                        <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlack"/>
                                        <g id="Carcasa">
                                            <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                            <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlack"/>
                                            <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlack"/>
                                            <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlack"/>
                                            <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                            <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                        </g>
                                        <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlack"/>
                                        <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlack"/>
                                        <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlack"/>
                                    </svg>
                                </Fragment>
                            </div>
                            }
                        </div>
                    }
                    {this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            {this.state.configAnt && this.state.configAnt.right.medirImpedancias ? <></> :
                            <div>
                                <Fragment>
                                    <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                        <g id="Resistencias_positivas">
                                            { this.state.estadoAnt.contPR === 4 &&
                                                <g id="R5_positivo">
                                                    <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>
                                            }
                                            { this.state.estadoAnt.contPR >= 2 &&
                                                <g id="R4_positivo">
                                                    <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>
                                            }
                                            { this.state.estadoAnt.contPR === 1 || this.state.estadoAnt.contPR === 3 ?
                                                <g id="R3_positivo">
                                                    <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                </g>
                                                :''
                                            }
                                            
                                            { this.state.estadoAnt.contPR >= 2 &&
                                                <g id="R2_positivo">
                                                    <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>
                                            }

                                            { this.state.estadoAnt.contPR === 4 &&
                                                <g id="R1_positivo">
                                                    <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>
                                            }

                                            { this.state.estadoAnt.contPR < 1 &&
                                                <>
                                                    <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlack"/>
                                                </>
                                            }
                                            <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                            c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                            h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlack"/>
                                            
                                        </g>
                                        <g id="Resistencias_negativas">
                                            {this.state.estadoAnt.contNR === 4 &&
                                                <g id="R5_negativo">
                                                    <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43"/>
                                                <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlack"/>
                                            </g>}
                                            {this.state.estadoAnt.contNR >= 2 &&
                                                <g id="R4_negativo">
                                                    <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                            </g>}
                                            {this.state.estadoAnt.contNR === 1 || this.state.estadoAnt.contNR === 3 ?
                                                <g id="R3_negativo">
                                                <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                            </g>:''}
                                            {this.state.estadoAnt.contNR >= 2 &&
                                                <g id="R2_negativo">
                                                    <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                            </g>}
                                            {this.state.estadoAnt.contNR === 4 &&
                                                <g id="R1_negativo">
                                                    <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                                <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                            </g>}
                                            { this.state.estadoAnt.contNR < 1 &&
                                                <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlack"/>
                                            }
                                            <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlack"/>
                                        </g>
                                        <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlack"/>
                                        <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlack"/>
                                        <g id="Carcasa">
                                            <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlack"/>
                                            <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlack"/>
                                            <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlack"/>
                                            <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                                <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                        </g>
                                        <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlack"/>
                                        <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlack"/>
                                        <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlack"/>
                                    </svg>
                                </Fragment>
                            </div>
                            }
						</div>
					}
                </div>
                {(this.state.left.programador === 'interleaving') || (this.state.right.programador === 'interleaving') ?
                <>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
					{this.state.ladoImpUniSel.key !== 'right'  && (this.state.left.programador === 'interleaving') &&
                        /** */
                        <>
                            <br></br>
                            <div className="ms-Grid-row ">
                                {
                                    this.state.tipoProgramacionSel !== 'ma'
                                    ?
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('stream.label')}:</Label></div>
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={this.state.estadoAnt.resultadoL} /></div>
                                            </div>
                                        </div>
                                    : <></>
                                }
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={this.state.estadoAnt.left.amplitude} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.estadoAnt.left.rateNew ? this.state.estadoAnt.left.rateNew : this.state.estadoAnt.left.rate} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.estadoAnt.left.pulse2} /></div>
                                    </div>
                                </div>
                            </div>
                            <Separator></Separator>
							<svg version="1.1" id="Layer_1" width="100%" height="650px" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="25 0 130.49 471.64">
                                <g id="Polo_1">
                                    { this.state.estadoAnt.left.leads2.l0 === 'negative' &&
                                        <g id="Polo_1_negativo">
                                            <g id="Fondo_negativo_7_">
                                                <path className="st0" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.estadoAnt.graficoSelL === 'mA'
                                            ? this.state.estadoAnt.intensidadLeft2.l0
                                                ? (Math.round((this.state.estadoAnt.intensidadLeft2.l0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.estadoAnt.intensidadLeftPorcent2.l0
                                                ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent2.l0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_negativo_7_" className="st1" d="M109.81,441.65c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86 c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,439.79,109.81,440.62,109.81,441.65z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads2.l0 === 'positive' &&
                                        <g id="Polo_1_positivo" className="st2">
                                            <g id="Fondo_positivo_7_" className="st3">
                                                <path className="st4" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.estadoAnt.graficoSelL === 'mA'
                                            ? this.state.estadoAnt.intensidadLeft2.l0
                                                ? (Math.round((this.state.estadoAnt.intensidadLeft2.l0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.estadoAnt.intensidadLeftPorcent2.l0
                                                ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent2.l0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_positivo_7_" className="st1" d="M108.76,440.16h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,440.89,109.34,440.16,108.76,440.16z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads2.l0 === 'neutral' &&
                                        <g id="Polo_1_off" className="st2">
                                            <path className="st6" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                            <path className="st5 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 434.3762)" className="st1 st7 st8">1</text>
                                    <g id="Tapa_14_">
                                        <ellipse className="st9" cx="102.8" cy="418.83" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,412.48c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,414.66,89.74,412.48,102.8,412.48 M102.8,411.48c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,411.48,102.8,411.48L102.8,411.48z"/>
                                    </g>
                                </g>
                                <g id="Polo_2">
                                    { this.state.estadoAnt.left.leads2.l1 === 'negative' &&
                                        <g id="Polo_2_negativo">
                                            <g id="Fondo_negativo_6_">
                                                <path className="st0" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.estadoAnt.graficoSelL === 'mA'
                                            ? this.state.estadoAnt.intensidadLeft2.l1
                                                ? (Math.round((this.state.estadoAnt.intensidadLeft2.l1 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.estadoAnt.intensidadLeftPorcent2.l1
                                                ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent2.l1 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_negativo_6_" className="st1" d="M109.81,386.6c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,384.74,109.81,385.58,109.81,386.6z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads2.l1 === 'positive' &&
                                        <g id="Polo_2_positivo" className="st2">
                                            <g id="Fondo_positivo_6_" className="st3">
                                                <path className="st4" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.estadoAnt.graficoSelL === 'mA'
                                            ? this.state.estadoAnt.intensidadLeft2.l1
                                                ? (Math.round((this.state.estadoAnt.intensidadLeft2.l1 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.estadoAnt.intensidadLeftPorcent2.l1
                                                ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent2.l1 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_positivo_6_" className="st1" d="M108.76,385.12h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,385.84,109.34,385.12,108.76,385.12z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads2.l1 === 'neutral' &&
                                        <g id="Polo_2_off" className="st2">
                                            <path className="st6" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                            <path className="st5 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 379.3322)" className="st1 st7 st8">2</text>
                                    <g id="Tapa_12_">
                                        <path className="st9" d="M102.8,370.64c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,368.5,115.7,370.64,102.8,370.64z"/>
                                        <path className="st1 strokeBlack" d="M102.8,357.44c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,357.44,102.8,357.44 M102.8,356.44c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,356.44,102.8,356.44L102.8,356.44z"/>
                                    </g>
                                </g>
                                <g id="Polo_3">
                                    { this.state.estadoAnt.left.leads2.l2 === 'negative' &&
                                        <g id="Polo_3_negativo" className="st2">
                                            <g id="Fondo_negativo_5_" className="st3">
                                                <path className="st0" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelL === 'mA'
                                                ? this.state.estadoAnt.intensidadLeft2.l2
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeft2.l2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadLeftPorcent2.l2
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent2.l2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_5_" className="st1" d="M109.81,331.56c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,329.7,109.81,330.53,109.81,331.56z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads2.l2 === 'positive' &&
                                        <g id="Polo_3_positivo">
                                            <g id="Fondo_positivo_5_">
                                                <path className="st4" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelL === 'mA'
                                                ? this.state.estadoAnt.intensidadLeft2.l2
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeft2.l2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadLeftPorcent2.l2
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent2.l2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_5_" className="st1" d="M108.76,330.07h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,330.8,109.34,330.07,108.76,330.07z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads2.l2 === 'neutral' &&
                                        <g id="Polo_3_off" className="st2">
                                            <path className="st6" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                            <path className="st5 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 324.2883)" className="st1 st7 st8">3</text>
                                    <g id="Tapa_10_">
                                        <path className="st9" d="M102.8,315.6c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,313.45,115.7,315.6,102.8,315.6z"/>
                                        <path className="st1 strokeBlack" d="M102.8,302.39c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,304.57,89.74,302.39,102.8,302.39 M102.8,301.39c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,301.39,102.8,301.39L102.8,301.39z"/>
                                    </g>
                                </g>
                                <g id="Polo_4">
                                    { this.state.estadoAnt.left.leads2.l3 === 'negative' &&
                                        <g id="Polo_4_negativo" className="st2">
                                            <g id="Fondo_negativo_4_" className="st3">
                                                <path className="st0" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelL === 'mA'
                                                ? this.state.estadoAnt.intensidadLeft2.l3
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeft2.l3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadLeftPorcent2.l3
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent2.l3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_4_" className="st1" d="M109.81,276.52c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,274.65,109.81,275.49,109.81,276.52z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads2.l3 === 'positive' &&
                                        <g id="Polo_4_positivo" className="st2">
                                            <g id="Fondo_positivo_4_" className="st3">
                                                <path className="st4" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelL === 'mA'
                                                ? this.state.estadoAnt.intensidadLeft2.l3
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeft2.l3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadLeftPorcent2.l3
                                                    ? (Math.round((this.state.estadoAnt.intensidadLeftPorcent2.l3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_4_" className="st1" d="M108.76,275.03h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,275.75,109.34,275.03,108.76,275.03z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.left.leads2.l3 === 'neutral' &&
                                        <g id="Polo_4_off">
                                            <path className="st10" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                            <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"/>
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 269.2434)" className="st1 st7 st8">4</text>
                                    <g id="Tapa_8_">
                                        <path className="st9" d="M102.8,260.55c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,258.41,115.7,260.55,102.8,260.55z"/>
                                        <path className="st1 strokeBlack" d="M102.8,247.35c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,249.53,89.74,247.35,102.8,247.35 M102.8,246.35c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,246.35,102.8,246.35L102.8,246.35z"/>
                                    </g>
                                </g>
                                <g id="Polo_5">
                                    <g id="Polo_5_negativo" className="st2">
                                        <g id="Fondo_negativo_3_" className="st3">
                                            <path className="st0" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_3_" className="st5" d="M109.81,226.21c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,224.35,109.81,225.18,109.81,226.21z"/>
                                    </g>
                                    <g id="Polo_5_positivo" className="st2">
                                        <g id="Fondo_positivo_3_" className="st3">
                                            <path className="st4" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_3_" className="st5" d="M108.76,224.72h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,225.45,109.34,224.72,108.76,224.72z"/>
                                    </g>
                                    <g id="Polo_5_off">
                                        <path className="st10" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                        <path className="st1 strokeBlack" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 214.1994)" className="st1 st7 st8">5</text>
                                    <g id="Tapa_6_">
                                        <path className="st9" d="M102.8,205.51c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,203.36,115.7,205.51,102.8,205.51z"/>
                                        <path className="st1 strokeBlack" d="M102.8,192.31c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,192.31,102.8,192.31 M102.8,191.31c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,191.31,102.8,191.31L102.8,191.31z"/>
                                    </g>
                                </g>
                                <g id="Polo_6">
                                    <g id="Polo_6_negativo" className="st2">
                                        <g id="Fondo_negativo_2_" className="st3">
                                            <path className="st0" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_2_" className="st5" d="M109.81,171.16c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,169.3,109.81,170.14,109.81,171.16z"/>
                                    </g>
                                    <g id="Polo_6_positivo" className="st2">
                                        <g id="Fondo_positivo_2_" className="st3">
                                            <path className="st4" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_2_" className="st5" d="M108.76,169.68h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,170.4,109.34,169.68,108.76,169.68z"/>
                                    </g>
                                    <g id="Polo_6_off">
                                        <path className="st10" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                        <path className="st1 strokeBlack" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 159.156)" className="st1 st7 st8">6</text>
                                    <g id="Tapa_1_">
                                        <path className="st9" d="M102.8,150.46c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,148.32,115.7,150.46,102.8,150.46z"/>
                                        <path className="st1 strokeBlack" d="M102.8,137.26c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,139.44,89.74,137.26,102.8,137.26 M102.8,136.26c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,136.47,105.59,136.26,102.8,136.26L102.8,136.26z"/>
                                    </g>
                                </g>
                                <g id="Polo_7">
                                    <g id="Polo_7_negativo" className="st2">
                                        <g id="Fondo_negativo_1_" className="st3">
                                            <path className="st0" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_negativo_1_" className="st5" d="M109.81,116.12c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,114.26,109.81,115.09,109.81,116.12z"/>
                                    </g>
                                    <g id="Polo_7_positivo" className="st2">
                                        <g id="Fondo_positivo_1_" className="st3">
                                            <path className="st4" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_positivo_1_" className="st5" d="M108.76,114.63h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,115.36,109.34,114.63,108.76,114.63z"/>
                                    </g>
                                    <g id="Polo_7_off">
                                        <path className="st10" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                        <path className="st1 strokeBlack" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 104.1111)" className="st1 st7 st8">7</text>
                                    <g id="Tapa_4_">
                                        <path className="st9" d="M102.8,95.42c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,93.28,115.7,95.42,102.8,95.42z"/>
                                        <path className="st1 strokeBlack" d="M102.8,82.22c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,84.4,89.74,82.22,102.8,82.22 M102.8,81.22c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,81.42,105.59,81.22,102.8,81.22L102.8,81.22z"/>
                                    </g>
                                </g>
                                <g id="Polo_8">
                                    <g id="Polo_8_negativo" className="st2">
                                        <g id="Fondo_negativo" className="st3">
                                            <path className="st0" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_negativo" className="st5" d="M109.81,61.08c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,59.21,109.81,60.05,109.81,61.08z"/>
                                    </g>
                                    <g id="Polo_8_positivo" className="st2">
                                        <g id="Fondo_positivo" className="st3">
                                            <path className="st4" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_positivo" className="st5" d="M108.76,59.59h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74
                                            h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04
                                            v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,60.31,109.34,59.59,108.76,59.59z"/>
                                    </g>
                                    <g id="Polo_8_off">
                                        <path className="st10" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                        <path className="st1 strokeBlack" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 49.0671)" className="st1 st7 st8">8</text>
                                    <g id="Tapa">
                                        <ellipse className="st9" cx="102.8" cy="33.52" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,27.17c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,27.17,102.8,27.17 M102.8,26.17c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,26.17,102.8,26.17L102.8,26.17z"/>
                                    </g>
                                </g>
                                <g id="Carcasa">
                                    <line className="st11 strokeBlack" x1="41.4" y1="10.25" x2="103.14" y2="10.25"/>
                                    <line className="st11 strokeBlack" x1="102.8" y1="27.17" x2="102.8" y2="10.25"/>
                                    {this.state.estadoAnt.left.leads2.lcase === 'positive' &&
                                        <>
                                            <path id="Carcasa_positivo" className="st12" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                            <path id="Icono_positivo_8_" className="st1 strokeBlack" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                        </>
                                    }
                                    {this.state.estadoAnt.left.leads2.lcase === 'neutral' &&
                                        <path id="Carcasa_off" className="st13 strokeBlack" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                    }
                                    <path id="Tapa_2_" className="st14 strokeBlack" d="M2.7,16.54c-1.22-20.84,39.46-20.84,39.45,0C23.01,16.54,2.7,16.54,2.7,16.54z"/>
                                    <text transform="matrix(1 0 0 1 14.4977 12.9934)" className="st1 st7 st8">IPG</text>
                                </g>
                            </svg>
                        </>
					}
					</div>
				    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
					{this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving') &&
                        <>
                            <br></br>
                            <div className="ms-Grid-row">
                                {
                                    this.state.tipoProgramacionSel !== 'ma'
                                    ?
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('stream.label')}:</Label></div>
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={this.state.estadoAnt.resultadoR2} /></div>
                                            </div>
                                        </div>
                                    : <></>
                                }
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={this.state.estadoAnt.right.amplitude} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.estadoAnt.right.rateNew ? this.state.estadoAnt.right.rateNew : this.state.estadoAnt.right.rate} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.estadoAnt.right.pulse2} /></div>
                                    </div>
                                </div>
                            </div>
                            <Separator></Separator>
							<svg version="1.1" id="Layer_1" width="100%" height="650px" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="25 0 130.49 471.64">
                                <g id="Polo_1">
                                    { this.state.estadoAnt.right.leads2.r0 === 'negative' &&
                                        <g id="Polo_1_negativo">
                                            <g id="Fondo_negativo_7_">
                                                <path className="st0" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.estadoAnt.graficoSelR2 === 'mA'
                                            ? this.state.estadoAnt.intensidadRight2.r0
                                                ? (Math.round((this.state.estadoAnt.intensidadRight2.r0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.estadoAnt.intensidadRightPorcent2.r0
                                                ? (Math.round((this.state.estadoAnt.intensidadRightPorcent2.r0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_negativo_7_" className="st1" d="M109.81,441.65c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86 c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,439.79,109.81,440.62,109.81,441.65z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads2.r0 === 'positive' &&
                                        <g id="Polo_1_positivo" className="st2">
                                            <g id="Fondo_positivo_7_" className="st3">
                                                <path className="st4" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.estadoAnt.graficoSelR2 === 'mA'
                                            ? this.state.estadoAnt.intensidadRight2.r0
                                                ? (Math.round((this.state.estadoAnt.intensidadRight2.r0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.estadoAnt.intensidadRightPorcent2.r0
                                                ? (Math.round((this.state.estadoAnt.intensidadRightPorcent2.r0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_positivo_7_" className="st1" d="M108.76,440.16h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,440.89,109.34,440.16,108.76,440.16z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads2.r0 === 'neutral' &&
                                        <g id="Polo_1_off" className="st2">
                                            <path className="st6" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                            <path className="st5 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 434.3762)" className="st1 st7 st8">1</text>
                                    <g id="Tapa_14_">
                                        <ellipse className="st9" cx="102.8" cy="418.83" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,412.48c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,414.66,89.74,412.48,102.8,412.48 M102.8,411.48c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,411.48,102.8,411.48L102.8,411.48z"/>
                                    </g>
                                </g>
                                <g id="Polo_2">
                                    { this.state.estadoAnt.right.leads2.r1 === 'negative' &&
                                        <g id="Polo_2_negativo">
                                            <g id="Fondo_negativo_6_">
                                                <path className="st0" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelR2 === 'mA'
                                                ? this.state.estadoAnt.intensidadRight2.r1
                                                    ? (Math.round((this.state.estadoAnt.intensidadRight2.r1 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadRightPorcent2.r1
                                                    ? (Math.round((this.state.estadoAnt.intensidadRightPorcent2.r1 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_6_" className="st1" d="M109.81,386.6c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,384.74,109.81,385.58,109.81,386.6z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads2.r1 === 'positive' &&
                                        <g id="Polo_2_positivo" className="st2">
                                            <g id="Fondo_positivo_6_" className="st3">
                                                <path className="st4" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelR2 === 'mA'
                                                ? this.state.estadoAnt.intensidadRight2.r1
                                                    ? (Math.round((this.state.estadoAnt.intensidadRight2.r1 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadRightPorcent2.r1
                                                    ? (Math.round((this.state.estadoAnt.intensidadRightPorcent2.r1 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_6_" className="st1" d="M108.76,385.12h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,385.84,109.34,385.12,108.76,385.12z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads2.r1 === 'neutral' &&
                                        <g id="Polo_2_off" className="st2">
                                            <path className="st6" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                            <path className="st5 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 379.3322)" className="st1 st7 st8">2</text>
                                    <g id="Tapa_12_">
                                        <path className="st9" d="M102.8,370.64c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,368.5,115.7,370.64,102.8,370.64z"/>
                                        <path className="st1 strokeBlack" d="M102.8,357.44c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,357.44,102.8,357.44 M102.8,356.44c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,356.44,102.8,356.44L102.8,356.44z"/>
                                    </g>
                                </g>
                                <g id="Polo_3">
                                    { this.state.estadoAnt.right.leads2.r2 === 'negative' &&
                                        <g id="Polo_3_negativo" className="st2">
                                            <g id="Fondo_negativo_5_" className="st3">
                                                <path className="st0" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelR2 === 'mA'
                                                ? this.state.estadoAnt.intensidadRight2.r2
                                                    ? (Math.round((this.state.estadoAnt.intensidadRight2.r2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadRightPorcent2.r2
                                                    ? (Math.round((this.state.estadoAnt.intensidadRightPorcent2.r2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_5_" className="st1" d="M109.81,331.56c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,329.7,109.81,330.53,109.81,331.56z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads2.r2 === 'positive' &&
                                        <g id="Polo_3_positivo">
                                            <g id="Fondo_positivo_5_">
                                                <path className="st4" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelR2 === 'mA'
                                                ? this.state.estadoAnt.intensidadRight2.r2
                                                    ? (Math.round((this.state.estadoAnt.intensidadRight2.r2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadRightPorcent2.r2
                                                    ? (Math.round((this.state.estadoAnt.intensidadRightPorcent2.r2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_5_" className="st1" d="M108.76,330.07h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,330.8,109.34,330.07,108.76,330.07z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads2.r2 === 'neutral' &&
                                        <g id="Polo_3_off" className="st2">
                                            <path className="st6" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                            <path className="st5 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 324.2883)" className="st1 st7 st8">3</text>
                                    <g id="Tapa_10_">
                                        <path className="st9" d="M102.8,315.6c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,313.45,115.7,315.6,102.8,315.6z"/>
                                        <path className="st1 strokeBlack" d="M102.8,302.39c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,304.57,89.74,302.39,102.8,302.39 M102.8,301.39c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,301.39,102.8,301.39L102.8,301.39z"/>
                                    </g>
                                </g>
                                <g id="Polo_4">
                                    { this.state.estadoAnt.right.leads2.r3 === 'negative' &&
                                        <g id="Polo_4_negativo" className="st2">
                                            <g id="Fondo_negativo_4_" className="st3">
                                                <path className="st0" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelR2 === 'mA'
                                                ? this.state.estadoAnt.intensidadRight2.r3
                                                    ? (Math.round((this.state.estadoAnt.intensidadRight2.r3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadRightPorcent2.r3
                                                    ? (Math.round((this.state.estadoAnt.intensidadRightPorcent2.r3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_4_" className="st1" d="M109.81,276.52c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,274.65,109.81,275.49,109.81,276.52z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads2.r3 === 'positive' &&
                                        <g id="Polo_4_positivo" className="st2">
                                            <g id="Fondo_positivo_4_" className="st3">
                                                <path className="st4" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.estadoAnt.graficoSelR2 === 'mA'
                                                ? this.state.estadoAnt.intensidadRight2.r3
                                                    ? (Math.round((this.state.estadoAnt.intensidadRight2.r3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.estadoAnt.intensidadRightPorcent2.r3
                                                    ? (Math.round((this.state.estadoAnt.intensidadRightPorcent2.r3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_4_" className="st1" d="M108.76,275.03h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,275.75,109.34,275.03,108.76,275.03z"/>
                                        </g>
                                    }
                                    { this.state.estadoAnt.right.leads2.r3 === 'neutral' &&
                                        <g id="Polo_4_off">
                                            <path className="st10" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                            <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"/>
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 269.2434)" className="st1 st7 st8">4</text>
                                    <g id="Tapa_8_">
                                        <path className="st9" d="M102.8,260.55c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,258.41,115.7,260.55,102.8,260.55z"/>
                                        <path className="st1 strokeBlack" d="M102.8,247.35c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,249.53,89.74,247.35,102.8,247.35 M102.8,246.35c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,246.35,102.8,246.35L102.8,246.35z"/>
                                    </g>
                                </g>
                                <g id="Polo_5">
                                    <g id="Polo_5_negativo" className="st2">
                                        <g id="Fondo_negativo_3_" className="st3">
                                            <path className="st0" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_3_" className="st5" d="M109.81,226.21c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,224.35,109.81,225.18,109.81,226.21z"/>
                                    </g>
                                    <g id="Polo_5_positivo" className="st2">
                                        <g id="Fondo_positivo_3_" className="st3">
                                            <path className="st4" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_3_" className="st5" d="M108.76,224.72h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,225.45,109.34,224.72,108.76,224.72z"/>
                                    </g>
                                    <g id="Polo_5_off">
                                        <path className="st10" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                        <path className="st1 strokeBlack" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 214.1994)" className="st1 st7 st8">5</text>
                                    <g id="Tapa_6_">
                                        <path className="st9" d="M102.8,205.51c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,203.36,115.7,205.51,102.8,205.51z"/>
                                        <path className="st1 strokeBlack" d="M102.8,192.31c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,192.31,102.8,192.31 M102.8,191.31c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,191.31,102.8,191.31L102.8,191.31z"/>
                                    </g>
                                </g>
                                <g id="Polo_6">
                                    <g id="Polo_6_negativo" className="st2">
                                        <g id="Fondo_negativo_2_" className="st3">
                                            <path className="st0" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_2_" className="st5" d="M109.81,171.16c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,169.3,109.81,170.14,109.81,171.16z"/>
                                    </g>
                                    <g id="Polo_6_positivo" className="st2">
                                        <g id="Fondo_positivo_2_" className="st3">
                                            <path className="st4" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_2_" className="st5" d="M108.76,169.68h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,170.4,109.34,169.68,108.76,169.68z"/>
                                    </g>
                                    <g id="Polo_6_off">
                                        <path className="st10" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                        <path className="st1 strokeBlack" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 159.156)" className="st1 st7 st8">6</text>
                                    <g id="Tapa_1_">
                                        <path className="st9" d="M102.8,150.46c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,148.32,115.7,150.46,102.8,150.46z"/>
                                        <path className="st1 strokeBlack" d="M102.8,137.26c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,139.44,89.74,137.26,102.8,137.26 M102.8,136.26c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,136.47,105.59,136.26,102.8,136.26L102.8,136.26z"/>
                                    </g>
                                </g>
                                <g id="Polo_7">
                                    <g id="Polo_7_negativo" className="st2">
                                        <g id="Fondo_negativo_1_" className="st3">
                                            <path className="st0" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_negativo_1_" className="st5" d="M109.81,116.12c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,114.26,109.81,115.09,109.81,116.12z"/>
                                    </g>
                                    <g id="Polo_7_positivo" className="st2">
                                        <g id="Fondo_positivo_1_" className="st3">
                                            <path className="st4" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_positivo_1_" className="st5" d="M108.76,114.63h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,115.36,109.34,114.63,108.76,114.63z"/>
                                    </g>
                                    <g id="Polo_7_off">
                                        <path className="st10" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                        <path className="st1 strokeBlack" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 104.1111)" className="st1 st7 st8">7</text>
                                    <g id="Tapa_4_">
                                        <path className="st9" d="M102.8,95.42c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,93.28,115.7,95.42,102.8,95.42z"/>
                                        <path className="st1 strokeBlack" d="M102.8,82.22c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,84.4,89.74,82.22,102.8,82.22 M102.8,81.22c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,81.42,105.59,81.22,102.8,81.22L102.8,81.22z"/>
                                    </g>
                                </g>
                                <g id="Polo_8">
                                    <g id="Polo_8_negativo" className="st2">
                                        <g id="Fondo_negativo" className="st3">
                                            <path className="st0" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_negativo" className="st5" d="M109.81,61.08c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,59.21,109.81,60.05,109.81,61.08z"/>
                                    </g>
                                    <g id="Polo_8_positivo" className="st2">
                                        <g id="Fondo_positivo" className="st3">
                                            <path className="st4" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_positivo" className="st5" d="M108.76,59.59h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74
                                            h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04
                                            v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,60.31,109.34,59.59,108.76,59.59z"/>
                                    </g>
                                    <g id="Polo_8_off">
                                        <path className="st10" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                        <path className="st1 strokeBlack" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 49.0671)" className="st1 st7 st8">8</text>
                                    <g id="Tapa">
                                        <ellipse className="st9" cx="102.8" cy="33.52" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,27.17c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,27.17,102.8,27.17 M102.8,26.17c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,26.17,102.8,26.17L102.8,26.17z"/>
                                    </g>
                                </g>
                                <g id="Carcasa">
                                    <line className="st11 strokeBlack" x1="41.4" y1="10.25" x2="103.14" y2="10.25"/>
                                    <line className="st11 strokeBlack" x1="102.8" y1="27.17" x2="102.8" y2="10.25"/>
                                    {this.state.estadoAnt.right.leads2.rcase === 'positive' &&
                                        <>
                                            <path id="Carcasa_positivo" className="st12 strokeBlack" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                            <path id="Icono_positivo_8_" className="st1" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                        </>
                                    }
                                    {this.state.estadoAnt.right.leads2.rcase === 'neutral' &&
                                        <path id="Carcasa_off" className="st13 strokeBlack" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                    }
                                    <path id="Tapa_2_" className="st14 strokeBlack" d="M2.7,16.54c-1.22-20.84,39.46-20.84,39.45,0C23.01,16.54,2.7,16.54,2.7,16.54z"/>
                                    <text transform="matrix(1 0 0 1 14.4977 12.9934)" className="st1 st7 st8">IPG</text>
                                </g>
                            </svg>
                        </>
					}
					</div>                                
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'right' && (this.state.left.programador === 'interleaving') &&
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Label className="ms-fontSize-16 ms-fontWeight-bold">{t('equivalentamplitude.text')}: <span className="ms-fontWeight-regular">{this.state.estadoAnt.resultadoL2} mA</span></Label>
                                    <Label>{t('appliedformula.text')}: {this.state.estadoAnt.formulaL2}</Label>
                                </div>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving') &&
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Label className="ms-fontSize-16 ms-fontWeight-bold">{t('equivalentamplitude.text')}: <span className="ms-fontWeight-regular">{this.state.estadoAnt.resultadoR2} mA</span></Label>
                                    <Label>{t('appliedformula.text')}: {this.state.estadoAnt.formulaR2}</Label>
                                </div>
                        }
                    </div>
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'right' && (this.state.left.programador === 'interleaving') &&
                                <div>
                                    <Fragment>
                                        <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                            <g id="Resistencias_positivas">
                                                { this.state.estadoAnt.contPL2 === 4 &&
                                                    <g id="R5_positivo">
                                                        <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }
                                                { this.state.estadoAnt.contPL2 >= 2 &&
                                                    <g id="R4_positivo">
                                                        <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43 " className="strokeBlack"/>
                                                    </g>
                                                }
                                                { this.state.estadoAnt.contPL2 === 1 || this.state.estadoAnt.contPL2 === 3 ?
                                                    <g id="R3_positivo">
                                                        <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                    </g>
                                                    : ''
                                                }
                                                
                                                { this.state.estadoAnt.contPL2 >= 2 &&
                                                    <g id="R2_positivo">
                                                        <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }

                                                { this.state.estadoAnt.contPL2 === 4 &&
                                                    <g id="R1_positivo">
                                                        <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }

                                                { this.state.estadoAnt.contPL2 < 1 &&
                                                    <>
                                                        <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlack"/>
                                                    </>
                                                }
                                                <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
        c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
        h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlack"/>
                                                
                                            </g>
                                            <g id="Resistencias_negativas">
                                                {this.state.estadoAnt.contNL2 === 4 &&
                                                    <g id="R5_negativo">
                                                    <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.estadoAnt.contNL2 >= 2 &&
                                                    <g id="R4_negativo">
                                                        <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.estadoAnt.contNL2 === 1 || this.state.estadoAnt.contNL2 === 3 ?
                                                    <g id="R3_negativo">
                                                    <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                </g>:''}
                                                {this.state.estadoAnt.contNL2 >= 2 &&
                                                    <g id="R2_negativo">
                                                        <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.estadoAnt.contNL2 === 4 &&
                                                    <g id="R1_negativo">
                                                        <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43"  className="strokeBlack"/>
                                                </g>}
                                                { this.state.estadoAnt.contNL2 < 1 &&
                                                    <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlack"/>
                                                }
                                                <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
        c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlack"/>
                                                
                                            </g>
                                            <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlack"/>
                                            <g id="Carcasa">
                                                <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                    h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                                <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlack"/>
                                                <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlack"/>
                                                <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlack"/>
                                                <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                                <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                    h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                            </g>
                                            <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlack"/>
                                        </svg>
                                    </Fragment>
                                </div>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving') &&
                                <div>
                                    <Fragment>
                                        <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                            <g id="Resistencias_positivas">
                                                { this.state.estadoAnt.contPR2 === 4 &&
                                                    <g id="R5_positivo">
                                                        <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }
                                                { this.state.estadoAnt.contPR2 >= 2 &&
                                                    <g id="R4_positivo">
                                                        <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }
                                                { this.state.estadoAnt.contPR2 === 1 || this.state.estadoAnt.contPR2 === 3 ?
                                                    <g id="R3_positivo">
                                                        <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                    </g>
                                                    :''
                                                }
                                                
                                                { this.state.estadoAnt.contPR2 >= 2 &&
                                                    <g id="R2_positivo">
                                                        <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }

                                                { this.state.estadoAnt.contPR2 === 4 &&
                                                    <g id="R1_positivo">
                                                        <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }

                                                { this.state.estadoAnt.contPR2 < 1 &&
                                                    <>
                                                        <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlack"/>
                                                    </>
                                                }
                                                <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlack"/>
                                                
                                            </g>
                                            <g id="Resistencias_negativas">
                                                {this.state.estadoAnt.contNR2 === 4 &&
                                                    <g id="R5_negativo">
                                                        <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43"/>
                                                    <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.estadoAnt.contNR2 >= 2 &&
                                                    <g id="R4_negativo">
                                                        <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.estadoAnt.contNR2 === 1 || this.state.estadoAnt.contNR2 === 3 ?
                                                    <g id="R3_negativo">
                                                    <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                </g>:''}
                                                {this.state.estadoAnt.contNR2 >= 2 &&
                                                    <g id="R2_negativo">
                                                        <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.estadoAnt.contNR2 === 4 &&
                                                    <g id="R1_negativo">
                                                        <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                { this.state.estadoAnt.contNR2 < 1 &&
                                                    <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlack"/>
                                                }
                                                <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
    c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlack"/>
                                            </g>
                                            <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlack"/>
                                            <g id="Carcasa">
                                                <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlack"/>
                                                <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlack"/>
                                                <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlack"/>
                                                <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                                    <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                    h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                            </g>
                                            <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlack"/>
                                        </svg>
                                    </Fragment>
                                </div>
                        }
                    </div>
                </div>
                </>
                : <></>}
                {/* {<InformeRecuento datos={this.state.estadoAnt} />} */}

                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Separator></Separator>
                        <Label className="ms-fontSize-24 ms-fontWeight-regular">{t('p2report.title')}</Label>
                    </div>
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
					{this.state.ladoImpUniSel.key !== 'right' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<Label className="ms-fontSize-20 ms-fontWeight-regular"><u>{t('left.text')}</u></Label>
							<Label className="ms-fontSize-14 ms-fontWeight-bold">{t('frequencycorrection.text')}: </Label>
							<Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.left.rateNew ? this.state.left.rateNew +' Hz' : t('nonecesario')} </Label>
						</div>
					}
					{this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<Label className="ms-fontSize-20 ms-fontWeight-regular"><u>{t('right.text')}</u></Label>
							<Label className="ms-fontSize-14 ms-fontWeight-bold">{t('frequencycorrection.text')}: </Label>
							<Label className="ms-fontSize-14 ms-fontWeight-regular">{this.state.right.rateNew ? this.state.right.rateNew +' Hz' : t('nonecesario')} </Label>
						</div>
					}
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Label className="ms-fontSize-14 ms-fontWeight-bold">{t('contactsconf.text')}: </Label>
                    </div>
                </div>
                {/** CASE */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
					{this.state.ladoImpUniSel.key !== 'right' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>IPG</span>
									<span className={'checkbox '+this.state.left.leads.lcase} title="lcase">
										{ this.state.left.leads.lcase === 'positive'
										// eslint-disable-next-line
										? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
										: this.state.left.leads.lcase === 'negative'
											// eslint-disable-next-line
											? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
											: <svg  width="10" height="10"></svg>
										}
									</span>
								</div>
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
									
								</div>
							</div>
						</div>
					}
					{this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>IPG</span>
									<span className={'checkbox '+this.state.right.leads.rcase} title="lcase">
										{ this.state.right.leads.rcase === 'positive'
										// eslint-disable-next-line
										? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
										: this.state.right.leads.rcase === 'negative'
											// eslint-disable-next-line
											? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
											: <svg  width="10" height="10"></svg>
										}
									</span>
								</div>
							</div>
						</div>
					}
                </div>
                {/** 3 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
					{this.state.ladoImpUniSel.key !== 'right' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>4</span>
									<span className={'checkbox '+this.state.left.leads.l3} title="lcase">
										{ this.state.left.leads.l3 === 'positive'
										// eslint-disable-next-line
										? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
										: this.state.left.leads.l3 === 'negative'
											// eslint-disable-next-line
											? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
											: <svg  width="10" height="10"></svg>
										}
									</span>
								</div>
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads.l3 !== 'neutral' ? this.state.left.impedance.l3 + ' Ω': '' }</span>
								</div>
							</div>
						</div>
					}
					{this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>4</span>
									<span className={'checkbox '+this.state.right.leads.r3} title="lcase">
										{ this.state.right.leads.r3 === 'positive'
										// eslint-disable-next-line
										? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
										: this.state.right.leads.r3 === 'negative'
											// eslint-disable-next-line
											? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
											: <svg  width="10" height="10"></svg>
										}
									</span>
								</div>
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads.r3 !== 'neutral' ? this.state.right.impedance.r3 + ' Ω': '' }</span>
								</div>
							</div>
						</div>
					}
                </div>
                {/** 2 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
					{this.state.ladoImpUniSel.key !== 'right' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>3</span>
									<span className={'checkbox '+this.state.left.leads.l2} title="lcase">
										{ this.state.left.leads.l2 === 'positive'
										// eslint-disable-next-line
										? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
										: this.state.left.leads.l2 === 'negative'
											// eslint-disable-next-line
											? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
											: <svg  width="10" height="10"></svg>
										}
									</span>
								</div>
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads.l2 !== 'neutral' ? this.state.left.impedance.l2 + ' Ω': '' }</span>
								</div>
							</div>
						</div>
					}
					{this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>3</span>
									<span className={'checkbox '+this.state.right.leads.r1} title="lcase">
										{ this.state.right.leads.r2 === 'positive'
										// eslint-disable-next-line
										? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
										: this.state.right.leads.r2 === 'negative'
											// eslint-disable-next-line
											? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
											: <svg  width="10" height="10"></svg>
										}
									</span>
								</div>
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads.r2!== 'neutral' ? this.state.right.impedance.r2 + ' Ω': '' }</span>
								</div>
							</div>
						</div>
					}
                </div>
                {/** 1 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
					{this.state.ladoImpUniSel.key !== 'right' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>2</span>
									<span className={'checkbox '+this.state.left.leads.l1} title="lcase">
										{ this.state.left.leads.l1 === 'positive'
										// eslint-disable-next-line
										? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
										: this.state.left.leads.l1 === 'negative'
											// eslint-disable-next-line
											? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
											: <svg  width="10" height="10"></svg>
										}
									</span>
								</div>
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads.l1 !== 'neutral' ? this.state.left.impedance.l1 + ' Ω': '' }</span>
								</div>
							</div>
						</div>
					}
					{this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>2</span>
									<span className={'checkbox '+this.state.right.leads.r1} title="lcase">
										{ this.state.right.leads.r1 === 'positive'
										// eslint-disable-next-line
										? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
										: this.state.right.leads.r1 === 'negative'
											// eslint-disable-next-line
											? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
											: <svg  width="10" height="10"></svg>
										}
									</span>
								</div>
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads.r1 !== 'neutral' ? this.state.right.impedance.r1 + ' Ω': '' }</span>
								</div>
							</div>
						</div>
					}
                </div>
                {/** 0 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
					{this.state.ladoImpUniSel.key !== 'right' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>1</span>
									<span className={'checkbox '+this.state.left.leads.l0} title="lcase">
										{ this.state.left.leads.l0 === 'positive'
										// eslint-disable-next-line
										? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
										: this.state.left.leads.l0 === 'negative'
											// eslint-disable-next-line
											? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
											: <svg  width="10" height="10"></svg>
										}
									</span>
								</div>
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads.l0 !== 'neutral' ? this.state.left.impedance.l0 + ' Ω': '' }</span>
								</div>
							</div>
						</div>
					}
					{this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<div className="ms-Grid-row">
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>1</span>
									<span className={'checkbox '+this.state.right.leads.r0} title="lcase">
										{ this.state.right.leads.r0 === 'positive'
										// eslint-disable-next-line
										? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
										: this.state.right.leads.r0 === 'negative'
											// eslint-disable-next-line
											? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
											: <svg  width="10" height="10"></svg>
										}
									</span>
								</div>
								<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
									<span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads.r0 !== 'neutral' ? this.state.right.impedance.r0 + ' Ω': '' }</span>
								</div>
							</div>
						</div>
					}
                </div>
                {(this.state.left.programador === 'interleaving' && (this.state.left.pulse !== this.state.left.pulse2)) || (this.state.right.programador === 'interleaving' && (this.state.right.pulse !== this.state.right.pulse2)) ?
                <>
                {/** CASE */}
                <div className="ms-Grid-row" style={{textAlign: 'left', marginTop:'10px'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'right' && (this.state.left.programador === 'interleaving' && (this.state.left.pulse !== this.state.left.pulse2)) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>IPG</span>
                                        <span className={'checkbox '+this.state.left.leads2.lcase} title="lcase">
                                            { this.state.left.leads2.lcase === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads2.lcase === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving' && (this.state.right.pulse !== this.state.right.pulse2)) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>IPG</span>
                                        <span className={'checkbox '+this.state.right.leads2.rcase} title="lcase">
                                            { this.state.right.leads2.rcase === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads2.rcase === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                {/** 3 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'right' && (this.state.left.programador === 'interleaving' && (this.state.left.pulse !== this.state.left.pulse2)) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>4</span>
                                        <span className={'checkbox '+this.state.left.leads2.l3} title="lcase">
                                            { this.state.left.leads2.l3 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads2.l3 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads2.l3 !== 'neutral' ? this.state.left.impedance2.l3 + ' Ω': '' }</span>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving' && (this.state.right.pulse !== this.state.right.pulse2)) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>4</span>
                                        <span className={'checkbox '+this.state.right.leads2.r3} title="lcase">
                                            { this.state.right.leads2.r3 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads2.r3 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads2.r3 !== 'neutral' ? this.state.right.impedance2.r3 + ' Ω': '' }</span>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                {/** 2 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'right' && (this.state.left.programador === 'interleaving' && (this.state.left.pulse !== this.state.left.pulse2)) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>3</span>
                                        <span className={'checkbox '+this.state.left.leads2.l2} title="lcase">
                                            { this.state.left.leads2.l2 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads2.l2 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads2.l2 !== 'neutral' ? this.state.left.impedance2.l2 + ' Ω': '' }</span>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving' && (this.state.right.pulse !== this.state.right.pulse2)) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>3</span>
                                        <span className={'checkbox '+this.state.right.leads2.r1} title="lcase">
                                            { this.state.right.leads2.r2 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads2.r2 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads2.r2!== 'neutral' ? this.state.right.impedance2.r2 + ' Ω': '' }</span>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                {/** 1 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'right' && (this.state.left.programador === 'interleaving' && (this.state.left.pulse !== this.state.left.pulse2)) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>2</span>
                                        <span className={'checkbox '+this.state.left.leads2.l1} title="lcase">
                                            { this.state.left.leads2.l1 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads2.l1 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads2.l1 !== 'neutral' ? this.state.left.impedance2.l1 + ' Ω': '' }</span>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving' && (this.state.right.pulse !== this.state.right.pulse2)) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>2</span>
                                        <span className={'checkbox '+this.state.right.leads2.r1} title="lcase">
                                            { this.state.right.leads2.r1 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads2.r1 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads2.r1 !== 'neutral' ? this.state.right.impedance2.r1 + ' Ω': '' }</span>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                {/** 0 */}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'right' && (this.state.left.programador === 'interleaving' && (this.state.left.pulse !== this.state.left.pulse2)) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>1</span>
                                        <span className={'checkbox '+this.state.left.leads2.l0} title="lcase">
                                            { this.state.left.leads2.l0 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.left.leads2.l0 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.left.leads2.l0 !== 'neutral' ? this.state.left.impedance2.l0 + ' Ω': '' }</span>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving' && (this.state.right.pulse !== this.state.right.pulse2)) &&
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'black'}}>1</span>
                                        <span className={'checkbox '+this.state.right.leads2.r0} title="lcase">
                                            { this.state.right.leads2.r0 === 'positive'
                                            // eslint-disable-next-line
                                            ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                            : this.state.right.leads2.r0 === 'negative'
                                                // eslint-disable-next-line
                                                ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                : <svg  width="10" height="10"></svg>
                                            }
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%'}}>{this.state.right.leads2.r0 !== 'neutral' ? this.state.right.impedance2.r0 + ' Ω': '' }</span>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                </>
                : <></>}
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Separator></Separator>
                        <Label className="ms-fontSize-24 ms-fontWeight-regular" >
                            {t('finalcoversion.text')}
                            <IconButton
                                title="Info"
                                ariaLabel="Info"
                                id={'infoPrinci1'}
                                onClick={this.visibleCalloutInfoPrinci1}
                                iconProps={iconProps}
                            />
                        </Label>
                        {this.state.isCalloutVisibleInfoPrinci1 && (
                            <Callout
                            target={'#infoPrinci1'}
                            setInitialFocus
                            ariaDescribedBy={'Teat'}
                            role="alertdialog"
                            style={{padding: '3%'}}
                            >
                                <Stack horizontalAlign="start" >
                                    <span>{t('pFin.alert')}</span>
                                    <br></br>
                                    <DefaultButton onClick={this.invisibleCalloutInfoPrinci1}>{t('p2.btn')}</DefaultButton>
                                </Stack>
                            </Callout>
                        )}
                    </div>
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
					{this.state.ladoImpUniSel.key !== 'right' &&
                        /** */
                        
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <Label className="ms-fontSize-20 ms-fontWeight-regular"><u>{t('left.text')}</u></Label>
                            <br></br>
                            <div className="ms-Grid-row ">
                                {
                                    this.state.tipoProgramacionSel !== 'ma'
                                    ?
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('stream.label')}:</Label></div>
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={this.state.resultadoL} /></div>
                                            </div>
                                        </div>
                                    : <></>
                                }
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={this.state.left.amplitude} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.left.rateNew ? this.state.left.rateNew : this.state.left.rate} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.left.pulse} /></div>
                                    </div>
                                </div>
                            </div>
                            <Separator></Separator>
							<svg version="1.1" id="Layer_1" width="100%" height="650px" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="25 0 130.49 471.64">
                                <g id="Polo_1">
                                    { this.state.left.leads.l0 === 'negative' &&
                                        <g id="Polo_1_negativo">
                                            <g id="Fondo_negativo_7_">
                                                <path className="st0" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.graficoSelL === 'mA'
                                            ? this.state.intensidadLeft.l0
                                                ? (Math.round((this.state.intensidadLeft.l0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.intensidadLeftPorcent.l0
                                                ? (Math.round((this.state.intensidadLeftPorcent.l0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_negativo_7_" className="st1" d="M109.81,441.65c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86 c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,439.79,109.81,440.62,109.81,441.65z"/>
                                        </g>
                                    }
                                    { this.state.left.leads.l0 === 'positive' &&
                                        <g id="Polo_1_positivo" className="st2">
                                            <g id="Fondo_positivo_7_" className="st3">
                                                <path className="st4" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.graficoSelL === 'mA'
                                            ? this.state.intensidadLeft.l0
                                                ? (Math.round((this.state.intensidadLeft.l0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.intensidadLeftPorcent.l0
                                                ? (Math.round((this.state.intensidadLeftPorcent.l0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_positivo_7_" className="st1" d="M108.76,440.16h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,440.89,109.34,440.16,108.76,440.16z"/>
                                        </g>
                                    }
                                    { this.state.left.leads.l0 === 'neutral' &&
                                        <g id="Polo_1_off" className="st2">
                                            <path className="st6" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                            <path className="st5 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 434.3762)" className="st1 st7 st8">1</text>
                                    <g id="Tapa_14_">
                                        <ellipse className="st9" cx="102.8" cy="418.83" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,412.48c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,414.66,89.74,412.48,102.8,412.48 M102.8,411.48c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,411.48,102.8,411.48L102.8,411.48z"/>
                                    </g>
                                </g>
                                <g id="Polo_2">
                                    { this.state.left.leads.l1 === 'negative' &&
                                        <g id="Polo_2_negativo">
                                            <g id="Fondo_negativo_6_">
                                                <path className="st0" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.graficoSelL === 'mA'
                                            ? this.state.intensidadLeft.l1
                                                ? (Math.round((this.state.intensidadLeft.l1 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.intensidadLeftPorcent.l1
                                                ? (Math.round((this.state.intensidadLeftPorcent.l1 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_negativo_6_" className="st1" d="M109.81,386.6c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,384.74,109.81,385.58,109.81,386.6z"/>
                                        </g>
                                    }
                                    { this.state.left.leads.l1 === 'positive' &&
                                        <g id="Polo_2_positivo" className="st2">
                                            <g id="Fondo_positivo_6_" className="st3">
                                                <path className="st4" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.graficoSelL === 'mA'
                                            ? this.state.intensidadLeft.l1
                                                ? (Math.round((this.state.intensidadLeft.l1 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.intensidadLeftPorcent.l1
                                                ? (Math.round((this.state.intensidadLeftPorcent.l1 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_positivo_6_" className="st1" d="M108.76,385.12h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,385.84,109.34,385.12,108.76,385.12z"/>
                                        </g>
                                    }
                                    { this.state.left.leads.l1 === 'neutral' &&
                                        <g id="Polo_2_off" className="st2">
                                            <path className="st6" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                            <path className="st5 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 379.3322)" className="st1 st7 st8">2</text>
                                    <g id="Tapa_12_">
                                        <path className="st9" d="M102.8,370.64c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,368.5,115.7,370.64,102.8,370.64z"/>
                                        <path className="st1 strokeBlack" d="M102.8,357.44c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,357.44,102.8,357.44 M102.8,356.44c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,356.44,102.8,356.44L102.8,356.44z"/>
                                    </g>
                                </g>
                                <g id="Polo_3">
                                    { this.state.left.leads.l2 === 'negative' &&
                                        <g id="Polo_3_negativo" className="st2">
                                            <g id="Fondo_negativo_5_" className="st3">
                                                <path className="st0" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelL === 'mA'
                                                ? this.state.intensidadLeft.l2
                                                    ? (Math.round((this.state.intensidadLeft.l2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadLeftPorcent.l2
                                                    ? (Math.round((this.state.intensidadLeftPorcent.l2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_5_" className="st1" d="M109.81,331.56c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,329.7,109.81,330.53,109.81,331.56z"/>
                                        </g>
                                    }
                                    { this.state.left.leads.l2 === 'positive' &&
                                        <g id="Polo_3_positivo">
                                            <g id="Fondo_positivo_5_">
                                                <path className="st4" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelL === 'mA'
                                                ? this.state.intensidadLeft.l2
                                                    ? (Math.round((this.state.intensidadLeft.l2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadLeftPorcent.l2
                                                    ? (Math.round((this.state.intensidadLeftPorcent.l2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_5_" className="st1" d="M108.76,330.07h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,330.8,109.34,330.07,108.76,330.07z"/>
                                        </g>
                                    }
                                    { this.state.left.leads.l2 === 'neutral' &&
                                        <g id="Polo_3_off" className="st2">
                                            <path className="st6" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                            <path className="st5 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 324.2883)" className="st1 st7 st8">3</text>
                                    <g id="Tapa_10_">
                                        <path className="st9" d="M102.8,315.6c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,313.45,115.7,315.6,102.8,315.6z"/>
                                        <path className="st1 strokeBlack" d="M102.8,302.39c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,304.57,89.74,302.39,102.8,302.39 M102.8,301.39c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,301.39,102.8,301.39L102.8,301.39z"/>
                                    </g>
                                </g>
                                <g id="Polo_4">
                                    { this.state.left.leads.l3 === 'negative' &&
                                        <g id="Polo_4_negativo" className="st2">
                                            <g id="Fondo_negativo_4_" className="st3">
                                                <path className="st0" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelL === 'mA'
                                                ? this.state.intensidadLeft.l3
                                                    ? (Math.round((this.state.intensidadLeft.l3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadLeftPorcent.l3
                                                    ? (Math.round((this.state.intensidadLeftPorcent.l3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_4_" className="st1" d="M109.81,276.52c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,274.65,109.81,275.49,109.81,276.52z"/>
                                        </g>
                                    }
                                    { this.state.left.leads.l3 === 'positive' &&
                                        <g id="Polo_4_positivo" className="st2">
                                            <g id="Fondo_positivo_4_" className="st3">
                                                <path className="st4" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelL === 'mA'
                                                ? this.state.intensidadLeft.l3
                                                    ? (Math.round((this.state.intensidadLeft.l3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadLeftPorcent.l3
                                                    ? (Math.round((this.state.intensidadLeftPorcent.l3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_4_" className="st1" d="M108.76,275.03h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,275.75,109.34,275.03,108.76,275.03z"/>
                                        </g>
                                    }
                                    { this.state.left.leads.l3 === 'neutral' &&
                                        <g id="Polo_4_off">
                                            <path className="st10" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                            <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"/>
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 269.2434)" className="st1 st7 st8">4</text>
                                    <g id="Tapa_8_">
                                        <path className="st9" d="M102.8,260.55c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,258.41,115.7,260.55,102.8,260.55z"/>
                                        <path className="st1 strokeBlack" d="M102.8,247.35c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,249.53,89.74,247.35,102.8,247.35 M102.8,246.35c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,246.35,102.8,246.35L102.8,246.35z"/>
                                    </g>
                                </g>
                                <g id="Polo_5">
                                    <g id="Polo_5_negativo" className="st2">
                                        <g id="Fondo_negativo_3_" className="st3">
                                            <path className="st0" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_3_" className="st5" d="M109.81,226.21c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,224.35,109.81,225.18,109.81,226.21z"/>
                                    </g>
                                    <g id="Polo_5_positivo" className="st2">
                                        <g id="Fondo_positivo_3_" className="st3">
                                            <path className="st4" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_3_" className="st5" d="M108.76,224.72h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,225.45,109.34,224.72,108.76,224.72z"/>
                                    </g>
                                    <g id="Polo_5_off">
                                        <path className="st10" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                        <path className="st1 strokeBlack" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 214.1994)" className="st1 st7 st8">5</text>
                                    <g id="Tapa_6_">
                                        <path className="st9" d="M102.8,205.51c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,203.36,115.7,205.51,102.8,205.51z"/>
                                        <path className="st1 strokeBlack" d="M102.8,192.31c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,192.31,102.8,192.31 M102.8,191.31c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,191.31,102.8,191.31L102.8,191.31z"/>
                                    </g>
                                </g>
                                <g id="Polo_6">
                                    <g id="Polo_6_negativo" className="st2">
                                        <g id="Fondo_negativo_2_" className="st3">
                                            <path className="st0" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_2_" className="st5" d="M109.81,171.16c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,169.3,109.81,170.14,109.81,171.16z"/>
                                    </g>
                                    <g id="Polo_6_positivo" className="st2">
                                        <g id="Fondo_positivo_2_" className="st3">
                                            <path className="st4" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_2_" className="st5" d="M108.76,169.68h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,170.4,109.34,169.68,108.76,169.68z"/>
                                    </g>
                                    <g id="Polo_6_off">
                                        <path className="st10" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                        <path className="st1 strokeBlack" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 159.156)" className="st1 st7 st8">6</text>
                                    <g id="Tapa_1_">
                                        <path className="st9" d="M102.8,150.46c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,148.32,115.7,150.46,102.8,150.46z"/>
                                        <path className="st1 strokeBlack" d="M102.8,137.26c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,139.44,89.74,137.26,102.8,137.26 M102.8,136.26c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,136.47,105.59,136.26,102.8,136.26L102.8,136.26z"/>
                                    </g>
                                </g>
                                <g id="Polo_7">
                                    <g id="Polo_7_negativo" className="st2">
                                        <g id="Fondo_negativo_1_" className="st3">
                                            <path className="st0" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_negativo_1_" className="st5" d="M109.81,116.12c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,114.26,109.81,115.09,109.81,116.12z"/>
                                    </g>
                                    <g id="Polo_7_positivo" className="st2">
                                        <g id="Fondo_positivo_1_" className="st3">
                                            <path className="st4" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_positivo_1_" className="st5" d="M108.76,114.63h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,115.36,109.34,114.63,108.76,114.63z"/>
                                    </g>
                                    <g id="Polo_7_off">
                                        <path className="st10" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                        <path className="st1 strokeBlack" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 104.1111)" className="st1 st7 st8">7</text>
                                    <g id="Tapa_4_">
                                        <path className="st9" d="M102.8,95.42c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,93.28,115.7,95.42,102.8,95.42z"/>
                                        <path className="st1 strokeBlack" d="M102.8,82.22c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,84.4,89.74,82.22,102.8,82.22 M102.8,81.22c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,81.42,105.59,81.22,102.8,81.22L102.8,81.22z"/>
                                    </g>
                                </g>
                                <g id="Polo_8">
                                    <g id="Polo_8_negativo" className="st2">
                                        <g id="Fondo_negativo" className="st3">
                                            <path className="st0" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_negativo" className="st5" d="M109.81,61.08c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,59.21,109.81,60.05,109.81,61.08z"/>
                                    </g>
                                    <g id="Polo_8_positivo" className="st2">
                                        <g id="Fondo_positivo" className="st3">
                                            <path className="st4" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_positivo" className="st5" d="M108.76,59.59h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74
                                            h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04
                                            v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,60.31,109.34,59.59,108.76,59.59z"/>
                                    </g>
                                    <g id="Polo_8_off">
                                        <path className="st10" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                        <path className="st1 strokeBlack" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 49.0671)" className="st1 st7 st8">8</text>
                                    <g id="Tapa">
                                        <ellipse className="st9" cx="102.8" cy="33.52" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,27.17c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,27.17,102.8,27.17 M102.8,26.17c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,26.17,102.8,26.17L102.8,26.17z"/>
                                    </g>
                                </g>
                                <g id="Carcasa">
                                    <line className="st11 strokeBlack" x1="41.4" y1="10.25" x2="103.14" y2="10.25"/>
                                    <line className="st11 strokeBlack" x1="102.8" y1="27.17" x2="102.8" y2="10.25"/>
                                    {this.state.left.leads.lcase === 'positive' &&
                                        <>
                                            <path id="Carcasa_positivo" className="st12" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                            <path id="Icono_positivo_8_" className="st1 strokeBlack" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                        </>
                                    }
                                    {this.state.left.programador === 'interleaving' &&
                                        <>
                                        {
                                            this.state.left.leads2.lcase === 'positive' &&
                                            <>
                                                <path id="Carcasa_positivo" className="st12" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                                <path id="Icono_positivo_8_" className="st1" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                            </>
                                        }
                                        {
                                            this.state.left.leads2.lcase === 'neutral' && this.state.left.leads.lcase !== 'positive' &&
                                            <>
                                                <path id="Carcasa_off" className="st13" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                            </>
                                        }
                                        {
                                            this.state.left.leads2.lcase === 'neutral' && this.state.left.leads.lcase === 'positive' &&
                                            <>
                                                <path id="Carcasa_positivo" className="st12" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                                <path id="Icono_positivo_8_" className="st1" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                            </>
                                        }

                                        </>
                                    }
                                    {this.state.left.leads.lcase === 'neutral' && this.state.left.programador !== 'interleaving' &&
                                        <path id="Carcasa_off" className="st13 strokeBlack" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                    }
                                    <path id="Tapa_2_" className="st14 strokeBlack" d="M2.7,16.54c-1.22-20.84,39.46-20.84,39.45,0C23.01,16.54,2.7,16.54,2.7,16.54z"/>
                                    <text transform="matrix(1 0 0 1 14.4977 12.9934)" className="st1 st7 st8">IPG</text>
                                </g>
                            </svg>
						</div>
					}
					{this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <Label className="ms-fontSize-20 ms-fontWeight-regular"><u>{t('right.text')}</u></Label>
                            <br></br>
                            <div className="ms-Grid-row">
                                {
                                    this.state.tipoProgramacionSel !== 'ma'
                                    ?
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('stream.label')}:</Label></div>
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={this.state.resultadoR} /></div>
                                            </div>
                                        </div>
                                    : <></>
                                }
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={this.state.right.amplitude} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.right.rateNew ? this.state.right.rateNew : this.state.right.rate} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.right.pulse} /></div>
                                    </div>
                                </div>
                            </div>
                            <Separator></Separator>
							<svg version="1.1" id="Layer_1" width="100%" height="650px" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="25 0 130.49 471.64">
                                <g id="Polo_1">
                                    { this.state.right.leads.r0 === 'negative' &&
                                        <g id="Polo_1_negativo">
                                            <g id="Fondo_negativo_7_">
                                                <path className="st0" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.graficoSelL === 'mA'
                                            ? this.state.intensidadRight.r0
                                                ? (Math.round((this.state.intensidadRight.r0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.intensidadRightPorcent.r0
                                                ? (Math.round((this.state.intensidadRightPorcent.r0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_negativo_7_" className="st1" d="M109.81,441.65c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86 c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,439.79,109.81,440.62,109.81,441.65z"/>
                                        </g>
                                    }
                                    { this.state.right.leads.r0 === 'positive' &&
                                        <g id="Polo_1_positivo" className="st2">
                                            <g id="Fondo_positivo_7_" className="st3">
                                                <path className="st4" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.graficoSelR === 'mA'
                                            ? this.state.intensidadRight.r0
                                                ? (Math.round((this.state.intensidadRight.r0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.intensidadRightPorcent.r0
                                                ? (Math.round((this.state.intensidadRightPorcent.r0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_positivo_7_" className="st1" d="M108.76,440.16h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,440.89,109.34,440.16,108.76,440.16z"/>
                                        </g>
                                    }
                                    { this.state.right.leads.r0 === 'neutral' &&
                                        <g id="Polo_1_off" className="st2">
                                            <path className="st6" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                            <path className="st5 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 434.3762)" className="st1 st7 st8">1</text>
                                    <g id="Tapa_14_">
                                        <ellipse className="st9" cx="102.8" cy="418.83" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,412.48c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,414.66,89.74,412.48,102.8,412.48 M102.8,411.48c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,411.48,102.8,411.48L102.8,411.48z"/>
                                    </g>
                                </g>
                                <g id="Polo_2">
                                    { this.state.right.leads.r1 === 'negative' &&
                                        <g id="Polo_2_negativo">
                                            <g id="Fondo_negativo_6_">
                                                <path className="st0" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelR === 'mA'
                                                ? this.state.intensidadRight.r1
                                                    ? (Math.round((this.state.intensidadRight.r1 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadRightPorcent.r1
                                                    ? (Math.round((this.state.intensidadRightPorcent.r1 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_6_" className="st1" d="M109.81,386.6c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,384.74,109.81,385.58,109.81,386.6z"/>
                                        </g>
                                    }
                                    { this.state.right.leads.r1 === 'positive' &&
                                        <g id="Polo_2_positivo" className="st2">
                                            <g id="Fondo_positivo_6_" className="st3">
                                                <path className="st4" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelR === 'mA'
                                                ? this.state.intensidadRight.r1
                                                    ? (Math.round((this.state.intensidadRight.r1 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadRightPorcent.r1
                                                    ? (Math.round((this.state.intensidadRightPorcent.r1 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_6_" className="st1" d="M108.76,385.12h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,385.84,109.34,385.12,108.76,385.12z"/>
                                        </g>
                                    }
                                    { this.state.right.leads.r1 === 'neutral' &&
                                        <g id="Polo_2_off" className="st2">
                                            <path className="st6" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                            <path className="st5 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 379.3322)" className="st1 st7 st8">2</text>
                                    <g id="Tapa_12_">
                                        <path className="st9" d="M102.8,370.64c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,368.5,115.7,370.64,102.8,370.64z"/>
                                        <path className="st1 strokeBlack" d="M102.8,357.44c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,357.44,102.8,357.44 M102.8,356.44c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,356.44,102.8,356.44L102.8,356.44z"/>
                                    </g>
                                </g>
                                <g id="Polo_3">
                                    { this.state.right.leads.r2 === 'negative' &&
                                        <g id="Polo_3_negativo" className="st2">
                                            <g id="Fondo_negativo_5_" className="st3">
                                                <path className="st0" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelR === 'mA'
                                                ? this.state.intensidadRight.r2
                                                    ? (Math.round((this.state.intensidadRight.r2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadRightPorcent.r2
                                                    ? (Math.round((this.state.intensidadRightPorcent.r2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_5_" className="st1" d="M109.81,331.56c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,329.7,109.81,330.53,109.81,331.56z"/>
                                        </g>
                                    }
                                    { this.state.right.leads.r2 === 'positive' &&
                                        <g id="Polo_3_positivo">
                                            <g id="Fondo_positivo_5_">
                                                <path className="st4" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelR === 'mA'
                                                ? this.state.intensidadRight.r2
                                                    ? (Math.round((this.state.intensidadRight.r2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadRightPorcent.r2
                                                    ? (Math.round((this.state.intensidadRightPorcent.r2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_5_" className="st1" d="M108.76,330.07h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,330.8,109.34,330.07,108.76,330.07z"/>
                                        </g>
                                    }
                                    { this.state.right.leads.r2 === 'neutral' &&
                                        <g id="Polo_3_off" className="st2">
                                            <path className="st6" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                            <path className="st5 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 324.2883)" className="st1 st7 st8">3</text>
                                    <g id="Tapa_10_">
                                        <path className="st9" d="M102.8,315.6c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,313.45,115.7,315.6,102.8,315.6z"/>
                                        <path className="st1 strokeBlack" d="M102.8,302.39c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,304.57,89.74,302.39,102.8,302.39 M102.8,301.39c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,301.39,102.8,301.39L102.8,301.39z"/>
                                    </g>
                                </g>
                                <g id="Polo_4">
                                    { this.state.right.leads.r3 === 'negative' &&
                                        <g id="Polo_4_negativo" className="st2">
                                            <g id="Fondo_negativo_4_" className="st3">
                                                <path className="st0" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelR === 'mA'
                                                ? this.state.intensidadRight.r3
                                                    ? (Math.round((this.state.intensidadRight.r3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadRightPorcent.r3
                                                    ? (Math.round((this.state.intensidadRightPorcent.r3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_4_" className="st1" d="M109.81,276.52c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,274.65,109.81,275.49,109.81,276.52z"/>
                                        </g>
                                    }
                                    { this.state.right.leads.r3 === 'positive' &&
                                        <g id="Polo_4_positivo" className="st2">
                                            <g id="Fondo_positivo_4_" className="st3">
                                                <path className="st4" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelR === 'mA'
                                                ? this.state.intensidadRight.r3
                                                    ? (Math.round((this.state.intensidadRight.r3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadRightPorcent.r3
                                                    ? (Math.round((this.state.intensidadRightPorcent.r3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_4_" className="st1" d="M108.76,275.03h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,275.75,109.34,275.03,108.76,275.03z"/>
                                        </g>
                                    }
                                    { this.state.right.leads.r3 === 'neutral' &&
                                        <g id="Polo_4_off">
                                            <path className="st10" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                            <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"/>
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 269.2434)" className="st1 st7 st8">4</text>
                                    <g id="Tapa_8_">
                                        <path className="st9" d="M102.8,260.55c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,258.41,115.7,260.55,102.8,260.55z"/>
                                        <path className="st1 strokeBlack" d="M102.8,247.35c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,249.53,89.74,247.35,102.8,247.35 M102.8,246.35c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,246.35,102.8,246.35L102.8,246.35z"/>
                                    </g>
                                </g>
                                <g id="Polo_5">
                                    <g id="Polo_5_negativo" className="st2">
                                        <g id="Fondo_negativo_3_" className="st3">
                                            <path className="st0" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_3_" className="st5" d="M109.81,226.21c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,224.35,109.81,225.18,109.81,226.21z"/>
                                    </g>
                                    <g id="Polo_5_positivo" className="st2">
                                        <g id="Fondo_positivo_3_" className="st3">
                                            <path className="st4" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_3_" className="st5" d="M108.76,224.72h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,225.45,109.34,224.72,108.76,224.72z"/>
                                    </g>
                                    <g id="Polo_5_off">
                                        <path className="st10" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                        <path className="st1 strokeBlack" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 214.1994)" className="st1 st7 st8">5</text>
                                    <g id="Tapa_6_">
                                        <path className="st9" d="M102.8,205.51c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,203.36,115.7,205.51,102.8,205.51z"/>
                                        <path className="st1 strokeBlack" d="M102.8,192.31c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,192.31,102.8,192.31 M102.8,191.31c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,191.31,102.8,191.31L102.8,191.31z"/>
                                    </g>
                                </g>
                                <g id="Polo_6">
                                    <g id="Polo_6_negativo" className="st2">
                                        <g id="Fondo_negativo_2_" className="st3">
                                            <path className="st0" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_2_" className="st5" d="M109.81,171.16c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,169.3,109.81,170.14,109.81,171.16z"/>
                                    </g>
                                    <g id="Polo_6_positivo" className="st2">
                                        <g id="Fondo_positivo_2_" className="st3">
                                            <path className="st4" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_2_" className="st5" d="M108.76,169.68h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,170.4,109.34,169.68,108.76,169.68z"/>
                                    </g>
                                    <g id="Polo_6_off">
                                        <path className="st10" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                        <path className="st1 strokeBlack" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 159.156)" className="st1 st7 st8">6</text>
                                    <g id="Tapa_1_">
                                        <path className="st9" d="M102.8,150.46c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,148.32,115.7,150.46,102.8,150.46z"/>
                                        <path className="st1 strokeBlack" d="M102.8,137.26c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,139.44,89.74,137.26,102.8,137.26 M102.8,136.26c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,136.47,105.59,136.26,102.8,136.26L102.8,136.26z"/>
                                    </g>
                                </g>
                                <g id="Polo_7">
                                    <g id="Polo_7_negativo" className="st2">
                                        <g id="Fondo_negativo_1_" className="st3">
                                            <path className="st0" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_negativo_1_" className="st5" d="M109.81,116.12c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,114.26,109.81,115.09,109.81,116.12z"/>
                                    </g>
                                    <g id="Polo_7_positivo" className="st2">
                                        <g id="Fondo_positivo_1_" className="st3">
                                            <path className="st4" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_positivo_1_" className="st5" d="M108.76,114.63h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,115.36,109.34,114.63,108.76,114.63z"/>
                                    </g>
                                    <g id="Polo_7_off">
                                        <path className="st10" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                        <path className="st1 strokeBlack" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 104.1111)" className="st1 st7 st8">7</text>
                                    <g id="Tapa_4_">
                                        <path className="st9" d="M102.8,95.42c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,93.28,115.7,95.42,102.8,95.42z"/>
                                        <path className="st1 strokeBlack" d="M102.8,82.22c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,84.4,89.74,82.22,102.8,82.22 M102.8,81.22c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,81.42,105.59,81.22,102.8,81.22L102.8,81.22z"/>
                                    </g>
                                </g>
                                <g id="Polo_8">
                                    <g id="Polo_8_negativo" className="st2">
                                        <g id="Fondo_negativo" className="st3">
                                            <path className="st0" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_negativo" className="st5" d="M109.81,61.08c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,59.21,109.81,60.05,109.81,61.08z"/>
                                    </g>
                                    <g id="Polo_8_positivo" className="st2">
                                        <g id="Fondo_positivo" className="st3">
                                            <path className="st4" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_positivo" className="st5" d="M108.76,59.59h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74
                                            h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04
                                            v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,60.31,109.34,59.59,108.76,59.59z"/>
                                    </g>
                                    <g id="Polo_8_off">
                                        <path className="st10" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                        <path className="st1 strokeBlack" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 49.0671)" className="st1 st7 st8">8</text>
                                    <g id="Tapa">
                                        <ellipse className="st9" cx="102.8" cy="33.52" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,27.17c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,27.17,102.8,27.17 M102.8,26.17c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,26.17,102.8,26.17L102.8,26.17z"/>
                                    </g>
                                </g>
                                <g id="Carcasa">
                                    <line className="st11 strokeBlack" x1="41.4" y1="10.25" x2="103.14" y2="10.25"/>
                                    <line className="st11 strokeBlack" x1="102.8" y1="27.17" x2="102.8" y2="10.25"/>
                                    {this.state.right.leads.rcase === 'positive' &&
                                        <>
                                            <path id="Carcasa_positivo" className="st12 strokeBlack" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                            <path id="Icono_positivo_8_" className="st1" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                        </>
                                    }
                                    {this.state.right.programador === 'interleaving' &&
                                        <>
                                        {
                                            this.state.right.leads2.rcase === 'positive' &&
                                            <>
                                                <path id="Carcasa_positivo" className="st12" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                                <path id="Icono_positivo_8_" className="st1" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                            </>
                                        }
                                        {
                                            this.state.right.leads2.rcase === 'neutral' && this.state.right.leads.rcase !== 'positive' &&
                                            <>
                                                <path id="Carcasa_off" className="st13" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                            </>
                                        }
                                        {
                                            this.state.right.leads2.rcase === 'neutral' && this.state.right.leads.rcase === 'positive' &&
                                            <>
                                                <path id="Carcasa_positivo" className="st12" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                                <path id="Icono_positivo_8_" className="st1" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                            </>
                                        }

                                        </>
                                    }
                                    {this.state.right.leads.rcase === 'neutral' && this.state.right.programador !== 'interleaving' &&
                                        <path id="Carcasa_off" className="st13 strokeBlack" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                    }
                                    <path id="Tapa_2_" className="st14 strokeBlack" d="M2.7,16.54c-1.22-20.84,39.46-20.84,39.45,0C23.01,16.54,2.7,16.54,2.7,16.54z"/>
                                    <text transform="matrix(1 0 0 1 14.4977 12.9934)" className="st1 st7 st8">IPG</text>
                                </g>
                            </svg>
						</div>
					}                                
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
					{this.state.ladoImpUniSel.key !== 'right' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
								<Label className="ms-fontSize-16 ms-fontWeight-bold">{t('equivalentamplitude.text')}: <span className="ms-fontWeight-regular">{this.state.resultadoL} mA</span></Label>
								<Label>{t('appliedformula.text')}: {this.state.formulaL}</Label>
							</div>
						</div>
					}
					{this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
								<Label className="ms-fontSize-16 ms-fontWeight-bold">{t('equivalentamplitude.text')}: <span className="ms-fontWeight-regular">{this.state.resultadoR} mA</span></Label>
								<Label>{t('appliedformula.text')}: {this.state.formulaR}</Label>
							</div>
						</div>
					}
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    {this.state.ladoImpUniSel.key !== 'right' &&
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <div>
                                <Fragment>
                                    <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                        <g id="Resistencias_positivas">
                                            { this.state.contPL === 4 &&
                                                <g id="R5_positivo">
                                                    <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>
                                            }
                                            { this.state.contPL >= 2 &&
                                                <g id="R4_positivo">
                                                    <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43 " className="strokeBlack"/>
                                                </g>
                                            }
                                            { this.state.contPL === 1 || this.state.contPL === 3 ?
                                                <g id="R3_positivo">
                                                    <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                </g>
                                                : ''
                                            }
                                            
                                            { this.state.contPL >= 2 &&
                                                <g id="R2_positivo">
                                                    <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>
                                            }

                                            { this.state.contPL === 4 &&
                                                <g id="R1_positivo">
                                                    <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>
                                            }

                                            { this.state.contPL < 1 &&
                                                <>
                                                    <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlack"/>
                                                </>
                                            }
                                            <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
    h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlack"/>
                                            
                                        </g>
                                        <g id="Resistencias_negativas">
                                            {this.state.contNL === 4 &&
                                                <g id="R5_negativo">
                                                <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43" className="strokeBlack"/>
                                                <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlack"/>
                                            </g>}
                                            {this.state.contNL >= 2 &&
                                                <g id="R4_negativo">
                                                    <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                            </g>}
                                            {this.state.contNL === 1 || this.state.contNL === 3 ?
                                                <g id="R3_negativo">
                                                <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                            </g>:''}
                                            {this.state.contNL >= 2 &&
                                                <g id="R2_negativo">
                                                    <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                            </g>}
                                            {this.state.contNL === 4 &&
                                                <g id="R1_negativo">
                                                    <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                                <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43"  className="strokeBlack"/>
                                            </g>}
                                            { this.state.contNL < 1 &&
                                                <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlack"/>
                                            }
                                            <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
    c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlack"/>
                                            
                                        </g>
                                        <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlack"/>
                                        <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlack"/>
                                        <g id="Carcasa">
                                            <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                            <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlack"/>
                                            <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlack"/>
                                            <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlack"/>
                                            <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                            <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                        </g>
                                        <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlack"/>
                                        <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlack"/>
                                        <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlack"/>
                                    </svg>
                                </Fragment>
                            </div>
                        </div>
                    }
                    {this.state.ladoImpUniSel.key !== 'left' &&
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <div>
                                <Fragment>
                                    <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                        <g id="Resistencias_positivas">
                                            { this.state.contPR === 4 &&
                                                <g id="R5_positivo">
                                                    <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>
                                            }
                                            { this.state.contPR >= 2 &&
                                                <g id="R4_positivo">
                                                    <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>
                                            }
                                            { this.state.contPR === 1 || this.state.contPR === 3 ?
                                                <g id="R3_positivo">
                                                    <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                </g>
                                                :''
                                            }
                                            
                                            { this.state.contPR >= 2 &&
                                                <g id="R2_positivo">
                                                    <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>
                                            }

                                            { this.state.contPR === 4 &&
                                                <g id="R1_positivo">
                                                    <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>
                                            }

                                            { this.state.contPR < 1 &&
                                                <>
                                                    <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlack"/>
                                                </>
                                            }
                                            <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                            c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                            h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlack"/>
                                            
                                        </g>
                                        <g id="Resistencias_negativas">
                                            {this.state.contNR === 4 &&
                                                <g id="R5_negativo">
                                                    <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43"/>
                                                <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlack"/>
                                            </g>}
                                            {this.state.contNR >= 2 &&
                                                <g id="R4_negativo">
                                                    <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                            </g>}
                                            {this.state.contNR === 1 || this.state.contNR === 3 ?
                                                <g id="R3_negativo">
                                                <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                            </g>:''}
                                            {this.state.contNR >= 2 &&
                                                <g id="R2_negativo">
                                                    <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                            </g>}
                                            {this.state.contNR === 4 &&
                                                <g id="R1_negativo">
                                                    <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                                <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                            </g>}
                                            { this.state.contNR < 1 &&
                                                <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlack"/>
                                            }
                                            <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlack"/>
                                        </g>
                                        <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlack"/>
                                        <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlack"/>
                                        <g id="Carcasa">
                                            <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlack"/>
                                            <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlack"/>
                                            <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlack"/>
                                            <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                                <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                        </g>
                                        <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlack"/>
                                        <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlack"/>
                                        <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlack"/>
                                    </svg>
                                </Fragment>
                            </div>
						</div>
					}
                </div>
                {(this.state.left.programador === 'interleaving' && (this.state.left.pulse !== this.state.left.pulse2)) || (this.state.right.programador === 'interleaving' && (this.state.right.pulse !== this.state.right.pulse2)) ?
                <>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
						<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
					{this.state.ladoImpUniSel.key !== 'right'  && (this.state.left.programador === 'interleaving' && (this.state.left.pulse !== this.state.left.pulse2)) &&
                        /** */
                        <>
                            <br></br>
                            <div className="ms-Grid-row ">
                                {
                                    this.state.tipoProgramacionSel !== 'ma'
                                    ?
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('stream.label')}:</Label></div>
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={this.state.resultadoL} /></div>
                                            </div>
                                        </div>
                                    : <></>
                                }
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={this.state.left.amplitude} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.left.rateNew ? this.state.left.rateNew : this.state.left.rate} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.left.pulse} /></div>
                                    </div>
                                </div>
                            </div>
                            <Separator></Separator>
							<svg version="1.1" id="Layer_1" width="100%" height="650px" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="25 0 130.49 471.64">
                                <g id="Polo_1">
                                    { this.state.left.leads2.l0 === 'negative' &&
                                        <g id="Polo_1_negativo">
                                            <g id="Fondo_negativo_7_">
                                                <path className="st0" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.graficoSelL2 === 'mA'
                                            ? this.state.intensidadLeft2.l0
                                                ? (Math.round((this.state.intensidadLeft2.l0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.intensidadLeftPorcent2.l0
                                                ? (Math.round((this.state.intensidadLeftPorcent2.l0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_negativo_7_" className="st1" d="M109.81,441.65c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86 c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,439.79,109.81,440.62,109.81,441.65z"/>
                                        </g>
                                    }
                                    { this.state.left.leads2.l0 === 'positive' &&
                                        <g id="Polo_1_positivo" className="st2">
                                            <g id="Fondo_positivo_7_" className="st3">
                                                <path className="st4" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.graficoSelL2 === 'mA'
                                            ? this.state.intensidadLeft2.l0
                                                ? (Math.round((this.state.intensidadLeft2.l0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.intensidadLeftPorcent2.l0
                                                ? (Math.round((this.state.intensidadLeftPorcent2.l0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_positivo_7_" className="st1" d="M108.76,440.16h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,440.89,109.34,440.16,108.76,440.16z"/>
                                        </g>
                                    }
                                    { this.state.left.leads2.l0 === 'neutral' &&
                                        <g id="Polo_1_off" className="st2">
                                            <path className="st6" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                            <path className="st5 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 434.3762)" className="st1 st7 st8">1</text>
                                    <g id="Tapa_14_">
                                        <ellipse className="st9" cx="102.8" cy="418.83" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,412.48c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,414.66,89.74,412.48,102.8,412.48 M102.8,411.48c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,411.48,102.8,411.48L102.8,411.48z"/>
                                    </g>
                                </g>
                                <g id="Polo_2">
                                    { this.state.left.leads2.l1 === 'negative' &&
                                        <g id="Polo_2_negativo">
                                            <g id="Fondo_negativo_6_">
                                                <path className="st0" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.graficoSelL2 === 'mA'
                                            ? this.state.intensidadLeft2.l1
                                                ? (Math.round((this.state.intensidadLeft2.l1 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.intensidadLeftPorcent2.l1
                                                ? (Math.round((this.state.intensidadLeftPorcent2.l1 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_negativo_6_" className="st1" d="M109.81,386.6c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,384.74,109.81,385.58,109.81,386.6z"/>
                                        </g>
                                    }
                                    { this.state.left.leads2.l1 === 'positive' &&
                                        <g id="Polo_2_positivo" className="st2">
                                            <g id="Fondo_positivo_6_" className="st3">
                                                <path className="st4" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.graficoSelL2 === 'mA'
                                            ? this.state.intensidadLeft2.l1
                                                ? (Math.round((this.state.intensidadLeft2.l1 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.intensidadLeftPorcent2.l1
                                                ? (Math.round((this.state.intensidadLeftPorcent2.l1 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_positivo_6_" className="st1" d="M108.76,385.12h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,385.84,109.34,385.12,108.76,385.12z"/>
                                        </g>
                                    }
                                    { this.state.left.leads2.l1 === 'neutral' &&
                                        <g id="Polo_2_off" className="st2">
                                            <path className="st6" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                            <path className="st5 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 379.3322)" className="st1 st7 st8">2</text>
                                    <g id="Tapa_12_">
                                        <path className="st9" d="M102.8,370.64c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,368.5,115.7,370.64,102.8,370.64z"/>
                                        <path className="st1 strokeBlack" d="M102.8,357.44c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,357.44,102.8,357.44 M102.8,356.44c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,356.44,102.8,356.44L102.8,356.44z"/>
                                    </g>
                                </g>
                                <g id="Polo_3">
                                    { this.state.left.leads2.l2 === 'negative' &&
                                        <g id="Polo_3_negativo" className="st2">
                                            <g id="Fondo_negativo_5_" className="st3">
                                                <path className="st0" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelL2 === 'mA'
                                                ? this.state.intensidadLeft2.l2
                                                    ? (Math.round((this.state.intensidadLeft2.l2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadLeftPorcent2.l2
                                                    ? (Math.round((this.state.intensidadLeftPorcent2.l2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_5_" className="st1" d="M109.81,331.56c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,329.7,109.81,330.53,109.81,331.56z"/>
                                        </g>
                                    }
                                    { this.state.left.leads2.l2 === 'positive' &&
                                        <g id="Polo_3_positivo">
                                            <g id="Fondo_positivo_5_">
                                                <path className="st4" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelL2 === 'mA'
                                                ? this.state.intensidadLeft2.l2
                                                    ? (Math.round((this.state.intensidadLeft2.l2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadLeftPorcent2.l2
                                                    ? (Math.round((this.state.intensidadLeftPorcent2.l2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_5_" className="st1" d="M108.76,330.07h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,330.8,109.34,330.07,108.76,330.07z"/>
                                        </g>
                                    }
                                    { this.state.left.leads2.l2 === 'neutral' &&
                                        <g id="Polo_3_off" className="st2">
                                            <path className="st6" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                            <path className="st5 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 324.2883)" className="st1 st7 st8">3</text>
                                    <g id="Tapa_10_">
                                        <path className="st9" d="M102.8,315.6c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,313.45,115.7,315.6,102.8,315.6z"/>
                                        <path className="st1 strokeBlack" d="M102.8,302.39c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,304.57,89.74,302.39,102.8,302.39 M102.8,301.39c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,301.39,102.8,301.39L102.8,301.39z"/>
                                    </g>
                                </g>
                                <g id="Polo_4">
                                    { this.state.left.leads2.l3 === 'negative' &&
                                        <g id="Polo_4_negativo" className="st2">
                                            <g id="Fondo_negativo_4_" className="st3">
                                                <path className="st0" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelL2 === 'mA'
                                                ? this.state.intensidadLeft2.l3
                                                    ? (Math.round((this.state.intensidadLeft2.l3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadLeftPorcent2.l3
                                                    ? (Math.round((this.state.intensidadLeftPorcent2.l3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_4_" className="st1" d="M109.81,276.52c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,274.65,109.81,275.49,109.81,276.52z"/>
                                        </g>
                                    }
                                    { this.state.left.leads2.l3 === 'positive' &&
                                        <g id="Polo_4_positivo" className="st2">
                                            <g id="Fondo_positivo_4_" className="st3">
                                                <path className="st4" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelL2 === 'mA'
                                                ? this.state.intensidadLeft2.l3
                                                    ? (Math.round((this.state.intensidadLeft2.l3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadLeftPorcent2.l3
                                                    ? (Math.round((this.state.intensidadLeftPorcent2.l3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_4_" className="st1" d="M108.76,275.03h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,275.75,109.34,275.03,108.76,275.03z"/>
                                        </g>
                                    }
                                    { this.state.left.leads2.l3 === 'neutral' &&
                                        <g id="Polo_4_off">
                                            <path className="st10" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                            <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"/>
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 269.2434)" className="st1 st7 st8">4</text>
                                    <g id="Tapa_8_">
                                        <path className="st9" d="M102.8,260.55c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,258.41,115.7,260.55,102.8,260.55z"/>
                                        <path className="st1 strokeBlack" d="M102.8,247.35c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,249.53,89.74,247.35,102.8,247.35 M102.8,246.35c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,246.35,102.8,246.35L102.8,246.35z"/>
                                    </g>
                                </g>
                                <g id="Polo_5">
                                    <g id="Polo_5_negativo" className="st2">
                                        <g id="Fondo_negativo_3_" className="st3">
                                            <path className="st0" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_3_" className="st5" d="M109.81,226.21c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,224.35,109.81,225.18,109.81,226.21z"/>
                                    </g>
                                    <g id="Polo_5_positivo" className="st2">
                                        <g id="Fondo_positivo_3_" className="st3">
                                            <path className="st4" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_3_" className="st5" d="M108.76,224.72h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,225.45,109.34,224.72,108.76,224.72z"/>
                                    </g>
                                    <g id="Polo_5_off">
                                        <path className="st10" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                        <path className="st1 strokeBlack" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 214.1994)" className="st1 st7 st8">5</text>
                                    <g id="Tapa_6_">
                                        <path className="st9" d="M102.8,205.51c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,203.36,115.7,205.51,102.8,205.51z"/>
                                        <path className="st1 strokeBlack" d="M102.8,192.31c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,192.31,102.8,192.31 M102.8,191.31c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,191.31,102.8,191.31L102.8,191.31z"/>
                                    </g>
                                </g>
                                <g id="Polo_6">
                                    <g id="Polo_6_negativo" className="st2">
                                        <g id="Fondo_negativo_2_" className="st3">
                                            <path className="st0" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_2_" className="st5" d="M109.81,171.16c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,169.3,109.81,170.14,109.81,171.16z"/>
                                    </g>
                                    <g id="Polo_6_positivo" className="st2">
                                        <g id="Fondo_positivo_2_" className="st3">
                                            <path className="st4" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_2_" className="st5" d="M108.76,169.68h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,170.4,109.34,169.68,108.76,169.68z"/>
                                    </g>
                                    <g id="Polo_6_off">
                                        <path className="st10" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                        <path className="st1 strokeBlack" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 159.156)" className="st1 st7 st8">6</text>
                                    <g id="Tapa_1_">
                                        <path className="st9" d="M102.8,150.46c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,148.32,115.7,150.46,102.8,150.46z"/>
                                        <path className="st1 strokeBlack" d="M102.8,137.26c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,139.44,89.74,137.26,102.8,137.26 M102.8,136.26c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,136.47,105.59,136.26,102.8,136.26L102.8,136.26z"/>
                                    </g>
                                </g>
                                <g id="Polo_7">
                                    <g id="Polo_7_negativo" className="st2">
                                        <g id="Fondo_negativo_1_" className="st3">
                                            <path className="st0" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_negativo_1_" className="st5" d="M109.81,116.12c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,114.26,109.81,115.09,109.81,116.12z"/>
                                    </g>
                                    <g id="Polo_7_positivo" className="st2">
                                        <g id="Fondo_positivo_1_" className="st3">
                                            <path className="st4" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_positivo_1_" className="st5" d="M108.76,114.63h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,115.36,109.34,114.63,108.76,114.63z"/>
                                    </g>
                                    <g id="Polo_7_off">
                                        <path className="st10" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                        <path className="st1 strokeBlack" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 104.1111)" className="st1 st7 st8">7</text>
                                    <g id="Tapa_4_">
                                        <path className="st9" d="M102.8,95.42c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,93.28,115.7,95.42,102.8,95.42z"/>
                                        <path className="st1 strokeBlack" d="M102.8,82.22c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,84.4,89.74,82.22,102.8,82.22 M102.8,81.22c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,81.42,105.59,81.22,102.8,81.22L102.8,81.22z"/>
                                    </g>
                                </g>
                                <g id="Polo_8">
                                    <g id="Polo_8_negativo" className="st2">
                                        <g id="Fondo_negativo" className="st3">
                                            <path className="st0" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_negativo" className="st5" d="M109.81,61.08c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,59.21,109.81,60.05,109.81,61.08z"/>
                                    </g>
                                    <g id="Polo_8_positivo" className="st2">
                                        <g id="Fondo_positivo" className="st3">
                                            <path className="st4" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_positivo" className="st5" d="M108.76,59.59h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74
                                            h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04
                                            v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,60.31,109.34,59.59,108.76,59.59z"/>
                                    </g>
                                    <g id="Polo_8_off">
                                        <path className="st10" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                        <path className="st1 strokeBlack" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 49.0671)" className="st1 st7 st8">8</text>
                                    <g id="Tapa">
                                        <ellipse className="st9" cx="102.8" cy="33.52" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,27.17c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,27.17,102.8,27.17 M102.8,26.17c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,26.17,102.8,26.17L102.8,26.17z"/>
                                    </g>
                                </g>
                                <g id="Carcasa">
                                    <line className="st11 strokeBlack" x1="41.4" y1="10.25" x2="103.14" y2="10.25"/>
                                    <line className="st11 strokeBlack" x1="102.8" y1="27.17" x2="102.8" y2="10.25"/>
                                    {this.state.left.leads2.lcase === 'positive' &&
                                        <>
                                            <path id="Carcasa_positivo" className="st12" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                            <path id="Icono_positivo_8_" className="st1 strokeBlack" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                        </>
                                    }
                                    {this.state.left.leads2.lcase === 'neutral' &&
                                        <path id="Carcasa_off" className="st13 strokeBlack" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                    }
                                    <path id="Tapa_2_" className="st14 strokeBlack" d="M2.7,16.54c-1.22-20.84,39.46-20.84,39.45,0C23.01,16.54,2.7,16.54,2.7,16.54z"/>
                                    <text transform="matrix(1 0 0 1 14.4977 12.9934)" className="st1 st7 st8">IPG</text>
                                </g>
                            </svg>
                        </>
					}
					</div>
				    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
					{this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving' && (this.state.right.pulse !== this.state.right.pulse2)) &&
                        <>
                            <br></br>
                            <div className="ms-Grid-row">
                                {
                                    this.state.tipoProgramacionSel !== 'ma'
                                    ?
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('stream.label')}:</Label></div>
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={this.state.resultadoR2} /></div>
                                            </div>
                                        </div>
                                    : <></>
                                }
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={this.state.right.amplitude} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.right.rateNew ? this.state.right.rateNew : this.state.right.rate} /></div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.right.pulse2} /></div>
                                    </div>
                                </div>
                            </div>
                            <Separator></Separator>
							<svg version="1.1" id="Layer_1" width="100%" height="650px" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="25 0 130.49 471.64">
                                <g id="Polo_1">
                                    { this.state.right.leads2.r0 === 'negative' &&
                                        <g id="Polo_1_negativo">
                                            <g id="Fondo_negativo_7_">
                                                <path className="st0" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.graficoSelR2 === 'mA'
                                            ? this.state.intensidadRight2.r0
                                                ? (Math.round((this.state.intensidadRight2.r0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.intensidadRightPorcent2.r0
                                                ? (Math.round((this.state.intensidadRightPorcent2.r0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_negativo_7_" className="st1" d="M109.81,441.65c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86 c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,439.79,109.81,440.62,109.81,441.65z"/>
                                        </g>
                                    }
                                    { this.state.right.leads2.r0 === 'positive' &&
                                        <g id="Polo_1_positivo" className="st2">
                                            <g id="Fondo_positivo_7_" className="st3">
                                                <path className="st4" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                <path className="st1 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                            {this.state.graficoSelR2 === 'mA'
                                            ? this.state.intensidadRight2.r0
                                                ? (Math.round((this.state.intensidadRight2.r0 + Number.EPSILON) * 10) / 10)+' mA'
                                                : ''
                                            : this.state.intensidadRightPorcent2.r0
                                                ? (Math.round((this.state.intensidadRightPorcent2.r0 + Number.EPSILON) * 100) / 100)+' %'
                                                : ''
                                            }
                                            </text>
                                            <path id="Icono_positivo_7_" className="st1" d="M108.76,440.16h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,440.89,109.34,440.16,108.76,440.16z"/>
                                        </g>
                                    }
                                    { this.state.right.leads2.r0 === 'neutral' &&
                                        <g id="Polo_1_off" className="st2">
                                            <path className="st6" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                            <path className="st5 strokeBlack" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 434.3762)" className="st1 st7 st8">1</text>
                                    <g id="Tapa_14_">
                                        <ellipse className="st9" cx="102.8" cy="418.83" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,412.48c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,414.66,89.74,412.48,102.8,412.48 M102.8,411.48c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,411.48,102.8,411.48L102.8,411.48z"/>
                                    </g>
                                </g>
                                <g id="Polo_2">
                                    { this.state.right.leads2.r1 === 'negative' &&
                                        <g id="Polo_2_negativo">
                                            <g id="Fondo_negativo_6_">
                                                <path className="st0" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelR2 === 'mA'
                                                ? this.state.intensidadRight2.r1
                                                    ? (Math.round((this.state.intensidadRight2.r1 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadRightPorcent2.r1
                                                    ? (Math.round((this.state.intensidadRightPorcent2.r1 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_6_" className="st1" d="M109.81,386.6c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,384.74,109.81,385.58,109.81,386.6z"/>
                                        </g>
                                    }
                                    { this.state.right.leads2.r1 === 'positive' &&
                                        <g id="Polo_2_positivo" className="st2">
                                            <g id="Fondo_positivo_6_" className="st3">
                                                <path className="st4" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                <path className="st1 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelR2 === 'mA'
                                                ? this.state.intensidadRight2.r1
                                                    ? (Math.round((this.state.intensidadRight2.r1 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadRightPorcent2.r1
                                                    ? (Math.round((this.state.intensidadRightPorcent2.r1 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_6_" className="st1" d="M108.76,385.12h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,385.84,109.34,385.12,108.76,385.12z"/>
                                        </g>
                                    }
                                    { this.state.right.leads2.r1 === 'neutral' &&
                                        <g id="Polo_2_off" className="st2">
                                            <path className="st6" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                            <path className="st5 strokeBlack" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 379.3322)" className="st1 st7 st8">2</text>
                                    <g id="Tapa_12_">
                                        <path className="st9" d="M102.8,370.64c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,368.5,115.7,370.64,102.8,370.64z"/>
                                        <path className="st1 strokeBlack" d="M102.8,357.44c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,357.44,102.8,357.44 M102.8,356.44c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,356.44,102.8,356.44L102.8,356.44z"/>
                                    </g>
                                </g>
                                <g id="Polo_3">
                                    { this.state.right.leads2.r2 === 'negative' &&
                                        <g id="Polo_3_negativo" className="st2">
                                            <g id="Fondo_negativo_5_" className="st3">
                                                <path className="st0" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelR2 === 'mA'
                                                ? this.state.intensidadRight2.r2
                                                    ? (Math.round((this.state.intensidadRight2.r2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadRightPorcent2.r2
                                                    ? (Math.round((this.state.intensidadRightPorcent2.r2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_5_" className="st1" d="M109.81,331.56c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,329.7,109.81,330.53,109.81,331.56z"/>
                                        </g>
                                    }
                                    { this.state.right.leads2.r2 === 'positive' &&
                                        <g id="Polo_3_positivo">
                                            <g id="Fondo_positivo_5_">
                                                <path className="st4" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                <path className="st1 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                    "/>
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelR2 === 'mA'
                                                ? this.state.intensidadRight2.r2
                                                    ? (Math.round((this.state.intensidadRight2.r2 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadRightPorcent2.r2
                                                    ? (Math.round((this.state.intensidadRightPorcent2.r2 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_5_" className="st1" d="M108.76,330.07h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,330.8,109.34,330.07,108.76,330.07z"/>
                                        </g>
                                    }
                                    { this.state.right.leads2.r2 === 'neutral' &&
                                        <g id="Polo_3_off" className="st2">
                                            <path className="st6" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                            <path className="st5 strokeBlack" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z"
                                                />
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 324.2883)" className="st1 st7 st8">3</text>
                                    <g id="Tapa_10_">
                                        <path className="st9" d="M102.8,315.6c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,313.45,115.7,315.6,102.8,315.6z"/>
                                        <path className="st1 strokeBlack" d="M102.8,302.39c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,304.57,89.74,302.39,102.8,302.39 M102.8,301.39c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,301.39,102.8,301.39L102.8,301.39z"/>
                                    </g>
                                </g>
                                <g id="Polo_4">
                                    { this.state.right.leads2.r3 === 'negative' &&
                                        <g id="Polo_4_negativo" className="st2">
                                            <g id="Fondo_negativo_4_" className="st3">
                                                <path className="st0" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelR2 === 'mA'
                                                ? this.state.intensidadRight2.r3
                                                    ? (Math.round((this.state.intensidadRight2.r3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadRightPorcent2.r3
                                                    ? (Math.round((this.state.intensidadRightPorcent2.r3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_negativo_4_" className="st1" d="M109.81,276.52c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,274.65,109.81,275.49,109.81,276.52z"/>
                                        </g>
                                    }
                                    { this.state.right.leads2.r3 === 'positive' &&
                                        <g id="Polo_4_positivo" className="st2">
                                            <g id="Fondo_positivo_4_" className="st3">
                                                <path className="st4" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                    />
                                            </g>
                                            <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                {this.state.graficoSelR2 === 'mA'
                                                ? this.state.intensidadRight2.r3
                                                    ? (Math.round((this.state.intensidadRight2.r3 + Number.EPSILON) * 10) / 10)+' mA'
                                                    : ''
                                                : this.state.intensidadRightPorcent2.r3
                                                    ? (Math.round((this.state.intensidadRightPorcent2.r3 + Number.EPSILON) * 100) / 100)+' %'
                                                    : ''
                                                }
                                            </text>
                                            <path id="Icono_positivo_4_" className="st1" d="M108.76,275.03h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,275.75,109.34,275.03,108.76,275.03z"/>
                                        </g>
                                    }
                                    { this.state.right.leads2.r3 === 'neutral' &&
                                        <g id="Polo_4_off">
                                            <path className="st10" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                            <path className="st1 strokeBlack" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"/>
                                        </g>
                                    }
                                    <text transform="matrix(1 0 0 1 78.7228 269.2434)" className="st1 st7 st8">4</text>
                                    <g id="Tapa_8_">
                                        <path className="st9" d="M102.8,260.55c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,258.41,115.7,260.55,102.8,260.55z"/>
                                        <path className="st1 strokeBlack" d="M102.8,247.35c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,249.53,89.74,247.35,102.8,247.35 M102.8,246.35c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35S105.59,246.35,102.8,246.35L102.8,246.35z"/>
                                    </g>
                                </g>
                                <g id="Polo_5">
                                    <g id="Polo_5_negativo" className="st2">
                                        <g id="Fondo_negativo_3_" className="st3">
                                            <path className="st0" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_3_" className="st5" d="M109.81,226.21c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,224.35,109.81,225.18,109.81,226.21z"/>
                                    </g>
                                    <g id="Polo_5_positivo" className="st2">
                                        <g id="Fondo_positivo_3_" className="st3">
                                            <path className="st4" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_3_" className="st5" d="M108.76,224.72h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,225.45,109.34,224.72,108.76,224.72z"/>
                                    </g>
                                    <g id="Polo_5_off">
                                        <path className="st10" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                        <path className="st1 strokeBlack" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 214.1994)" className="st1 st7 st8">5</text>
                                    <g id="Tapa_6_">
                                        <path className="st9" d="M102.8,205.51c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,203.36,115.7,205.51,102.8,205.51z"/>
                                        <path className="st1 strokeBlack" d="M102.8,192.31c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,192.31,102.8,192.31 M102.8,191.31c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,191.31,102.8,191.31L102.8,191.31z"/>
                                    </g>
                                </g>
                                <g id="Polo_6">
                                    <g id="Polo_6_negativo" className="st2">
                                        <g id="Fondo_negativo_2_" className="st3">
                                            <path className="st0" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_negativo_2_" className="st5" d="M109.81,171.16c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,169.3,109.81,170.14,109.81,171.16z"/>
                                    </g>
                                    <g id="Polo_6_positivo" className="st2">
                                        <g id="Fondo_positivo_2_" className="st3">
                                            <path className="st4" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                "/>
                                        </g>
                                        <path id="Icono_positivo_2_" className="st5" d="M108.76,169.68h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,170.4,109.34,169.68,108.76,169.68z"/>
                                    </g>
                                    <g id="Polo_6_off">
                                        <path className="st10" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                        <path className="st1 strokeBlack" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z"
                                            />
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 159.156)" className="st1 st7 st8">6</text>
                                    <g id="Tapa_1_">
                                        <path className="st9" d="M102.8,150.46c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,148.32,115.7,150.46,102.8,150.46z"/>
                                        <path className="st1 strokeBlack" d="M102.8,137.26c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,139.44,89.74,137.26,102.8,137.26 M102.8,136.26c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,136.47,105.59,136.26,102.8,136.26L102.8,136.26z"/>
                                    </g>
                                </g>
                                <g id="Polo_7">
                                    <g id="Polo_7_negativo" className="st2">
                                        <g id="Fondo_negativo_1_" className="st3">
                                            <path className="st0" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_negativo_1_" className="st5" d="M109.81,116.12c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,114.26,109.81,115.09,109.81,116.12z"/>
                                    </g>
                                    <g id="Polo_7_positivo" className="st2">
                                        <g id="Fondo_positivo_1_" className="st3">
                                            <path className="st4" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                        </g>
                                        <path id="Icono_positivo_1_" className="st5" d="M108.76,114.63h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                            v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                            c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,115.36,109.34,114.63,108.76,114.63z"/>
                                    </g>
                                    <g id="Polo_7_off">
                                        <path className="st10" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                        <path className="st1 strokeBlack" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 104.1111)" className="st1 st7 st8">7</text>
                                    <g id="Tapa_4_">
                                        <path className="st9" d="M102.8,95.42c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                            C129.58,93.28,115.7,95.42,102.8,95.42z"/>
                                        <path className="st1 strokeBlack" d="M102.8,82.22c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            C76.52,84.4,89.74,82.22,102.8,82.22 M102.8,81.22c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                            s27.28-0.21,27.28-7.35C130.08,81.42,105.59,81.22,102.8,81.22L102.8,81.22z"/>
                                    </g>
                                </g>
                                <g id="Polo_8">
                                    <g id="Polo_8_negativo" className="st2">
                                        <g id="Fondo_negativo" className="st3">
                                            <path className="st0" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_negativo" className="st5" d="M109.81,61.08c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,59.21,109.81,60.05,109.81,61.08z"/>
                                    </g>
                                    <g id="Polo_8_positivo" className="st2">
                                        <g id="Fondo_positivo" className="st3">
                                            <path className="st4" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                        </g>
                                        <path id="Icono_positivo" className="st5" d="M108.76,59.59h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74
                                            h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04
                                            v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,60.31,109.34,59.59,108.76,59.59z"/>
                                    </g>
                                    <g id="Polo_8_off">
                                        <path className="st10" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                            s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                        <path className="st1 strokeBlack" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                            c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                            S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                    </g>
                                    <text transform="matrix(1 0 0 1 78.7228 49.0671)" className="st1 st7 st8">8</text>
                                    <g id="Tapa">
                                        <ellipse className="st9" cx="102.8" cy="33.52" rx="26.78" ry="6.85"/>
                                        <path className="st1 strokeBlack" d="M102.8,27.17c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                            S89.74,27.17,102.8,27.17 M102.8,26.17c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                            S105.59,26.17,102.8,26.17L102.8,26.17z"/>
                                    </g>
                                </g>
                                <g id="Carcasa">
                                    <line className="st11 strokeBlack" x1="41.4" y1="10.25" x2="103.14" y2="10.25"/>
                                    <line className="st11 strokeBlack" x1="102.8" y1="27.17" x2="102.8" y2="10.25"/>
                                    {this.state.right.leads2.rcase === 'positive' &&
                                        <>
                                            <path id="Carcasa_positivo" className="st12 strokeBlack" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                            <path id="Icono_positivo_8_" className="st1" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                        </>
                                    }
                                    {this.state.right.leads2.rcase === 'neutral' &&
                                        <path id="Carcasa_off" className="st13 strokeBlack" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                    }
                                    <path id="Tapa_2_" className="st14 strokeBlack" d="M2.7,16.54c-1.22-20.84,39.46-20.84,39.45,0C23.01,16.54,2.7,16.54,2.7,16.54z"/>
                                    <text transform="matrix(1 0 0 1 14.4977 12.9934)" className="st1 st7 st8">IPG</text>
                                </g>
                            </svg>
                        </>
					}
					</div>                                
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'right' && (this.state.left.programador === 'interleaving' && (this.state.left.pulse !== this.state.left.pulse2)) &&
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Label className="ms-fontSize-16 ms-fontWeight-bold">{t('equivalentamplitude.text')}: <span className="ms-fontWeight-regular">{this.state.resultadoL2} mA</span></Label>
                                    <Label>{t('appliedformula.text')}: {this.state.formulaL2}</Label>
                                </div>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving' && (this.state.right.pulse !== this.state.right.pulse2)) &&
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Label className="ms-fontSize-16 ms-fontWeight-bold">{t('equivalentamplitude.text')}: <span className="ms-fontWeight-regular">{this.state.resultadoR2} mA</span></Label>
                                    <Label>{t('appliedformula.text')}: {this.state.formulaR2}</Label>
                                </div>
                        }
                    </div>
                </div>
                <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'right' && (this.state.left.programador === 'interleaving' && (this.state.left.pulse !== this.state.left.pulse2)) &&
                                <div>
                                    <Fragment>
                                        <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                            <g id="Resistencias_positivas">
                                                { this.state.contPL2 === 4 &&
                                                    <g id="R5_positivo">
                                                        <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }
                                                { this.state.contPL2 >= 2 &&
                                                    <g id="R4_positivo">
                                                        <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43 " className="strokeBlack"/>
                                                    </g>
                                                }
                                                { this.state.contPL2 === 1 || this.state.contPL2 === 3 ?
                                                    <g id="R3_positivo">
                                                        <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                    </g>
                                                    : ''
                                                }
                                                
                                                { this.state.contPL2 >= 2 &&
                                                    <g id="R2_positivo">
                                                        <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }

                                                { this.state.contPL2 === 4 &&
                                                    <g id="R1_positivo">
                                                        <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }

                                                { this.state.contPL2 < 1 &&
                                                    <>
                                                        <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlack"/>
                                                    </>
                                                }
                                                <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
        c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
        h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlack"/>
                                                
                                            </g>
                                            <g id="Resistencias_negativas">
                                                {this.state.contNL2 === 4 &&
                                                    <g id="R5_negativo">
                                                    <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.contNL2 >= 2 &&
                                                    <g id="R4_negativo">
                                                        <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.contNL2 === 1 || this.state.contNL2 === 3 ?
                                                    <g id="R3_negativo">
                                                    <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                </g>:''}
                                                {this.state.contNL2 >= 2 &&
                                                    <g id="R2_negativo">
                                                        <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.contNL2 === 4 &&
                                                    <g id="R1_negativo">
                                                        <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43"  className="strokeBlack"/>
                                                </g>}
                                                { this.state.contNL2 < 1 &&
                                                    <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlack"/>
                                                }
                                                <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
        c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlack"/>
                                                
                                            </g>
                                            <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlack"/>
                                            <g id="Carcasa">
                                                <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                    h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                                <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlack"/>
                                                <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlack"/>
                                                <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlack"/>
                                                <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                                <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                    h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                            </g>
                                            <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlack"/>
                                        </svg>
                                    </Fragment>
                                </div>
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        {this.state.ladoImpUniSel.key !== 'left' && (this.state.right.programador === 'interleaving' && (this.state.right.pulse !== this.state.right.pulse2)) &&
                                <div>
                                    <Fragment>
                                        <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                            <g id="Resistencias_positivas">
                                                { this.state.contPR2 === 4 &&
                                                    <g id="R5_positivo">
                                                        <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }
                                                { this.state.contPR2 >= 2 &&
                                                    <g id="R4_positivo">
                                                        <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }
                                                { this.state.contPR2 === 1 || this.state.contPR2 === 3 ?
                                                    <g id="R3_positivo">
                                                        <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                    </g>
                                                    :''
                                                }
                                                
                                                { this.state.contPR2 >= 2 &&
                                                    <g id="R2_positivo">
                                                        <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }

                                                { this.state.contPR2 === 4 &&
                                                    <g id="R1_positivo">
                                                        <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }

                                                { this.state.contPR2 < 1 &&
                                                    <>
                                                        <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlack"/>
                                                    </>
                                                }
                                                <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlack"/>
                                                
                                            </g>
                                            <g id="Resistencias_negativas">
                                                {this.state.contNR2 === 4 &&
                                                    <g id="R5_negativo">
                                                        <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43"/>
                                                    <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.contNR2 >= 2 &&
                                                    <g id="R4_negativo">
                                                        <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.contNR2 === 1 || this.state.contNR2 === 3 ?
                                                    <g id="R3_negativo">
                                                    <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                </g>:''}
                                                {this.state.contNR2 >= 2 &&
                                                    <g id="R2_negativo">
                                                        <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.contNR2 === 4 &&
                                                    <g id="R1_negativo">
                                                        <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                { this.state.contNR2 < 1 &&
                                                    <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlack"/>
                                                }
                                                <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
    c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlack"/>
                                            </g>
                                            <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlack"/>
                                            <g id="Carcasa">
                                                <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlack"/>
                                                <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlack"/>
                                                <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlack"/>
                                                <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                                    <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                    h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                            </g>
                                            <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlack"/>
                                        </svg>
                                    </Fragment>
                                </div>
                        }
                    </div>
                </div>
                </>
                : <></>}
                {this.state.tipoProgramacionSel.key === 'v' ? 
                <>
                        <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                             <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                 <Separator></Separator>
                                 <Label className="ms-fontSize-24 ms-fontWeight-regular" >
                                     {t('finalImpedancia.text')}
                                     <IconButton
                                         title="Info"
                                         ariaLabel="Info"
                                         id={'infoPrinci2'}
                                         onClick={this.visibleCalloutInfoPrinci2}
                                         iconProps={iconProps}
                                     />
                                 </Label>
                                 {this.state.isCalloutVisibleInfoPrinci2 && (
                                     <Callout
                                     target={'#infoPrinci2'}
                                     setInitialFocus
                                     ariaDescribedBy={'Teat'}
                                     role="alertdialog"
                                     style={{padding: '3%'}}
                                     >
                                         <Stack horizontalAlign="start" >
                                             <span>{t('pFin.alert')}</span>
                                             <br></br>
                                             <DefaultButton onClick={this.invisibleCalloutInfoPrinci2}>{t('p2.btn')}</DefaultButton>
                                         </Stack>
                                     </Callout>
                                 )}
                             </div>
                        </div> 
                        <div className="ms-Grid-row" style={{textAlign: 'left'}}>
                            {this.state.ladoImpUniSel.key !== 'right' &&
                                <>
                            
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							            <Label className="ms-fontSize-20 ms-fontWeight-regular"><u>{t('left.text')}</u></Label>
						            </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('finalCorriente.text')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={this.state.configAnt.left.terapiaCorriente.l} /></div>
                                        </div>
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('finalImpedancia.text')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'Ω'} ariaLabel="" disabled={true} value={this.state.configAnt.left.terapiaImpedancia.l} /></div>
                                        </div>
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={this.state.estadoAnt.left.amplitude} /></div>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.estadoAnt.left.rateNew ? this.state.estadoAnt.left.rateNew : this.state.estadoAnt.left.rate} /></div>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.estadoAnt.left.pulse} /></div>
                                        </div>
                                    </div>

                                    <Fragment>
                                        <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                            <g id="Resistencias_positivas">
                                                { this.state.contPR2 === 4 &&
                                                    <g id="R5_positivo">
                                                        <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }
                                                { this.state.contPR2 >= 2 &&
                                                    <g id="R4_positivo">
                                                        <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }
                                                { this.state.contPR2 === 1 || this.state.contPR2 === 3 ?
                                                    <g id="R3_positivo">
                                                        <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                    </g>
                                                    :''
                                                }
                                                
                                                { this.state.contPR2 >= 2 &&
                                                    <g id="R2_positivo">
                                                        <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }

                                                { this.state.contPR2 === 4 &&
                                                    <g id="R1_positivo">
                                                        <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }

                                                { this.state.contPR2 < 1 &&
                                                    <>
                                                        <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlack"/>
                                                    </>
                                                }
                                                <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlack"/>
                                                
                                            </g>
                                            <g id="Resistencias_negativas">
                                                {this.state.contNR2 === 4 &&
                                                    <g id="R5_negativo">
                                                        <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43"/>
                                                    <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.contNR2 >= 2 &&
                                                    <g id="R4_negativo">
                                                        <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.contNR2 === 1 || this.state.contNR2 === 3 ?
                                                    <g id="R3_negativo">
                                                    <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                </g>:''}
                                                {this.state.contNR2 >= 2 &&
                                                    <g id="R2_negativo">
                                                        <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.contNR2 === 4 &&
                                                    <g id="R1_negativo">
                                                        <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                { this.state.contNR2 < 1 &&
                                                    <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlack"/>
                                                }
                                                <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
    c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlack"/>
                                            </g>
                                            <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlack"/>
                                            <g id="Carcasa">
                                                <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlack"/>
                                                <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlack"/>
                                                <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlack"/>
                                                <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                                    <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                    h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                            </g>
                                            <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlack"/>
                                        </svg>
                                    </Fragment>
                                </div>
                                </>
                            }

                            {this.state.ladoImpUniSel.key !== 'left' &&
                                <>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
							            <Label className="ms-fontSize-20 ms-fontWeight-regular"><u>{t('right.text')}</u></Label>
						            </div>

                                    
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('finalCorriente.text')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={this.state.configAnt.right.terapiaCorriente.r} /></div>
                                        </div>
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('finalImpedancia.text')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'Ω'} ariaLabel="" disabled={true} value={this.state.configAnt.right.terapiaImpedancia.r} /></div>
                                        </div>
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={this.state.estadoAnt.left.amplitude} /></div>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.estadoAnt.left.rateNew ? this.state.estadoAnt.left.rateNew : this.state.estadoAnt.left.rate} /></div>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.estadoAnt.left.pulse} /></div>
                                        </div>
                                    </div>
                                    
                                    <Fragment>
                                        <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                            <g id="Resistencias_positivas">
                                                { this.state.contPR2 === 4 &&
                                                    <g id="R5_positivo">
                                                        <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }
                                                { this.state.contPR2 >= 2 &&
                                                    <g id="R4_positivo">
                                                        <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }
                                                { this.state.contPR2 === 1 || this.state.contPR2 === 3 ?
                                                    <g id="R3_positivo">
                                                        <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlack"/>
                                                    </g>
                                                    :''
                                                }
                                                
                                                { this.state.contPR2 >= 2 &&
                                                    <g id="R2_positivo">
                                                        <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }

                                                { this.state.contPR2 === 4 &&
                                                    <g id="R1_positivo">
                                                        <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                        <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                        <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlack"/>
                                                        <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlack"/>
                                                    </g>
                                                }

                                                { this.state.contPR2 < 1 &&
                                                    <>
                                                        <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlack"/>
                                                    </>
                                                }
                                                <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlack"/>
                                                
                                            </g>
                                            <g id="Resistencias_negativas">
                                                {this.state.contNR2 === 4 &&
                                                    <g id="R5_negativo">
                                                        <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43"/>
                                                    <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.contNR2 >= 2 &&
                                                    <g id="R4_negativo">
                                                        <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.contNR2 === 1 || this.state.contNR2 === 3 ?
                                                    <g id="R3_negativo">
                                                    <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlack"/>
                                                </g>:''}
                                                {this.state.contNR2 >= 2 &&
                                                    <g id="R2_negativo">
                                                        <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                {this.state.contNR2 === 4 &&
                                                    <g id="R1_negativo">
                                                        <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                                    <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlack"/>
                                                    <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlack"/>
                                                    <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43" className="strokeBlack"/>
                                                </g>}
                                                { this.state.contNR2 < 1 &&
                                                    <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlack"/>
                                                }
                                                <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
    c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlack"/>
                                            </g>
                                            <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlack"/>
                                            <g id="Carcasa">
                                                <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlack"/>
                                                <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlack"/>
                                                <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlack"/>
                                                <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                                    <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                    h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                            </g>
                                            <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlack"/>
                                            <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlack"/>
                                        </svg>
                                    </Fragment>

                                </div>
                            
                                </>
                            }
                        </div>
                    </>
                    :<></>   
            }
                <div className="ms-Grid-row" style={{marginBottom: '2%'}}>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        <Link
                            to={{
                                pathname: "/conversionfinal",
                                state: this.state
                            }}
                        >
                            <PrimaryButton text={t('return.btn')}/>
                        </Link>
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                        <DefaultButton onClick={this.descargarImg} text={t('download.btn')}/>
                    </div>
                    
                </div>
				
			</div>
		</div>
            </>
        )
    }
}

export default withTranslation()(VerInforme);