import React, {Suspense} from 'react';
import '../App.css';
import '../styles/style.css'
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Link } from 'react-router-dom';
import { PrimaryButton, createTheme, loadTheme, Label, Dropdown, DatePicker, DefaultButton} from '@fluentui/react';
import adaptadorB26 from '../images/Adaptador-B26.jpg';
import adaptadorM8 from '../images/Adaptador-M8.jpg';
// eslint-disable-next-line
import * as idb from 'idb';

import { withTranslation } from 'react-i18next'
import i18next from 'i18next'

import moment from 'moment';



const myThemeAnt = createTheme({
    palette: {
        themePrimary: '#303030',
        themeLighterAlt: '#e6e6e6',
        themeLighter: '#cfcfcf',
        themeLight: '#b9b9b9',
        themeTertiary: '#a2a2a2',
        themeSecondary: '#8b8b8b',
        themeDarkAlt: '#757575',
        themeDark: '#5e5e5e',
        themeDarker: '#474747',
        neutralLighterAlt: '#e1e2d2',
        neutralLighter: '#dddecf',
        neutralLight: '#d4d5c6',
        neutralQuaternaryAlt: '#c5c6b9',
        neutralQuaternary: '#bcbdb0',
        neutralTertiaryAlt: '#b5b6a9',
        neutralTertiary: '#594949',
        neutralSecondary: '#372b2b',
        neutralPrimaryAlt: '#2f2424',
        neutralPrimary: '#000000',
        neutralDark: '#151010',
        black: '#0b0808',
        white: '#e8e9d8',
    }});

loadTheme(myThemeAnt);

const DayPickerStrings = {
	months: [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	],
	shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],

	days: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],

	shortDays: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],

	goToToday: 'Ir a hoy',
	prevMonthAriaLabel: 'Mes anterior',
	nextMonthAriaLabel: 'Mes siguiente',
	prevYearAriaLabel: 'Año anterior ',
	nextYearAriaLabel: 'Año siguiente',
	closeButtonAriaLabel: 'Cerrar calendario',
	monthPickerHeaderAriaLabel: '{0}, select to change the year',
	yearPickerHeaderAriaLabel: '{0}, select to change the month',
};

/** TEXTO PARA EL CALENDARIO EN PORTUGUÉS */
const DayPickerStringsPt = {
	months: [
		'Janeiro',
		'Fevereiro',
		'Março',
		'Abril',
		'Maio',
		'Junho',
		'Julho',
		'Agosto',
		'Setembro',
		'Outubro',
		'Novembro',
		'Dezembro',
	],
	shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],

	days: ['Domigo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sabado'],

	shortDays: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],

	goToToday: 'Ir para hoje',
	prevMonthAriaLabel: 'Mês Anterior',
	nextMonthAriaLabel: 'No próximo mês',
	prevYearAriaLabel: 'Ano anterior ',
	nextYearAriaLabel: 'Ano seguinte',
	closeButtonAriaLabel: 'Calendário de encerramento',
	monthPickerHeaderAriaLabel: '{0}, select to change the year',
	yearPickerHeaderAriaLabel: '{0}, select to change the month',
};
const onFormatDate = (date?: Date): string => {
	return !date ? '' : date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear() % 100);
};

const today = new Date()

class ConversorMetronicP0 extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			idioma: i18next.language,
			tiposImplantes:[
				{key: 'uni', text: props.t('unilateral.text'), type: 'tipoImplanteSel'},
				{key: 'bil', text: props.t('bilateral.text'), type: 'tipoImplanteSel'}
			],
			ladoImpUni:[
				{key: 'left', text: props.t('left.text'), type: 'ladoImpUniSel'},
				{key: 'right', text: props.t('right.text'), type: 'ladoImpUniSel'}
			],
			patologias: [
				{ key: 'Parkinson', text: props.t('parkinson.text'), type: 'patologiaSel'},
				{ key: 'Temblor', text: props.t('essentialtremor.text'), type: 'patologiaSel'},
				{ key: 'Distonía', text: props.t('dystonia.text'), type: 'patologiaSel'},
				{ key: 'TOC', text: props.t('toc.text'), type: 'patologiaSel'},
				{ key: 'Otros', text: props.t('others.text'), type: 'patologiaSel'}
			],
			generadores: [
				{ key: 'Kinetra', text: props.t('kinetra.text'), type: 'generadorSel', adaptadorimg: adaptadorB26, adaptador: `${props.t('adapter.text')} B26`},
				{ key: 'Soletra', text: props.t('soletra.text'), type: 'generadorSel', adaptadorimg: adaptadorB26, adaptador: `${props.t('adapter.text')} B26`},
				{ key: 'SC', text: props.t('activesc.text'), type: 'generadorSel', adaptadorimg: adaptadorM8, adaptador: `${props.t('adapter.text')} M8`},
				{ key: 'PC', text: props.t('activepc.text'), type: 'generadorSel', adaptadorimg: adaptadorM8, adaptador: `${props.t('adapter.text')} M8`},
				{ key: 'RC', text: props.t('activerc.text'), type: 'generadorSel', adaptadorimg: adaptadorM8, adaptador: `${props.t('adapter.text')} M8`}
			],
			zonestimulada: [
				{ key: 'STN', text: props.t('snt.text'), type: 'zonestumulaSel'},
				{ key: 'Gpi', text: props.t('gpi.text'), type: 'zonestumulaSel' },
				{ key: 'VIM', text: props.t('vim.text'), type: 'zonestumulaSel' },
				{ key: 'Otro', text: props.t('other.text'), type: 'zonestumulaSel'}
			],
			patologiaSel: {},
			generadorSel: {},
			zonestumulaSel:{},
			fechaSel: '',
			tipoImplanteSel:{},
			ladoImpUniSel: {},
			diferenciaFecha :''
		}
		this.verladoImpUni = false
		this.adaptadorFrase = ''
		this.adaptadorImagen = ''
		this.muestraAdaptador = false
		this.onInputchange = this.onInputchange.bind(this)
		this.onInputchangeFecha = this.onInputchangeFecha.bind(this)
		this.onParseDateFromString = this.onParseDateFromString.bind(this)
	}
	
	onInputchange(event, item) {
		this.setState({[item.type]: item})
		if(item.adaptador){
			this.adaptadorFrase = this.props.t('use.text')+' '+ item.adaptador
			this.adaptadorImagen = item.adaptadorimg
		}
		if(item.type === 'tipoImplanteSel'){
			if(item.key === 'bil'){
				this.setState({ladoImpUniSel: {}})
			}
			
		}
	}
	onInputchangeFecha(event){
		const today = moment()
		const date = moment(event)

		var years = today.diff(date, 'year');
		date.add(years, 'years');

		var months = today.diff(date, 'months');
		date.add(months, 'months');

		var days = today.diff(date, 'days');
		const {t} = this.props

		this.setState({fechaSel: event})

		/*
		const {t} = this.props
		if(!event){
			return false;
		}
		
		this.setState({fechaSel: event})
		
		var fechaAct = new Date();
		if(fechaAct.getFullYear()+fechaAct.getMonth()+fechaAct.getDay() === event.getFullYear()+event.getMonth()+event.getDay()){
			this.setState({diferenciaFecha: t('date.last.replacement.label')+': hoy'})
			return false
		}
		var diferencia = fechaAct - event;
		
		var contdias = Math.floor(diferencia/(1000*60*60*24));
		var contmeses = Math.floor(diferencia/(1000*60*60*24*30));
		var contanyos = Math.floor(diferencia/(1000*60*60*24*365));
		
		var anio = event.getFullYear();
		var mes = event.getMonth();
		var mesAct = fechaAct.getMonth();
		var anioAct = fechaAct.getFullYear();
		
		for(var i = anio; i<=anioAct;i++){
			var bisiesto = false;
			if (((i % 4 === 0) && (i % 100 !== 0 )) || (i % 400 === 0)){bisiesto = true;}
			if (i!==anio && i!==anioAct){mes=0;mesAct=11;}else if (i === anioAct){mesAct=fechaAct.getMonth();}else if(i===anio){mesAct=11;}
			for(var x = mes;x<=mesAct;x++){
			if ((x === 3 || x === 5 || x === 8 || x === 10)){
				if (x !== fechaAct.getMonth() || i!==fechaAct.getFullYear()){contdias=contdias+1;}
				else if(x !== fechaAct.getMonth() && i===fechaAct.getFullYear()){contdias=contdias+1;}
				else if(x === fechaAct.getMonth() && i!==fechaAct.getFullYear()){contdias=contdias+1;}
				else{}
			}else{
				if(bisiesto && x === 1){contdias = contdias+2}else if (!bisiesto && x===1){contdias = contdias+3}
				else{}
			}
			}
		}
		if (event.getDate() > fechaAct.getDate()){contmeses = contmeses - 1;}
		if (contmeses>12){contmeses = contmeses%12}
		if (contdias>=31){contdias = contdias%31}
		*/
		//this.setState({diferenciaFecha: t('date.last.replacement.time.label')+(contanyos!==0?contanyos===1?contanyos+t('anio.label'):contanyos+t('anios.label'): '')+(contmeses!==0?contmeses === 1? contmeses+t('mes.label'):contmeses+t('meses.label'):'')+(contdias<=31?contdias!==0?contdias+t('dias.label'):'':'')})
		if(event){
			this.setState({
				diferenciaFecha: `${t('date.last.replacement.time.label')} ${years !== 0 ? years===1 ? years+t('anio.label') : years+t('anios.label')  : ''} ${months !== 0 ? months === 1 ? months+t('mes.label') : months+t('meses.label') : ''} ${days !== 0 ? days+t('dias.label') : years === 0 && months === 0 && days === 0 ? days+t('dias.label') : ''}`
			})
		}
		
	}

	onParseDateFromString(event){
		const date = this.state.fechaSel || new Date();
		const values = (event || '').trim().split('/');
		const day = event.length > 0 ? Math.max(1, Math.min(31, parseInt(values[0], 10))) : date.getDate();
		const month = event.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : date.getMonth();
		let year = event.length > 2 ? parseInt(values[2], 10) : date.getFullYear();
		if (year < 100) {
			year += date.getFullYear() - (date.getFullYear() % 100);
		}
		this.setState({fechaSel: new Date(year, month, day)})
		return new Date(year, month, day);
		
	}
	
	componentDidMount (){
		window.scrollTo(0, 0);
		console.info(process.env.REACT_APP_API_URL)
		var userLang = navigator.language || navigator.userLanguage; 
        const navLang = userLang.split('-')
		i18next.changeLanguage(sessionStorage.getItem('lang') ? sessionStorage.getItem('lang') : navLang[0]);
		this.setState({idioma: navLang[0], ladoImpUni:[
			{key: 'left', text: this.props.t('left.text'), type: 'ladoImpUniSel'},
			{key: 'right', text: this.props.t('right.text'), type: 'ladoImpUniSel'}
		]})
	}
	/*
	componentDidMount (){
		if (!('indexedDB' in window)) {
			console.info('This browser doesn\'t support IndexedDB');
		}else{
			const dbPromise = idb.openDB('registros', 3);

			dbPromise.then(function(db) {
				var tx = db.transaction('logs', 'readonly');
				var store = tx.objectStore('logs');
				return store.getAll();
			}).then(function(items) {
				console.log('Items by name:', items, items.length);
				if(items.length > 0){
					if ('serviceWorker' in navigator && 'SyncManager' in window) {
						console.info('enter1')
						navigator.serviceWorker.ready.then(function(reg) {
							console.info('enter2')
							return reg.sync.register('subida-datos');
						}).catch(function() {
							console.info('enter3')
							// system was unable to register for a sync,
							// this could be an OS-level restriction
							fetch('http://bscidatacenter.local.com/').then(function(response) {
								response.text().then(function(text) {
									console.info(text);
								});
							});
						}).then((rs) => {
							console.info('Background sync registered!');
							console.info(rs)
						});
					}else{
						console.info('serviceworker/sync not supported')
						//postDataFromThePage();
					}	
				}
			});
		}
	}
	*/

	siguente(){
        document.getElementById('next').click()
    }
    render() {
		const {t} = this.props
        return (
            <Suspense fallback="loading">
                <div className="ms-Grid-row" id="contentCenter">
                    <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg4"></div>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
								<Label className="ms-fontSize-16 ms-fontWeight-regular">{t('statistical.data.title')}</Label>
							</div>
						</div>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
							<Dropdown
								label={t('pathology.type.label')}
								options={[
									{ key: 'Parkinson', text: t('parkinson.text'), type: 'patologiaSel'},
									{ key: 'Temblor', text: t('essentialtremor.text'), type: 'patologiaSel'},
									{ key: 'Distonía', text: t('dystonia.text'), type: 'patologiaSel'},
									{ key: 'TOC', text: t('toc.text'), type: 'patologiaSel'},
									{ key: 'Otros', text: t('others.text'), type: 'patologiaSel'}
								]}
								name = 'opciones_patoligia'
								onChange={this.onInputchange}
								disabled={false}
								required={true}
							/>
							</div>
						</div>
						<br></br>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
							<Dropdown
								label={t('stimulation.zone.label')}
								options={this.state.zonestimulada}
								name='opciones1'
								onChange = {this.onInputchange}
								disabled={false}
								required={true}
							/>
							</div>
						</div>
						<br></br>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
								<Dropdown
								label={t('implant.type.label')}
								options={this.state.tiposImplantes}
								name='opciones1'
								onChange = {this.onInputchange}
								disabled={false}
								required={true}
							/>
							</div>
						</div>
						{this.state.tipoImplanteSel.key === 'uni' &&
							<>
								<br></br>
								<div className="ms-Grid-row">
									<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
										<Dropdown
											label={t('unilateral.implant.side.label')}
											options={[
												{key: 'left', text: t('left.text'), type: 'ladoImpUniSel'},
												{key: 'right', text: t('right.text'), type: 'ladoImpUniSel'}
											]}
											name='opciones1'
											onChange = {this.onInputchange}
											disabled={false}
											required={true}
										/>
									</div>
								</div>
							</>
						}
						<br></br>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
							<Dropdown
								label={t('generator.model.label')}
								options={this.state.generadores}
								name='opciones1'
								onChange = {this.onInputchange}
								disabled={false}
								required={true}
							/>
							</div>
						</div>
						<br></br>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
								{/*
								<DatePicker
									formatDate={onFormatDate}
									label ="Fecha del último recambio"
									strings={DayPickerStrings}
									showWeekNumbers={true}
									formatDay ={1}
									firstDayOfWeek ={1}
									showMonthPickerAsOverlay={true}
									placeholder="Seleccione una fecha"
									ariaLabel="Seleccione una fecha"
									onSelectDate={this.onInputchangeFecha}
									
								/>
								*/}
								<DatePicker
									label ={t('date.last.replacement.label')}
									isRequired={true}
									allowTextInput={true}
									isDayPickerVisible
									firstDayOfWeek={1}
									strings={i18next.language === 'es' ? DayPickerStrings : DayPickerStringsPt}
									onSelectDate={this.onInputchangeFecha}
									formatDate={onFormatDate}
									parseDateFromString={this.onParseDateFromString}
									placeholder={t('select.date.label')}
									ariaLabel={t('select.date.label')}
									value={this.state.fechaSel}
									maxDate={today}
									showGoToToday={true}
								/>
							</div>
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
								<p>{this.state.diferenciaFecha}</p>
							</div>
						</div>
						
						<br></br>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
								<Label>{this.adaptadorFrase}</Label>
							</div>
						</div>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{textAlign: 'center'}}>
								{
									this.adaptadorImagen &&
									<img src={this.adaptadorImagen} id="adaptador" alt="Adaptador" style={{width: '100%', height: '100%', maxWidth:375, maxHeight: 275}} />
								}
								
							</div>
						</div>
						<br></br>
						<div className="ms-Grid-row">
							<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <Link
                                    to={{
                                        pathname: "/",
                                        state: this.state
                                    }}
                                >
                                    <DefaultButton text={t('return.btn')}/>
                                </Link>
                            </div>
							<div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{marginBottom: '2%', textAlign: 'right'}}>
									<PrimaryButton
										text={t('next.btn')}
										className='btn-p0'
										style={{color: 'white'}}
										disabled={
											this.state.patologiaSel.key && this.state.zonestumulaSel.key && this.state.tipoImplanteSel.key && this.state.generadorSel.key 
											? this.state.tipoImplanteSel.key === 'uni' 
												? this.state.ladoImpUniSel.key 
													? false
													: true
												: false
											: true
										}
										onClick={this.siguente}
									/>
									<Link
										to={{
											pathname: "/configuracioninicial",
											state: this.state
										}}
									>
										<PrimaryButton
											className='btn-p0'
											text={t('next.btn')} 
											id="next"
											style={{display: 'none'}}
										/>
									</Link>
							</div>
						</div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg4"></div>
                </div>
            </Suspense>
        );
    }
}

export default withTranslation()(ConversorMetronicP0);