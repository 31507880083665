import React from 'react';
import '../App.css';
import '../styles/style.css'
//import { Link } from 'react-router-dom';
//import cocovitaIndex from '../images/00-imagen-inicial-COCOVITA.jpg'
import {data} from '../data/data'
import { createTheme, loadTheme, Label, TextField, Separator, PrimaryButton, Spinner, SpinnerSize, MessageBar, MessageBarType} from '@fluentui/react';

const myThemeAnt = createTheme({
    palette: {
        themePrimary: '#303030',
        themeLighterAlt: '#e6e6e6',
        themeLighter: '#cfcfcf',
        themeLight: '#b9b9b9',
        themeTertiary: '#a2a2a2',
        themeSecondary: '#8b8b8b',
        themeDarkAlt: '#757575',
        themeDark: '#5e5e5e',
        themeDarker: '#474747',
        neutralLighterAlt: '#e1e2d2',
        neutralLighter: '#dddecf',
        neutralLight: '#d4d5c6',
        neutralQuaternaryAlt: '#c5c6b9',
        neutralQuaternary: '#bcbdb0',
        neutralTertiaryAlt: '#b5b6a9',
        neutralTertiary: '#594949',
        neutralSecondary: '#372b2b',
        neutralPrimaryAlt: '#2f2424',
        neutralPrimary: '#000000',
        neutralDark: '#151010',
        black: '#0b0808',
        white: '#e8e9d8',
    }});

loadTheme(myThemeAnt);

class ConversorMetronicIndex extends React.Component {
    // eslint-disable-next-line
    constructor(props){
        super(props);
        this.state = {
            token: ``,
            email:  ``,
            password: ``,
            password_confirmation: ``,
            showErr: false,

            provincias: [],
            name_new: ``,
            surname_new: ``,
            email_new: ``,
            provincia_new: 0,

            verCargandoLogin: false,
            verMsgLogin: false,
            typeMsgLogin: `success`,
            msgLogin: ``,

            verModalResetPass: false,
            verCargandoResetPass: false,

            verMsgResetPass: false,
            typeMsgResetPass: `success`,
            msgResetPass: ``

        }
        this.onInputchange = this.onInputchange.bind(this)
        this.openModalResetPass = this.openModalResetPass.bind(this)
        this.closeModalResetPass = this.closeModalResetPass.bind(this)
        this.recuperarPass = this.recuperarPass.bind(this)
        this.login = this.login.bind(this)
        this.reestablecerPass = this.reestablecerPass.bind(this)
    }

    componentDidMount(){
        window.scrollTo(0, 0)
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        
        if(!urlParams.get('token')){
            this.props.history.push("/login")
        }
        this.setState({
            token: urlParams.get('token'),
            email: urlParams.get('email') ? urlParams.get('email') : ``
        })
    }

    onInputchange(event){
        const campo = event.target.name
        const value = event.target.value
        this.setState({[campo]: value})
        this.setState({showErr: false})
    }

    reestablecerPass(){
        this.setState({verCargandoLogin: true})
        data.reestablecerPass(this.state.token, this.state.email, this.state.password, this.state.password_confirmation)
        .then(response => {
            this.setState({verCargandoLogin: false})
            if(response.data.data){
                this.setState({
                    verMsgLogin: true,
                    typeMsgLogin: 'success',
                    msgLogin: response.data.data + '; Entrando en el sistema...',
                })
                setTimeout(function(){ this.login() }.bind(this), 3000);
            }
            if(response.data.error){
                this.setState({
                    verMsgLogin: true,
                    typeMsgLogin: 'danger',
                    msgLogin: response.data.error
                })
            }
        })
        .catch(error => {
            this.setState({verCargandoLogin: false})
            if(error.response.data.errors.password){
                this.setState({
                    verMsgLogin: true,
                    typeMsgLogin: 'danger',
                    msgLogin: error.response.data.errors.password[0]
                })
            }
            if(error.response.data.errors.email){
                this.setState({
                    verMsgLogin: true,
                    typeMsgLogin: 'danger',
                    msgLogin: error.response.data.errors.email[0]
                })
            }
        })
    }

    login(){
        this.setState({verCargandoLogin: true})
        data.login(this.state.email, this.state.password)
        .then(response => {
            localStorage.setItem('auth', response.data.access_token);
            this.setState({verCargandoLogin: false})
            this.props.history.push("/datos")
            window.location.reload()
        })
        .catch(() => {
            this.setState({verCargandoLogin: false})
            this.setState({
                verMsgLogin: true,
                typeMsgLogin: 'danger',
                msgLogin: `Correo electrónico o contraseña incorrectos`
            })
        })
    }

    openModalResetPass(){
        this.setState({verModalResetPass: true})
    }
    closeModalResetPass(){
        this.setState({
            verModalResetPass: false,
            verCargandoResetPass: false,
            verMsgResetPass: false
        })
    }

    recuperarPass(){
        this.setState({verCargandoResetPass: true})
        data.resetPass(this.state.email)
        .then(response => {
            this.setState({verCargandoResetPass: false})
            if(response.data.error){
                this.setState({
                    msgResetPass: response.data.error,
                    typeMsgResetPass: 'danger',
                    verMsgResetPass: true
                })
            }
            if(response.data.data){
                this.setState({
                    msgResetPass: response.data.data,
                    typeMsgResetPass: 'success',
                    verMsgResetPass: true
                })
            }
        })
        .catch(error => {
            this.setState({verCargandoResetPass: false})
            if(error.response.data.error){
                this.setState({
                    msgResetPass: error.response.data.errors.email[0],
                    typeMsgResetPass: 'danger',
                    verMsgResetPass: true
                })
            }
        })
    }

    render(){
        return(
            <>
                <div className="ms-Grid-row" id="contentCenter">
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"></div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                        <div className="ms-Grid-row">
                            
                                
                            <Label className="ms-fontWeight-regular ms-fontSize-28">Establecer contraseña</Label>
                            <Separator></Separator>
                            <TextField 
                                label="Correo electrónico"
                                name="email"
                                type="email"
                                value={this.state.email}
                                onChange={this.onInputchange}
                            />
                            <TextField 
                                label="Contraseña"
                                name="password"
                                type="password"
                                canRevealPassword
                                onChange={this.onInputchange}
                            />
                            <TextField 
                                label="Repetir Contraseña"
                                name="password_confirmation"
                                type="password"
                                onChange={this.onInputchange}
                            />
                            {this.state.verMsgLogin
                                ?
                                    <>
                                    <br></br>
                                        <MessageBar
                                            messageBarType={this.state.typeMsgLogin === 'success' ?  MessageBarType.success : MessageBarType.error }
                                            isMultiline={false}
                                        >
                                            {this.state.msgLogin}
                                        </MessageBar>
                                    </>
                                : ''
                            }
                            <br></br>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {!this.state.verCargandoLogin
                                        ? <PrimaryButton text="Establecer" onClick={this.reestablecerPass} allowDisabledFocus disabled={false} checked={false} />
                                        : <Spinner size={SpinnerSize.medium} />
                                    }
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"></div>
                </div>
            </>
        )
    }
}

export default ConversorMetronicIndex