import React from 'react';
import '../App.css';
import '../styles/style.css'
//import { Link } from 'react-router-dom';
//import cocovitaIndex from '../images/00-imagen-inicial-COCOVITA.jpg'
import {data} from '../data/data'
import { Dropdown, createTheme, loadTheme, Label, TextField, Separator, PrimaryButton, ActionButton, Modal, IconButton, Spinner, SpinnerSize, MessageBar, MessageBarType} from '@fluentui/react';

import { withTranslation } from 'react-i18next'
import i18next from 'i18next'

const myThemeAnt = createTheme({
    palette: {
        themePrimary: '#303030',
        themeLighterAlt: '#e6e6e6',
        themeLighter: '#cfcfcf',
        themeLight: '#b9b9b9',
        themeTertiary: '#a2a2a2',
        themeSecondary: '#8b8b8b',
        themeDarkAlt: '#757575',
        themeDark: '#5e5e5e',
        themeDarker: '#474747',
        neutralLighterAlt: '#e1e2d2',
        neutralLighter: '#dddecf',
        neutralLight: '#d4d5c6',
        neutralQuaternaryAlt: '#c5c6b9',
        neutralQuaternary: '#bcbdb0',
        neutralTertiaryAlt: '#b5b6a9',
        neutralTertiary: '#594949',
        neutralSecondary: '#372b2b',
        neutralPrimaryAlt: '#2f2424',
        neutralPrimary: '#000000',
        neutralDark: '#151010',
        black: '#0b0808',
        white: '#e8e9d8',
    }});

loadTheme(myThemeAnt);

const cancelIcon: iconProps = {iconName: 'Cancel'}

class ConversorMetronicIndex extends React.Component {
    // eslint-disable-next-line
    constructor(props){
        super(props);
        this.state = {
            name_new: ``,
            surnames_new: ``,
            pais_id: 1,
            provincia_id: 1,
            paises: [],
            provincias: [],
            provinciasAux: [],
            email_new: ``,

            email:  ``,
            password: ``,
            showErr: false,

            verCargandoLogin: false,
            verMsgLogin: false,
            typeMsgLogin: `success`,
            msgLogin: ``,

            verModalResetPass: false,
            verCargandoResetPass: false,

            verMsgResetPass: false,
            typeMsgResetPass: `success`,
            msgResetPass: ``,

            verModalRegistro: false,
            verCargandoResgistro: false,

            verMsgRegistro: false,
            typeMsgRegistro: `success`,
            msgRegistro: ``,
            inviCode:``

        }
        this.onInputchange = this.onInputchange.bind(this)
        this.openModalResetPass = this.openModalResetPass.bind(this)
        this.closeModalResetPass = this.closeModalResetPass.bind(this)
        this.recuperarPass = this.recuperarPass.bind(this)
        this.login = this.login.bind(this)
        this.onInputChangeSelect = this.onInputChangeSelect.bind(this)
        this.onInputChangeSelectPais = this.onInputChangeSelectPais.bind(this)
        this.openModalRegistro = this.openModalRegistro.bind(this)
        this.closeModalRegistro = this.closeModalRegistro.bind(this)
        this.registrar = this.registrar.bind(this)
    }

    componentDidMount(){
        window.scrollTo(0, 0)

        var userLang = navigator.language || navigator.userLanguage; 
        const navLang = userLang.split('-')
        //console.info(navLang[0])
        i18next.changeLanguage(navLang[0]);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.get('invicode')){
            this.setState({
                inviCode: urlParams.get('invicode'),
                verModalRegistro: true
            })
        }

        if(localStorage.getItem('auth')){
            data.getProfile()
            .then(() => {
                this.props.history.push("/datos")
            })
            .catch(() => {
                localStorage.removeItem('auth')
                this.props.history.push("/login")
            })
        }else{
            localStorage.removeItem('auth')
            this.props.history.push("/login")
        }
        
        data.getPaises()
        .then(response => {
            this.setState({paises: response.data})
        })
        data.getProvincias()
        .then(response => {
            this.setState({provincias: response.data})
            this.setState({provinciasAux: response.data})
        })
    }

    onInputchange(event){
        const campo = event.target.name
        const value = event.target.value
        this.setState({[campo]: value})
        this.setState({showErr: false})
    }

    onInputChangeSelectPais(event, item){
        this.setState({pais_id: item.key})
        let prov = (this.state.provinciasAux).filter(provincia => provincia.pais_id === item.key)
        this.setState({provincias: prov})
    }
    onInputChangeSelect(event, item){
        this.setState({provincia_id: item.key})
    }

    login(){
        this.setState({verCargandoLogin: true})
        data.login(this.state.email, this.state.password)
        .then(response => {
            localStorage.setItem('auth', response.data.access_token);
            this.setState({verCargandoLogin: false})
            this.props.history.push("/datos")
            window.location.reload()
        })
        .catch(error => {
            this.setState({verCargandoLogin: false})
            this.setState({
                verMsgLogin: true,
                typeMsgLogin: 'danger',
                msgLogin: this.props.t('login.fail')
            })
        })
    }

    openModalResetPass(){
        this.setState({verModalResetPass: true})
    }
    closeModalResetPass(){
        this.setState({
            verModalResetPass: false,
            verCargandoResetPass: false,
            verMsgResetPass: false
        })
    }

    recuperarPass(){
        this.setState({verCargandoResetPass: true})
        data.resetPass(this.state.email)
        .then(response => {
            this.setState({verCargandoResetPass: false})
            if(response.data.error){
                this.setState({
                    msgResetPass: response.data.error,
                    typeMsgResetPass: 'danger',
                    verMsgResetPass: true
                })
            }
            if(response.data.data){
                this.setState({
                    msgResetPass: response.data.data,
                    typeMsgResetPass: 'success',
                    verMsgResetPass: true
                })
            }
        })
        .catch(error => {
            this.setState({verCargandoResetPass: false})
            if(error.response.data.error){
                this.setState({
                    msgResetPass: error.response.data.errors.email[0],
                    typeMsgResetPass: 'danger',
                    verMsgResetPass: true
                })
            }
        })
    }

    openModalRegistro(){
        this.setState({
            verModalRegistro: true,
            verCargandoResgistro: false,
            verMsgRegistro: false,
            msgRegistro: ``
        })
    }

    closeModalRegistro(){
        this.setState({
            verModalRegistro: false,
            verCargandoResgistro: false,
            verMsgRegistro: false,
            msgRegistro: ``
        })
    }

    registrar(){
        this.setState({verCargandoResgistro: true})
        data.registrar(this.state.name_new, this.state.surnames_new, this.state.email_new, this.state.pais_id, this.state.provincia_id, this.state.inviCode)
        .then(response =>  {
            this.setState({verCargandoResgistro: false})
            this.setState({
                typeMsgRegistro: `success`,
                msgRegistro: response.data.message,
                verMsgRegistro: true
            })
        })
        .catch(error => {
            this.setState({verCargandoResgistro: false})
            if(error.response.data.surnames){
                this.setState({
                    typeMsgRegistro: `danger`,
                    msgRegistro: error.response.data.surnames[0],
                    verMsgRegistro: true
                })
            }
            if(error.response.data.name){
                this.setState({
                    typeMsgRegistro: `danger`,
                    msgRegistro: error.response.data.name[0],
                    verMsgRegistro: true
                })
            }
            if(error.response.data.email){
                this.setState({
                    typeMsgRegistro: `danger`,
                    msgRegistro: error.response.data.email[0],
                    verMsgRegistro: true
                })
            }
            if(error.response.data.invicode){
                
                this.setState({
                    typeMsgRegistro: `danger`,
                    msgRegistro: this.props.t('invitationcode.not.exists'),
                    verMsgRegistro: true
                })
            }
            if(error.response.data.used){
                this.setState({
                    typeMsgRegistro: `danger`,
                    msgRegistro: error.response.data.used,
                    verMsgRegistro: true
                })
            }
        })
    }

    render(){
        const {t} = this.props
        return(
            <>
                <div className="ms-Grid-row" id="contentCenter">
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"></div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                        <div className="ms-Grid-row">
                            <Label className="ms-fontWeight-regular ms-fontSize-28">{t('login.title')}</Label>
                            <Label className="ms-fontWeight-regular ms-fontSize-16">{t('login.intro')}</Label>
                            <TextField 
                                label={t('email.label')}
                                name="email"
                                type="email"
                                onChange={this.onInputchange}
                            />
                            <TextField 
                                label={t('password.label')}
                                name="password"
                                type="password"
                                canRevealPassword
                                onChange={this.onInputchange}
                            />
                            {this.state.verMsgLogin
                                ?
                                    <>
                                    <br></br>
                                        <MessageBar
                                            messageBarType={this.state.typeMsgLogin === 'success' ?  MessageBarType.success : MessageBarType.error }
                                            isMultiline={false}
                                        >
                                            {this.state.msgLogin}
                                        </MessageBar>
                                    </>
                                : ''
                            }
                            <br></br>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {!this.state.verCargandoLogin
                                        ? <PrimaryButton text={t('login.title')} onClick={this.login} allowDisabledFocus disabled={false} checked={false} />
                                        : <Spinner size={SpinnerSize.medium} />
                                    }
                                    
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                    <ActionButton onClick={this.openModalResetPass} allowDisabledFocus disabled={false} checked={false}>
                                        {t('password.forgotten')}
                                    </ActionButton>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{textAlign: 'center'}}>
                                    <ActionButton onClick={this.openModalRegistro} allowDisabledFocus disabled={false} checked={false}>
                                        <p>{t('not.registered')} <u>{t('not.registered.here')}</u></p>
                                    </ActionButton>
                                </div>
                            </div>
                            <Modal
                                isOpen={this.state.verModalResetPass}
                                onDismiss={this.closeModalResetPass}
                                isBlocking={false}
                                style={{width: '20000px'}}
                                containerClassName={{container: {
                                    display: 'flex',
                                    flexFlow: 'column nowrap',
                                    alignItems: 'stretch',
                                }}}
                            >
                                
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{
                                    flex: '1 1 auto',
                                    borderTop: `4px solid #003c71`,
                                    color: 'black',
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    padding: '12px 12px 0px 24px'}}
                                >
                                    <Label className="ms-fontWeight-regular ms-fontSize-20">{t('password.recover.title')}</Label>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={cancelIcon}
                                        ariaLabel="Close popup modal"
                                        onClick={this.closeModalResetPass}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{
                                    flex: '4 4 auto',
                                    padding: '0 24px 24px 24px',
                                    overflowY: 'hidden',
                                    selectors: {
                                        p: { margin: '14px 0' },
                                        'p:first-child': { marginTop: 0 },
                                        'p:last-child': { marginBottom: 0 },
                                    }}}
                                >
                                    <Separator></Separator>
                                    <TextField 
                                        label={t('email.label')}
                                        name="email"
                                        type="email"
                                        value={this.state.email}
                                        onChange={this.onInputchange}
                                    />
                                    
                                    {this.state.verMsgResetPass
                                        ?
                                            <>
                                            <br></br>
                                                <MessageBar
                                                    messageBarType={this.state.typeMsgResetPass === 'success' ?  MessageBarType.success : MessageBarType.error }
                                                    isMultiline={false}
                                                >
                                                    {this.state.msgResetPass}
                                                </MessageBar>
                                            </>
                                        : ''
                                    }
                                    <br></br>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{textAlign: 'right'}}>
                                            {!this.state.verCargandoResetPass
                                                ? <PrimaryButton text={t('password.recover.title.btn')} onClick={this.recuperarPass} allowDisabledFocus disabled={false} checked={false} />
                                                : <Spinner size={SpinnerSize.medium} />
                                            }
                                        </div>
                                    </div>
                                    <br></br>
                                </div>
                            </Modal>

                            <Modal
                                isOpen={this.state.verModalRegistro}
                                onDismiss={this.closeModalRegistro}
                                isBlocking={false}
                                style={{width: '20000px'}}
                                containerClassName={{container: {
                                    display: 'flex',
                                    flexFlow: 'column nowrap',
                                    alignItems: 'stretch',
                                }}}
                            >
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{
                                    flex: '1 1 auto',
                                    borderTop: `4px solid #003c71`,
                                    color: 'black',
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 'bold',
                                    padding: '12px 12px 0px 24px'}}
                                >
                                    <Label className="ms-fontWeight-regular ms-fontSize-20">{t('register.title')}</Label>
                                    <IconButton
                                        styles={iconButtonStyles}
                                        iconProps={{iconName: 'Cancel'}}
                                        ariaLabel="Close popup modal"
                                        onClick={this.closeModalRegistro}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{
                                    flex: '4 4 auto',
                                    padding: '0 24px 24px 24px',
                                    overflowY: 'hidden',
                                    selectors: {
                                        p: { margin: '14px 0' },
                                        'p:first-child': { marginTop: 0 },
                                        'p:last-child': { marginBottom: 0 },
                                    }}}
                                >
                                    <Separator></Separator>
                                    <TextField 
                                        label={t('name.label')}
                                        name="name_new"
                                        type="text"
                                        onChange={this.onInputchange}
                                    />
                                    <TextField 
                                        label={t('surnames.label')}
                                        name="surnames_new"
                                        type="text"
                                        onChange={this.onInputchange}
                                    />
                                    <Dropdown
                                        label={t('pais.label')}
                                        name="pais_id"
                                        onChange={this.onInputChangeSelectPais}
                                        options={this.state.paises.map((x,y) => {return {key: x.id, text: x.name}})}
                                    />
                                    <Dropdown
                                        label={t('province.label')}
                                        name="provincia_id"
                                        onChange={this.onInputChangeSelect}
                                        options={this.state.provincias.map((x,y) => {return {key: x.id, text: x.name}})}
                                    />
                                    <TextField 
                                        label={t('email.label')}
                                        name="email_new"
                                        type="email"
                                        onChange={this.onInputchange}
                                    />
                                    <TextField 
                                        label={t('invitationcode.label')}
                                        name="inviCode"
                                        type="text"
                                        onChange={this.onInputchange}
                                        defaultValue={this.state.inviCode}
                                    />
                                    {this.state.verMsgRegistro
                                        ?
                                            <>
                                            <br></br>
                                                <MessageBar
                                                    messageBarType={this.state.typeMsgRegistro === 'success' ?  MessageBarType.success : MessageBarType.error }
                                                    isMultiline={false}
                                                >
                                                    {this.state.msgRegistro}
                                                </MessageBar>
                                            </>
                                        : ''
                                    }
                                    <br></br>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{textAlign: 'center'}}>
                                            {!this.state.verCargandoResgistro
                                                ? <PrimaryButton text={t('register.title.btn')} onClick={this.registrar} allowDisabledFocus disabled={false} checked={false} />
                                                : <Spinner size={SpinnerSize.medium} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"></div>
                </div>
            </>
        )
    }
}

const iconButtonStyles = {
    root: {
        color: 'black',
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: 'black',
    },
};

export default withTranslation() (ConversorMetronicIndex)