import React from 'react';
import '../App.css';
import '../styles/style.css'
import 'office-ui-fabric-react/dist/css/fabric.css';
import replacedBattery from '../images/replacedBattery.gif';

import { Link } from 'react-router-dom';
import { PrimaryButton, DefaultButton } from '@fluentui/react'
import { createTheme, loadTheme} from '@fluentui/react';

const myTheme3 = createTheme({
    palette: {
        themePrimary: '#000000',
        themeLighterAlt: '#898989',
        themeLighter: '#737373',
        themeLight: '#595959',
        themeTertiary: '#373737',
        themeSecondary: '#2f2f2f',
        themeDarkAlt: '#252525',
        themeDark: '#151515',
        themeDarker: '#0b0b0b',
        neutralLighterAlt: '#e8e9d9',
        neutralLighter: '#e9eadb',
        neutralLight: '#eaebdd',
        neutralQuaternaryAlt: '#ebecdf',
        neutralQuaternary: '#ecede0',
        neutralTertiaryAlt: '#eff0e5',
        neutralTertiary: '#b9b9b9',
        neutralSecondary: '#a2a2a2',
        neutralPrimaryAlt: '#8b8b8b',
        neutralPrimary: '#303030',
        neutralDark: '#5e5e5e',
        black: '#474747',
        white: '#e8e9d8',
    }});
    
loadTheme(myTheme3);

class ConversorMetronicP2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            left:{
                amplitude: '-',
                rate: '-',
                pulse: '-',
                soft: '-',
                leads:{
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance: {
                    l0: '-',
                    l1: '-',
                    l2: '-',
                    l3: '-'
                }
            },
            right:{
                amplitude: '-',
                rate: '-',
                pulse: '-',
                soft: '-'
            }
        };
    }
    componentDidMount () {
        window.scrollTo(0, 0)
        if(!this.props.location.state){
            window.location.href = '/'
        }
        this.setState(this.props.location.state,() => {
            //console.info(this.state)
        })
    }
    render() {
        return (
            <>
                <div className="ms-Grid-row" id="contentCenter">
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"></div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4" style={{textAlign: 'center'}}>
                        <h3>Step 2 - Replace battery</h3>
                        <img src={replacedBattery} id="replacedBattery" alt="replacedBattery" style={{width: '100%'}} />
                        <br></br>
                        <Link
                            to={{
                                pathname: "/",
                            }}
                        >
                            <DefaultButton text="Return"/>
                        </Link>
                        <Link
                            to={{
                                pathname: "/conversorp3",
                                state: this.state
                            }}
                        >
                            <PrimaryButton text="Next"/>
                        </Link>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4"></div>
                </div>
                

                
                
            </>
        );
    }
}

export default ConversorMetronicP2;