import React from 'react';
import '../App.css';
import '../styles/style.css'
import '../styles/styleblack.css'
import 'office-ui-fabric-react/dist/css/fabric.css';

import { Link } from 'react-router-dom';
import { DefaultButton, PrimaryButton, TextField, Dropdown, Callout} from '@fluentui/react'
import { Label } from '@fluentui/react';
import { Pivot, PivotItem } from '@fluentui/react';

import { Separator } from '@fluentui/react';
import { createTheme, loadTheme, Stack, IconButton} from '@fluentui/react';
import clinico from "../images/01-Programador-Clinico.png";
import mando from "../images/02-Mando.png";
import mandoPT from "../images/02-Mando-v2-PT.png";

import { withTranslation} from 'react-i18next'

import i18next from 'i18next'

const myTheme3 = createTheme({
    palette: {
        themePrimary: '#804099',
        themeLighterAlt: '#050306',
        themeLighter: '#140a18',
        themeLight: '#26132e',
        themeTertiary: '#4d275c',
        themeSecondary: '#713987',
        themeDarkAlt: '#8b4ea3',
        themeDark: '#9b63b1',
        themeDarker: '#b486c6',
        neutralLighterAlt: '#3c3c3c',
        neutralLighter: '#444444',
        neutralLight: '#515151',
        neutralQuaternaryAlt: '#595959',
        neutralQuaternary: '#5f5f5f',
        neutralTertiaryAlt: '#7a7a7a',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#333333',
    }});
    const iconProps = { iconName: 'Info' };
class ConversorMetronicP3 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            left:{
                amplitude: '',
                rate: '',
                pulse: '',
                soft: '',
                leads:{
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance: {
                    l0: '',
                    l1: '',
                    l2: '',
                    l3: ''
                },
                toleranciasSel: {},
            },
            right:{
                amplitude: '',
                rate: '',
                pulse: '',
                soft: '',
                leads:{
                    r0: 'neutral',
                    r1: 'neutral',
                    r2: 'neutral',
                    r3: 'neutral',
                },
                impedance: {
                    r0: '',
                    r1: '',
                    r2: '',
                    r3: ''
                },
                toleranciasSel: {},
            },
            tipoProgramacionSel: {},
            ladoImpUniSel: {},

            tolerancias: [
                {key: 'five', text: '-5%', type: 'toleranciasSel', valor: '5'},
                {key: 'ten', text: '-10%', type: 'toleranciasSel', valor: '10'},
                {key: 'fifteen', text: '-15%', type: 'toleranciasSel', valor: '15'},
                {key: 'twenty', text: '-20%', type: 'toleranciasSel', valor: '20'},
                {key: 'twentyfive', text: '-25%', type: 'toleranciasSel', valor: '25'},
                {key: 'cero', text: props.t('sinmargen'), type: 'toleranciasSel', valor: '0'},
            ],
            toleranciasSel: {},
            toleranciasSelL: {key: 'cero', text: props.t('sinmargen'), type: 'toleranciasSel', valor: '0'},
            toleranciasSelR: {key: 'cero', text: props.t('sinmargen'), type: 'toleranciasSel', valor: '0'},
            isCalloutVisible: false,
            isCalloutVisibleInfoPrinci: false,
            validInputL:false,
            validInputR: false,
            fusion:{
                left:{
                    amplitude: '',
                    rate: '',
                    pulse: '',
                    soft: '',
                    leads:{
                        lcase: 'neutral',
                        l0: 'neutral',
                        l1: 'neutral',
                        l2: 'neutral',
                        l3: 'neutral',
                    },
                    impedance: {
                        lcase: '',
                        l0: '',
                        l1: '',
                        l2: '',
                        l3: ''
                    },
                    toleranciasSel: {},
                },
                right:{
                    amplitude: '',
                    rate: '',
                    pulse: '',
                    soft: '',
                    leads:{
                        rcase: 'neutral',
                        r0: 'neutral',
                        r1: 'neutral',
                        r2: 'neutral',
                        r3: 'neutral',
                    },
                    impedance: {
                        rcase: '',
                        r0: '',
                        r1: '',
                        r2: '',
                        r3: ''
                    },
                    toleranciasSel: {},
                },
            }
        };
		this.btn ='';
        this.valido = '';
        this.validor = '';
		this.siguiente = '';
        this.anterior = '';
        this.siguienter = '';
		this.anteriorr = '';
        this.onInputchange = this.onInputchange.bind(this);
        this.isFloatNum = this.isFloatNum.bind(this);
        this.changeRateLeft = this.changeRateLeft.bind(this);
        this.changeRateRigth = this.changeRateRigth.bind(this)
        this.visibleCallout	 = this.visibleCallout.bind(this)
        this.visibleCalloutInfoPrinci = this.visibleCalloutInfoPrinci.bind(this)
        this.invisibleCallout = this.invisibleCallout.bind(this)
        this.invisibleCalloutInfoPrinci =  this.invisibleCalloutInfoPrinci.bind(this)
        this.onInputchangeTolerancia = this.onInputchangeTolerancia.bind(this)
		this.btn1 ='P3-btn2Err';
        this.btn2 ='P3-btn2Err';
        this.btn1r ='P3-btn2Err';
		this.btn2r ='P3-btn2Err';
        //this.estadoAnt = this.props.location.state;

    }
	
    componentDidMount () {
        window.scrollTo(0, 0)
        document.body.style = 'background: #000;';
        loadTheme(myTheme3)
        if(!this.props.location.state){
            window.location.href = '/'
        }
        //console.log(this.props.location.state)
        this.setState(this.props.location.state, () => {
            let auxArray = JSON.parse(JSON.stringify(this.state))
                //console.log(auxArray)
                if(auxArray.left.programador === 'interleaving' && (auxArray.left.pulse === auxArray.left.pulse2)) {
                    if(auxArray.left.leads['lcase'] === 'neutral') {
                        auxArray.left.leads['lcase'] = auxArray.left.leads2['lcase']
                        auxArray.left.impedance['lcase'] = auxArray.left.impedance2['lcase']
                    }
                }
                if(auxArray.right.programador === 'interleaving' && (auxArray.right.pulse === auxArray.right.pulse2)) {
                    if(auxArray.right.leads['rcase'] === 'neutral') {
                        auxArray.right.leads['rcase'] = auxArray.right.leads2['rcase']
                        auxArray.right.impedance['rcase'] = auxArray.right.impedance2['rcase']
                    }
                }
                for(let i = 0; i <= 3; i++) {
                    if(auxArray.left.programador === 'interleaving' && (auxArray.left.pulse === auxArray.left.pulse2)) {
                        if(auxArray.left.leads['l'+i] === 'neutral') {
                            auxArray.left.leads['l'+i] = auxArray.left.leads2['l'+i]
                            auxArray.left.impedance['l'+i] = auxArray.left.impedance2['l'+i]
                        }
                    }
                    if(auxArray.right.programador === 'interleaving' && (auxArray.right.pulse === auxArray.right.pulse2)) {
                        if(auxArray.right.leads['r'+i] === 'neutral') {
                            auxArray.right.leads['r'+i] = auxArray.right.leads2['r'+i]
                            auxArray.right.impedance['r'+i] = auxArray.right.impedance2['r'+i]
                        }
                    }
                    this.setState({fusion: auxArray})
                }
        })
        
        var arrNum= [2,6,10,15,17,20,22,26,30,34,40,45,50,53,57,60,66,71,74,79,85,89,95,99,104,112,113,116,119,128,130,132,136,139,143,149,154,159,170,174,179,185,198,204,208,223,227,231,238,255];
        if(this.props.location.state.left.rate){//El valor de rate existe
            if(arrNum.includes(this.props.location.state.left.rate)){//Comprobarmos si existe el rate en el array de rates permitidos
                this.valido = true;
            }else{
                this.valido = false;
                const menores = arrNum.filter(numero => numero < this.props.location.state.left.rate)
                const mayores = arrNum.filter(numero => numero > this.props.location.state.left.rate)
                this.anterior = Math.max.apply(null, menores)
                this.siguiente = Math.min.apply(null, mayores)
            }
        }
        if(this.props.location.state.right.rate){//El valor de rate existe
            if(arrNum.includes(this.props.location.state.right.rate)){//Comprobarmos si existe el rate en el array de rates permitidos
                this.validor = true;
            }else{
                this.validor = false;
                const menores = arrNum.filter(numero => numero < this.props.location.state.right.rate)
                const mayores = arrNum.filter(numero => numero > this.props.location.state.right.rate)
                this.anteriorr = Math.max.apply(null, menores)
                this.siguienter = Math.min.apply(null, mayores)
            }
        }
    }

	
    isFloatNum(value){
        if(value.length > 0){
            if(!parseFloat(value)){
                return this.props.t('intval.alert')
            }
        }
    }
	
	changeRateLeft(event){
		const valor = event.target.textContent.split(' ');
		this.setState(prevState => ({
            left: {
                ...prevState.left,
                rateNew: valor[0]
            }
		}));
		if(Number(valor[0]) === this.siguiente){
			this.btn1 = 'P3-btn2Err';
			this.btn2 = 'P3-btn1Err';
		}else if (Number(valor[0]) === this.anterior){
			this.btn1 = 'P3-btn1Err';
			this.btn2 = 'P3-btn2Err';
		}else{
			this.btn1 = 'P3-btn2Err';
			this.btn2 = 'P3-btn2Err';
        }
    }

    changeRateRigth(event){
		const valor = event.target.textContent.split(' ');
		this.setState(prevState => ({
            right: {
                ...prevState.right,
                rateNew: valor[0]
            }
		}));
		if(Number(valor[0]) === this.siguienter){
			this.btn1r = 'P3-btn2Err';
			this.btn2r = 'P3-btn1Err';
		}else if (Number(valor[0]) === this.anteriorr){
			this.btn1r = 'P3-btn1Err';
			this.btn2r = 'P3-btn2Err';
		}else{
			this.btn1r = 'P3-btn2Err';
			this.btn2r = 'P3-btn2Err';
        }
	}

    onInputchange(event){
        if(parseFloat(event.target.value)){
            const parts = event.target.name.split('.')
            const aux = event.target.dataset.child
            let aux2 = aux.split('2')
            const leads = aux2.length > 1 ? 'leads2' : 'leads'
            //console.log(leads)
            //console.log(aux)
            //return false;
            this.setState(prevState => ({
                [parts[0]]: {
                    ...prevState[parts[0]],
                    [leads]:{
                        ...prevState[parts[0]][leads],
                    },
                    [aux]:{
                        ...prevState[parts[0]][aux],
                        [parts[1]]: parseFloat(event.target.value)
                    }
                }
            }),() => {
                let auxArray = JSON.parse(JSON.stringify(this.state))
                //console.log(auxArray)
                if(auxArray.left.leads['lcase'] === 'neutral') {
                    auxArray.left.leads['lcase'] = auxArray.left.leads2['lcase']
                    auxArray.left.impedance['lcase'] = auxArray.left.impedance2['lcase']
                }
                if(auxArray.right.leads['rcase'] === 'neutral') {
                    auxArray.right.leads['rcase'] = auxArray.right.leads2['rcase']
                    auxArray.right.impedance['rcase'] = auxArray.right.impedance2['rcase']
                }
                for(let i = 0; i <= 3; i++) {
                    if(auxArray.left.programador === 'interleaving' && (auxArray.left.pulse === auxArray.left.pulse2)) {
                        if(auxArray.left.leads['l'+i] === 'neutral') {
                            auxArray.left.leads['l'+i] = auxArray.left.leads2['l'+i]
                            auxArray.left.impedance['l'+i] = auxArray.left.impedance2['l'+i]
                        }
                    }
                    if(auxArray.right.programador === 'interleaving' && (auxArray.right.pulse === auxArray.right.pulse2)) {
                        if(auxArray.right.leads['r'+i] === 'neutral') {
                            auxArray.right.leads['r'+i] = auxArray.right.leads2['r'+i]
                            auxArray.right.impedance['r'+i] = auxArray.right.impedance2['r'+i]
                        }
                    }
                    this.setState({fusion: auxArray})
                }
                /*Maquinaria para filtar en objetos*/
                Object.filter = (obj, predicate) => 
                    Object.keys(obj)
                        .filter( key => predicate(obj[key]) )
                        // eslint-disable-next-line
                        .reduce( (res, key) => (res[key] = obj[key], res), {} );

                Object.size = function(obj) {
                    var size = 0, key;
                    for (key in obj) {
                        if (obj.hasOwnProperty(key)) size++;
                    }
                    return size;
                };
                //console.info(parts[0])
                if(parts[0] === 'left'){
                    let negativos = Object.filter(this.props.location.state.left.leads, lead => lead === 'negative'); 
                    let positivos = Object.filter(this.props.location.state.left.leads, lead => lead === 'positive'); 
                    delete positivos.lcase
                    delete negativos.lcase
                    let sumaNegativos = 0
                    let sumaPositivos = 0
                    let objNegativos = {}
                    let objPositivos = {}
                    if(Object.size(negativos) > 0){
                        Object.entries(negativos).forEach(([key]) => {
                            objNegativos[key] = this.state.left.impedance[key]
                        });
                        sumaNegativos = this.sum(objNegativos)
                    }
                    
                    if(Object.size(positivos) > 0){
                        Object.entries(positivos).forEach(([key]) => {
                            objPositivos[key] = this.state.left.impedance[key]
                        });
                        sumaPositivos = this.sum(objPositivos)
                    }
                    if((sumaPositivos + sumaNegativos) > 0){
                        this.setState({validInputL: true})
                    }else{
                        this.setState({validInputL: false})
                    }
                    
                }else{
                    let negativos = Object.filter(this.props.location.state.right.leads, lead => lead === 'negative'); 
                    let positivos = Object.filter(this.props.location.state.right.leads, lead => lead === 'positive'); 
                    delete positivos.rcase
                    delete negativos.rcase
                    let sumaNegativos = 0
                    let sumaPositivos = 0
                    let objNegativos = {}
                    let objPositivos = {}
                    if(Object.size(negativos) > 0){
                        Object.entries(negativos).forEach(([key]) => {
                            objNegativos[key] = this.state.right.impedance[key]
                        });
                        sumaNegativos = this.sum(objNegativos)
                    }
                    
                    if(Object.size(positivos) > 0){
                        Object.entries(positivos).forEach(([key]) => {
                            objPositivos[key] = this.state.right.impedance[key]
                        });
                        sumaPositivos = this.sum(objPositivos)
                    }
                    if((sumaPositivos + sumaNegativos) > 0){
                        this.setState({validInputR: true})
                    }else{
                        this.setState({validInputR: false})
                    }
                }
            })
        }
    }
    sum( obj , porcent = 0) {
        var sum = 0;
        for( var el in obj ) {
            if( obj.hasOwnProperty( el ) ) {
                sum += parseFloat( (obj[el] - (obj[el] * (porcent / 100))) );
            }
        }
        return sum;
    }
    visibleCallout(){
        this.setState({isCalloutVisible:  true})
    }
    visibleCalloutInfoPrinci(){
        this.setState({isCalloutVisibleInfoPrinci:  true})
    }
    invisibleCallout(){
        this.setState({isCalloutVisible:  false})
    }
    invisibleCalloutInfoPrinci(){
        this.setState({isCalloutVisibleInfoPrinci:  false})
    }
    onInputchangeTolerancia(event, item){
        this.setState({toleranciasSel: item})
        const parts = event.target.attributes.name.value.split('.')
        if(parts[1] === 'left'){
            this.setState({toleranciasSelL: item})
        }else{
            this.setState({toleranciasSelR: item})
        }
    }
    siguente(){
        document.getElementById('next').click()
    }
    render() {
		const {t} = this.props
        return (
            <>
                <div className="ms-Grid-row" id="contentCenter">
                    <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg4"></div>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                        <Label className="ms-fontSize-24 ms-fontWeight-regular" >
                            {t('p2.title')}
                            <IconButton
                                title="Info"
                                ariaLabel="Info"
                                id={'infoPrinci'}
                                onClick={this.visibleCalloutInfoPrinci}
                                iconProps={iconProps}
                            />
                        </Label>
                        {this.state.isCalloutVisibleInfoPrinci && (
                            <Callout
                            target={'#infoPrinci'}
                            setInitialFocus
                            ariaDescribedBy={'Teat'}
                            role="alertdialog"
                            style={{padding: '3%'}}
                            >
                                <Stack horizontalAlign="start" >
                                    <span style={{color: 'white'}}>{t('p2.alert')}</span>
                                    <br></br>
                                    <DefaultButton onClick={this.invisibleCalloutInfoPrinci}>{t('p2.btn')}</DefaultButton>
                                </Stack>
                            </Callout>
                        )}
                        {/** 
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Label className="ms-fontWeight-semibold" style={{textAlign: 'justify'}}>
                                    Por favor, proceda a medir impedancias del dispositivo actual. Puede hacerlo tanto desde el mando como  desde el Programador Clínico, puede ver a continuación un ejemplo de cada una de las mediciones.
                                </Label>
                            </div>
                        </div>
                        */}
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Pivot aria-label="Basic Pivot Example">
                                    <PivotItem
                                    headerText={t('clinicalprogrammer.header')}
                                    headerButtonProps={{
                                        'data-order': 1,
                                        'data-title': 'Progrmador',
                                        }}
                                    >
                                    {/* Imgen programador */}
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 imgP3" style={{textAlign: 'center'}}>
                                                <img src={clinico} alt="Programador Clínico" style={{width: '100%', height: '100%', maxWidth: 462, maxHeight: 274}}/>
                                            </div>
                                        </div>
                                    </PivotItem>
                                    <PivotItem
                                    headerText={t('controler.header')}
                                    headerButtonProps={{
                                        'data-order': 1,
                                        'data-title': 'Mando',
                                        }}
                                    >
                                    {/* Imagen Mando */}
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 imgP3" style={{textAlign: 'center'}}>
                                                {
                                                    i18next.language === 'pt' ? 
                                                        <img src={mandoPT} alt="Mando" style={{width: '100%', height: '100%', maxWidth: 379, maxHeight: 261}}/>
                                                    :
                                                        <img src={mando} alt="Mando" style={{width: '100%', height: '100%', maxWidth: 379, maxHeight: 261}}/>
                                                }
                                                
                                            </div>
                                        </div>
                                    </PivotItem>
                                </Pivot>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{textAlign: 'justify'}}>
                                <Label className="ms-fontWeight-semibold">
                                    {t('inputspreview.text')}
                                    <br></br>
                                    {t('inputspreview.subtext')}
                                </Label>
                            </div>
                        </div>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Pivot aria-label="Basic Pivot Example">
                                {this.state.ladoImpUniSel.key !== 'right' &&
                                    <PivotItem
                                        headerText={t('leftsnt.header')}
                                        headerButtonProps={{
                                            'data-order': 1,
                                            'data-title': 'Left',
                                            }}
                                    >
                                        <Label className="ms-fontWeight-regular ms-fontSize-28">{t('left.text')}</Label>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                <Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}: <span className="ms-fontWeight-regular">{this.state.left.amplitude} {this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'}</span></Label>
                                            </div>
                                            {this.state.left.programador !== 'interleaving' ?
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                <Label className="ms-fontWeight-semibold">{t('pulse.label')}: <span className="ms-fontWeight-regular">{this.state.left.pulse} μs</span></Label>
                                            </div> : <></>}
                                        </div>
                                        <div className="ms-Grid-row">
                                            {
                                            this.valido 
                                            ?
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                    <Label className="ms-fontWeight-semibold">{t('frequency.label')}: <span className="ms-fontWeight-regular">{this.state.left.rate} Hz </span></Label>
                                                </div>
                                            :
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <div className="ms-Grid-col ms-sm7 ms-md7 ms-lg7 P3-botton-p">
                                                        <Label className="ms-fontWeight-semibold">{t('frequency.label')}: <span className="ms-fontWeight-regular nameErr">{this.state.left.rate} Hz </span></Label>
                                                        <Label className="P3warning nameErr">{t('frequency.alert')}</Label>
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5 btn-container P3-botton-p">
                                                        <DefaultButton data={this.anterior} text={this.anterior + ' Hz'} className={this.btn1} onClick={this.changeRateLeft}/>
                                                        <DefaultButton name={this.siguiente} text={this.siguiente + ' Hz'} className={this.btn2} onClick={this.changeRateLeft}/>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {/*Lead Config */}
                                        <div className="ms-Grid-row" id="contentCenter">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '3%'}}>
                                                <Separator></Separator>
                                                <Label className="ms-fontSize-16 ms-fontWeight-regular">{t('contactsconf.text')}</Label>
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '3%'}}>
                                                <Stack horizontal verticalAlign="center">
                                                    <span id={'infoBtn'} style={{color: 'white'}}>{t('conversionmargin.text')}</span>
                                                    <IconButton
                                                    title="Info"
                                                    ariaLabel="Info"
                                                    onClick={this.visibleCallout}
                                                    iconProps={iconProps}
                                                    />
                                                </Stack>
                                                <Dropdown
                                                    label=''
                                                    options={this.state.tolerancias}
                                                    name = 'tolerancia.left'
                                                    onChange={this.onInputchangeTolerancia}
                                                    defaultSelectedKey={this.state.toleranciasSelL.key.toString()}
                                                    disabled={false}
                                                />
                                                {this.state.isCalloutVisible && (
                                                    <Callout
                                                    target={'#infoBtn'}
                                                    setInitialFocus
                                                    ariaDescribedBy={'Teat'}
                                                    role="alertdialog"
                                                    style={{padding: '3%'}}
                                                    >
                                                        <Stack horizontalAlign="start" >
                                                            <span style={{color: 'white'}}>{t('conversionmargin.alert')}</span>
                                                            <br></br>
                                                            <DefaultButton onClick={this.invisibleCallout}>{t('p2.btn')}</DefaultButton>
                                                        </Stack>
                                                    </Callout>
                                                )}
                                            </div>
                                            <br></br>{/*Carcasa */}
                                            <div className={this.state.left.programador === 'interleaving' && (this.state.left.pulse !== this.state.left.pulse2) ? "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6" : "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12"} style={{marginBottom: '2%'}}>
                                                <div className="ms-Grid-row">
                                                {this.state.left.programador === 'interleaving' ?
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <Label className="ms-fontWeight-semibold">{t('pulse.label')}: <span className="ms-fontWeight-regular">{this.state.left.pulse} μs</span></Label>
                                                    </div> : <></>}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                                <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'white'}}>IPG</span>
                                                                <span className={'checkboxBlack '+this.state.fusion.left.leads.lcase} title="lcase" style={{cursor: 'no-drop'}}>
                                                                    { this.state.fusion.left.leads.lcase === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.fusion.left.leads.lcase === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 3 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                                <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'white'}}>4</span>
                                                                <span className={'checkboxBlack '+this.state.fusion.left.leads.l3} title="l3" style={{cursor: 'no-drop'}}>
                                                                    {this.state.fusion.left.leads.l3 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.fusion.left.leads.l3 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                    
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    ariaLabel="Example text field with https:// prefix"
                                                                    name="left.l3"
                                                                    data-child={this.state.left.programador === 'interleaving' && (this.state.left.pulse === this.state.left.pulse2) ? 
                                                                        this.state.left.leads.l3 !== 'neutral' ? 'impedance' : 'impedance2'
                                                                    : 'impedance'}
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.fusion.left.leads.l3 !== 'neutral' ? this.state.fusion.left.impedance.l3 : '' }
                                                                    disabled={this.state.fusion.left.leads.l3 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 2 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>3</span>
                                                                <span className={'checkboxBlack '+this.state.fusion.left.leads.l2} title="l2" style={{cursor: 'no-drop'}}>
                                                                {this.state.fusion.left.leads.l2 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.fusion.left.leads.l2 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    name="left.l2"
                                                                    data-child={this.state.left.programador === 'interleaving' && (this.state.left.pulse === this.state.left.pulse2) ? 
                                                                        this.state.left.leads.l2 !== 'neutral' ? 'impedance' : 'impedance2'
                                                                    : 'impedance'}
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.fusion.left.leads.l2 !== 'neutral' ? this.state.fusion.left.impedance.l2 : '' }
                                                                    disabled={this.state.fusion.left.leads.l2 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 1 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>2</span>
                                                                <span className={'checkboxBlack '+this.state.left.leads.l1} title="l1" style={{cursor: 'no-drop'}}>
                                                                {this.state.fusion.left.leads.l1 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.fusion.left.leads.l1 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    name="left.l1"
                                                                    data-child={this.state.left.programador === 'interleaving' && (this.state.left.pulse === this.state.left.pulse2) ? 
                                                                        this.state.left.leads.l1 !== 'neutral' ? 'impedance' : 'impedance2'
                                                                    : 'impedance'}
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.fusion.left.leads.l1 !== 'neutral' ? this.state.fusion.left.impedance.l1 : '' }
                                                                    disabled={this.state.fusion.left.leads.l1 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 0 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>1</span>
                                                                <span className={'checkboxBlack '+this.state.fusion.left.leads.l0} title="l0" style={{cursor: 'no-drop'}}>
                                                                {this.state.fusion.left.leads.l0 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.fusion.left.leads.l0 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    ariaLabel="Example text field with https:// prefix"
                                                                    name="left.l0"
                                                                    data-child={this.state.left.programador === 'interleaving' && (this.state.left.pulse === this.state.left.pulse2) ? 
                                                                        this.state.left.leads.l0 !== 'neutral' ? 'impedance' : 'impedance2'
                                                                    : 'impedance'}
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.fusion.left.leads.l0 !== 'neutral' ? this.state.fusion.left.impedance.l0 : '' }
                                                                    disabled={this.state.fusion.left.leads.l0 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.left.programador === 'interleaving' && (this.state.left.pulse !== this.state.left.pulse2) ?
                                            <div className={this.state.left.programador === 'interleaving' && (this.state.left.pulse !== this.state.left.pulse2) ? "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6" : "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12"} style={{marginBottom: '2%'}}>
                                                <div className="ms-Grid-row">
                                                    {this.state.left.programador === 'interleaving' ?
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <Label className="ms-fontWeight-semibold">{t('pulse.label')}: <span className="ms-fontWeight-regular">{this.state.left.pulse2} μs</span></Label>
                                                    </div> : <></>}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                                <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'white'}}>IPG</span>
                                                                <span className={'checkboxBlack '+this.state.left.leads2.lcase} title="lcase" style={{cursor: 'no-drop'}}>
                                                                    { this.state.left.leads2.lcase === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.left.leads2.lcase === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 3 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                                <span className="ms-fontWeight-bold ms-fontSize-16" style={{marginRight: '14%', color: 'white'}}>4</span>
                                                                <span className={'checkboxBlack '+this.state.left.leads2.l3} title="l3" style={{cursor: 'no-drop'}}>
                                                                    { this.state.left.leads2.l3 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.left.leads2.l3 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    ariaLabel="Example text field with https:// prefix"
                                                                    name="left.l3"
                                                                    data-child="impedance2"
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.left.leads2.l3 !== 'neutral' ? this.state.left.impedance2.l3 : '' }
                                                                    disabled={this.state.left.leads2.l3 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 2 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>3</span>
                                                                <span className={'checkboxBlack '+this.state.left.leads2.l2} title="l2" style={{cursor: 'no-drop'}}>
                                                                    { this.state.left.leads2.l2 === 'positive'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                        : this.state.left.leads2.l2 === 'negative'
                                                                            // eslint-disable-next-line
                                                                            ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                            : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    name="left.l2"
                                                                    data-child="impedance2"
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.left.leads2.l2 !== 'neutral' ? this.state.left.impedance2.l2 : '' }
                                                                    disabled={this.state.left.leads2.l2 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 1 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>2</span>
                                                                <span className={'checkboxBlack '+this.state.left.leads2.l1} title="l1" style={{cursor: 'no-drop'}}>
                                                                    { this.state.left.leads2.l1 === 'positive'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                        : this.state.left.leads2.l1 === 'negative'
                                                                            // eslint-disable-next-line
                                                                            ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                            : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    name="left.l1"
                                                                    data-child="impedance2"
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.left.leads2.l1 !== 'neutral' ? this.state.left.impedance2.l1 : '' }
                                                                    disabled={this.state.left.leads2.l1 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 0 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>1</span>
                                                                <span className={'checkboxBlack '+this.state.left.leads2.l0} title="l0" style={{cursor: 'no-drop'}}>
                                                                    { this.state.left.leads2.l0 === 'positive'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                        : this.state.left.leads2.l0 === 'negative'
                                                                            // eslint-disable-next-line
                                                                            ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                            : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    ariaLabel="Example text field with https:// prefix"
                                                                    name="left.l0"
                                                                    data-child="impedance2"
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.left.leads2.l0 !== 'neutral' ? this.state.left.impedance2.l0 : '' }
                                                                    disabled={this.state.left.leads2.l0 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : <></>}
                                        </div>
                                    </PivotItem>
                                }
                                {this.state.ladoImpUniSel.key !== 'left' &&
                                    <PivotItem headerText={t('rightsnt.header')}>
                                        <Label className="ms-fontWeight-regular ms-fontSize-28">{t('right.text')}</Label>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                <Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}: <span className="ms-fontWeight-regular">{this.state.right.amplitude} {this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'}</span></Label>
                                            </div>
                                            {this.state.right.programador !== 'interleaving' ?
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                <Label className="ms-fontWeight-semibold">{t('pulse.label')}: <span className="ms-fontWeight-regular">{this.state.right.pulse} μs</span></Label>
                                            </div> : <></>}
                                        </div>
                                        <div className="ms-Grid-row">
                                            {
                                            this.validor 
                                            ?
                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                    <Label className="ms-fontWeight-semibold">{t('frequency.label')}: <span className="ms-fontWeight-regular">{this.state.right.rate} Hz </span></Label>
                                                </div>
                                            :
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <div className="ms-Grid-col ms-sm7 ms-md7 ms-lg7 P3-botton-p">
                                                        <Label className="ms-fontWeight-semibold">{t('frequency.label')}: <span className="ms-fontWeight-regular nameErr">{this.state.right.rate} Hz </span></Label>
                                                        <Label className="P3warning nameErr">{t('frequency.alert')}</Label>
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm5 ms-md5 ms-lg5 btn-container P3-botton-p">
                                                        <DefaultButton data={this.anteriorr} text={this.anteriorr + ' Hz'} className={this.btn1r} onClick={this.changeRateRigth}/>
                                                        <DefaultButton name={this.siguienter} text={this.siguienter + ' Hz'} className={this.btn2r} onClick={this.changeRateRigth}/>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {/*Lead Config */}
                                        <div className="ms-Grid-row" id="contentCenter">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '3%'}}>
                                                <Separator></Separator>
                                                <Label className="ms-fontSize-16 ms-fontWeight-regular">{t('contactsconf.text')}</Label>
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '3%'}}>
                                                <Stack horizontal verticalAlign="center">
                                                    <span id={'infoBtn'} style={{color: 'white'}}>{t('conversionmargin.text')}</span>
                                                    <IconButton
                                                    title="Info"
                                                    ariaLabel="Info"
                                                    onClick={this.visibleCallout}
                                                    iconProps={iconProps}
                                                    />
                                                </Stack>
                                                <Dropdown
                                                    label=''
                                                    options={this.state.tolerancias}
                                                    name = 'tolerancia.rigth'
                                                    onChange={this.onInputchangeTolerancia}
                                                    defaultSelectedKey={this.state.toleranciasSelR.key.toString()}
                                                    disabled={false}
                                                />
                                                {this.state.isCalloutVisible && (
                                                    <Callout
                                                    target={'#infoBtn'}
                                                    setInitialFocus
                                                    ariaDescribedBy={'Teat'}
                                                    role="alertdialog"
                                                    style={{padding: '3%'}}
                                                    >
                                                        <Stack horizontalAlign="start" >
                                                            <span style={{color: 'white'}}>{t('conversionmargin.alert')}</span>
                                                            <br></br>
                                                            <DefaultButton onClick={this.invisibleCallout}>{t('p2.btn')}</DefaultButton>
                                                        </Stack>
                                                    </Callout>
                                                )}
                                            </div>
                                            <br></br>{/*Carcasa */}
                                            <div className={this.state.right.programador === 'interleaving' && (this.state.right.pulse !== this.state.right.pulse2) ? "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6" : "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12"} style={{marginBottom: '2%'}}>
                                                <div className="ms-Grid-row">
                                                {this.state.right.programador === 'interleaving' ?
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <Label className="ms-fontWeight-semibold">{t('pulse.label')}: <span className="ms-fontWeight-regular">{this.state.right.pulse} μs</span></Label>
                                                    </div> : <></>}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>IPG</span>
                                                                <span className={'checkboxBlack '+this.state.fusion.right.leads.rcase} title="rcase" style={{cursor: 'no-drop'}}>
                                                                    { this.state.fusion.right.leads.rcase === 'positive'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                        : this.state.fusion.right.leads.rcase === 'negative'
                                                                            // eslint-disable-next-line
                                                                            ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                            : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 7 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>4</span>
                                                                <span className={'checkboxBlack '+this.state.fusion.right.leads.r3} title="r3" style={{cursor: 'no-drop'}}>
                                                                    { this.state.fusion.right.leads.r3 === 'positive'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                        : this.state.fusion.right.leads.r3 === 'negative'
                                                                            // eslint-disable-next-line
                                                                            ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                            : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    ariaLabel=""
                                                                    name="right.r3"
                                                                    data-child={this.state.right.programador === 'interleaving' && (this.state.right.pulse === this.state.right.pulse2) ? 
                                                                        this.state.right.leads.r3 !== 'neutral' ? 'impedance' : 'impedance2'
                                                                    : 'impedance'}
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.fusion.right.leads.r3 !== 'neutral' ? this.state.fusion.right.impedance.r3 : ''}
                                                                    disabled={this.state.fusion.right.leads.r3 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 6 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>3</span>
                                                                <span className={'checkboxBlack '+this.state.fusion.right.leads.r2} title="r2" style={{cursor: 'no-drop'}}>
                                                                    { this.state.fusion.right.leads.r2 === 'positive'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                        : this.state.fusion.right.leads.r2 === 'negative'
                                                                            // eslint-disable-next-line
                                                                            ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                            : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    ariaLabel=""
                                                                    name="right.r2"
                                                                    data-child={this.state.right.programador === 'interleaving' && (this.state.right.pulse === this.state.right.pulse2) ? 
                                                                        this.state.right.leads.r2 !== 'neutral' ? 'impedance' : 'impedance2'
                                                                    : 'impedance'}
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.fusion.right.leads.r2 !== 'neutral' ? this.state.fusion.right.impedance.r2 : ''}
                                                                    disabled={this.state.fusion.right.leads.r2 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 5 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>2</span>
                                                                <span className={'checkboxBlack '+this.state.fusion.right.leads.r1} title="r1" style={{cursor: 'no-drop'}}>
                                                                    { this.state.fusion.right.leads.r1 === 'positive'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                        : this.state.fusion.right.leads.r1 === 'negative'
                                                                            // eslint-disable-next-line
                                                                            ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                            : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    ariaLabel=""
                                                                    name="right.r1"
                                                                    data-child={this.state.right.programador === 'interleaving' && (this.state.right.pulse === this.state.right.pulse2) ? 
                                                                        this.state.right.leads.r1 !== 'neutral' ? 'impedance' : 'impedance2'
                                                                    : 'impedance'}
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.fusion.right.leads.r1 !== 'neutral' ? this.state.fusion.right.impedance.r1 : ''}
                                                                    disabled={this.state.fusion.right.leads.r1 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 4 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>1</span>
                                                                <span className={'checkboxBlack '+this.state.fusion.right.leads.r0} title="r0" style={{cursor: 'no-drop'}}>
                                                                    { this.state.fusion.right.leads.r0 === 'positive'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                        : this.state.fusion.right.leads.r0 === 'negative'
                                                                            // eslint-disable-next-line
                                                                            ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                            : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    ariaLabel=""
                                                                    name="right.r0"
                                                                    data-child={this.state.right.programador === 'interleaving' && (this.state.right.pulse === this.state.right.pulse2) ? 
                                                                        this.state.right.leads.r0 !== 'neutral' ? 'impedance' : 'impedance2'
                                                                    : 'impedance'}
                                                                    readOnly={false}
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.fusion.right.leads.r0 !== 'neutral' ? this.state.fusion.right.impedance.r0 : ''}
                                                                    disabled={this.state.fusion.right.leads.r0 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.right.programador === 'interleaving' && (this.state.right.pulse !== this.state.right.pulse2) ?
                                            <div className={this.state.right.programador === 'interleaving' && (this.state.right.pulse !== this.state.right.pulse2) ? "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6" : "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12"} style={{marginBottom: '2%'}}>
                                                <div className="ms-Grid-row">
                                                    {this.state.right.programador === 'interleaving' ?
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <Label className="ms-fontWeight-semibold">{t('pulse.label')}: <span className="ms-fontWeight-regular">{this.state.right.pulse2} μs</span></Label>
                                                    </div> : <></>}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>IPG</span>
                                                                <span className={'checkboxBlack '+this.state.right.leads2.rcase} title="rcase" style={{cursor: 'no-drop'}}>
                                                                    { this.state.right.leads2.rcase === 'positive'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                        : this.state.right.leads2.rcase === 'negative'
                                                                            // eslint-disable-next-line
                                                                            ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                            : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 7 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>4</span>
                                                                <span className={'checkboxBlack '+this.state.right.leads2.r3} title="r3" style={{cursor: 'no-drop'}}>
                                                                    { this.state.right.leads2.r3 === 'positive'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                        : this.state.right.leads2.r3 === 'negative'
                                                                            // eslint-disable-next-line
                                                                            ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                            : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    ariaLabel=""
                                                                    name="right.r3"
                                                                    data-child="impedance2"
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.right.leads2.r3 !== 'neutral' ? this.state.right.impedance2.r3 : ''}
                                                                    disabled={this.state.right.leads2.r3 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 6 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>3</span>
                                                                <span className={'checkboxBlack '+this.state.right.leads2.r2} title="r2" style={{cursor: 'no-drop'}}>
                                                                    { this.state.right.leads2.r2 === 'positive'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                        : this.state.right.leads2.r2 === 'negative'
                                                                            // eslint-disable-next-line
                                                                            ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                            : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    ariaLabel=""
                                                                    name="right.r2"
                                                                    data-child="impedance2"
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.right.leads2.r2 !== 'neutral' ? this.state.right.impedance2.r2 : ''}
                                                                    disabled={this.state.right.leads2.r2 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 5 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>2</span>
                                                                <span className={'checkboxBlack '+this.state.right.leads2.r1} title="r1" style={{cursor: 'no-drop'}}>
                                                                    { this.state.right.leads2.r1 === 'positive'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                        : this.state.right.leads2.r1 === 'negative'
                                                                            // eslint-disable-next-line
                                                                            ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                            : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    ariaLabel=""
                                                                    name="right.r1"
                                                                    data-child="impedance2"
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.right.leads2.r1 !== 'neutral' ? this.state.right.impedance2.r1 : ''}
                                                                    disabled={this.state.right.leads2.r1 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 4 */}
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right', color: 'white'}}>
                                                                <span className="label" style={{marginRight: '14%'}}>1</span>
                                                                <span className={'checkboxBlack '+this.state.right.leads2.r0} title="r0" style={{cursor: 'no-drop'}}>
                                                                    { this.state.right.leads2.r0 === 'positive'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                        : this.state.right.leads2.r0 === 'negative'
                                                                            // eslint-disable-next-line
                                                                            ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%"><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                            : <svg  width="10" height="10"></svg>
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                                <TextField 
                                                                    suffix="Ω"
                                                                    ariaLabel=""
                                                                    name="right.r0"
                                                                    data-child="impedance2"
                                                                    readOnly={false}
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.right.leads2.r0 !== 'neutral' ? this.state.right.impedance2.r0 : ''}
                                                                    disabled={this.state.right.leads2.r0 !== 'neutral' ? false : true }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : <></>}
                                        </div>
                                    </PivotItem>
                                }
                                </Pivot>
                            </div>
                        </div>
                        
                        
                            {/*Lead Config
                            <div className="ms-Grid-row" id="contentCenter">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Separator></Separator>
                                    <Label>Lead configuration</Label>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                            <Label className="label">4 {this.state.left.leads.l3}</Label>
                                        </div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                            <TextField 
                                                suffix="Ω"
                                                ariaLabel="Example text field with https:// prefix"
                                                name="left.l3"
                                                onChange={this.onInputchange}
                                                onGetErrorMessage={this.isFloatNum}
                                            />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                            <Label className="label">3 {this.state.left.leads.l2}</Label>
                                        </div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                            <TextField 
                                                suffix="Ω"
                                                ariaLabel="Example text field with https:// prefix"
                                                name="left.l2"
                                                onChange={this.onInputchange}
                                                onGetErrorMessage={this.isFloatNum}
                                            />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                            <Label className="label">2 {this.state.left.leads.l1}</Label>
                                        </div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                            <TextField 
                                                suffix="Ω"
                                                ariaLabel="Example text field with https:// prefix"
                                                name="left.l1"
                                                onChange={this.onInputchange}
                                                onGetErrorMessage={this.isFloatNum}
                                            />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                            <Label className="label">1 {this.state.left.leads.l0}</Label>
                                        </div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                            <TextField 
                                                suffix="Ω"
                                                ariaLabel="Example text field with https:// prefix"
                                                name="left.l0"
                                                onChange={this.onInputchange}
                                                onGetErrorMessage={this.isFloatNum}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                                 */}
                            <br></br>
                        <div className="ms-Grid-row" style={{marginBottom: '10%'}}>
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <Link
                                    to={{
                                        pathname: "/configuracioninicial",
                                        state: this.state
                                    }}
                                >
                                    <DefaultButton text={t('return.btn')}/>
                                </Link>
                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                <Link
                                    to={{
                                        pathname: "/conversionfinal",
                                        state: this.state
                                    }}
                                >
                                    <PrimaryButton text={t('finalize.btn')} id="next" style={{display: 'none'}}/>
                                </Link>
                                <PrimaryButton text={t('finalize.btn')} onClick={this.siguente} disabled={
                                    this.state.tipoProgramacionSel.key
                                    ?   this.state.tipoImplanteSel.key === 'uni'
                                        ?   this.state.ladoImpUniSel.key === 'left'
                                            ?   this.state.validInputL
                                                ? false
                                                : true
                                            :   this.state.validInputR
                                                ? false
                                                : true
                                        :   this.state.validInputL && this.state.validInputR
                                            ? false
                                            : true
                                    : true
                                }/>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg4"></div>
                </div>
                

                
                
            </>
        );
    }
}

export default withTranslation()(ConversorMetronicP3);