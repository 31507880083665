import axios from 'axios'
//const url_backend = `http://127.0.0.1:8000`
const url_backend = process.env.REACT_APP_API_URL_DEVELOP
export const data = {
    resetPass: function(email){
        return axios.post(url_backend+`/api/auth/forgot-password`, {
            email: email
        })
    },
    reestablecerPass: function(token, email, password, password_confirmation){
        return axios.post(url_backend+`/api/auth/reset-password`, {
            token: token,
            email: email,
            password: password,
            password_confirmation: password_confirmation
        })
    },
    login: function(email, password){
        return axios.post(url_backend+`/api/auth/login`, {
            email: email,
            password: password
        })
    },
    logout: function(){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('auth')
        return axios.post(url_backend+`/api/auth/logout`)
    },
    getProfile: function () {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('auth')
        return axios.get(url_backend+`/api/auth/user-profile`)
    },
    getProvincias: function(id){
        return axios.get(url_backend+`/api/provincias/provincias`)
    },
    getPaises: function(id){
        return axios.get(url_backend+`/api/paises/paises`)
    },
    updateProfile: function(id, name, surnames, email, pais_id, provincia_id){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.put(url_backend+`/api/users/user/${id}`, {
            name: name,
            surnames: surnames,
            email: email,
            pais_id: pais_id,
            provincia_id: provincia_id
        })
    },
    updatePass: function(id, current_pass, new_pass){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.put(url_backend+`/api/users/userupdatepass/${id}`, {
            password: current_pass,
            new_password: new_pass
        })
    },
    registrar: function(name, surnames, email, pais_id, provincia_id, invicode){
        return axios.post(url_backend+`/api/auth/register`, {
            name: name,
            surnames: surnames,
            email: email,
            pais_id: pais_id,
            provincia_id: provincia_id,
            invicode: invicode
        })
    },
    guardarDatos: function(datos){
        return axios.post(url_backend+`/api/datos/datos`, datos)
    }
}