import React from 'react';
import '../App.css';
import '../styles/style.css'
import 'office-ui-fabric-react/dist/css/fabric.css';
// import logobsci from '../images/logo-cabecera-BSCI_COCOVITA.png';
import logobsci from '../images/logo-cabecera-BSCI_COCOVITA-v2.png';
import ConversorMetronic from './coversorMetronic';
import { BrowserRouter, Route, Switch, Redirect, withRouter} from 'react-router-dom';
import MetronicIndex from './conversorMetronicIndex'
import MetronicP0 from './conversorMetronicP0'
import MetronicP2 from './conversorMetronicP2'
import MetronicP3 from './conversorMetronicP3'
import MetronicFin from './conversorMetronicFin'
import VerInforme from './verInforme'
import About from './about'
import ResetPassword from './resetPassword'
import {DirectionalHint, TeachingBubble, Persona, PersonaSize, Modal, Label, IconButton, Separator, TextField, Dropdown, MessageBar, MessageBarType, PrimaryButton, Spinner, SpinnerSize} from '@fluentui/react';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

import {data} from '../data/data'

import { withTranslation } from 'react-i18next'
import { createTheme} from '@fluentui/react';
import i18n from "i18next";


const dropdown = createTheme({
    palette: {
        themePrimary: '#003c71',
        themeLighterAlt: '#000204',
        themeLighter: '#000a12',
        themeLight: '#001222',
        themeTertiary: '#002443',
        themeSecondary: '#003563',
        themeDarkAlt: '#0d497e',
        themeDark: '#235f92',
        themeDarker: '#4d81af',
        neutralLighterAlt: '#054176',
        neutralLighter: '#0a477c',
        neutralLight: '#135085',
        neutralQuaternaryAlt: '#19568a',
        neutralQuaternary: '#1f5b8f',
        neutralTertiaryAlt: '#3971a2',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#003c71',
}});

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            porcent: 0.1,
            verStateLogin: false,
            verModalUserProfile: false,

            id: 0,
            name: ``,
            surnames: ``,
            email: ``,
            pais_id: 1,
            provincia_id: 1,
            current_pass: ``,
            new_pass: ``,
            paises: [],
            provincias: [],
            provinciasAux: [],

            verMsgPerfil: false,
            typeMsgPerfil: `success`,
            msgPerfil: ``,

            verMsgPass: false,
            typeMsgPass: `success`,
            msgPass: ``,
            verCargandoPerfil: false,

            teachingBubbleVisible: false,
            lang: [
                {key: 'es', text: `${this.props.t('es')}`},
                {key: 'pt', text: `${this.props.t('pt')}`}
            ]
        }
        this.componentDidMount = this.componentDidMount.bind(this)
        this.logout = this.logout.bind(this)
        this.openModalUserProfile = this.openModalUserProfile.bind(this)
        this.closeModalUserProfile = this.closeModalUserProfile.bind(this)
        this.onInputchange = this.onInputchange.bind(this)
        this.onInputChangeSelect = this.onInputChangeSelect.bind(this)
        this.onInputChangeSelectPais = this.onInputChangeSelectPais.bind(this)
        this.actualizarPerfil = this.actualizarPerfil.bind(this)
        this.openLogOut = this.openLogOut.bind(this)
        
    }

    componentDidMount(){
        console.info('inicio')
        if(localStorage.getItem('auth')){
            data.getProfile()
            .then(() => {
                this.setState({verStateLogin: true})
                this.props.history.push("/datos")
            })
            .catch(() => {
                this.setState({verStateLogin: false})
                localStorage.removeItem('auth')
                this.props.history.push("/login")
            })
        }else{
            this.setState({verStateLogin: false})
            localStorage.removeItem('auth')
            this.props.history.push("/login")
        }
        data.getPaises()
        .then(response => {
            this.setState({paises: response.data})
        })
        data.getProvincias()
        .then(response => {
            this.setState({provincias: response.data})
            this.setState({provinciasAux: response.data})
        })
    }

    onInputchange(event){
        const campo = event.target.name
        const value = event.target.value
        this.setState({[campo]: value})
        this.setState({showErr: false})
    }
    onInputChangeSelectPais(event, item){
        this.setState({pais_id: item.key})
        let prov = (this.state.provinciasAux).filter(provincia => provincia.pais_id === item.key)
        this.setState({provincias: prov})
    }
    onInputChangeSelect(event, item){
        this.setState({provincia_id: item.key})
    }

    logout(){
        data.logout()
        .then(() => {
            localStorage.removeItem('auth')
            this.props.history.push("/login")
            window.location.reload()
        })
        .catch(error => {
            console.error(error)
        })
    }

    openModalUserProfile(){
        data.getProfile()
        .then(response => {
            this.setState({
                verModalUserProfile: true,
                id: response.data.id,
                name: response.data.name,
                surnames: response.data.surnames,
                provincia_id: response.data.provincia_id,
                email: response.data.email
            })
        })
    }

    closeModalUserProfile(){
        this.setState({
            verModalUserProfile: false
        })
    }

    actualizarPerfil(){
        this.setState({verCargandoPerfil: true})
        data.updateProfile(this.state.id, this.state.name, this.state.surnames, this.state.email, this.state.pais_id, this.state.provincia_id)
        .then(response => {
            this.setState({
                verCargandoPerfil: false,
                msgPerfil: response.data.message,
                typeMsgPerfil: 'success',
                verMsgPerfil: true
            })
        })
        .catch(error => {
            this.setState({
                verCargandoPerfil: false,
                msgPerfil: error.response.data.message,
                typeMsgPerfil: 'danger',
                verMsgPerfil: true
            })
        })
        if(this.state.current_pass && this.state.new_pass){
            this.setState({verCargandoPerfil: true})
            data.updatePass(this.state.id, this.state.current_pass, this.state.new_pass)
            .then(response => {
                console.info(response)
                this.setState({
                    verCargandoPerfil: false,
                    msgPass: response.data.message,
                    typeMsgPass: 'success',
                    verMsgPass: true
                })
            })
            .catch(error => {
                console.info(error.response.data)
                if(error.response.data.password){
                    this.setState({
                        msgPass: error.response.data.password[0],
                    })
                }
                this.setState({
                    verCargandoPerfil: false,
                    typeMsgPass: 'danger',
                    verMsgPass: true
                })
            })
        }
    }

    onChangeDropdownLanguaje(lang){

    }

    openLogOut(){
        this.setState({teachingBubbleVisible: true})
    }

    _alertClicked = (e) =>{
        e.preventDefault();
        this.setState({
            hola: 'adios'
        })
        alert('pruebas')
    }

    render() {
        const {t} = this.props
        return (
            <div className="App">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" id="topNavbar">
                            {this.state.verStateLogin
                                ? 
                                    <>
                                        
                                        <span style={{float: 'left', marginTop: '5px', cursor: 'pointer', marginLeft: '11px'}}>
                                            <Persona text="" size={PersonaSize.size32} style={{cursor: 'pointer'}} onClick={this.openModalUserProfile} />
                                        </span>
                                    </>
                                : ''
                            }
                            <span style={{float: 'left', marginTop: '5px', cursor: 'pointer', marginLeft: '11px'}}>
                            <Dropdown
                                placeholder={`${t('sel.idioma')}`}
                                options={this.state.lang}
                                theme={dropdown}
                                selectedKey={i18n.language === 'es' ? 'es' : 'pt'}
                                onChange={(key, object) => {i18n.changeLanguage(object.key); sessionStorage.setItem('lang', object.key)}}
                            />
                            </span>
                            <img src={logobsci} id="logobsci" alt="Logo" style={this.state.verStateLogin ? {}: {}} />
                            {this.state.verStateLogin
                                ? 
                                    <>
                                        <span style={{float: 'right', marginTop: '9px', marginRight: '11px'}}>
                                            <FontIcon id="targetLogOut" iconName="SignOut" style={{fontSize: '22px', color: 'white', cursor: 'pointer'}} onClick={this.openLogOut}/>
                                        </span>
                                    </>
                                : ''
                            }
                            
                        </div>
                    </div>

                    {this.state.teachingBubbleVisible && (
                        <TeachingBubble
                            calloutProps={{ directionalHint: DirectionalHint.TopCenter }}
                            target="#targetLogOut"
                            primaryButtonProps={{children: t('logout.btn'), onClick: this.logout}}
                            secondaryButtonProps={{children: t('cancel.btn'), onClick: () => this.setState({teachingBubbleVisible: false})}}
                            onDismiss={() => this.setState({teachingBubbleVisible: false})}
                            headline={t('logout.btn')}
                        >
                            {t('logout.quest')}
                        </TeachingBubble>
                    )}
                    <Modal
                        isOpen={this.state.verModalUserProfile}
                        onDismiss={this.closeModalUserProfile}
                        isBlocking={false}
                        style={{width: '20000px'}}
                        containerClassName={{container: {
                            display: 'flex',
                            flexFlow: 'column nowrap',
                            alignItems: 'stretch',
                        }}}
                    >
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{
                            flex: '1 1 auto',
                            borderTop: `4px solid #003c71`,
                            color: 'black',
                            display: 'flex',
                            alignItems: 'center',
                            fontWeight: 'bold',
                            padding: '12px 12px 0px 24px'}}
                        >
                            <Label className="ms-fontWeight-regular ms-fontSize-20">{t('profile.title')}</Label>
                            <IconButton
                                styles={iconButtonStyles}
                                iconProps={{iconName: 'Cancel'}}
                                ariaLabel="Close popup modal"
                                onClick={this.closeModalUserProfile}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{
                            flex: '4 4 auto',
                            padding: '0 24px 24px 24px',
                            overflowY: 'hidden',
                            selectors: {
                                p: { margin: '14px 0' },
                                'p:first-child': { marginTop: 0 },
                                'p:last-child': { marginBottom: 0 },
                            }}}
                        >
                            <Separator></Separator>
                            <TextField 
                                label={t('name.label')}
                                name="name"
                                type="text"
                                value={this.state.name}
                                onChange={this.onInputchange}
                            />
                            <TextField 
                                label={t('surnames.label')}
                                name="surnames"
                                type="text"
                                value={this.state.surnames}
                                onChange={this.onInputchange}
                            />
                            <Dropdown
                                label={t('pais.label')}
                                name="pais_id"
                                onChange={this.onInputChangeSelectPais}
                                options={this.state.paises.map((x,y) => {return {key: x.id, text: x.name}})}
                                defaultSelectedKey={this.state.pais_id ? this.state.pais_id : 1}
                            />
                            <Dropdown
                                label={t('province.label')}
                                name="provincia_id"
                                
                                onChange={this.onInputChangeSelect}
                                options={this.state.provincias.map((x,y) => {return {key: x.id, text: x.name}})}
                                defaultSelectedKey={this.state.provincia_id ? this.state.provincia_id : 1}
                            />
                            <TextField 
                                label={t('email.label')}
                                name="email"
                                type="email"
                                value={this.state.email}
                                onChange={this.onInputchange}
                            />
                            <Separator></Separator>
                            <Label className="ms-fontWeight-regular ms-fontSize-14">{t('passwordchange.title')}</Label>
                            <TextField 
                                label={t('passwordact.label')}
                                name="current_pass"
                                type="password"
                                canRevealPassword
                                onChange={this.onInputchange}
                            />
                            <TextField 
                                label={t('passwordnew.label')}
                                name="new_pass"
                                type="password"
                                onChange={this.onInputchange}
                            />
                            {this.state.verMsgPerfil
                                ?
                                    <>
                                    <br></br>
                                        <MessageBar
                                            messageBarType={this.state.typeMsgPerfil === 'success' ?  MessageBarType.success : MessageBarType.error }
                                            isMultiline={false}
                                        >
                                            {this.state.msgPerfil}
                                        </MessageBar>
                                    </>
                                : ''
                            }
                            {this.state.verMsgPass
                                ?
                                    <>
                                    <br></br>
                                        <MessageBar
                                            messageBarType={this.state.typeMsgPass === 'success' ?  MessageBarType.success : MessageBarType.error }
                                            isMultiline={false}
                                        >
                                            {this.state.msgPass}
                                        </MessageBar>
                                    </>
                                : ''
                            }
                            <br></br>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{textAlign: 'center'}}>
                                    {!this.state.verCargandoPerfil
                                        ? <PrimaryButton text={t('save.btn')} onClick={this.actualizarPerfil} allowDisabledFocus disabled={false} checked={false} />
                                        : <Spinner size={SpinnerSize.medium} />
                                    }
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div className="ms-Grid" dir="ltr">
                <BrowserRouter>
                    <Switch>
                        <Route
                            path="/about"
                            exact
                            component={About}
                        />
                        <Redirect
                            from="/home"
                            to="/datos"
                        />
                        
                        <Route
                            path="/login"
                            exact
                            component={MetronicIndex}
                        />
                        
                        <Route
                            path="/datos"
                            exact
                            component={MetronicP0}
                        />
						<Route
                            path="/configuracioninicial"
                            exact
                            component={ConversorMetronic}
                        />
                        <Route
                            path="/conversorp2"
                            exact
                            component={MetronicP2}
                        />
                        <Route
                            path="/lecturaimpedancias"
                            exact
                            component={MetronicP3}
                        />
                        <Route
                            path="/conversionfinal"
                            exact
                            component={MetronicFin}
                        />
                        <Route
                            path="/informe"
                            exact
                            component={VerInforme}
                        />
                        <Route
                            path="/reset-password"
                            component={ResetPassword}
                        />
                        <Redirect to="/login" />
                    </Switch>
                </BrowserRouter>
                </div>
            </div>
        );
    }
}

const iconButtonStyles = {
    root: {
        color: 'black',
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: 'black',
    },
};

export default withTranslation()(withRouter(Index));