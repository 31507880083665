import React from 'react';
import '../App.css';
import '../styles/style.css'
import 'office-ui-fabric-react/dist/css/fabric.css';
import { Link } from 'react-router-dom';

import { PrimaryButton, DefaultButton, Dropdown, TeachingBubble, DirectionalHint, Checkbox, Callout, Stack, IconButton } from '@fluentui/react'
import { Pivot, PivotItem } from '@fluentui/react';
import { Label } from '@fluentui/react';
import { TextField } from '@fluentui/react';

import { Separator } from '@fluentui/react';

import { createTheme, loadTheme} from '@fluentui/react';

import replacedBattery from '../images/replacedBattery.gif';

import { withTranslation } from 'react-i18next'

const myThemeAnt = createTheme({
    palette: {
        themePrimary: '#303030',
        themeLighterAlt: '#e6e6e6',
        themeLighter: '#cfcfcf',
        themeLight: '#b9b9b9',
        themeTertiary: '#a2a2a2',
        themeSecondary: '#8b8b8b',
        themeDarkAlt: '#757575',
        themeDark: '#5e5e5e',
        themeDarker: '#474747',
        neutralLighterAlt: '#e1e2d2',
        neutralLighter: '#dddecf',
        neutralLight: '#d4d5c6',
        neutralQuaternaryAlt: '#c5c6b9',
        neutralQuaternary: '#bcbdb0',
        neutralTertiaryAlt: '#b5b6a9',
        neutralTertiary: '#594949',
        neutralSecondary: '#372b2b',
        neutralPrimaryAlt: '#2f2424',
        neutralPrimary: '#000000',
        neutralDark: '#151010',
        black: '#0b0808',
        white: '#e8e9d8',
    }});
    
loadTheme(myThemeAnt);

const iconProps = { iconName: 'Info' };
class ConversorMetronic extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            teachingBubbleVisible: false,
            isVisibleInfo: false,
            tipoProgramacion: [
				{ key: 'v', text: props.t('voltage.text')},
				{ key: 'ma', text: props.t('stream.text')},
			],
            tipoProgramacionSel: {},
            ladoImpUniSel: {},
            left:{
                programador: 'normal',
                amplitude: '',
                rate: 130,
                pulse: 60,
                pulse2: 60,
                soft: 4,
                leads:{
                    lcase: 'neutral',
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance: {
                    lcase: '-',
                    l0: '-',
                    l1: '-',
                    l2: '-',
                    l3: '-'
                },
                leads2:{
                    lcase: 'neutral',
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance2: {
                    lcase: '-',
                    l0: '-',
                    l1: '-',
                    l2: '-',
                    l3: '-'
                },
                terapiaCorriente:{l:0},
                terapiaImpedancia:{l:0},
            },
            right:{
                programador: 'normal',
                amplitude: '',
                rate: 130,
                pulse: 60,
                pulse2: 60,
                soft: 4,
                leads:{
                    rcase: 'neutral',
                    r0: 'neutral',
                    r1: 'neutral',
                    r2: 'neutral',
                    r3: 'neutral',
                },
                impedance: {
                    rcase: '-',
                    r0: '-',
                    r1: '-',
                    r2: '-',
                    r3: '-'
                },
                leads2:{
                    rcase: 'neutral',
                    r0: 'neutral',
                    r1: 'neutral',
                    r2: 'neutral',
                    r3: 'neutral',
                },
                impedance2: {
                    rcase: '-',
                    r0: '-',
                    r1: '-',
                    r2: '-',
                    r3: '-'
                },
                terapiaCorriente:{r:0},
                terapiaImpedancia:{r:0},
            },
            configAnt: {
                left:{
                    impedance: {
                        lcase: 0,
                        l0: 0,
                        l1: 0,
                        l2: 0,
                        l3: 0
                    },
                    impedance2: {
                        lcase: 0,
                        l0: 0,
                        l1: 0,
                        l2: 0,
                        l3: 0
                    },
                    terapiaCorriente:{l:0},
                    terapiaImpedancia:{l:0},
                    medirImpedancias: false
                },
                right:{
                    impedance: {
                        rcase: 0,
                        r0: 0,
                        r1: 0,
                        r2: 0,
                        r3: 0
                    },
                    impedance2: {
                        rcase: 0,
                        r0: 0,
                        r1: 0,
                        r2: 0,
                        r3: 0
                    },
                    terapiaCorriente:{r:0},
                    terapiaImpedancia:{r:0},
                    medirImpedancias: false
                },
            },
            rangeRate: [],
            rangeAmplitide: [],
            rangePulse: [],
            validoL: false,
            validoR: false,
            validoL2: false,
            validoR2: false,
            mensajeErrPositiveL: false, 
            mensajeErrPositiveR: false 
        };
        //console.log(this.props.location.state)
        this.teachingBubbleVisible = 'false'
        this.onInputchange = this.onInputchange.bind(this);
        this.onInputchangeCheckBox = this.onInputchangeCheckBox.bind(this);
        this.isFloatNum = this.isFloatNum.bind(this);
        this.onInputDropDownchangePrograma = this.onInputDropDownchangePrograma.bind(this);
        this.onInputchangeProgramador =  this.onInputchangeProgramador.bind(this)
        this.onInputchangeDropdown =  this.onInputchangeDropdown.bind(this)
        this.onInputchangeDropdownTerapia =  this.onInputchangeDropdownTerapia.bind(this)
        this.onCheckBoxChange =  this.onCheckBoxChange.bind(this)
        this.toggleTeachingBubbleVisible = this.toggleTeachingBubbleVisible.bind(this)
        this.compruebaLeadsLeft = this.compruebaLeadsLeft.bind(this)
        this.compruebaLeadsRight = this.compruebaLeadsRight.bind(this)
        this.funcionVacia = this.funcionVacia.bind(this)
        this.visibleCalloutInfoPrinci = this.visibleCalloutInfoPrinci.bind(this)
        this.invisibleCalloutInfoPrinci = this.invisibleCalloutInfoPrinci.bind(this)
    }
    visibleCalloutInfoPrinci(){
        this.setState({isVisibleInfo:  true})
    }
    invisibleCalloutInfoPrinci(){
        this.setState({isVisibleInfo:  false})
    }
    _alertClicked = (e) =>{
        e.preventDefault();
        this.setState({
            hola: 'adios'
        })
    }
    isFloatNum(value){
        if(value !== "0"){
            if(value.length > 0){
                if(!parseFloat(value)){
                    return this.props.t('intval.alert')
                }
            }
        }
    }
    //Para los inputs type text
    onInputchange(event){
        //console.log(this.state.configAnt)
        if(parseFloat(event.target.value)){
            const parts = event.target.name.split('.')
            console.log(parts)

            const aux = event.target.dataset.child
            console.log(aux)
            this.setState(prevState => ({
                configAnt: {
                    ...prevState.configAnt,
                    [parts[0]]: {
                        ...prevState.configAnt[parts[0]],
                        [aux]:{
                            ...prevState.configAnt[parts[0]][aux],
                            [parts[1]]: parseFloat(event.target.value)
                        }
                        
                    }
                }
            }))
            //console.log(this.state.configAnt)
        }
    }
    //Para programador
    onInputchangeProgramador(event, item){
        const parts = event.target.attributes.name.value.split('.')
        this.setState(prevState => ({
            [parts[0]]: {
                ...prevState[parts[0]],
                [parts[1]]: item.key
            }
        }))
        
        
        
    }
    //Para los imputs checkbox dropdown
    onInputchangeDropdown(event, item){
        const parts = event.target.attributes.name.value.split('.')
        //console.log(parts)
        if(parts[1] === 'rate'){
            this.setState(prevState => ({
                left: {
                    ...prevState.left,
                    [parts[1]]: parseFloat(item.key)
                },
                right: {
                    ...prevState.right,
                    [parts[1]]: parseFloat(item.key)
                }
            }))
        }else{
            this.setState(prevState => ({
                [parts[0]]: {
                    ...prevState[parts[0]],
                    [parts[1]]: parseFloat(item.key)
                }
            }))
        }
        
        
    }

    onInputchangeDropdownTerapia(event, item){
        const parts = event.target.attributes.name.value.split('.')
        console.log(parts)
        console.log(item.key)
        this.setState(prevState => ({
            configAnt: {
                ...prevState.configAnt,
                [parts[0]]: {
                    ...prevState.configAnt[parts[0]],
                    [parts[1]]: {
                        ...prevState.configAnt[parts[0]][parts[1]],
                        [parts[2]]:parseFloat(item.key)
                    }
                } 
            }
        }), () => {
            //console.log(this.state.configAnt.left.terapiaCorriente.l)
        })
    }

    onCheckBoxChange(event, item) {
        //console.log(event)
        //console.log(item)
        //return false;
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };

        const part = event.target.attributes.name.value
        this.setState(prevState => ({
            configAnt: {
                ...prevState.configAnt,
                [part]: {
                    ...prevState.configAnt[part],
                    medirImpedancias:(item)
                } 
            }
        }), () => {
            //console.log(this.state.configAnt)
            
        })
    }
    
    onInputchangeCheckBox(event){
        let lead = ''
        let aux = ''
        let estado = ''
        if(event.target.parentElement){
            if(String(event.target.parentElement.className).includes('checkbox')){
                lead = event.target.parentElement.title
                aux = event.target.parentElement.dataset.child
                if(event.target.parentElement.className === 'checkbox positive'){
                    estado = 'negative'
                }else if(event.target.parentElement.className === 'checkbox negative'){
                    estado = 'neutral'
                }else{
                    estado = 'positive'
                }
            }else if(String(event.target.className).includes('checkbox')){
                lead = event.target.title
                aux = event.target.dataset.child
                if(event.target.className === 'checkbox positive'){
                    estado = 'negative'
                }else if(event.target.className === 'checkbox negative'){
                    estado = 'neutral'
                }else{
                    estado = 'positive'
                }
            }else{
                if(String(event.target.parentElement.parentElement.className).includes('checkbox')){
                    lead = event.target.parentElement.parentElement.title
                    aux = event.target.parentElement.parentElement.dataset.child
                    if(event.target.parentElement.parentElement.className === 'checkbox positive'){
                        estado = 'negative'
                    }else if(event.target.parentElement.parentElement.className === 'checkbox negative'){
                        estado = 'neutral'
                    }else{
                        estado = 'positive'
                    }
                }
            }
        }
        
        const partsLead = lead.split("")
        if(partsLead[0] === 'l'){
            //LeftPart
            this.setState(prevState => ({
                left: {
                    ...prevState.left,
                    [aux]:{
                        ...prevState.left[aux],
                        [lead]: estado
                    }
                }
            }), () => {
                if(estado === 'neutral') {
                    
                }
                this.compruebaCaseLeft()
            })
            
        }else if(partsLead[0] === 'r'){
            this.setState(prevState => ({
                right: {
                    ...prevState.right,
                    [aux]:{
                        ...prevState.right[aux],
                        [lead]: estado
                    }
                }
            }), () => (
                this.compruebaCaseRight()
            ))
        }
    }
    compruebaCaseLeft(){
        /*Maquinaria para filtar en objetos*/
        Object.filter = (obj, predicate) => 
        Object.keys(obj)
            .filter( key => predicate(obj[key]) )
            // eslint-disable-next-line
            .reduce( (res, key) => (res[key] = obj[key], res), {} );
        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let positivos = Object.filter(this.state.left.leads, lead => lead === 'positive');
        let negativos = Object.filter(this.state.left.leads, lead => lead === 'negative');
        delete positivos.lcase
        delete negativos.lcase
        let positivos2 = Object.filter(this.state.left.leads2, lead => lead === 'positive');
        let negativos2 = Object.filter(this.state.left.leads2, lead => lead === 'negative');
        delete positivos2.lcase
        delete negativos2.lcase
        if(Object.size(positivos) + Object.size(negativos) === 0){
            this.setState({validoL: false})
        }else{
            this.setState({validoL: true})
        }
        if(Object.size(positivos) >= 1){
            this.setState(prevState => ({
                left: {
                    ...prevState.left,
                    leads:{
                        ...prevState.left.leads,
                        lcase: 'neutral'
                    }
                }
            }), () => {
                if(Object.size(negativos) === 0){
                    this.setState({mensajeErrPositiveL: true})
                }else{
                    this.setState({mensajeErrPositiveL: false})
                }
            }
            )
        }else if(Object.size(negativos) >=1){
            this.setState(prevState => ({
                left: {
                    ...prevState.left,
                    leads:{
                        ...prevState.left.leads,
                        lcase: 'positive'
                    }
                }
            }), () => {
                if(Object.size(positivos) === 0){
                    this.setState({mensajeErrPositiveL: false})
                }
            })
        }else{
            this.setState(prevState => ({
                left: {
                    ...prevState.left,
                    leads:{
                        ...prevState.left.leads,
                        lcase: 'neutral'
                    }
                }
            }))
        }

        if(Object.size(positivos2) + Object.size(negativos2) === 0){
            this.setState({validoL2: false})
        }else{
            this.setState({validoL2: true})
        }
        if(Object.size(positivos2) >= 1){
            this.setState(prevState => ({
                left: {
                    ...prevState.left,
                    leads2:{
                        ...prevState.left.leads2,
                        lcase: 'neutral'
                    }
                }
            }))
        }else if(Object.size(negativos2) >=1){
            this.setState(prevState => ({
                left: {
                    ...prevState.left,
                    leads2:{
                        ...prevState.left.leads2,
                        lcase: 'positive'
                    }
                }
            }))
        }else{
            this.setState(prevState => ({
                left: {
                    ...prevState.left,
                    leads2:{
                        ...prevState.left.leads2,
                        lcase: 'neutral'
                    }
                }
            }))
        }
    }
    funcionVacia() {

    }
    compruebaCaseRight(){
        /*Maquinaria para filtar en objetos*/
        Object.filter = (obj, predicate) => 
        Object.keys(obj)
            .filter( key => predicate(obj[key]) )
            // eslint-disable-next-line
            .reduce( (res, key) => (res[key] = obj[key], res), {} );
        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let positivos = Object.filter(this.state.right.leads, lead => lead === 'positive');
        let negativos = Object.filter(this.state.right.leads, lead => lead === 'negative');
        delete positivos.rcase
        delete negativos.rcase
        let positivos2 = Object.filter(this.state.right.leads2, lead => lead === 'positive');
        let negativos2 = Object.filter(this.state.right.leads2, lead => lead === 'negative');
        delete positivos2.rcase
        delete negativos2.rcase
        if(Object.size(positivos) + Object.size(negativos) === 0){
            this.setState({validoR: false})
        }else{
            this.setState({validoR: true})
        }
        if(Object.size(positivos) >=1){
            this.setState(prevState => ({
                right: {
                    ...prevState.right,
                    leads:{
                        ...prevState.right.leads,
                        rcase: 'neutral'
                    }
                }
            }), () => {
                if(Object.size(negativos) === 0){
                    this.setState({mensajeErrPositiveR: true})
                }else{
                    this.setState({mensajeErrPositiveR: false})
                }
            })
        }else if(Object.size(negativos) >=1){
            this.setState(prevState => ({
                right: {
                    ...prevState.right,
                    leads:{
                        ...prevState.right.leads,
                        rcase: 'positive'
                    }
                }
            }), () => {
                if(Object.size(positivos) === 0){
                    this.setState({mensajeErrPositiveR: false})
                }
            })
        }else{
            this.setState(prevState => ({
                right: {
                    ...prevState.right,
                    leads:{
                        ...prevState.right.leads,
                        rcase: 'neutral'
                    }
                }
            }))
        }

        if(Object.size(positivos2) + Object.size(negativos2) === 0){
            this.setState({validoR2: false})
        }else{
            this.setState({validoR2: true})
        }
        if(Object.size(positivos2) >=1){
            this.setState(prevState => ({
                right: {
                    ...prevState.right,
                    leads2:{
                        ...prevState.right.leads2,
                        rcase: 'neutral'
                    }
                }
            }))
        }else if(Object.size(negativos2) >=1){
            this.setState(prevState => ({
                right: {
                    ...prevState.right,
                    leads2:{
                        ...prevState.right.leads2,
                        rcase: 'positive'
                    }
                }
            }))
        }else{
            this.setState(prevState => ({
                right: {
                    ...prevState.right,
                    leads2:{
                        ...prevState.right.leads2,
                        rcase: 'neutral'
                    }
                }
            }))
        }
    }

    range(start, end, jump, simbolo) {
        let suma = 0
        // eslint-disable-next-line
        return Array(end - start + 1).fill().map((_, idx) => {
            suma = start+idx
            if(suma%jump === 0){
                return {key: suma.toString(), text: suma.toString() + ' ' + simbolo}
            }
            
        })
    }
    rangeFloats(start, end, jump, simbolo){
        let arr = []
        for (let index = start; index < end; index = index + jump) {
            arr.push({key: parseFloat(Math.round((index + Number.EPSILON) * 100) / 100).toString(), text: parseFloat(Math.round((index + Number.EPSILON) * 100) / 100).toString() + ' ' + simbolo})
        }
        return arr
    }
    rangeFloatsIncEsp(start, end, jump, desde, hasta, jumptwo,  simbolo){
        let arr = []
        for (let index = start; index < hasta+1; index = index + jump) {
            arr.push({key: parseFloat(Math.round((index + Number.EPSILON) * 100) / 100).toString(), text: parseFloat(Math.round((index + Number.EPSILON) * 100) / 100).toString() + ' ' + simbolo})
        }
        for (let index = hasta+jumptwo; index < end; index = index + jumptwo) {
            arr.push({key: parseFloat(Math.round((index + Number.EPSILON) * 100) / 100).toString(), text: parseFloat(Math.round((index + Number.EPSILON) * 100) / 100).toString() + ' ' + simbolo})
        }
        return arr
    }

    onInputDropDownchangePrograma(event, item){
        this.setState({tipoProgramacionSel: item})
        if(item.key === 'v'){
            let resultVolt = this.rangeFloats(0, 10.5, 0.05, 'V');
            resultVolt = resultVolt.filter(function( element ) {
                return element !== undefined;
            });
            let resultRate = this.rangeFloatsIncEsp(2, 255, 1, 2, 10, 5, 'Hz');
            resultRate = resultRate.filter(function( element ) {
                return element !== undefined;
            });
            this.setState({rangeRate: resultRate, rangeAmplitide: resultVolt})
        }else{
            let resultVolt = this.rangeFloats(0, 25.5, 0.1, 'mA');
            resultVolt = resultVolt.filter(function( element ) {
                return element !== undefined;
            });
            let resultRate = this.rangeFloats(30, 255, 5, 'Hz');
            resultRate = resultRate.filter(function( element ) {
                return element !== undefined;
            });
            this.setState({rangeRate: resultRate, rangeAmplitide: resultVolt})
        }
    }

    componentDidMount () {
        window.scrollTo(0, 0)
        document.body.style = 'background: #e8e9d8;';
        loadTheme(myThemeAnt)
        if(!this.props.location.state){
            window.location.href = '/'
        }
        this.setState(this.props.location.state, () => {
            //console.log(this.props.location.state)
            if(this.state.tipoProgramacionSel){
                if(this.state.tipoProgramacionSel.key === 'v'){
                    let resultVolt = this.rangeFloats(0, 10.5, 0.05, 'V');
                    resultVolt = resultVolt.filter(function( element ) {
                        return element !== undefined;
                    });
                    let resultRate = this.rangeFloatsIncEsp(2, 255, 1, 2, 10, 5, 'Hz');
                    resultRate = resultRate.filter(function( element ) {
                        return element !== undefined;
                    });
                    this.setState({rangeRate: resultRate, rangeAmplitide: resultVolt})
                }else{
                    let resultVolt = this.rangeFloats(0, 25.5, 0.1, 'mA');
                    resultVolt = resultVolt.filter(function( element ) {
                        return element !== undefined;
                    });
                    let resultRate = this.rangeFloats(30, 255, 5, 'Hz');
                    resultRate = resultRate.filter(function( element ) {
                        return element !== undefined;
                    });
                    this.setState({rangeRate: resultRate, rangeAmplitide: resultVolt})
                }

                let resultVolt = this.rangeFloats(0, 25.5, 0.1, 'mA');
                resultVolt = resultVolt.filter(function( element ) {
                    return element !== undefined;
                });
                this.setState({rangeAmplitideTerapia: resultVolt})
            }
        })
        let resultPulse = this.range(60, 450, 10, 'μs');
        resultPulse = resultPulse.filter(function( element ) {
            return element !== undefined;
        });
        this.setState({rangePulse: resultPulse})
    }

    toggleTeachingBubbleVisible(value, estado){
        let ver = true
        for(let i = 0; i <= 3; i++) {
            if(!this.state.configAnt.left.medirImpedancias) {
                if(this.state.left.leads['l'+i] !== 'neutral' && (this.state.configAnt.left.impedance['l'+i] === '' || this.state.configAnt.left.impedance['l'+i] === 0 || this.state.configAnt.left.impedance['l'+i] === '-'))
                    ver = false
                if(this.state.left.programador === 'interleaving') {
                    if(this.state.left.leads2['l'+i] !== 'neutral' && (this.state.configAnt.left.impedance2['l'+i] === '' || this.state.configAnt.left.impedance2['l'+i] === 0 || this.state.configAnt.left.impedance['l'+i] === '-'))
                        ver = false
                }
            }
            if(!this.state.configAnt.right.medirImpedancias) {
                if(this.state.right.leads['r'+i] !== 'neutral' && (this.state.configAnt.right.impedance['r'+i] === '' || this.state.configAnt.right.impedance['r'+i] === 0 || this.state.configAnt.right.impedance['r'+i] === '-'))
                    ver = false
                if(this.state.right.programador === 'interleaving') {
                    if(this.state.right.leads2['r'+i] !== 'neutral' && (this.state.configAnt.right.impedance2['r'+i] === '' || this.state.configAnt.right.impedance2['r'+i] === 0 || this.state.configAnt.right.impedance['r'+i] === '-'))
                        ver = false
                }
            }
        }
        if(ver)
            this.setState({teachingBubbleVisible: value})
        else
            alert('Tiene que rellenar todas las impedancias que correspondan')
    }
    alerta(){
        document.getElementById('next').click()
    }
    compruebaPositivosYNegativos(){
        /*Maquinaria para filtar en objetos*/
        Object.filter = (obj, predicate) => 
        Object.keys(obj)
            .filter( key => predicate(obj[key]) )
            // eslint-disable-next-line
            .reduce( (res, key) => (res[key] = obj[key], res), {} );
        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let positivosl = Object.filter(this.state.left.leads, lead => lead === 'positive');
        let negativosl = Object.filter(this.state.left.leads, lead => lead === 'negative');
        delete positivosl.lcase
        delete negativosl.lcase

        let positivosr = Object.filter(this.state.right.leads, lead => lead === 'positive');
        let negativosr = Object.filter(this.state.right.leads, lead => lead === 'negative');
        delete positivosr.rcase
        delete negativosr.rcase

        if(Object.size(positivosr) + Object.size(positivosl) + Object.size(negativosl) + Object.size(negativosr) === 0 ){
            return false
        }else{
            return true
        }
    }

    compruebaLeadsLeft(){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.props.location.state.left.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.props.location.state.left.leads, lead => lead === 'positive'); 
        delete positivos.rcase
        delete negativos.rcase
        return Object.size(negativos) + Object.size(positivos)
    }

    compruebaLeadsRight(){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.props.location.state.right.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.props.location.state.right.leads, lead => lead === 'positive'); 
        delete positivos.rcase
        delete negativos.rcase
        return Object.size(negativos) + Object.size(positivos)
    }

    render() {
        const {t} = this.props
        return (
            <>
                <div className="ms-Grid-row" id="contentCenter">
                    <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg4"></div>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4">
                        <Label className="ms-fontSize-24 ms-fontWeight-regular">
                            {t('p1.title')}
                            <IconButton
                                title="Info"
                                ariaLabel="Info"
                                id={'infoPrinci'}
                                onClick={this.visibleCalloutInfoPrinci}
                                iconProps={iconProps}
                            />
                        </Label>
                        {this.state.isVisibleInfo && (
                            <Callout
                            target={'#infoPrinci'}
                            setInitialFocus
                            ariaDescribedBy={'Teat'}
                            role="alertdialog"
                            style={{padding: '3%'}}
                            >
                                <Stack horizontalAlign="start" >
                                    <span style={{color: 'black'}}>{t('p1.info')}</span>
                                    <br></br>
                                    <DefaultButton onClick={this.invisibleCalloutInfoPrinci}>{t('p2.btn')}</DefaultButton>
                                </Stack>
                            </Callout>
                        )}
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Dropdown
                            label={t('typeprogramming.label')}
                            options={this.state.tipoProgramacion}
                            defaultSelectedKey={this.state.tipoProgramacionSel.key}
                            name = 'tipo_programacion'
                            onChange={this.onInputDropDownchangePrograma}
                            disabled={false}
                        />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <Pivot aria-label="Basic Pivot Example">
                            {this.state.ladoImpUniSel.key !== 'right' &&
                                <PivotItem
                                headerText={t('leftsnt.header')}
                                headerButtonProps={{
                                    'data-order': 1,
                                    'data-title': 'Left',
                                    }}
                                >
                                    <Label className="ms-fontWeight-regular ms-fontSize-28">{t('left.text')}</Label>
                                    <div className="ms-Grid-row">
                                        {/*DropDowns */}
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <Label className="ms-fontWeight-regular ms-fontSize-12" style={{color: '#ac0000'}}>{this.state.tipoProgramacionSel.key ? undefined : t('typeprogramming.notif')}</Label>
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12" style={{marginBottom:'15px'}}>
                                            {this.props.location.state.generadorSel.text === 'Soletra' ||this.props.location.state.generadorSel.text === 'Kinetra' ?
                                   '':
                                        <Dropdown
                                                label={t('programador.label')}
                                                options={[{key: 'normal', text: t('simple.text')}, {key: 'interleaving', text: t('interleaving.text')}]}
                                                defaultSelectedKey={this.state.left.programador.toString()}
                                                name = 'left.programador'
                                                onChange={this.onInputchangeProgramador}
                                                disabled={this.state.tipoProgramacionSel.key ? false : true}
                                                required={true}
                                            />

                                            }
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6">
                                            <Dropdown
                                                label={this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}
                                                options={this.state.rangeAmplitide}
                                                defaultSelectedKey={this.state.left.amplitude.toString()}
                                                name = 'left.amplitude'
                                                onChange={this.onInputchangeDropdown}
                                                disabled={this.state.tipoProgramacionSel.key ? false : true}
                                                required={true}
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6">
                                            <Dropdown
                                                label={t('frequency.label')}
                                                options={this.state.rangeRate}
                                                defaultSelectedKey={this.state.left.rate.toString()}
                                                name = 'left.rate'
                                                onChange={this.onInputchangeDropdown}
                                                disabled={this.state.tipoProgramacionSel.key ? false : true}
                                                required={true}
                                            />
                                        </div>
                                        {this.state.left.programador === 'normal' ?
                                        <>
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6">
                                                <Dropdown
                                                    label={t('pulse.label')}
                                                    options={this.state.rangePulse}
                                                    defaultSelectedKey={this.state.left.pulse.toString()}
                                                    name = 'left.pulse'
                                                    onChange={this.onInputchangeDropdown}
                                                    disabled={false}
                                                    required={true}
                                                />
                                            </div>
                                            </> : <></>
                                        }
                                    </div>
                                    {/*Lead Config */}
                                    <div className="ms-Grid-row" id="contentCenter">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '3%'}}>
                                            <Separator></Separator>
                                            <Label className="ms-fontSize-16 ms-fontWeight-regular">{t('contactsconf.text')}</Label>
                                            <Label className="ms-fontSize-14 ms-fontWeight-regular">{t('contactsconf.subtext')}</Label>
                                            <Checkbox label={t('sinimpedancias.text')} name="left" onChange={this.onCheckBoxChange} checked={this.state.configAnt.left.medirImpedancias} />
                                            {
                                                this.state.mensajeErrPositiveL && (
                                                    <p className="ms-fontSize-12 ms-fontWeight-regular" style={{color: '#ac0000'}}>{t('msg_negativo')}</p>
                                                )
                                            }
                                        </div>
                                        <br></br>{/*Carcasa-IPG */}
                                        <div className={this.state.left.programador === 'interleaving' ? "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl6" : "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12"} style={{marginBottom: '2%'}}>
                                            <div className="ms-Grid-row">
                                                {this.state.left.programador === 'interleaving' ?
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                     <div style={{fontWeight:'bold'}}>Grupo A</div>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                            <Dropdown
                                                                label={t('pulse.label')}
                                                                options={this.state.rangePulse}
                                                                defaultSelectedKey={this.state.left.pulse.toString()}
                                                                name = 'left.pulse'
                                                                onChange={this.onInputchangeDropdown}
                                                                disabled={false}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> : <></>}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>IPG</span>
                                                            <span className={'checkbox '+this.state.left.leads.lcase} title="lcase" style={{cursor: 'no-drop'}}>
                                                                { this.state.left.leads.lcase === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.left.leads.lcase === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 3 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>3</span>
                                                            <span className={'checkbox '+this.state.left.leads.l3} style={this.state.left.leads2.l3 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} data-child="leads" title="l3" onClick={this.state.left.leads2.l3 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.left.leads.l3 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.left.leads.l3 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                ariaLabel="Example text field with https:// prefix"
                                                                name="left.l3"
                                                                data-child="impedance"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.left.leads.l3 !== 'neutral' ? this.state.configAnt.left.impedance.l3 : '' }
                                                                disabled={this.state.configAnt.left.medirImpedancias ? true : (this.state.left.leads.l3 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 2 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>2</span>
                                                            <span className={'checkbox '+this.state.left.leads.l2} style={this.state.left.leads2.l2 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} data-child="leads" title="l2"  onClick={this.state.left.leads2.l2 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.left.leads.l2 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.left.leads.l2 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                name="left.l2"
                                                                data-child="impedance"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.left.leads.l2 !== 'neutral' ? this.state.configAnt.left.impedance.l2 : '' }
                                                                disabled={this.state.configAnt.left.medirImpedancias ? true : (this.state.left.leads.l2 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 1 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>1</span>
                                                            <span className={'checkbox '+this.state.left.leads.l1} style={this.state.left.leads2.l1 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} data-child="leads" title="l1"  onClick={this.state.left.leads2.l1 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.left.leads.l1 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.left.leads.l1 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                name="left.l1"
                                                                data-child="impedance"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.left.leads.l1 !== 'neutral' ? this.state.configAnt.left.impedance.l1 : '' }
                                                                disabled={this.state.configAnt.left.medirImpedancias ? true : (this.state.left.leads.l1 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 0 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>0</span>
                                                            <span className={'checkbox '+this.state.left.leads.l0} style={this.state.left.leads2.l0 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} data-child="leads" title="l0"  onClick={this.state.left.leads2.l0 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.left.leads.l0 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.left.leads.l0 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                ariaLabel="Example text field with https:// prefix"
                                                                name="left.l0"
                                                                data-child="impedance"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.left.leads.l0 !== 'neutral' ? this.state.configAnt.left.impedance.l0 : '' }
                                                                disabled={this.state.configAnt.left.medirImpedancias ? true : (this.state.left.leads.l0 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.left.programador === 'interleaving' ?
                                        <div className={this.state.left.programador === 'interleaving' ? "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl6" : "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12"} style={{marginBottom: '2%'}}>
                                            <div className="ms-Grid-row">
                                            <div style={{fontWeight:'bold',padding: '0 0 0 7px'}}>Grupo B</div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                            <Dropdown
                                                                label={t('pulse.label')}
                                                                options={this.state.rangePulse}
                                                                defaultSelectedKey={this.state.left.pulse2.toString()}
                                                                name = 'left.pulse2'
                                                                onChange={this.onInputchangeDropdown}
                                                                disabled={false}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>IPG</span>
                                                            <span className={'checkbox '+this.state.left.leads2.lcase} title="lcase" style={{cursor: 'no-drop'}}>
                                                                { this.state.left.leads2.lcase === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.left.leads2.lcase === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 3 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>3</span>
                                                            <span className={'checkbox '+this.state.left.leads2.l3} style={this.state.left.leads.l3 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} data-child="leads2" title="l3"  onClick={this.state.left.leads.l3 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.left.leads2.l3 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.left.leads2.l3 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                ariaLabel="Example text field with https:// prefix"
                                                                name="left.l3"
                                                                data-child="impedance2"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.left.leads2.l3 !== 'neutral' ? this.state.configAnt.left.impedance2.l3 : '' }
                                                                disabled={this.state.configAnt.left.medirImpedancias ? true : (this.state.left.leads2.l3 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 2 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>2</span>
                                                            <span className={'checkbox '+this.state.left.leads2.l2} style={this.state.left.leads.l2 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} data-child="leads2" title="l2"  onClick={this.state.left.leads.l2 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.left.leads2.l2 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.left.leads2.l2 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                name="left.l2"
                                                                data-child="impedance2"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.left.leads2.l2 !== 'neutral' ? this.state.configAnt.left.impedance2.l2 : '' }
                                                                disabled={this.state.configAnt.left.medirImpedancias ? true : (this.state.left.leads2.l2 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 1 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>1</span>
                                                            <span className={'checkbox '+this.state.left.leads2.l1} style={this.state.left.leads.l1 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} data-child="leads2" title="l1"  onClick={this.state.left.leads.l1 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.left.leads2.l1 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.left.leads2.l1 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                name="left.l1"
                                                                data-child="impedance2"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.left.leads2.l1 !== 'neutral' ? this.state.configAnt.left.impedance2.l1 : '' }
                                                                disabled={this.state.configAnt.left.medirImpedancias ? true : (this.state.left.leads2.l1 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 0 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>0</span>
                                                            <span className={'checkbox '+this.state.left.leads2.l0} style={this.state.left.leads.l0 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} data-child="leads2" title="l0"  onClick={this.state.left.leads.l0 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.left.leads2.l0 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.left.leads2.l0 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                ariaLabel="Example text field with https:// prefix"
                                                                name="left.l0"
                                                                data-child="impedance2"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.left.leads2.l0 !== 'neutral' ? this.state.configAnt.left.impedance2.l0 : '' }
                                                                disabled={this.state.configAnt.left.medirImpedancias ? true : (this.state.left.leads2.l0 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : <></>
                                        }
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm5 ms-md12 ms-lg12">
                                                    {this.state.tipoProgramacionSel.key === 'v' ?
                                                    <>
                                                    <Label className="ms-fontSize-24 ms-fontWeight-regular">{t('impedancia.terapia')}</Label>
                                                    <Label className="ms-fontSize-14 ms-fontWeight-regular">{t('contactsconf.subtext2')}</Label>
                                                        <div style={{marginTop:'15px',marginBottom:'15px'}}>
                                                                {/* <Label className="ms-fontSize-14 ms-fontWeight-regular">Corriente</Label>
                                                                <TextField 
                                                                    suffix="mA"
                                                                    ariaLabel="Example text field with https:// prefix"
                                                                    name="left.l"
                                                                    data-child="terapiaCorriente"
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.configAnt.left.terapiaCorriente.l}
                                                                /> */}
                                                                <Dropdown
                                                                    label={t('stream.label')}
                                                                    options={this.state.rangeAmplitideTerapia}
                                                                    defaultSelectedKey={this.state.configAnt.left.terapiaCorriente.l.toString()}
                                                                    name = 'left.terapiaCorriente.l'
                                                                    onChange={this.onInputchangeDropdownTerapia}
                                                                    disabled={this.state.tipoProgramacionSel.key ? false : true}
                                                                />
                                                            </div>
                                                            <div>
                                                            <Label className="ms-fontSize-14 ms-fontWeight-regular">{t('impedancia')}</Label>
                                                            <TextField 
                                                                suffix="Ω"
                                                                ariaLabel="Example text field with https:// prefix"
                                                                name="left.l"
                                                                data-child="terapiaImpedancia"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.configAnt.left.terapiaImpedancia.l}
                                                            />

                                                        </div>
                                                    </>
                                                    : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </PivotItem>
                            }
                            {this.state.ladoImpUniSel.key !== 'left' &&
                                <PivotItem headerText={t('rightsnt.header')}>
                                <Label className="ms-fontWeight-regular ms-fontSize-28">{t('right.text')}</Label>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <Label className="ms-fontWeight-regular ms-fontSize-12" style={{color: '#ac0000'}}>{this.state.tipoProgramacionSel.key ? undefined : t('typeprogramming.notif')}</Label>
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12" style={{marginBottom:'15px'}}>
                                        {this.props.location.state.generadorSel.text === 'Soletra' ||this.props.location.state.generadorSel.text === 'Kinetra' ?
                                        '':
                                            <Dropdown
                                                label={t('programador.label')}
                                                options={[{key: 'normal', text: t('simple.text')}, {key: 'interleaving', text: 'Interleaving'}]}
                                                defaultSelectedKey={this.state.right.programador.toString()}
                                                name = 'right.programador'
                                                onChange={this.onInputchangeProgramador}
                                                disabled={this.state.tipoProgramacionSel.key ? false : true}
                                                required={true}
                                            />
                                        }
                                        </div>
                        
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6">
                                            <Dropdown
                                                label={this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}
                                                options={this.state.rangeAmplitide}
                                                defaultSelectedKey={this.state.right.amplitude.toString()}
                                                name = 'right.amplitude'
                                                onChange={this.onInputchangeDropdown}
                                                disabled={this.state.tipoProgramacionSel.key ? false : true}
                                                required={true}
                                            />
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6">
                                            <Dropdown
                                                label={t('frequency.label')}
                                                options={this.state.rangeRate}
                                                defaultSelectedKey={this.state.right.rate.toString()}
                                                name = 'right.rate'
                                                onChange={this.onInputchangeDropdown}
                                                disabled={this.state.tipoProgramacionSel.key ? false : true}
                                                required={true}
                                            />
                                        </div>
                                    {this.state.right.programador === 'normal' ?
                                    <>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6">
                                            <Dropdown
                                                label={t('pulse.label')}
                                                options={this.state.rangePulse}
                                                defaultSelectedKey={this.state.right.pulse.toString()}
                                                name = 'right.pulse'
                                                onChange={this.onInputchangeDropdown}
                                                disabled={false}
                                                required={true}
                                            />
                                        </div>
                                    </> : <></>
                                    }
                                    </div>
                                    {/*Lead Config */}
                                    <div className="ms-Grid-row" id="contentCenter">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '3%'}}>
                                            <Separator></Separator>
                                            <Label className="ms-fontSize-16 ms-fontWeight-regular">{t('contactsconf.text')}</Label>
                                            <Label className="ms-fontSize-14 ms-fontWeight-regular">{t('contactsconf.subtext')}</Label>
                                            <Checkbox label={t('sinimpedancias.text')} name="right" onChange={this.onCheckBoxChange} checked={this.state.configAnt.right.medirImpedancias} />
                                            {
                                                this.state.mensajeErrPositiveR && (
                                                    <p className="ms-fontSize-12 ms-fontWeight-regular" style={{color: '#ac0000'}}>{t('msg_negativo')}</p>
                                                )
                                            }
                                            
                                        </div>
                                        <br></br>{/*Carcasa-IPG */}
                                        <div className={this.state.right.programador === 'interleaving' ? "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl6" : "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12"} style={{marginBottom: '2%'}}>
                                            <div className="ms-Grid-row">
                                                {this.state.right.programador === 'interleaving' ?
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div style={{fontWeight:'bold'}}>Grupo A</div>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                            <Dropdown
                                                                label={t('pulse.label')}
                                                                options={this.state.rangePulse}
                                                                defaultSelectedKey={this.state.right.pulse.toString()}
                                                                name = 'right.pulse'
                                                                onChange={this.onInputchangeDropdown}
                                                                disabled={false}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> : <></>}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>IPG</span>
                                                            <span className={'checkbox '+this.state.right.leads.rcase} title="rcase" style={{cursor: 'no-drop'}}>
                                                                { this.state.right.leads.rcase === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.right.leads.rcase === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 3 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>7</span>
                                                            <span className={'checkbox '+this.state.right.leads.r3} data-child="leads" title="r3" style={this.state.right.leads2.r3 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} onClick={this.state.right.leads2.r3 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.right.leads.r3 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.right.leads.r3 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                ariaLabel=""
                                                                name="right.r3"
                                                                data-child="impedance"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.right.leads.r3 !== 'neutral' ? this.state.configAnt.right.impedance.r3 : ''}
                                                                disabled={this.state.configAnt.right.medirImpedancias ? true : (this.state.right.leads.r3 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 2 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>6</span>
                                                            <span className={'checkbox '+this.state.right.leads.r2} data-child="leads" title="r2" style={this.state.right.leads2.r2 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} onClick={this.state.right.leads2.r2 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.right.leads.r2 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.right.leads.r2 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                ariaLabel=""
                                                                name="right.r2"
                                                                data-child="impedance"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.right.leads.r2 !== 'neutral' ? this.state.configAnt.right.impedance.r2 : ''}
                                                                disabled={this.state.configAnt.right.medirImpedancias ? true : (this.state.right.leads.r2 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 1 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>5</span>
                                                            <span className={'checkbox '+this.state.right.leads.r1} data-child="leads" title="r1" style={this.state.right.leads2.r1 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} onClick={this.state.right.leads2.r1 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.right.leads.r1 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.right.leads.r1 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                ariaLabel=""
                                                                name="right.r1"
                                                                data-child="impedance"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.right.leads.r1 !== 'neutral' ? this.state.configAnt.right.impedance.r1 : ''}
                                                                disabled={this.state.configAnt.right.medirImpedancias ? true : (this.state.right.leads.r1 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 0 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>4</span>
                                                            <span className={'checkbox '+this.state.right.leads.r0} data-child="leads" title="r0" style={this.state.right.leads2.r0 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} onClick={this.state.right.leads2.r0 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.right.leads.r0 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.right.leads.r0 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                ariaLabel=""
                                                                name="right.r0"
                                                                data-child="impedance"
                                                                readOnly={false}
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.right.leads.r0 !== 'neutral' ? this.state.configAnt.right.impedance.r0 : ''}
                                                                disabled={this.state.configAnt.right.medirImpedancias ? true : (this.state.right.leads.r0 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.right.programador === 'interleaving' ?
                                        <div className={this.state.right.programador === 'interleaving' ? "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl6" : "ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12"} style={{marginBottom: '2%'}}>
                                            <div className="ms-Grid-row">
                                            <div style={{fontWeight:'bold',padding:'0 0 0 7px'}}>Grupo B</div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                            <Dropdown
                                                                label={t('pulse.label')}
                                                                options={this.state.rangePulse}
                                                                defaultSelectedKey={this.state.right.pulse2.toString()}
                                                                name = 'right.pulse2'
                                                                onChange={this.onInputchangeDropdown}
                                                                disabled={false}
                                                                required={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>IPG</span>
                                                            <span className={'checkbox '+this.state.right.leads2.rcase} title="rcase" style={{cursor: 'no-drop'}}>
                                                                { this.state.right.leads2.rcase === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.right.leads2.rcase === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 3 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>7</span>
                                                            <span className={'checkbox '+this.state.right.leads2.r3} data-child="leads2" title="r3" style={this.state.right.leads.r3 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} onClick={this.state.right.leads.r3 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.right.leads2.r3 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.right.leads2.r3 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                ariaLabel=""
                                                                name="right.r3"
                                                                data-child="impedance2"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.right.leads2.r3 !== 'neutral' ? this.state.configAnt.right.impedance2.r3 : ''}
                                                                disabled={this.state.configAnt.right.medirImpedancias ? true : (this.state.right.leads2.r3 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 2 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>6</span>
                                                            <span className={'checkbox '+this.state.right.leads2.r2} data-child="leads2" title="r2" style={this.state.right.leads.r2 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} onClick={this.state.right.leads.r2 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.right.leads2.r2 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.right.leads2.r2 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                ariaLabel=""
                                                                name="right.r2"
                                                                data-child="impedance2"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.right.leads2.r2 !== 'neutral' ? this.state.configAnt.right.impedance2.r2 : ''}
                                                                disabled={this.state.configAnt.right.medirImpedancias ? true : (this.state.right.leads2.r2 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 1 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '2%'}}>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>5</span>
                                                            <span className={'checkbox '+this.state.right.leads2.r1} data-child="leads2" title="r1" style={this.state.right.leads.r1 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} onClick={this.state.right.leads.r1 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.right.leads2.r1 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.right.leads2.r1 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                ariaLabel=""
                                                                name="right.r1"
                                                                data-child="impedance2"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.right.leads2.r1 !== 'neutral' ? this.state.configAnt.right.impedance2.r1 : ''}
                                                                disabled={this.state.configAnt.right.medirImpedancias ? true : (this.state.right.leads2.r1 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* 0 */}
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm5 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                                            <span className="label" style={{marginRight: '14%'}}>4</span>
                                                            <span className={'checkbox '+this.state.right.leads2.r0} data-child="leads2" title="r0" style={this.state.right.leads.r0 === 'neutral' ? {cursor:'pointer'} : {cursor:'no-drop'}} onClick={this.state.right.leads.r0 === 'neutral' ? this.onInputchangeCheckBox : this.funcionVacia}>
                                                                { this.state.right.leads2.r0 === 'positive'
                                                                    // eslint-disable-next-line
                                                                    ? <svg id="i-checkmark" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M16, 30 V1 M30,16 H1" /></svg>
                                                                    : this.state.right.leads2.r0 === 'negative'
                                                                        // eslint-disable-next-line
                                                                        ? <svg id="i-close" viewBox="0 0 32 32" width="20" height="20" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="10.9375%" style={{marginTop: '6px', stroke: '#303030', strokeWidth: '10%', zIndex: '2', position: 'sticky'}}><path d="M0, 0 V0 M30,16 H1" /></svg>
                                                                        : <svg  width="10" height="10"></svg>
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                                            <TextField 
                                                                suffix="Ω"
                                                                ariaLabel=""
                                                                name="right.r0"
                                                                data-child="impedance2"
                                                                readOnly={false}
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.right.leads2.r0 !== 'neutral' ? this.state.configAnt.right.impedance2.r0 : ''}
                                                                disabled={this.state.configAnt.right.medirImpedancias ? true : (this.state.right.leads2.r0 !== 'neutral' ? false : true) }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : <></> }
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm5 ms-md12 ms-lg12">
                                                    {this.state.tipoProgramacionSel.key === 'v' ?
                                                    <>
                                                    <Label className="ms-fontSize-24 ms-fontWeight-regular">{t('impedancia.terapia')}</Label>
                                                    <Label className="ms-fontSize-14 ms-fontWeight-regular">{t('contactsconf.subtext2')}</Label>
                                                            <div style={{marginTop:'15px',marginBottom:'15px'}}>
                                                                {/* <Label className="ms-fontSize-14 ms-fontWeight-regular">Corriente</Label>
                                                                <TextField 
                                                                    suffix="mA"
                                                                    ariaLabel="Example text field with https:// prefix"
                                                                    name="right.r"
                                                                    data-child="terapiaCorriente"
                                                                    onChange={this.onInputchange}
                                                                    onGetErrorMessage={this.isFloatNum}
                                                                    placeholder={this.state.configAnt.right.terapiaCorriente.r}
                                                                /> */}
                                                                <Dropdown
                                                                    label={t('stream.label')}
                                                                    options={this.state.rangeAmplitideTerapia}
                                                                    defaultSelectedKey={this.state.configAnt.right.terapiaCorriente.r.toString()}
                                                                    name = 'right.terapiaCorriente.r'
                                                                    onChange={this.onInputchangeDropdownTerapia}
                                                                    disabled={this.state.tipoProgramacionSel.key ? false : true}
                                                                />
                                                            </div>
                                                            <div>
                                                            <Label className="ms-fontSize-14 ms-fontWeight-regular">{t('impedancia')}</Label>
                                                            <TextField 
                                                                suffix="Ω"
                                                                ariaLabel="Example text field with https:// prefix"
                                                                name="right.r"
                                                                data-child="terapiaImpedancia"
                                                                onChange={this.onInputchange}
                                                                onGetErrorMessage={this.isFloatNum}
                                                                placeholder={this.state.configAnt.right.terapiaImpedancia.r}
                                                            />

                                                            </div>
                                                    </>
                                                    : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </PivotItem>
                                
                            }
                        </Pivot>
                        </div>
                    </div>
                    <br></br>
                    <div className="ms-Grid-row" style={{marginBottom: '3%'}}>
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                <Link
                                    to={{
                                        pathname: "/datos",
                                    }}
                                >
                                    <DefaultButton text={t('return.btn')}/>
                                </Link>
                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" style={{textAlign: 'right'}}>
                                <Link
                                    to={{
                                        pathname: "/lecturaimpedancias",
                                        state: this.state
                                    }}
                                >
                                    <PrimaryButton text={t('next.btn')} id="next" style={{display: 'none'}} disabled={this.state.tipoProgramacionSel.key ? false : true}/>
                                </Link>

                                <PrimaryButton id="targetButton" text={t('next.btn')} onClick={this.toggleTeachingBubbleVisible.bind(this, true)} disabled={
                                    // eslint-disable-next-line
                                    //this.state.tipoProgramacionSel.key && this.state.left.amplitude || this.state.right.amplitude ? false : true
                                    this.state.tipoProgramacionSel.key
                                    ?   this.state.tipoImplanteSel.key === 'uni'
                                        ?   this.state.ladoImpUniSel.key === 'left'
                                            ?   this.state.left.amplitude && this.state.validoL
                                                ? false
                                                : true
                                            :   this.state.right.amplitude && this.state.validoR
                                                ? false
                                                : true
                                        :   this.state.left.amplitude && this.state.validoL && this.state.right.amplitude && this.state.validoR
                                            ? false
                                            : true
                                    : true
                                    
                                    
                                }/>
                                {this.state.teachingBubbleVisible && (
                                    <TeachingBubble
                                    illustrationImage={{ src: replacedBattery, alt: 'Reemplazo de bateria' }}
                                    calloutProps={{ directionalHint: DirectionalHint.TopCenter }}
                                    hasCloseButton={true}
                                    closeButtonAriaLabel="Close"
                                    target="#targetButton"
                                    primaryButtonProps={{children: t('next.btn'), onClick: this.alerta}}
                                    secondaryButtonProps={{children: t('notyet.btn'), onClick: this.toggleTeachingBubbleVisible.bind(this, false)}}
                                    onDismiss={this.toggleTeachingBubbleVisible.bind(this, false)}
                                    headline={t('batteryreplacement.text')}
                                    >
                                        {t('batteryreplacement.subtext')}
                                    </TeachingBubble>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md3 ms-lg4"></div>
                </div>
            </>
        );
    }
}

export default withTranslation()(ConversorMetronic);