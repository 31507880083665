import React, {Fragment} from 'react';
import '../App.css';
import '../styles/styleFinal.css'
import '../styles/styleblack.css'
import 'office-ui-fabric-react/dist/css/fabric.css';

import { DefaultButton, Toggle, TextField} from '@fluentui/react'
import { Pivot, PivotItem } from '@fluentui/react';
import { Separator } from '@fluentui/react';
import { Label } from '@fluentui/react';
import { FontIcon } from '@fluentui/react';
//ErrorBadge

import { createTheme, loadTheme} from '@fluentui/react';

import * as idb from 'idb';
import {data} from '../data/data'

import { withTranslation } from 'react-i18next'

import i18next from 'i18next'
import mando from "../images/leyenda-circuito-ESP.png";
import mandoPT from "../images/leyenda-circuito-PT.png";


const myTheme = createTheme({
    palette: {
        themePrimary: '#804099',
        themeLighterAlt: '#050306',
        themeLighter: '#140a18',
        themeLight: '#26132e',
        themeTertiary: '#4d275c',
        themeSecondary: '#713987',
        themeDarkAlt: '#8b4ea3',
        themeDark: '#9b63b1',
        themeDarker: '#b486c6',
        neutralLighterAlt: '#3c3c3c',
        neutralLighter: '#444444',
        neutralLight: '#515151',
        neutralQuaternaryAlt: '#595959',
        neutralQuaternary: '#5f5f5f',
        neutralTertiaryAlt: '#7a7a7a',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        white: '#333333',
    }});
let dbPromise = ''
if (!('indexedDB' in window)) {
    console.info('This browser doesn\'t support IndexedDB');
}else{
    dbPromise = idb.openDB('registros', 3, {
        upgrade(db){
            db.createObjectStore('logs', {keyPath: 'id', autoIncrement: false});
        }
    });
}
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        // eslint-disable-next-line
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const id = uuidv4()

class ConversorMetronicRecuento extends React.Component {

    constructor(props) {
        super(props);
        //console.log(props)
        this.state = {
            left:{
                amplitude: '',
                rate: '',
                pulse: '',
                soft: '',
                leads:{
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance: {
                    l0: '',
                    l1: '',
                    l2: '',
                    l3: ''
                }
            },
            leftG2:{
                amplitude: '',
                rate: '',
                pulse: '',
                soft: '',
                leads:{
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance: {
                    l0: '',
                    l1: '',
                    l2: '',
                    l3: ''
                }
            },
            right:{
                amplitude: '',
                rate: '',
                pulse: '',
                soft: '',
                leads:{
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance: {
                    l0: '',
                    l1: '',
                    l2: '',
                    l3: ''
                },
            },
            rightG2:{
                amplitude: '',
                rate: '',
                pulse: '',
                soft: '',
                leads:{
                    l0: 'neutral',
                    l1: 'neutral',
                    l2: 'neutral',
                    l3: 'neutral',
                },
                impedance: {
                    l0: '',
                    l1: '',
                    l2: '',
                    l3: ''
                },
            },
            estadoAnt: {},
            fusion:{
                left:{
                    amplitude: '',
                    rate: '',
                    pulse: '',
                    soft: '',
                    leads:{
                        lcase: 'neutral',
                        l0: 'neutral',
                        l1: 'neutral',
                        l2: 'neutral',
                        l3: 'neutral',
                    },
                    impedance: {
                        lcase: '',
                        l0: '',
                        l1: '',
                        l2: '',
                        l3: ''
                    },
                    toleranciasSel: {},
                },
                right:{
                    amplitude: '',
                    rate: '',
                    pulse: '',
                    soft: '',
                    leads:{
                        rcase: 'neutral',
                        r0: 'neutral',
                        r1: 'neutral',
                        r2: 'neutral',
                        r3: 'neutral',
                    },
                    impedance: {
                        rcase: '',
                        r0: '',
                        r1: '',
                        r2: '',
                        r3: ''
                    },
                    toleranciasSel: {},
                },
            },
            intensidadLeft: {},
            toleranciasLeft: {},
            intensidadLeftPorcent: {},

            intensidadLeft2: {},
            toleranciasLeft2: {},
            intensidadLeftPorcent2: {},

            toleranciasLeftten: {},
            toleranciasLeftPorcentten: {},
            toleranciasLeftfive: {},
            toleranciasLeftPorcentfive: {},
            toleranciasLeftPorcent: {},

            toleranciasLeftten2: {},
            toleranciasLeftPorcentten2: {},
            toleranciasLeftfive2: {},
            toleranciasLeftPorcentfive2: {},
            toleranciasLeftPorcent2: {},

            intensidadRight: {},
            intensidadRightPorcent: {},
            tipoProgramacionSel: {},
            ladoImpUniSel: {},

            intensidadRight2: {},
            intensidadRightPorcent2: {},
            tipoProgramacionSel2: {},
            ladoImpUniSel2: {},

            tolerancias: [
                {key: 'five', text: '5%', type: 'toleranciasSel', valor: '5'},
                {key: 'ten', text: '10%', type: 'toleranciasSel', valor: '10'},
                {key: 'fifteen', text: '15%', type: 'toleranciasSel', valor: '15'},
                {key: 'twenty', text: '20%', type: 'toleranciasSel', valor: '20'},
                {key: 'twentyfive', text: '25%', type: 'toleranciasSel', valor: '25'},
                {key: 'cero', text: props.t('sinmargen'), type: 'toleranciasSel', valor: '0'},
            ],
            toleranciasSel: {},
            toleranciasSelL: {},
            toleranciasSelR: {},
            toleranciaApplicada: '',
            mostrarFinalLeft: false,
            mostrarFinalRigth: false,
            graficoSelL: 'mA',
            graficoSelR: 'mA',
            contPL: 0,
            contNL: 0,
            contPR: 0,
            contNR: 0,

            toleranciasSel2: {},
            toleranciasSelL2: {},
            toleranciasSelR2: {},
            toleranciaApplicada2: '',
            mostrarFinalLeft2: false,
            mostrarFinalRigth2: false,
            graficoSelL2: 'mA',
            graficoSelR2: 'mA',
            contPL2: 0,
            contNL2: 0,
            contPR2: 0,
            contNR2: 0
        };
        this.btn1 = 'btnSel' 
        this.btn2 = 'btnNoSel'
        this.onInputchange = this.onInputchange.bind(this);
        this.toggleOnChange = this.toggleOnChange.bind(this)
        this.toggleOnChange2 = this.toggleOnChange2.bind(this)
        this.changeRateLeft = this.changeRateLeft.bind(this)
        this.changeRateRigth = this.changeRateRigth.bind(this)
        this.changeRateLeft2 = this.changeRateLeft2.bind(this)
        this.changeRateRigth2 = this.changeRateRigth2.bind(this)
        //console.log(this.props.hiddeFrame)
    }
    sum( obj , porcent = 0) {
        var sum = 0;
        for( var el in obj ) {
            if( obj.hasOwnProperty( el ) ) {
                sum += parseFloat( (obj[el] - (obj[el] * (porcent / 100))) );
            }
        }
        return sum;
    }

    componentDidMount () {
        window.scrollTo(0, 0)
        document.body.style = 'background: #000;';
        loadTheme(myTheme);
        //console.log(this.props.datos.estadoAnt)
        this.setState(this.props.datos.estadoAnt,() => {
            let auxArray = JSON.parse(JSON.stringify(this.state))
            if(this.state.left.programador === 'interleaving') {
                let auxLeft = {
                    amplitude: this.state.left.amplitude,
                    rate: this.state.left.rate,
                    pulse: this.state.left.pulse2,
                    soft: this.state.left.soft,
                    leads:{
                        lcase: this.state.left.leads2.lcase,
                        l0: this.state.left.leads2.l0,
                        l1: this.state.left.leads2.l1,
                        l2: this.state.left.leads2.l2,
                        l3: this.state.left.leads2.l3,
                    },
                    impedance: {
                        lcase: this.state.configAnt.left.impedance2.lcase,
                        l0: this.state.configAnt.left.impedance2.l0,
                        l1: this.state.configAnt.left.impedance2.l1,
                        l2: this.state.configAnt.left.impedance2.l2,
                        l3: this.state.configAnt.left.impedance2.l3,
                    }
                }
                //console.log(auxLeft)
                this.setState({leftG2: auxLeft}, () => {
                    //console.log(this.state)
                    this.primeraFuncion()
                })
            }
            if(this.state.right.programador === 'interleaving') {
                let auxRight = {
                    amplitude: this.state.right.amplitude,
                    rate: this.state.right.rate,
                    pulse: this.state.right.pulse2,
                    soft: this.state.right.soft,
                    leads:{
                        rcase: this.state.right.leads2.rcase,
                        r0: this.state.right.leads2.r0,
                        r1: this.state.right.leads2.r1,
                        r2: this.state.right.leads2.r2,
                        r3: this.state.right.leads2.r3,
                    },
                    impedance: {
                        rcase: this.state.configAnt.right.impedance2.rcase,
                        r0: this.state.configAnt.right.impedance2.r0,
                        r1: this.state.configAnt.right.impedance2.r1,
                        r2: this.state.configAnt.right.impedance2.r2,
                        r3: this.state.configAnt.right.impedance2.r3,
                    }
                }
                this.setState({rightG2: auxRight}, () => {
                    //console.log(this.state)
                    this.primeraFuncion()
                })
            }
            if(auxArray.left.programador !== 'interleaving' && auxArray.right.programador !== 'interleaving') {
                this.primeraFuncion()
            }
            console.log(this.state)
        })
    }

    primeraFuncion() {
        const stado = this.state
        if(dbPromise){
            try {
                dbPromise.then(function(db,) {
                    
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        var item = {
                            id: id,
                            left: stado.left,
                            right: stado.right,
                            left2: stado.leftG2,
                            right2: stado.rightG2,
                            configAnt: stado.configAnt,
                            fechaSel: stado.fechaSel,
                            formulaL: stado.formulaL,
                            formulaR: stado.formulaR,
                            formulaL2: stado.formulaL2,
                            formulaR2: stado.formulaR2,
                            resultadoL: stado.resultadoL,
                            resultadoR: stado.resultadoR,
                            resultadoL2: stado.resultadoL2,
                            resultadoR2: stado.resultadoR2,

                            intensidadLeft: stado.intensidadLeft,
                            intensidadLeftPorcent: stado.intensidadLeftPorcent,
                            intensidadLeft2: stado.intensidadLeft2,
                            intensidadLeftPorcent2: stado.intensidadLeftPorcent2,
                            intensidadRight: stado.intensidadRight,
                            intensidadRightPorcent: stado.intensidadRightPorcent,
                            intensidadRight2: stado.intensidadRight2,
                            intensidadRightPorcent2: stado.intensidadRightPorcent2,

                            generadorSel: stado.generadorSel,
                            ladoImpUniSel: stado.ladoImpUniSel,
                            patologiaSel: stado.patologiaSel,
                            tipoImplanteSel: stado.tipoImplanteSel,
                            tipoProgramacionSel: stado.tipoProgramacionSel,
                            zonestumulaSel: stado.zonestumulaSel,
                            toleranciasSelL: stado.toleranciasSelL,
                            toleranciasSelR: stado.toleranciasSelR,

                            generadorSel2: stado.generadorSel2,
                            ladoImpUniSel2: stado.ladoImpUniSel2,
                            patologiaSel2: stado.patologiaSel2,
                            tipoImplanteSel2: stado.tipoImplanteSel2,
                            tipoProgramacionSel2: stado.tipoProgramacionSel2,
                            zonestumulaSel2: stado.zonestumulaSel2,
                            toleranciasSelL2: stado.toleranciasSelL2,
                            toleranciasSelR2: stado.toleranciasSelR2,
                        };
                        store.add(item);
                        return tx.complete;
                    
                }).then(function(log) {
                    dbPromise.then(function(db) { 
                        var tx = db.transaction('logs', 'readonly');
                        var store = tx.objectStore('logs');
                        return store.getAll();
                    })
                    .then(function(log){
                        // eslint-disable-next-line
                        return log.map(function(registro) {
                            data.guardarDatos(registro)
                            .then(() => {
                                dbPromise.then(function(db) { 
                                    var tx = db.transaction('logs', 'readwrite');
                                    var store = tx.objectStore('logs');
                                    return store.delete(registro.id);
                                })
                            })
                            .catch(() => console.warn('err'))
                        })
                    })
                    
                })
            }catch (error) {
                console.info(error)
            }
        }

        this.setState({resultadoL: 0})
        this.setState({resultadoR: 0})
        this.setState({resultadoL2: 0})
        this.setState({resultadoR2: 0})
        this.setState({
            mostrarFinalLeft: false,
            mostrarFinalRigth: false
        })
        this.setState({
            mostrarFinalLeft2: false,
            mostrarFinalRigth2: false
        })
        this.calcularLeft()
        this.calcularRigth()
        Object.filter = (obj, predicate) => 
        Object.keys(obj)
            .filter( key => predicate(obj[key]) )
            // eslint-disable-next-line
            .reduce( (res, key) => (res[key] = obj[key], res), {} );
        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.left.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.left.leads, lead => lead === 'positive'); 
        delete positivos.lcase
        delete negativos.lcase
        let negativosr = Object.filter(this.state.right.leads, lead => lead === 'negative'); 
        let positivosr = Object.filter(this.state.right.leads, lead => lead === 'positive'); 
        delete positivosr.rcase
        delete negativosr.rcase
        this.setState({
            contPL: Object.size(positivos),
            contNL: Object.size(negativos),
            contPR: Object.size(positivosr),
            contNR: Object.size(negativosr),
        })

        if(this.state.left.programador === 'interleaving') {
            this.calcularLeftG2()
            let negativos = Object.filter(this.state.leftG2.leads, lead => lead === 'negative'); 
            let positivos = Object.filter(this.state.leftG2.leads, lead => lead === 'positive'); 
            delete positivos.lcase
            delete negativos.lcase
            this.setState({
                contPL2: Object.size(positivos),
                contNL2: Object.size(negativos)
            })
        }
        if(this.state.right.programador === 'interleaving') {
            this.calcularRigthG2()
            let negativosr = Object.filter(this.state.rightG2.leads, lead => lead === 'negative'); 
            let positivosr = Object.filter(this.state.rightG2.leads, lead => lead === 'positive'); 
            delete positivosr.rcase
            delete negativosr.rcase
            this.setState({
                contPR2: Object.size(positivosr),
                contNR2: Object.size(negativosr),
            })
        }
        this.props.change(this.state)
    }

    calcularLeft(){
        /*Maquinaria para filtar en objetos*/
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.left.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.left.leads, lead => lead === 'positive'); 
        delete positivos.lcase
        delete negativos.lcase
        //Obtenemos el voltaje
        let amplitude = this.state.left.amplitude
        //Sacamos las intensidades de cada uno de los leads agrupados
        //Negativos
        let sumaNegativos = 0
        let objNegativos = {}
        let intensidadNeg = {}
        let rEquivaNeg = {}
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.configAnt.left.impedance[key]
            objNegativos[key] = impedance
            intensidadNeg[key] = ((amplitude/impedance)*1000)
            rEquivaNeg[key] = (1/impedance)
        });
        sumaNegativos = this.sum(objNegativos)
        //Positivos
        let sumaPositivos = 0
        let objPositivos = {}
        let intensidadPos = {}
        let rEquivaPos = {}
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.configAnt.left.impedance[key]
            objPositivos[key] = impedance
            intensidadPos[key] = ((amplitude/impedance)*1000)
            rEquivaPos[key] = (1/impedance)
        });
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.configAnt.left.impedance[key]
            intensidadNeg[key] = ((amplitude/impedance)*1000)
        });
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.configAnt.left.impedance[key]
            intensidadPos[key] = ((amplitude/impedance)*1000)
        });
        /*
            m
            Rp + RN * Ma
        */

        sumaPositivos = this.sum(objPositivos)
        //Obtenemos el total
        //let total = 0
        //let formula = ''
        if(Object.size(negativos) >= 2 && Object.size(positivos) === 0){// Negativos SOLO en paralelo Scenario 3
            this.calcParalelos('left', negativos, intensidadNeg, amplitude)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 0){// POSITIVOS SOLO en paralelo Scenario 3
            this.calcParalelos('left', positivos, intensidadPos, amplitude)
        }else if(Object.size(negativos) >= 2 && Object.size(positivos) === 1){// Scenario 4 Negativos
            this.calcScnFourDes('left', amplitude, negativos, sumaPositivos, rEquivaNeg, positivos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 1){// Scenario 4 Positivos
            this.calcScnFourDes('left', amplitude, positivos, sumaNegativos, rEquivaPos, negativos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) >= 2){// Scenario 4 Positivos
            this.calcScnFourFav('left', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
        }else{
            this.calcScnFourFav('left', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
            /*
            formula = 'It = '+amplitude +' / (' + sumaNegativos + ' + ' + sumaPositivos+ ')'
            total = ((amplitude/(sumaNegativos+sumaPositivos))*1000)
            this.setState({
                resultadoL: Math.round((total + Number.EPSILON) * 100) / 100,
                formulaL: formula
            }, () => {
                //Tolerancias
                this.porcentajes(5, this.state.resultadoL, this.state.formulaL, 'resultadoLTfive', 'formulaLTfive')
                this.porcentajes(10, this.state.resultadoL, this.state.formulaL, 'resultadoLTten', 'formulaLTten')
                this.porcentajes(0, this.state.resultadoL, this.state.formulaL, 'resultadoLSinT', 'formulaLSinT')
            })
            */
        }
        

    }

    calcularLeftG2(){
        /*Maquinaria para filtar en objetos*/
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //console.log(this.state.leftG2)
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.leftG2.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.leftG2.leads, lead => lead === 'positive'); 
        delete positivos.lcase
        delete negativos.lcase
        //Obtenemos el voltaje
        let amplitude = this.state.leftG2.amplitude
        //Sacamos las intensidades de cada uno de los leads agrupados
        //Negativos
        let sumaNegativos = 0
        let objNegativos = {}
        let intensidadNeg = {}
        let rEquivaNeg = {}
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.leftG2.impedance[key]
            objNegativos[key] = impedance
            intensidadNeg[key] = ((amplitude/impedance)*1000)
            rEquivaNeg[key] = (1/impedance)
        });
        sumaNegativos = this.sum(objNegativos)
        //Positivos
        let sumaPositivos = 0
        let objPositivos = {}
        let intensidadPos = {}
        let rEquivaPos = {}
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.leftG2.impedance[key]
            objPositivos[key] = impedance
            intensidadPos[key] = ((amplitude/impedance)*1000)
            rEquivaPos[key] = (1/impedance)
        });
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.leftG2.impedance[key]
            intensidadNeg[key] = ((amplitude/impedance)*1000)
        });
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.leftG2.impedance[key]
            intensidadPos[key] = ((amplitude/impedance)*1000)
        });
        /*
            m
            Rp + RN * Ma
        */

        sumaPositivos = this.sum(objPositivos)
        //Obtenemos el total
        //let total = 0
        //let formula = ''
        if(Object.size(negativos) >= 2 && Object.size(positivos) === 0){// Negativos SOLO en paralelo Scenario 3
            this.calcParalelos2('leftG2', negativos, intensidadNeg, amplitude)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 0){// POSITIVOS SOLO en paralelo Scenario 3
            this.calcParalelos2('leftG2', positivos, intensidadPos, amplitude)
        }else if(Object.size(negativos) >= 2 && Object.size(positivos) === 1){// Scenario 4 Negativos
            this.calcScnFourDes2('leftG2', amplitude, negativos, sumaPositivos, rEquivaNeg, positivos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 1){// Scenario 4 Positivos
            this.calcScnFourDes2('leftG2', amplitude, positivos, sumaNegativos, rEquivaPos, negativos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) >= 2){// Scenario 4 Positivos
            this.calcScnFourFav2('leftG2', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
        }else{
            this.calcScnFourFav2('leftG2', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
            /*
            formula = 'It = '+amplitude +' / (' + sumaNegativos + ' + ' + sumaPositivos+ ')'
            total = ((amplitude/(sumaNegativos+sumaPositivos))*1000)
            this.setState({
                resultadoL: Math.round((total + Number.EPSILON) * 100) / 100,
                formulaL: formula
            }, () => {
                //Tolerancias
                this.porcentajes(5, this.state.resultadoL, this.state.formulaL, 'resultadoLTfive', 'formulaLTfive')
                this.porcentajes(10, this.state.resultadoL, this.state.formulaL, 'resultadoLTten', 'formulaLTten')
                this.porcentajes(0, this.state.resultadoL, this.state.formulaL, 'resultadoLSinT', 'formulaLSinT')
            })
            */
        }
        

    }

    calcularRigth(){
        /*Maquinaria para filtar en objetos*/
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.right.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.right.leads, lead => lead === 'positive'); 
        delete positivos.rcase
        delete negativos.rcase
        //Obtenemos el voltaje
        let amplitude = this.state.right.amplitude
        //Sacamos las intensidades de cada uno de los leads agrupados
            //Scenarios
        //Negativos
        let sumaNegativos = 0
        let objNegativos = {}
        let intensidadNeg = {}
        let rEquivaNeg = {}
            Object.entries(negativos).forEach(([key]) => {
                const impedance = this.state.configAnt.right.impedance[key]
                objNegativos[key] = impedance
                intensidadNeg[key] = ((amplitude/impedance)*1000)
                rEquivaNeg[key] = (1/impedance)
        });
        sumaNegativos = this.sum(objNegativos)
        //Positivos
        let sumaPositivos = 0
        let objPositivos = {}
        let intensidadPos = {}
        let rEquivaPos = {}
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.configAnt.right.impedance[key]
            objPositivos[key] = impedance
            intensidadPos[key] = ((amplitude/impedance)*1000)
            rEquivaPos[key] = (1/impedance)
        });
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        sumaPositivos = this.sum(objPositivos)
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.configAnt.right.impedance[key]
            intensidadNeg[key] = ((amplitude/impedance)*1000)
        });
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.configAnt.right.impedance[key]
            intensidadPos[key] = ((amplitude/impedance)*1000)
        });
        //Obtenemos el total
        //let total = 0
        //let formula = ''
        if(Object.size(negativos) >= 2 && Object.size(positivos) === 0){// Negativos SOLO en paralelo Scenario 3
            this.calcParalelos('right', negativos, intensidadNeg, amplitude)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 0){// POSITIVOS SOLO en paralelo Scenario 3
            this.calcParalelos('right', positivos, intensidadPos, amplitude)
        }else if(Object.size(negativos) >= 2 && Object.size(positivos) === 1){// Scenario 4 Negativos
            this.calcScnFourDes('right', amplitude, negativos, sumaPositivos, rEquivaNeg, positivos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 1){// Scenario 4 Positivos
            this.calcScnFourDes('right', amplitude, positivos, sumaNegativos, rEquivaPos, negativos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) >= 2){// Scenario 4 Positivos
            this.calcScnFourFav('right', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
        }else{

            this.calcScnFourFav('right', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
            /*
            this.setState({
                intensidadRight: ObjTodos
            })
            formula = 'It = '+amplitude +' / (' + sumaNegativos + ' + ' + sumaPositivos+ ')'
            total = ((amplitude/(sumaNegativos+sumaPositivos))*1000)
            this.setState({})
            this.setState({
                resultadoR: Math.round((total + Number.EPSILON) * 100) / 100,
                formulaR: formula,
            }, () => {
                this.porcentajes(5, this.state.resultadoR, this.state.formulaR, 'resultadoRTfive', 'formulaRTfive')
                this.porcentajes(10, this.state.resultadoR, this.state.formulaR, 'resultadoRTten', 'formulaRTten')
                this.porcentajes(0, this.state.resultadoR, this.state.formulaR, 'resultadoRSinT', 'formulaRSinT')
            })
            */
        }
        //Tolerancias
        
    }

    calcularRigthG2(){
        /*Maquinaria para filtar en objetos*/
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        //Paso 1 Buscamos los leads left con la misma polaridad ya juntar paralelos
        let negativos = Object.filter(this.state.rightG2.leads, lead => lead === 'negative'); 
        let positivos = Object.filter(this.state.rightG2.leads, lead => lead === 'positive'); 
        delete positivos.rcase
        delete negativos.rcase
        //Obtenemos el voltaje
        let amplitude = this.state.rightG2.amplitude
        //Sacamos las intensidades de cada uno de los leads agrupados
            //Scenarios
        //Negativos
        let sumaNegativos = 0
        let objNegativos = {}
        let intensidadNeg = {}
        let rEquivaNeg = {}
            Object.entries(negativos).forEach(([key]) => {
                const impedance = this.state.rightG2.impedance[key]
                objNegativos[key] = impedance
                intensidadNeg[key] = ((amplitude/impedance)*1000)
                rEquivaNeg[key] = (1/impedance)
        });
        sumaNegativos = this.sum(objNegativos)
        //Positivos
        let sumaPositivos = 0
        let objPositivos = {}
        let intensidadPos = {}
        let rEquivaPos = {}
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.rightG2.impedance[key]
            objPositivos[key] = impedance
            intensidadPos[key] = ((amplitude/impedance)*1000)
            rEquivaPos[key] = (1/impedance)
        });
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        sumaPositivos = this.sum(objPositivos)
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.rightG2.impedance[key]
            intensidadNeg[key] = ((amplitude/impedance)*1000)
        });
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.rightG2.impedance[key]
            intensidadPos[key] = ((amplitude/impedance)*1000)
        });
        //Obtenemos el total
        //let total = 0
        //let formula = ''
        if(Object.size(negativos) >= 2 && Object.size(positivos) === 0){// Negativos SOLO en paralelo Scenario 3
            this.calcParalelos2('rightG2', negativos, intensidadNeg, amplitude)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 0){// POSITIVOS SOLO en paralelo Scenario 3
            this.calcParalelos2('rightG2', positivos, intensidadPos, amplitude)
        }else if(Object.size(negativos) >= 2 && Object.size(positivos) === 1){// Scenario 4 Negativos
            this.calcScnFourDes2('rightG2', amplitude, negativos, sumaPositivos, rEquivaNeg, positivos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) === 1){// Scenario 4 Positivos
            this.calcScnFourDes2('rightG2', amplitude, positivos, sumaNegativos, rEquivaPos, negativos)
        }else if(Object.size(positivos) >= 2 && Object.size(negativos) >= 2){// Scenario 4 Positivos
            this.calcScnFourFav2('rightG2', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
        }else{

            this.calcScnFourFav2('rightG2', amplitude, negativos, rEquivaNeg, positivos, rEquivaPos)
            /*
            this.setState({
                intensidadRight: ObjTodos
            })
            formula = 'It = '+amplitude +' / (' + sumaNegativos + ' + ' + sumaPositivos+ ')'
            total = ((amplitude/(sumaNegativos+sumaPositivos))*1000)
            this.setState({})
            this.setState({
                resultadoR: Math.round((total + Number.EPSILON) * 100) / 100,
                formulaR: formula,
            }, () => {
                this.porcentajes(5, this.state.resultadoR, this.state.formulaR, 'resultadoRTfive', 'formulaRTfive')
                this.porcentajes(10, this.state.resultadoR, this.state.formulaR, 'resultadoRTten', 'formulaRTten')
                this.porcentajes(0, this.state.resultadoR, this.state.formulaR, 'resultadoRSinT', 'formulaRSinT')
            })
            */
        }
        //Tolerancias
        
    }

    calcParalelos(tipo, arrTipo, intensidad, amplitude){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let cont = 0
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'left'){
            letra = 'L'
            intensidadLado = 'intensidadLeft'
            intensidadLadoPorcent = 'intensidadLeftPorcent'
            resultado = 'resultadoL'
            formulaApp = 'formulaL'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight'
            intensidadLadoPorcent = 'intensidadRightPorcent'
            resultado = 'resultadoR'
            formulaApp = 'formulaR'
        }
        let total = this.sum(intensidad)
        let formula = 'It = '
        Object.entries(arrTipo).forEach(([key]) => {
            cont ++
            const impedance = this.state.configAnt[tipo].impedance[key]
            formula = formula + '(' + amplitude +' / '+ impedance+ ')'
            if(cont < Object.size(arrTipo))
                formula = formula + ' + '
        })
        this.setState({
            [intensidadLado]: intensidad
        }, () => {
            if(dbPromise){
                const stado = this.state
                dbPromise.then(function(db) {
                    var tx = db.transaction('logs', 'readwrite');
                    var store = tx.objectStore('logs');
                    return store.openCursor(IDBKeyRange.only(id));
                }).then(function logItems(cursor) {
                    if (!cursor) {
                        return;
                    }
                    const updateData = cursor.value;
                    updateData[intensidadLado] = stado[intensidadLado]
                    cursor.update(updateData)
                    return cursor.continue().then(logItems);
                });
            }
        })
        Object.entries(intensidad).forEach(([key]) => {
            this.setState(prevState => ({
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad[key]*100)/total)
                },
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        this.setState({
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }, () =>{
            this.porcentajes(5, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfive', 'formula'+letra+'Tfive', 'intensidad'+letra+'Tfive', tipo)
            this.porcentajes(10, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tten', 'formula'+letra+'Tten', 'intensidad'+letra+'Tten', tipo)
            this.porcentajes(15, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfifteen', 'formula'+letra+'Tfifteen', 'intensidad'+letra+'Tfifteen', tipo)
            this.porcentajes(20, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwenty', 'formula'+letra+'Ttwenty', 'intensidad'+letra+'Ttwenty', tipo)
            this.porcentajes(25, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwentyfive', 'formula'+letra+'Ttwentyfive', 'intensidad'+letra+'Ttwentyfive', tipo)
            this.porcentajes(0, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'SinT', 'formula'+letra+'SinT', 'intensidad'+letra+'SinT', tipo)
        })
    }

    calcParalelos2(tipo, arrTipo, intensidad, amplitude){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let cont = 0
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'leftG2'){
            letra = 'L'
            intensidadLado = 'intensidadLeft2'
            intensidadLadoPorcent = 'intensidadLeftPorcent2'
            resultado = 'resultadoL2'
            formulaApp = 'formulaL2'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight2'
            intensidadLadoPorcent = 'intensidadRightPorcent2'
            resultado = 'resultadoR2'
            formulaApp = 'formulaR2'
        }
        let total = this.sum(intensidad)
        let formula = 'It = '
        Object.entries(arrTipo).forEach(([key]) => {
            cont ++
            const impedance = this.state[tipo].impedance[key]
            formula = formula + '(' + amplitude +' / '+ impedance+ ')'
            if(cont < Object.size(arrTipo))
                formula = formula + ' + '
        })
        this.setState({
            [intensidadLado]: intensidad
        }, () => {
            if(dbPromise){
                const stado = this.state
                dbPromise.then(function(db) {
                    var tx = db.transaction('logs', 'readwrite');
                    var store = tx.objectStore('logs');
                    return store.openCursor(IDBKeyRange.only(id));
                }).then(function logItems(cursor) {
                    if (!cursor) {
                        return;
                    }
                    const updateData = cursor.value;
                    updateData[intensidadLado] = stado[intensidadLado]
                    cursor.update(updateData)
                    return cursor.continue().then(logItems);
                });
            }
        })
        Object.entries(intensidad).forEach(([key]) => {
            this.setState(prevState => ({
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad[key]*100)/total)
                },
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        this.setState({
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }, () =>{
            this.porcentajes2(5, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfive2', 'formula'+letra+'Tfive2', 'intensidad'+letra+'Tfive2', tipo)
            this.porcentajes2(10, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tten2', 'formula'+letra+'Tten2', 'intensidad'+letra+'Tten2', tipo)
            this.porcentajes2(15, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfifteen2', 'formula'+letra+'Tfifteen2', 'intensidad'+letra+'Tfifteen2', tipo)
            this.porcentajes2(20, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwenty2', 'formula'+letra+'Ttwenty2', 'intensidad'+letra+'Ttwenty2', tipo)
            this.porcentajes2(25, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwentyfive2', 'formula'+letra+'Ttwentyfive2', 'intensidad'+letra+'Ttwentyfive2', tipo)
            this.porcentajes2(0, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'SinT2', 'formula'+letra+'SinT2', 'intensidad'+letra+'SinT2', tipo)
        })
    }

    calcScnFourDes(tipo, amplitude, arrFav, sumFav, rEquivaFav, arrDes){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'left'){
            letra = 'L'
            intensidadLado = 'intensidadLeft'
            intensidadLadoPorcent = 'intensidadLeftPorcent'
            resultado = 'resultadoL'
            formulaApp = 'formulaL'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight'
            intensidadLadoPorcent = 'intensidadRightPorcent'
            resultado = 'resultadoR'
            formulaApp = 'formulaR'
        }
        const rEq = this.sum(rEquivaFav) > 0 ? (1/this.sum(rEquivaFav)) : 0
        /*
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEq + sumFav) * amplitude)/1000
        }
        */
        let formula = 'It = '+Math.round((amplitude + Number.EPSILON) * 100) / 100  +' / (' + Math.round((rEq + Number.EPSILON) * 100) / 100 + ' + ' + sumFav + ')'
        let total = ((amplitude/(rEq+sumFav))*1000)


        const vEq = (rEq*total)/1000
        Object.entries(arrFav).forEach(([key]) => {
            const impedance = this.state.configAnt[tipo].impedance[key]
            const intensidad = (vEq/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: intensidad
                }, 
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                },
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        Object.entries(arrDes).forEach(([key]) => {
            //const impedance = this.props.location.state[tipo].impedance[key]
            //const intensidad = (vEq/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: total
                },
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((total*100)/total)
                },
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        //this.setState({[resultado]: Math.round((total + Number.EPSILON) * 100) / 100})
        this.setState({
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }, () =>{
            this.porcentajes(5, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfive', 'formula'+letra+'Tfive', 'intensidad'+letra+'Tfive', tipo)
            this.porcentajes(10, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tten', 'formula'+letra+'Tten', 'intensidad'+letra+'Tten', tipo)
            this.porcentajes(15, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfifteen', 'formula'+letra+'Tfifteen', 'intensidad'+letra+'Tfifteen', tipo)
            this.porcentajes(20, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwenty', 'formula'+letra+'Ttwenty', 'intensidad'+letra+'Ttwenty', tipo)
            this.porcentajes(25, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwentyfive', 'formula'+letra+'Ttwentyfive', 'intensidad'+letra+'Ttwentyfive', tipo)
            this.porcentajes(0, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'SinT', 'formula'+letra+'SinT', 'intensidad'+letra+'SinT', tipo)
        })
    }

    calcScnFourDes2(tipo, amplitude, arrFav, sumFav, rEquivaFav, arrDes){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'leftG2'){
            letra = 'L'
            intensidadLado = 'intensidadLeft2'
            intensidadLadoPorcent = 'intensidadLeftPorcent2'
            resultado = 'resultadoL2'
            formulaApp = 'formulaL2'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight2'
            intensidadLadoPorcent = 'intensidadRightPorcent2'
            resultado = 'resultadoR2'
            formulaApp = 'formulaR2'
        }
        const rEq = this.sum(rEquivaFav) > 0 ? (1/this.sum(rEquivaFav)) : 0
        /*
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEq + sumFav) * amplitude)/1000
        }
        */
        let formula = 'It = '+Math.round((amplitude + Number.EPSILON) * 100) / 100  +' / (' + Math.round((rEq + Number.EPSILON) * 100) / 100 + ' + ' + sumFav + ')'
        let total = ((amplitude/(rEq+sumFav))*1000)


        const vEq = (rEq*total)/1000
        Object.entries(arrFav).forEach(([key]) => {
            const impedance = this.state[tipo].impedance[key]
            const intensidad = (vEq/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: intensidad
                }, 
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                },
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        Object.entries(arrDes).forEach(([key]) => {
            //const impedance = this.props.location.state[tipo].impedance[key]
            //const intensidad = (vEq/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: total
                },
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((total*100)/total)
                },
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        //this.setState({[resultado]: Math.round((total + Number.EPSILON) * 100) / 100})
        this.setState({
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }, () =>{
            this.porcentajes2(5, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfive2', 'formula'+letra+'Tfive2', 'intensidad'+letra+'Tfive2', tipo)
            this.porcentajes2(10, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tten2', 'formula'+letra+'Tten2', 'intensidad'+letra+'Tten2', tipo)
            this.porcentajes2(15, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfifteen2', 'formula'+letra+'Tfifteen2', 'intensidad'+letra+'Tfifteen2', tipo)
            this.porcentajes2(20, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwenty2', 'formula'+letra+'Ttwenty2', 'intensidad'+letra+'Ttwenty2', tipo)
            this.porcentajes2(25, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwentyfive2', 'formula'+letra+'Ttwentyfive2', 'intensidad'+letra+'Ttwentyfive2', tipo)
            this.porcentajes2(0, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'SinT2', 'formula'+letra+'SinT2', 'intensidad'+letra+'SinT2', tipo)
        })
    }

    calcScnFourFav(tipo, amplitude, negativos, rEquivaNeg, positivos, rEquivaPos){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'left'){
            letra = 'L'
            intensidadLado = 'intensidadLeft'
            intensidadLadoPorcent = 'intensidadLeftPorcent'
            resultado = 'resultadoL'
            formulaApp = 'formulaL'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight'
            intensidadLadoPorcent = 'intensidadRightPorcent'
            resultado = 'resultadoR'
            formulaApp = 'formulaR'
        }
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        /*
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        */
        /*
            m
            Rp + RN * Ma
        */
        let formula = 'It = '+Math.round((amplitude + Number.EPSILON) * 100) / 100 +' / (' + Math.round((rEqN + Number.EPSILON) * 100) / 100 + ' + ' + Math.round((rEqP + Number.EPSILON) * 100) / 100 + ')'
        let total = ((amplitude/(rEqN+rEqP))*1000)

        const vEqP = (rEqP*total)/1000
        const vEqN = (rEqN*total)/1000
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state.configAnt[tipo].impedance[key]
            const intensidad = (vEqN/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: intensidad
                },
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                }
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
            
        })
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state.configAnt[tipo].impedance[key]
            const intensidad = (vEqP/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: intensidad
                },
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                }
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        //this.setState({[resultado]: Math.round((total + Number.EPSILON) * 100) / 100})
        this.setState({
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }, () =>{
            this.porcentajes(5, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfive', 'formula'+letra+'Tfive', 'intensidad'+letra+'Tfive', tipo)
            this.porcentajes(10, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tten', 'formula'+letra+'Tten', 'intensidad'+letra+'Tten', tipo)
            this.porcentajes(15, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfifteen', 'formula'+letra+'Tfifteen', 'intensidad'+letra+'Tfifteen', tipo)
            this.porcentajes(20, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwenty', 'formula'+letra+'Ttwenty', 'intensidad'+letra+'Ttwenty', tipo)
            this.porcentajes(25, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwentyfive', 'formula'+letra+'Ttwentyfive', 'intensidad'+letra+'Ttwentyfive', tipo)
            this.porcentajes(0, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'SinT', 'formula'+letra+'SinT', 'intensidad'+letra+'SinT', tipo)
        })
    }

    calcScnFourFav2(tipo, amplitude, negativos, rEquivaNeg, positivos, rEquivaPos){
        Object.filter = (obj, predicate) => 
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                // eslint-disable-next-line
                .reduce( (res, key) => (res[key] = obj[key], res), {} );

        Object.size = function(obj) {
            var size = 0, key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
        };
        let intensidadLado = ''
        let intensidadLadoPorcent = ''
        let resultado = ''
        let formulaApp = ''
        let letra = 'L'
        if(tipo === 'leftG2'){
            letra = 'L'
            intensidadLado = 'intensidadLeft2'
            intensidadLadoPorcent = 'intensidadLeftPorcent2'
            resultado = 'resultadoL2'
            formulaApp = 'formulaL2'
        }else{
            letra = 'R'
            intensidadLado = 'intensidadRight2'
            intensidadLadoPorcent = 'intensidadRightPorcent2'
            resultado = 'resultadoR2'
            formulaApp = 'formulaR2'
        }
        const rEqP = this.sum(rEquivaPos) > 0 ? (1/this.sum(rEquivaPos)) : 0
        const rEqN = this.sum(rEquivaNeg) > 0 ? (1/this.sum(rEquivaNeg)) : 0
        /*
        if (this.state.tipoProgramacionSel.key === 'ma'){
            amplitude = ((rEqP + rEqN) * amplitude)/1000
        }
        */
        /*
            m
            Rp + RN * Ma
        */
        let formula = 'It = '+Math.round((amplitude + Number.EPSILON) * 100) / 100 +' / (' + Math.round((rEqN + Number.EPSILON) * 100) / 100 + ' + ' + Math.round((rEqP + Number.EPSILON) * 100) / 100 + ')'
        let total = ((amplitude/(rEqN+rEqP))*1000)

        const vEqP = (rEqP*total)/1000
        const vEqN = (rEqN*total)/1000
        Object.entries(negativos).forEach(([key]) => {
            const impedance = this.state[tipo].impedance[key]
            const intensidad = (vEqN/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: intensidad
                },
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                }
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
            
        })
        Object.entries(positivos).forEach(([key]) => {
            const impedance = this.state[tipo].impedance[key]
            const intensidad = (vEqP/impedance)*1000
            this.setState(prevState => ({
                [intensidadLado]:{
                    ...prevState[intensidadLado],
                    [key]: intensidad
                },
                [intensidadLadoPorcent]:{
                    ...prevState[intensidadLadoPorcent],
                    [key]: ((intensidad*100)/total)
                }
            }), () => {
                if(dbPromise){
                    const stado = this.state
                    dbPromise.then(function(db) {
                        var tx = db.transaction('logs', 'readwrite');
                        var store = tx.objectStore('logs');
                        return store.openCursor(IDBKeyRange.only(id));
                    }).then(function logItems(cursor) {
                        if (!cursor) {
                            return;
                        }
                        const updateData = cursor.value;
                        updateData[intensidadLado] = stado[intensidadLado]
                        updateData[intensidadLadoPorcent] = stado[intensidadLadoPorcent]
                        cursor.update(updateData)
                        return cursor.continue().then(logItems);
                    });
                }
            })
        })
        //this.setState({[resultado]: Math.round((total + Number.EPSILON) * 100) / 100})
        this.setState({
            [resultado]: Math.round((total + Number.EPSILON) * 100) / 100,
            [formulaApp]: formula
        }, () =>{
            this.porcentajes2(5, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfive2', 'formula'+letra+'Tfive2', 'intensidad'+letra+'Tfive2', tipo)
            this.porcentajes2(10, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tten2', 'formula'+letra+'Tten2', 'intensidad'+letra+'Tten2', tipo)
            this.porcentajes2(15, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Tfifteen2', 'formula'+letra+'Tfifteen2', 'intensidad'+letra+'Tfifteen2', tipo)
            this.porcentajes2(20, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwenty2', 'formula'+letra+'Ttwenty2', 'intensidad'+letra+'Ttwenty2', tipo)
            this.porcentajes2(25, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'Ttwentyfive2', 'formula'+letra+'Ttwentyfive2', 'intensidad'+letra+'Ttwentyfive2', tipo)
            this.porcentajes2(0, this.state[resultado], this.state[formulaApp], 'resultado'+letra+'SinT2', 'formula'+letra+'SinT2', 'intensidad'+letra+'SinT2', tipo)
        })
    }

    porcentajes(porciento, total, formula, nombre, nombreF, intensidadF, tipo){
        const descuento = (1-(1*(porciento/100)))
        let formu = formula.replace('It = ', '')
        if(porciento !== 0){
            formu = 'It = '+descuento+ ' * ['+formu+']'
        }else{
            formu = formula
        }
        const calculo = descuento*(total)

        this.setState({
            [nombre]: Math.round((calculo + Number.EPSILON) * 100) / 100,
            [nombreF]: formu
        }, () => {
            if(dbPromise){
                dbPromise.then(function(db) {
                    var tx = db.transaction('logs', 'readwrite');
                    var store = tx.objectStore('logs');
                    return store.openCursor(IDBKeyRange.only(id));
                }).then(function logItems(cursor) {
                    if (!cursor) {
                        return;
                    }
                    const updateData = cursor.value;
                    updateData[nombre] = Math.round((calculo + Number.EPSILON) * 100) / 100
                    updateData[nombreF] = formu
                    cursor.update(updateData)
                    return cursor.continue().then(logItems);
                });
            }
            
        })

        
        if(parseInt(this.state.toleranciasSelL.valor) === porciento){
            if(tipo === 'left'){
                this.setState({
                    resultadoL: Math.round((calculo + Number.EPSILON) * 100) / 100,
                    formulaL: formu
                }, () => {
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoL = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaL = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }
            /*else{
                this.setState({resultadoR: Math.round((calculo + Number.EPSILON) * 100) / 100, formulaR: formu}, () =>{
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoR = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaR = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }*/
        }else if(parseInt(this.state.toleranciasSelR.valor) === porciento){
            if(tipo === 'right'){ 
                this.setState({resultadoR: Math.round((calculo + Number.EPSILON) * 100) / 100, formulaR: formu}, () =>{
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoR = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaR = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }
        }

        if(tipo === 'left'){
            Object.entries(this.state.intensidadLeft).forEach(([key, value]) => {
                this.setState(prevState => ({
                    [intensidadF]:{
                        ...prevState[intensidadF],
                        [key]: descuento*(value)
                    }
                }), () => {
                    if(dbPromise){
                        const stado = this.state
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData[intensidadF] = stado[intensidadF]
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
                if(parseInt(this.state.toleranciasSelL.valor) === porciento){
                    this.setState(prevState => ({
                        intensidadLeft:{
                            ...prevState.intensidadLeft,
                            [key]: descuento*(value)
                        }
                    }), () => {
                        if(dbPromise){
                            const stado = this.state
                            dbPromise.then(function(db) {
                                var tx = db.transaction('logs', 'readwrite');
                                var store = tx.objectStore('logs');
                                return store.openCursor(IDBKeyRange.only(id));
                            }).then(function logItems(cursor) {
                                if (!cursor) {
                                    return;
                                }
                                const updateData = cursor.value;
                                updateData.intensidadLeft = stado.intensidadLeft
                                cursor.update(updateData)
                                return cursor.continue().then(logItems);
                            });
                        }
                    })
                }
            })
        }else{
            Object.entries(this.state.intensidadRight).forEach(([key, value]) => {
                this.setState(prevState => ({
                    [intensidadF]:{
                        ...prevState[intensidadF],
                        [key]: descuento*(value)
                    }
                }), () => {
                    if(dbPromise){
                        const stado = this.state
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData[intensidadF] = stado[intensidadF]
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
                if(parseInt(this.state.toleranciasSelR.valor) === porciento){
                    this.setState(prevState => ({
                        intensidadRight:{
                            ...prevState.intensidadRight,
                            [key]: descuento*(value)
                        }
                    }), () => {
                        if(dbPromise){
                            const stado = this.state
                            dbPromise.then(function(db) {
                                var tx = db.transaction('logs', 'readwrite');
                                var store = tx.objectStore('logs');
                                return store.openCursor(IDBKeyRange.only(id));
                            }).then(function logItems(cursor) {
                                if (!cursor) {
                                    return;
                                }
                                const updateData = cursor.value;
                                updateData.intensidadRight = stado.intensidadRight
                                cursor.update(updateData)
                                return cursor.continue().then(logItems);
                            });
                        }
                    })
                }
            })
        }
    }
    porcentajes2(porciento, total, formula, nombre, nombreF, intensidadF, tipo){
        const descuento = (1-(1*(porciento/100)))
        let formu = formula.replace('It = ', '')
        if(porciento !== 0){
            formu = 'It = '+descuento+ ' * ['+formu+']'
        }else{
            formu = formula
        }
        const calculo = descuento*(total)

        this.setState({
            [nombre]: Math.round((calculo + Number.EPSILON) * 100) / 100,
            [nombreF]: formu
        }, () => {
            if(dbPromise){
                dbPromise.then(function(db) {
                    var tx = db.transaction('logs', 'readwrite');
                    var store = tx.objectStore('logs');
                    return store.openCursor(IDBKeyRange.only(id));
                }).then(function logItems(cursor) {
                    if (!cursor) {
                        return;
                    }
                    const updateData = cursor.value;
                    updateData[nombre] = Math.round((calculo + Number.EPSILON) * 100) / 100
                    updateData[nombreF] = formu
                    cursor.update(updateData)
                    return cursor.continue().then(logItems);
                });
            }
            
        })

        
        if(parseInt(this.state.toleranciasSelL2.valor) === porciento){
            if(tipo === 'leftG2'){
                this.setState({
                    resultadoL2: Math.round((calculo + Number.EPSILON) * 100) / 100,
                    formulaL2: formu
                }, () => {
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoL = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaL = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }
            /*else{
                this.setState({resultadoR: Math.round((calculo + Number.EPSILON) * 100) / 100, formulaR: formu}, () =>{
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoR = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaR = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }*/
        }else if(parseInt(this.state.toleranciasSelR2.valor) === porciento){
            if(tipo === 'rightG2'){ 
                this.setState({resultadoR2: Math.round((calculo + Number.EPSILON) * 100) / 100, formulaR2: formu}, () =>{
                    if(dbPromise){
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData.resultadoR = Math.round((calculo + Number.EPSILON) * 100) / 100
                            updateData.formulaR = formu
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
            }
        }

        if(tipo === 'leftG2'){
            Object.entries(this.state.intensidadLeft2).forEach(([key, value]) => {
                this.setState(prevState => ({
                    [intensidadF]:{
                        ...prevState[intensidadF],
                        [key]: descuento*(value)
                    }
                }), () => {
                    if(dbPromise){
                        const stado = this.state
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData[intensidadF] = stado[intensidadF]
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
                if(parseInt(this.state.toleranciasSelL2.valor) === porciento){
                    this.setState(prevState => ({
                        intensidadLeft2:{
                            ...prevState.intensidadLeft2,
                            [key]: descuento*(value)
                        }
                    }), () => {
                        if(dbPromise){
                            const stado = this.state
                            dbPromise.then(function(db) {
                                var tx = db.transaction('logs', 'readwrite');
                                var store = tx.objectStore('logs');
                                return store.openCursor(IDBKeyRange.only(id));
                            }).then(function logItems(cursor) {
                                if (!cursor) {
                                    return;
                                }
                                const updateData = cursor.value;
                                updateData.intensidadLeft2 = stado.intensidadLeft2
                                cursor.update(updateData)
                                return cursor.continue().then(logItems);
                            });
                        }
                    })
                }
            })
        }else{
            Object.entries(this.state.intensidadRight).forEach(([key, value]) => {
                this.setState(prevState => ({
                    [intensidadF]:{
                        ...prevState[intensidadF],
                        [key]: descuento*(value)
                    }
                }), () => {
                    if(dbPromise){
                        const stado = this.state
                        dbPromise.then(function(db) {
                            var tx = db.transaction('logs', 'readwrite');
                            var store = tx.objectStore('logs');
                            return store.openCursor(IDBKeyRange.only(id));
                        }).then(function logItems(cursor) {
                            if (!cursor) {
                                return;
                            }
                            const updateData = cursor.value;
                            updateData[intensidadF] = stado[intensidadF]
                            cursor.update(updateData)
                            return cursor.continue().then(logItems);
                        });
                    }
                })
                if(parseInt(this.state.toleranciasSelR2.valor) === porciento){
                    this.setState(prevState => ({
                        intensidadRight2:{
                            ...prevState.intensidadRight2,
                            [key]: descuento*(value)
                        }
                    }), () => {
                        if(dbPromise){
                            const stado = this.state
                            dbPromise.then(function(db) {
                                var tx = db.transaction('logs', 'readwrite');
                                var store = tx.objectStore('logs');
                                return store.openCursor(IDBKeyRange.only(id));
                            }).then(function logItems(cursor) {
                                if (!cursor) {
                                    return;
                                }
                                const updateData = cursor.value;
                                updateData.intensidadRight2 = stado.intensidadRight2
                                cursor.update(updateData)
                                return cursor.continue().then(logItems);
                            });
                        }
                    })
                }
            })
        }
    }

    onInputchange(event, item) {
        this.setState({toleranciasSel: item})
        const parts = event.target.attributes.name.value.split('.')
        let lado = ''
        if(parts[1] === 'left'){
            lado = 'L'
        }else{
            lado = 'R'
        }
        const ladoCap = parts[1].charAt(0).toUpperCase() + parts[1].slice(1);   
        if(item.key === 'five'){
            this.setState({['resultado'+lado]: this.state['resultado'+lado+'Tfive']})
            this.setState({['formula'+lado]: this.state['formula'+lado+'Tfive']})
            this.setState({['intensidad'+ladoCap]: this.state['intensidad'+lado+'Tfive']})
        }else if(item.key === 'ten'){
            this.setState({['resultado'+lado]: this.state['resultado'+lado+'Tten']})
            this.setState({['formula'+lado]: this.state['formula'+lado+'Tten']})
            this.setState({['intensidad'+ladoCap]: this.state['intensidad'+lado+'Tten']})
        }else{
            this.setState({['resultado'+lado]: this.state['resultado'+lado+'SinT']})
            this.setState({['formula'+lado]: this.state['formula'+lado+'SinT']})
            this.setState({['intensidad'+ladoCap]: this.state['intensidad'+lado+'SinT']})
        }
    }

    toggleOnChange(event, checked){
        let lado = ''
        if(event.target.attributes.name){
            lado = event.target.attributes.name.value
        }else{
            lado = event.target.parentElement.attributes.name.value
        }
        if(lado === 'toggleLeft'){
            this.setState({mostrarFinalLeft: checked})
        }else if(lado === 'toggleRight'){
            this.setState({mostrarFinalRigth : checked})
        }
    }

    toggleOnChange2(event, checked){
        let lado = ''
        if(event.target.attributes.name){
            lado = event.target.attributes.name.value
        }else{
            lado = event.target.parentElement.attributes.name.value
        }
        if(lado === 'toggleLeft2'){
            this.setState({mostrarFinalLeft2: checked})
        }else if(lado === 'toggleRight2'){
            this.setState({mostrarFinalRigth2: checked})
        }
    }

    changeRateLeft(event){
		this.setState({
            graficoSelL: event.target.textContent
        })
    }
    changeRateLeft2(event){
		this.setState({
            graficoSelL2: event.target.textContent
        })
    }
    
    changeRateRigth(event){
		this.setState({
            graficoSelR: event.target.textContent
        })
    }
    changeRateRigth2(event){
		this.setState({
            graficoSelR2: event.target.textContent
        })
    }
    render() {
        const {t} = this.props
        return (
            <>
                        <Pivot aria-label="Basic Pivot Example">
                        {this.state.ladoImpUniSel.key !== 'right' &&
                            <PivotItem
                            headerText={t('leftsnt.header')}
                            headerButtonProps={{
                                'data-order': 1,
                                'data-title': 'Left',
                                }}
                            >
                                <Label className="ms-fontWeight-regular ms-fontSize-28">{t('left.text')}</Label>
                                <div className="ms-Grid-row ms-hiddenMd ms-hiddenLg ms-hiddenXl ms-hiddenXxxl ms-hiddenXxlUp">
                                    {
                                        this.state.tipoProgramacionSel !== 'ma'
                                        ?
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginTop: '10px'}}>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('stream.label')}:</Label></div>
                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={this.state.resultadoL} /></div>
                                                </div>
                                            </div>
                                        : <></>
                                    }
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginTop: '10px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={this.state.left.amplitude} /></div>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginTop: '10px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.left.rateNew ? this.state.left.rateNew : this.state.left.rate} /></div>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginTop: '10px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.left.pulse} /></div>
                                        </div>
                                    </div>
                                </div>
                                {/*Lead Config */}
                                <div className="ms-Grid-row" id="contentCenter">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '3%'}}>
                                        { this.state.resultadoL >= 20 
                                        ?
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <Label className="ms-fontWeight-regular ms-fontSize-16" style={{color: '#c80000'}}><FontIcon iconName="AlertSettings" style={{color: '#c80000'}} /> {t('resultr.alert')}</Label>
                                                </div>
                                            </div>
                                        :
                                            <></>
                                        }
                                        <Separator></Separator>
                                        <Label className="ms-fontSize-16 ms-fontWeight-regular">{t('contactsconf.text')}</Label>
                                    </div>
                                    {/*
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '3%'}}>
                                    <Dropdown
                                        label='Aplicar Tolerancia'
                                        options={this.state.tolerancias}
                                        name = 'tolerancia.left'
                                        defaultSelectedKey={'cero'}
                                        onChange={this.onInputchange}
                                        disabled={false}
                                    />
                                    </div>
                                    */}
                                    {this.state.configAnt && !this.state.configAnt.left.medirImpedancias ? 
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '3%'}}>
                                    <Pivot aria-label="Basic Pivot Example">
                                        <PivotItem
                                        headerText={t('graphicsnt.header')}
                                        headerButtonProps={{
                                            'data-order': 1,
                                            'data-title': 'Left',
                                            }}
                                        >
                                            <div className="ms-Grid-row">
                                                {this.props.hiddeFrame === 1 ?
                                                                                           <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                                                           <div className="ms-Grid-row">
                                                                                               <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 btn-container P3-botton-p " style={{marginBottom: '20px'}}>
                                                                                                   <DefaultButton text={'mA'} style={this.state.graficoSelL === 'mA' ? {backgroundColor: '#d141f0'} : {}} name='l.ma' onClick={this.changeRateLeft} />
                                                                                                   <DefaultButton text={'%'}  style={this.state.graficoSelL === '%' ? {backgroundColor: '#d141f0'} : {}} name='l.%' onClick={this.changeRateLeft}/>
                                                                                               </div>
                                                                                               <div>
                                                                                                   <Fragment>
                                                                                                       <svg version="1.1" id="Layer_1" width="100%" height="650px" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="25 0 130.49 471.64">
                                                                                                           <g id="Polo_1">
                                                                                                               { this.state.left.leads.l0 === 'negative' &&
                                                                                                                   <g id="Polo_1_negativo">
                                                                                                                       <g id="Fondo_negativo_7_">
                                                                                                                           <path className="st0" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                               s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                                                                                           <path className="st1" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                                                                                               c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                               s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                                                                                               />
                                                                                                                       </g>
                                                                                                                       <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                       {this.state.graficoSelL === 'mA'
                                                                                                                       ? this.state.intensidadLeft.l0
                                                                                                                           ? (Math.round((this.state.intensidadLeft.l0 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                           : ''
                                                                                                                       : this.state.intensidadLeftPorcent.l0
                                                                                                                           ? (Math.round((this.state.intensidadLeftPorcent.l0 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                           : ''
                                                                                                                       }
                                                                                                                       </text>
                                                                                                                       <path id="Icono_negativo_7_" className="st1" d="M109.81,441.65c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86 c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,439.79,109.81,440.62,109.81,441.65z"/>
                                                                                                                   </g>
                                                                                                               }
                                                                                                               { this.state.left.leads.l0 === 'positive' &&
                                                                                                                   <g id="Polo_1_positivo" className="st2">
                                                                                                                       <g id="Fondo_positivo_7_" className="st3">
                                                                                                                           <path className="st4" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                               s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                                                                                           <path className="st1" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                                                                                               c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                               s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                                                                                               />
                                                                                                                       </g>
                                                                                                                       <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                       {this.state.graficoSelL === 'mA'
                                                                                                                       ? this.state.intensidadLeft.l0
                                                                                                                           ? (Math.round((this.state.intensidadLeft.l0 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                           : ''
                                                                                                                       : this.state.intensidadLeftPorcent.l0
                                                                                                                           ? (Math.round((this.state.intensidadLeftPorcent.l0 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                           : ''
                                                                                                                       }
                                                                                                                       </text>
                                                                                                                       <path id="Icono_positivo_7_" className="st1" d="M108.76,440.16h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                           v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                           c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,440.89,109.34,440.16,108.76,440.16z"/>
                                                                                                                   </g>
                                                                                                               }
                                                                                                               { this.state.left.leads.l0 === 'neutral' &&
                                                                                                                   <g id="Polo_1_off" className="st2">
                                                                                                                       <path className="st6" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                           s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                                                                                       <path className="st5" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                                                                                           c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                           s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                                                                                           />
                                                                                                                   </g>
                                                                                                               }
                                                                                                               <text transform="matrix(1 0 0 1 78.7228 434.3762)" className="st1 st7 st8">1</text>
                                                                                                               <g id="Tapa_14_">
                                                                                                                   <ellipse className="st9" cx="102.8" cy="418.83" rx="26.78" ry="6.85"/>
                                                                                                                   <path className="st1" d="M102.8,412.48c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                       C76.52,414.66,89.74,412.48,102.8,412.48 M102.8,411.48c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                                                                                                       s27.28-0.21,27.28-7.35S105.59,411.48,102.8,411.48L102.8,411.48z"/>
                                                                                                               </g>
                                                                                                           </g>
                                                                                                           <g id="Polo_2">
                                                                                                               { this.state.left.leads.l1 === 'negative' &&
                                                                                                                   <g id="Polo_2_negativo">
                                                                                                                       <g id="Fondo_negativo_6_">
                                                                                                                           <path className="st0" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                               s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                                                                                           <path className="st1" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                               c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                               s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                                                                                               "/>
                                                                                                                       </g>
                                                                                                                       <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                       {this.state.graficoSelL === 'mA'
                                                                                                                       ? this.state.intensidadLeft.l1
                                                                                                                           ? (Math.round((this.state.intensidadLeft.l1 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                           : ''
                                                                                                                       : this.state.intensidadLeftPorcent.l1
                                                                                                                           ? (Math.round((this.state.intensidadLeftPorcent.l1 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                           : ''
                                                                                                                       }
                                                                                                                       </text>
                                                                                                                       <path id="Icono_negativo_6_" className="st1" d="M109.81,386.6c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                                                           c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,384.74,109.81,385.58,109.81,386.6z"/>
                                                                                                                   </g>
                                                                                                               }
                                                                                                               { this.state.left.leads.l1 === 'positive' &&
                                                                                                                   <g id="Polo_2_positivo" className="st2">
                                                                                                                       <g id="Fondo_positivo_6_" className="st3">
                                                                                                                           <path className="st4" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                               s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                                                                                           <path className="st1" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                               c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                               s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                                                                                               "/>
                                                                                                                       </g>
                                                                                                                       <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                       {this.state.graficoSelL === 'mA'
                                                                                                                       ? this.state.intensidadLeft.l1
                                                                                                                           ? (Math.round((this.state.intensidadLeft.l1 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                           : ''
                                                                                                                       : this.state.intensidadLeftPorcent.l1
                                                                                                                           ? (Math.round((this.state.intensidadLeftPorcent.l1 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                           : ''
                                                                                                                       }
                                                                                                                       </text>
                                                                                                                       <path id="Icono_positivo_6_" className="st1" d="M108.76,385.12h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                           v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                           c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,385.84,109.34,385.12,108.76,385.12z"/>
                                                                                                                   </g>
                                                                                                               }
                                                                                                               { this.state.left.leads.l1 === 'neutral' &&
                                                                                                                   <g id="Polo_2_off" className="st2">
                                                                                                                       <path className="st6" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                           s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                                                                                       <path className="st5" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                           c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                           s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z"
                                                                                                                           />
                                                                                                                   </g>
                                                                                                               }
                                                                                                               <text transform="matrix(1 0 0 1 78.7228 379.3322)" className="st1 st7 st8">2</text>
                                                                                                               <g id="Tapa_12_">
                                                                                                                   <path className="st9" d="M102.8,370.64c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                                                       C129.58,368.5,115.7,370.64,102.8,370.64z"/>
                                                                                                                   <path className="st1" d="M102.8,357.44c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                       S89.74,357.44,102.8,357.44 M102.8,356.44c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                                                                                                       S105.59,356.44,102.8,356.44L102.8,356.44z"/>
                                                                                                               </g>
                                                                                                           </g>
                                                                                                           <g id="Polo_3">
                                                                                                               { this.state.left.leads.l2 === 'negative' &&
                                                                                                                   <g id="Polo_3_negativo" className="st2">
                                                                                                                       <g id="Fondo_negativo_5_" className="st3">
                                                                                                                           <path className="st0" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                               s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                                                                                           <path className="st1" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                               c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                               s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                                                                                               "/>
                                                                                                                       </g>
                                                                                                                       <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                           {this.state.graficoSelL === 'mA'
                                                                                                                           ? this.state.intensidadLeft.l2
                                                                                                                               ? (Math.round((this.state.intensidadLeft.l2 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                               : ''
                                                                                                                           : this.state.intensidadLeftPorcent.l2
                                                                                                                               ? (Math.round((this.state.intensidadLeftPorcent.l2 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                               : ''
                                                                                                                           }
                                                                                                                       </text>
                                                                                                                       <path id="Icono_negativo_5_" className="st1" d="M109.81,331.56c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                                                           c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,329.7,109.81,330.53,109.81,331.56z"/>
                                                                                                                   </g>
                                                                                                               }
                                                                                                               { this.state.left.leads.l2 === 'positive' &&
                                                                                                                   <g id="Polo_3_positivo">
                                                                                                                       <g id="Fondo_positivo_5_">
                                                                                                                           <path className="st4" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                               s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                                                                                           <path className="st1" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                               c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                               s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                                                                                               "/>
                                                                                                                       </g>
                                                                                                                       <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                           {this.state.graficoSelL === 'mA'
                                                                                                                           ? this.state.intensidadLeft.l2
                                                                                                                               ? (Math.round((this.state.intensidadLeft.l2 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                               : ''
                                                                                                                           : this.state.intensidadLeftPorcent.l2
                                                                                                                               ? (Math.round((this.state.intensidadLeftPorcent.l2 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                               : ''
                                                                                                                           }
                                                                                                                       </text>
                                                                                                                       <path id="Icono_positivo_5_" className="st1" d="M108.76,330.07h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                           v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                           c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,330.8,109.34,330.07,108.76,330.07z"/>
                                                                                                                   </g>
                                                                                                               }
                                                                                                               { this.state.left.leads.l2 === 'neutral' &&
                                                                                                                   <g id="Polo_3_off" className="st2">
                                                                                                                       <path className="st6" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                           s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                                                                                       <path className="st5" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                           c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                           s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z"
                                                                                                                           />
                                                                                                                   </g>
                                                                                                               }
                                                                                                               <text transform="matrix(1 0 0 1 78.7228 324.2883)" className="st1 st7 st8">3</text>
                                                                                                               <g id="Tapa_10_">
                                                                                                                   <path className="st9" d="M102.8,315.6c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                                                       C129.58,313.45,115.7,315.6,102.8,315.6z"/>
                                                                                                                   <path className="st1" d="M102.8,302.39c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                       C76.52,304.57,89.74,302.39,102.8,302.39 M102.8,301.39c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                                                                                                       s27.28-0.21,27.28-7.35S105.59,301.39,102.8,301.39L102.8,301.39z"/>
                                                                                                               </g>
                                                                                                           </g>
                                                                                                           <g id="Polo_4">
                                                                                                               { this.state.left.leads.l3 === 'negative' &&
                                                                                                                   <g id="Polo_4_negativo" className="st2">
                                                                                                                       <g id="Fondo_negativo_4_" className="st3">
                                                                                                                           <path className="st0" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                               s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                                                                                           <path className="st1" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                               c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                               s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                                                                                               />
                                                                                                                       </g>
                                                                                                                       <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                           {this.state.graficoSelL === 'mA'
                                                                                                                           ? this.state.intensidadLeft.l3
                                                                                                                               ? (Math.round((this.state.intensidadLeft.l3 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                               : ''
                                                                                                                           : this.state.intensidadLeftPorcent.l3
                                                                                                                               ? (Math.round((this.state.intensidadLeftPorcent.l3 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                               : ''
                                                                                                                           }
                                                                                                                       </text>
                                                                                                                       <path id="Icono_negativo_4_" className="st1" d="M109.81,276.52c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                                                               c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,274.65,109.81,275.49,109.81,276.52z"/>
                                                                                                                   </g>
                                                                                                               }
                                                                                                               { this.state.left.leads.l3 === 'positive' &&
                                                                                                                   <g id="Polo_4_positivo" className="st2">
                                                                                                                       <g id="Fondo_positivo_4_" className="st3">
                                                                                                                           <path className="st4" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                               s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                                                                                           <path className="st1" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                               c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                               s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                                                                                               />
                                                                                                                       </g>
                                                                                                                       <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                           {this.state.graficoSelL === 'mA'
                                                                                                                           ? this.state.intensidadLeft.l3
                                                                                                                               ? (Math.round((this.state.intensidadLeft.l3 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                               : ''
                                                                                                                           : this.state.intensidadLeftPorcent.l3
                                                                                                                               ? (Math.round((this.state.intensidadLeftPorcent.l3 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                               : ''
                                                                                                                           }
                                                                                                                       </text>
                                                                                                                       <path id="Icono_positivo_4_" className="st1" d="M108.76,275.03h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                           v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                           c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,275.75,109.34,275.03,108.76,275.03z"/>
                                                                                                                   </g>
                                                                                                               }
                                                                                                               { this.state.left.leads.l3 === 'neutral' &&
                                                                                                                   <g id="Polo_4_off">
                                                                                                                       <path className="st10" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                           s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                                                                                       <path className="st1" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                           c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                           s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"/>
                                                                                                                   </g>
                                                                                                               }
                                                                                                               <text transform="matrix(1 0 0 1 78.7228 269.2434)" className="st1 st7 st8">4</text>
                                                                                                               <g id="Tapa_8_">
                                                                                                                   <path className="st9" d="M102.8,260.55c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                                                       C129.58,258.41,115.7,260.55,102.8,260.55z"/>
                                                                                                                   <path className="st1" d="M102.8,247.35c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                       C76.52,249.53,89.74,247.35,102.8,247.35 M102.8,246.35c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                                                                                                       s27.28-0.21,27.28-7.35S105.59,246.35,102.8,246.35L102.8,246.35z"/>
                                                                                                               </g>
                                                                                                           </g>
                                                                                                           <g id="Polo_5">
                                                                                                               <g id="Polo_5_negativo" className="st2">
                                                                                                                   <g id="Fondo_negativo_3_" className="st3">
                                                                                                                       <path className="st0" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                           s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                                                                                                       <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                           c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                           s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                                                                                           "/>
                                                                                                                   </g>
                                                                                                                   <path id="Icono_negativo_3_" className="st5" d="M109.81,226.21c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                                                       c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,224.35,109.81,225.18,109.81,226.21z"/>
                                                                                                               </g>
                                                                                                               <g id="Polo_5_positivo" className="st2">
                                                                                                                   <g id="Fondo_positivo_3_" className="st3">
                                                                                                                       <path className="st4" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                           s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                                                                                                       <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                           c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                           s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                                                                                           "/>
                                                                                                                   </g>
                                                                                                                   <path id="Icono_positivo_3_" className="st5" d="M108.76,224.72h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                       v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                       c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,225.45,109.34,224.72,108.76,224.72z"/>
                                                                                                               </g>
                                                                                                               <g id="Polo_5_off">
                                                                                                                   <path className="st10" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                       s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                                                                                                   <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                       c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                       s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z"
                                                                                                                       />
                                                                                                               </g>
                                                                                                               <text transform="matrix(1 0 0 1 96.383 230.9116)" class="st1 st7">Ω</text>
                                                                                                               <text transform="matrix(1 0 0 1 78.7228 214.1994)" className="st1 st7 st8">5</text>
                                                                                                               <g id="Tapa_6_">
                                                                                                                   <path className="st9" d="M102.8,205.51c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                                                       C129.58,203.36,115.7,205.51,102.8,205.51z"/>
                                                                                                                   <path className="st1" d="M102.8,192.31c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                       S89.74,192.31,102.8,192.31 M102.8,191.31c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                                                                                                       S105.59,191.31,102.8,191.31L102.8,191.31z"/>
                                                                                                               </g>
                                                                                                           </g>
                                                                                                           <g id="Polo_6">
                                                                                                               <g id="Polo_6_negativo" className="st2">
                                                                                                                   <g id="Fondo_negativo_2_" className="st3">
                                                                                                                       <path className="st0" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                           s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                                                                                                       <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                           c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                           s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                                                                                           "/>
                                                                                                                   </g>
                                                                                                                   <path id="Icono_negativo_2_" className="st5" d="M109.81,171.16c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                                                       c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,169.3,109.81,170.14,109.81,171.16z"/>
                                                                                                               </g>
                                                                                                               <g id="Polo_6_positivo" className="st2">
                                                                                                                   <g id="Fondo_positivo_2_" className="st3">
                                                                                                                       <path className="st4" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                           s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                                                                                                       <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                           c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                           s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                                                                                           "/>
                                                                                                                   </g>
                                                                                                                   <path id="Icono_positivo_2_" className="st5" d="M108.76,169.68h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                       v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                       c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,170.4,109.34,169.68,108.76,169.68z"/>
                                                                                                               </g>
                                                                                                               <g id="Polo_6_off">
                                                                                                                   <path className="st10" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                       s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                                                                                                   <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                       c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                       s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z"
                                                                                                                       />
                                                                                                               </g>
                                                                                                               <text transform="matrix(1 0 0 1 96.383 175.8672)" class="st1 st7">Ω</text>
                                                                                                               <text transform="matrix(1 0 0 1 78.7228 159.156)" className="st1 st7 st8">6</text>
                                                                                                               <g id="Tapa_1_">
                                                                                                                   <path className="st9" d="M102.8,150.46c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                                                       C129.58,148.32,115.7,150.46,102.8,150.46z"/>
                                                                                                                   <path className="st1" d="M102.8,137.26c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                       C76.52,139.44,89.74,137.26,102.8,137.26 M102.8,136.26c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                                                                                                       s27.28-0.21,27.28-7.35C130.08,136.47,105.59,136.26,102.8,136.26L102.8,136.26z"/>
                                                                                                               </g>
                                                                                                           </g>
                                                                                                           <g id="Polo_7">
                                                                                                               <g id="Polo_7_negativo" className="st2">
                                                                                                                   <g id="Fondo_negativo_1_" className="st3">
                                                                                                                       <path className="st0" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                           s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                                                                                                       <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                                                                                           c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                           S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                                                                                                   </g>
                                                                                                                   <path id="Icono_negativo_1_" className="st5" d="M109.81,116.12c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                                                       c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,114.26,109.81,115.09,109.81,116.12z"/>
                                                                                                               </g>
                                                                                                               <g id="Polo_7_positivo" className="st2">
                                                                                                                   <g id="Fondo_positivo_1_" className="st3">
                                                                                                                       <path className="st4" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                           s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                                                                                                       <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                                                                                           c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                           S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                                                                                                   </g>
                                                                                                                   <path id="Icono_positivo_1_" className="st5" d="M108.76,114.63h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                       v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                       c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,115.36,109.34,114.63,108.76,114.63z"/>
                                                                                                               </g>
                                                                                                               <g id="Polo_7_off">
                                                                                                                   <path className="st10" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                       s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                                                                                                   <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                                                                                       c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                       S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                                                                                               </g>
                                                                                                               <text transform="matrix(1 0 0 1 96.383 120.823)" class="st1 st7">Ω</text>
                                                                                                               <text transform="matrix(1 0 0 1 78.7228 104.1111)" className="st1 st7 st8">7</text>
                                                                                                               <g id="Tapa_4_">
                                                                                                                   <path className="st9" d="M102.8,95.42c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                                                       C129.58,93.28,115.7,95.42,102.8,95.42z"/>
                                                                                                                   <path className="st1" d="M102.8,82.22c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                       C76.52,84.4,89.74,82.22,102.8,82.22 M102.8,81.22c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                                                                                                       s27.28-0.21,27.28-7.35C130.08,81.42,105.59,81.22,102.8,81.22L102.8,81.22z"/>
                                                                                                               </g>
                                                                                                           </g>
                                                                                                           <g id="Polo_8">
                                                                                                               <g id="Polo_8_negativo" className="st2">
                                                                                                                   <g id="Fondo_negativo" className="st3">
                                                                                                                       <path className="st0" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                           s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                                                                                                       <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                                                                                           c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                           S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                                                                                                   </g>
                                                                                                                   <path id="Icono_negativo" className="st5" d="M109.81,61.08c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                                                       c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,59.21,109.81,60.05,109.81,61.08z"/>
                                                                                                               </g>
                                                                                                               <g id="Polo_8_positivo" className="st2">
                                                                                                                   <g id="Fondo_positivo" className="st3">
                                                                                                                       <path className="st4" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                           s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                                                                                                       <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                                                                                           c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                           S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                                                                                                   </g>
                                                                                                                   <path id="Icono_positivo" className="st5" d="M108.76,59.59h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74
                                                                                                                       h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04
                                                                                                                       v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,60.31,109.34,59.59,108.76,59.59z"/>
                                                                                                               </g>
                                                                                                               <g id="Polo_8_off">
                                                                                                                   <path className="st10" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                       s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                                                                                                   <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                                                                                       c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                       S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                                                                                               </g>
                                                                                                               <text transform="matrix(1 0 0 1 96.3829 65.779)" class="st1 st7">Ω</text>
                                                                                                               <text transform="matrix(1 0 0 1 78.7228 49.0671)" className="st1 st7 st8">8</text>
                                                                                                               <g id="Tapa">
                                                                                                                   <ellipse className="st9" cx="102.8" cy="33.52" rx="26.78" ry="6.85"/>
                                                                                                                   <path className="st1" d="M102.8,27.17c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                       S89.74,27.17,102.8,27.17 M102.8,26.17c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                                                                                                       S105.59,26.17,102.8,26.17L102.8,26.17z"/>
                                                                                                               </g>
                                                                                                           </g>
                                                                                                           <g id="Carcasa">
                                                                                                               <line className="st11" x1="41.4" y1="10.25" x2="103.14" y2="10.25"/>
                                                                                                               <line className="st11" x1="102.8" y1="27.17" x2="102.8" y2="10.25"/>
                                                                                                               {this.state.left.leads.lcase === 'positive' &&
                                                                                                                   <>
                                                                                                                       <path id="Carcasa_positivo" className="st12" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                                                                                                       <path id="Icono_positivo_8_" className="st1" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                           v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                           c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                                                                                                   </>
                                                                                                               }
                                                                                                               {this.state.left.leads.lcase === 'neutral' &&
                                                                                                                   <path id="Carcasa_off" className="st13" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                                                                                               }
                                                                                                               <path id="Tapa_2_" className="st14" d="M2.7,16.54c-1.22-20.84,39.46-20.84,39.45,0C23.01,16.54,2.7,16.54,2.7,16.54z"/>
                                                                                                               <text transform="matrix(1 0 0 1 14.4977 12.9934)" className="st1 st7 st8">IPG</text>
                                                                                                           </g>
                                                                                                       </svg>
                                                                                                   </Fragment>
                                                                                               </div>
                                                                                           </div>
                                                                                       </div>:''    
                                            }
                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-hiddenSm" style={{marginTop: '50px'}}>
                                                    <Separator></Separator>
                                                    <div className="ms-Grid-row ">
                                                        {
                                                            this.state.tipoProgramacionSel !== 'ma' && this.props.hiddeFrame === 1
                                                            ?
                                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                                    <div className="ms-Grid-row">
                                                                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{t('stream.label')}:</Label></div>
                                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={(Math.round((this.state.resultadoL + Number.EPSILON) * 10) / 10)} /></div>
                                                                    </div>
                                                                </div>
                                                            : <>
                                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                                    <div className="ms-Grid-row">
                                                                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{t('finalCorriente.text')}:</Label></div>
                                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={(Math.round((this.props.datos.configAnt.left.terapiaCorriente.l + Number.EPSILON) * 10) / 10)} /></div>
                                                                    </div>
                                                                </div>

                                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                                    <div className="ms-Grid-row">
                                                                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{t('finalImpedancia.text')}:</Label></div>
                                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'Ω'} ariaLabel="" disabled={true} value={this.props.datos.configAnt.left.terapiaImpedancia.l} /></div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={(Math.round((this.state.left.amplitude + Number.EPSILON) * 10) / 10)} /></div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.left.rateNew ? this.state.left.rateNew : this.state.left.rate} /></div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.left.pulse} /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ms-Grid-row ">
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                            <Separator></Separator>
                                                            <Toggle label={t('showmoredata.text')} inlineLabel onText={t('notshow.text')} offText={t('show.text')} name="toggleLeft"  onChange={this.toggleOnChange}/>
                                                        </div>
                                                        {this.state.mostrarFinalLeft &&
                                                            <>
                                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                                    <Label className="ms-fontSize-16 ms-fontWeight-bold">Amplitud equivalente: <span className="ms-fontWeight-regular">{this.state.resultadoL} mA</span></Label>
                                                                    <Label>Fórmula aplicada: {this.state.formulaL}</Label>
                                                                </div>
                                                                <div>
                                                                    <Fragment>
                                                                        <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                                                            <g id="Resistencias_positivas">
                                                                                { this.state.contPL === 4 &&
                                                                                    <g id="R5_positivo">
                                                                                        <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    </g>
                                                                                }
                                                                                { this.state.contPL >= 2 &&
                                                                                    <g id="R4_positivo">
                                                                                        <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43 " className="strokeBlanco"/>
                                                                                    </g>
                                                                                }
                                                                                { this.state.contPL === 1 || this.state.contPL === 3 ?
                                                                                    <g id="R3_positivo">
                                                                                        <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    </g>
                                                                                    : ''
                                                                                }
                                                                                
                                                                                { this.state.contPL >= 2 &&
                                                                                    <g id="R2_positivo">
                                                                                        <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    </g>
                                                                                }

                                                                                { this.state.contPL === 4 &&
                                                                                    <g id="R1_positivo">
                                                                                        <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    </g>
                                                                                }

                                                                                { this.state.contPL < 1 &&
                                                                                    <>
                                                                                        <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlanco"/>
                                                                                    </>
                                                                                }
                                                                                <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
		c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
		h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlanco"/>
                                                                                
                                                                            </g>
                                                                            <g id="Resistencias_negativas">
                                                                                {this.state.contNL === 4 &&
                                                                                    <g id="R5_negativo">
                                                                                    <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                </g>}
                                                                                {this.state.contNL >= 2 &&
                                                                                    <g id="R4_negativo">
                                                                                        <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                </g>}
                                                                                {this.state.contNL === 1 || this.state.contNL === 3 ?
                                                                                    <g id="R3_negativo">
                                                                                    <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                </g>:''}
                                                                                {this.state.contNL >= 2 &&
                                                                                    <g id="R2_negativo">
                                                                                        <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                </g>}
                                                                                {this.state.contNL === 4 &&
                                                                                    <g id="R1_negativo">
                                                                                        <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43"  className="strokeBlanco"/>
                                                                                </g>}
                                                                                { this.state.contNL < 1 &&
                                                                                    <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlanco"/>
                                                                                }
                                                                                <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
		c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlanco"/>
                                                                                
                                                                            </g>
                                                                            <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlanco"/>
                                                                            <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlanco"/>
                                                                            <g id="Carcasa">
                                                                                <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                                                    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                                                    h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                                                                <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlanco"/>
                                                                                <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlanco"/>
                                                                                <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlanco"/>
                                                                                <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
		                                                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                                                                <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                                                    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                                                    h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                                                            </g>
                                                                            <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlanco"/>
                                                                            <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlanco"/>
                                                                            <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlanco"/>
                                                                        </svg>
                                                                    </Fragment>
                                                                    <Label>{t('infoleyenda')}</Label>
                                                                    {
                                                                        i18next.language === 'pt' ? 
                                                                            <img src={mandoPT} alt="Mando" style={{width: '100%', height: '100%'}}/>
                                                                        :
                                                                            <img src={mando} alt="Mando" style={{width: '100%', height: '100%'}}/>
                                                                    }
                                                                </div>
                                                            </>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </PivotItem>
                                        {this.state.left.programador === 'interleaving' && this.props.frameHidde === 1 ?
                                        <PivotItem
                                        headerText={t('graphicsnt.headerG2')}
                                        headerButtonProps={{
                                            'data-order': 1,
                                            'data-title': 'Left',
                                            }}
                                        >
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 btn-container P3-botton-p " style={{marginBottom: '20px'}}>
                                                            <DefaultButton text={'mA'} style={this.state.graficoSelL2 === 'mA' ? {backgroundColor: '#d141f0'} : {}} name='l.ma' onClick={this.changeRateLeft2} />
                                                            <DefaultButton text={'%'}  style={this.state.graficoSelL2 === '%' ? {backgroundColor: '#d141f0'} : {}} name='l.%' onClick={this.changeRateLeft2}/>
                                                        </div>
                                                        <div>
                                                            <Fragment>
                                                                <svg version="1.1" id="Layer_1" width="100%" height="650px" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="25 0 130.49 471.64">
                                                                    <g id="Polo_1">
                                                                        { this.state.leftG2.leads.l0 === 'negative' &&
                                                                            <g id="Polo_1_negativo">
                                                                                <g id="Fondo_negativo_7_">
                                                                                    <path className="st0" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                                                    <path className="st1" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                                                        />
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                {this.state.graficoSelL2 === 'mA'
                                                                                ? this.state.intensidadLeft2.l0
                                                                                    ? (Math.round((this.state.intensidadLeft2.l0 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                    : ''
                                                                                : this.state.intensidadLeftPorcent2.l0
                                                                                    ? (Math.round((this.state.intensidadLeftPorcent2.l0 + Number.EPSILON) * 1) / 1)+' %'
                                                                                    : ''
                                                                                }
                                                                                </text>
                                                                                <path id="Icono_negativo_7_" className="st1" d="M109.81,441.65c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86 c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,439.79,109.81,440.62,109.81,441.65z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.leftG2.leads.l0 === 'positive' &&
                                                                            <g id="Polo_1_positivo" className="st2">
                                                                                <g id="Fondo_positivo_7_" className="st3">
                                                                                    <path className="st4" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                                                    <path className="st1" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                                                        />
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                {this.state.graficoSelL2 === 'mA'
                                                                                ? this.state.intensidadLeft2.l0
                                                                                    ? (Math.round((this.state.intensidadLeft2.l0 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                    : ''
                                                                                : this.state.intensidadLeftPorcent2.l0
                                                                                    ? (Math.round((this.state.intensidadLeftPorcent2.l0 + Number.EPSILON) * 1) / 1)+' %'
                                                                                    : ''
                                                                                }
                                                                                </text>
                                                                                <path id="Icono_positivo_7_" className="st1" d="M108.76,440.16h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,440.89,109.34,440.16,108.76,440.16z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.leftG2.leads.l0 === 'neutral' &&
                                                                            <g id="Polo_1_off" className="st2">
                                                                                <path className="st6" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                                                <path className="st5" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                                                    />
                                                                            </g>
                                                                        }
                                                                        <text transform="matrix(1 0 0 1 78.7228 434.3762)" className="st1 st7 st8">1</text>
                                                                        <g id="Tapa_14_">
                                                                            <ellipse className="st9" cx="102.8" cy="418.83" rx="26.78" ry="6.85"/>
                                                                            <path className="st1" d="M102.8,412.48c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                C76.52,414.66,89.74,412.48,102.8,412.48 M102.8,411.48c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                                                                s27.28-0.21,27.28-7.35S105.59,411.48,102.8,411.48L102.8,411.48z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Polo_2">
                                                                        { this.state.leftG2.leads.l1 === 'negative' &&
                                                                            <g id="Polo_2_negativo">
                                                                                <g id="Fondo_negativo_6_">
                                                                                    <path className="st0" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                                                    <path className="st1" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                                                        "/>
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                {this.state.graficoSelL2 === 'mA'
                                                                                ? this.state.intensidadLeft2.l1
                                                                                    ? (Math.round((this.state.intensidadLeft2.l1 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                    : ''
                                                                                : this.state.intensidadLeftPorcent2.l1
                                                                                    ? (Math.round((this.state.intensidadLeftPorcent2.l1 + Number.EPSILON) * 1) / 1)+' %'
                                                                                    : ''
                                                                                }
                                                                                </text>
                                                                                <path id="Icono_negativo_6_" className="st1" d="M109.81,386.6c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,384.74,109.81,385.58,109.81,386.6z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.leftG2.leads.l1 === 'positive' &&
                                                                            <g id="Polo_2_positivo" className="st2">
                                                                                <g id="Fondo_positivo_6_" className="st3">
                                                                                    <path className="st4" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                                                    <path className="st1" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                                                        "/>
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                {this.state.graficoSelL === 'mA'
                                                                                ? this.state.intensidadLeft2.l1
                                                                                    ? (Math.round((this.state.intensidadLeft2.l1 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                    : ''
                                                                                : this.state.intensidadLeftPorcent2.l1
                                                                                    ? (Math.round((this.state.intensidadLeftPorcent2.l1 + Number.EPSILON) * 1) / 1)+' %'
                                                                                    : ''
                                                                                }
                                                                                </text>
                                                                                <path id="Icono_positivo_6_" className="st1" d="M108.76,385.12h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,385.84,109.34,385.12,108.76,385.12z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.leftG2.leads.l1 === 'neutral' &&
                                                                            <g id="Polo_2_off" className="st2">
                                                                                <path className="st6" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                                                <path className="st5" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z"
                                                                                    />
                                                                            </g>
                                                                        }
                                                                        <text transform="matrix(1 0 0 1 78.7228 379.3322)" className="st1 st7 st8">2</text>
                                                                        <g id="Tapa_12_">
                                                                            <path className="st9" d="M102.8,370.64c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                C129.58,368.5,115.7,370.64,102.8,370.64z"/>
                                                                            <path className="st1" d="M102.8,357.44c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                S89.74,357.44,102.8,357.44 M102.8,356.44c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                                                                S105.59,356.44,102.8,356.44L102.8,356.44z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Polo_3">
                                                                        { this.state.leftG2.leads.l2 === 'negative' &&
                                                                            <g id="Polo_3_negativo" className="st2">
                                                                                <g id="Fondo_negativo_5_" className="st3">
                                                                                    <path className="st0" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                                                    <path className="st1" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                                                        "/>
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                    {this.state.graficoSelL2 === 'mA'
                                                                                    ? this.state.intensidadLeft2.l2
                                                                                        ? (Math.round((this.state.intensidadLeft2.l2 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                        : ''
                                                                                    : this.state.intensidadLeftPorcent2.l2
                                                                                        ? (Math.round((this.state.intensidadLeftPorcent2.l2 + Number.EPSILON) * 1) / 1)+' %'
                                                                                        : ''
                                                                                    }
                                                                                </text>
                                                                                <path id="Icono_negativo_5_" className="st1" d="M109.81,331.56c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,329.7,109.81,330.53,109.81,331.56z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.leftG2.leads.l2 === 'positive' &&
                                                                            <g id="Polo_3_positivo">
                                                                                <g id="Fondo_positivo_5_">
                                                                                    <path className="st4" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                                                    <path className="st1" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                                                        "/>
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                    {this.state.graficoSelL2 === 'mA'
                                                                                    ? this.state.intensidadLeft2.l2
                                                                                        ? (Math.round((this.state.intensidadLeft2.l2 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                        : ''
                                                                                    : this.state.intensidadLeftPorcent2.l2
                                                                                        ? (Math.round((this.state.intensidadLeftPorcent2.l2 + Number.EPSILON) * 1) / 1)+' %'
                                                                                        : ''
                                                                                    }
                                                                                </text>
                                                                                <path id="Icono_positivo_5_" className="st1" d="M108.76,330.07h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,330.8,109.34,330.07,108.76,330.07z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.leftG2.leads.l2 === 'neutral' &&
                                                                            <g id="Polo_3_off" className="st2">
                                                                                <path className="st6" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                                                <path className="st5" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z"
                                                                                    />
                                                                            </g>
                                                                        }
                                                                        <text transform="matrix(1 0 0 1 78.7228 324.2883)" className="st1 st7 st8">3</text>
                                                                        <g id="Tapa_10_">
                                                                            <path className="st9" d="M102.8,315.6c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                C129.58,313.45,115.7,315.6,102.8,315.6z"/>
                                                                            <path className="st1" d="M102.8,302.39c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                C76.52,304.57,89.74,302.39,102.8,302.39 M102.8,301.39c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                                                                s27.28-0.21,27.28-7.35S105.59,301.39,102.8,301.39L102.8,301.39z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Polo_4">
                                                                        { this.state.leftG2.leads.l3 === 'negative' &&
                                                                            <g id="Polo_4_negativo" className="st2">
                                                                                <g id="Fondo_negativo_4_" className="st3">
                                                                                    <path className="st0" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                                                    <path className="st1" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                                                        />
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                    {this.state.graficoSelL2 === 'mA'
                                                                                    ? this.state.intensidadLeft2.l3
                                                                                        ? (Math.round((this.state.intensidadLeft2.l3 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                        : ''
                                                                                    : this.state.intensidadLeftPorcent2.l3
                                                                                        ? (Math.round((this.state.intensidadLeftPorcent2.l3 + Number.EPSILON) * 1) / 1)+' %'
                                                                                        : ''
                                                                                    }
                                                                                </text>
                                                                                <path id="Icono_negativo_4_" className="st1" d="M109.81,276.52c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                        c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,274.65,109.81,275.49,109.81,276.52z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.leftG2.leads.l3 === 'positive' &&
                                                                            <g id="Polo_4_positivo" className="st2">
                                                                                <g id="Fondo_positivo_4_" className="st3">
                                                                                    <path className="st4" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                                                    <path className="st1" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                                                        />
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                    {this.state.graficoSelL2 === 'mA'
                                                                                    ? this.state.intensidadLeft2.l3
                                                                                        ? (Math.round((this.state.intensidadLeft2.l3 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                        : ''
                                                                                    : this.state.intensidadLeftPorcent2.l3
                                                                                        ? (Math.round((this.state.intensidadLeftPorcent2.l3 + Number.EPSILON) * 1) / 1)+' %'
                                                                                        : ''
                                                                                    }
                                                                                </text>
                                                                                <path id="Icono_positivo_4_" className="st1" d="M108.76,275.03h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,275.75,109.34,275.03,108.76,275.03z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.leftG2.leads.l3 === 'neutral' &&
                                                                            <g id="Polo_4_off">
                                                                                <path className="st10" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                                                <path className="st1" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"/>
                                                                            </g>
                                                                        }
                                                                        <text transform="matrix(1 0 0 1 78.7228 269.2434)" className="st1 st7 st8">4</text>
                                                                        <g id="Tapa_8_">
                                                                            <path className="st9" d="M102.8,260.55c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                C129.58,258.41,115.7,260.55,102.8,260.55z"/>
                                                                            <path className="st1" d="M102.8,247.35c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                C76.52,249.53,89.74,247.35,102.8,247.35 M102.8,246.35c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                                                                s27.28-0.21,27.28-7.35S105.59,246.35,102.8,246.35L102.8,246.35z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Polo_5">
                                                                        <g id="Polo_5_negativo" className="st2">
                                                                            <g id="Fondo_negativo_3_" className="st3">
                                                                                <path className="st0" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                                                                <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                                                    "/>
                                                                            </g>
                                                                            <path id="Icono_negativo_3_" className="st5" d="M109.81,226.21c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,224.35,109.81,225.18,109.81,226.21z"/>
                                                                        </g>
                                                                        <g id="Polo_5_positivo" className="st2">
                                                                            <g id="Fondo_positivo_3_" className="st3">
                                                                                <path className="st4" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                                                                <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                                                    "/>
                                                                            </g>
                                                                            <path id="Icono_positivo_3_" className="st5" d="M108.76,224.72h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,225.45,109.34,224.72,108.76,224.72z"/>
                                                                        </g>
                                                                        <g id="Polo_5_off">
                                                                            <path className="st10" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z"
                                                                                />
                                                                        </g>
                                                                        <text transform="matrix(1 0 0 1 96.383 230.9116)" class="st1 st7">Ω</text>
                                                                        <text transform="matrix(1 0 0 1 78.7228 214.1994)" className="st1 st7 st8">5</text>
                                                                        <g id="Tapa_6_">
                                                                            <path className="st9" d="M102.8,205.51c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                C129.58,203.36,115.7,205.51,102.8,205.51z"/>
                                                                            <path className="st1" d="M102.8,192.31c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                S89.74,192.31,102.8,192.31 M102.8,191.31c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                                                                S105.59,191.31,102.8,191.31L102.8,191.31z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Polo_6">
                                                                        <g id="Polo_6_negativo" className="st2">
                                                                            <g id="Fondo_negativo_2_" className="st3">
                                                                                <path className="st0" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                                                                <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                                                    "/>
                                                                            </g>
                                                                            <path id="Icono_negativo_2_" className="st5" d="M109.81,171.16c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,169.3,109.81,170.14,109.81,171.16z"/>
                                                                        </g>
                                                                        <g id="Polo_6_positivo" className="st2">
                                                                            <g id="Fondo_positivo_2_" className="st3">
                                                                                <path className="st4" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                                                                <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                                                    "/>
                                                                            </g>
                                                                            <path id="Icono_positivo_2_" className="st5" d="M108.76,169.68h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,170.4,109.34,169.68,108.76,169.68z"/>
                                                                        </g>
                                                                        <g id="Polo_6_off">
                                                                            <path className="st10" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z"
                                                                                />
                                                                        </g>
                                                                        <text transform="matrix(1 0 0 1 96.383 175.8672)" class="st1 st7">Ω</text>
                                                                        <text transform="matrix(1 0 0 1 78.7228 159.156)" className="st1 st7 st8">6</text>
                                                                        <g id="Tapa_1_">
                                                                            <path className="st9" d="M102.8,150.46c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                C129.58,148.32,115.7,150.46,102.8,150.46z"/>
                                                                            <path className="st1" d="M102.8,137.26c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                C76.52,139.44,89.74,137.26,102.8,137.26 M102.8,136.26c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                                                                s27.28-0.21,27.28-7.35C130.08,136.47,105.59,136.26,102.8,136.26L102.8,136.26z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Polo_7">
                                                                        <g id="Polo_7_negativo" className="st2">
                                                                            <g id="Fondo_negativo_1_" className="st3">
                                                                                <path className="st0" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                                                                <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                                                            </g>
                                                                            <path id="Icono_negativo_1_" className="st5" d="M109.81,116.12c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,114.26,109.81,115.09,109.81,116.12z"/>
                                                                        </g>
                                                                        <g id="Polo_7_positivo" className="st2">
                                                                            <g id="Fondo_positivo_1_" className="st3">
                                                                                <path className="st4" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                                                                <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                                                            </g>
                                                                            <path id="Icono_positivo_1_" className="st5" d="M108.76,114.63h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,115.36,109.34,114.63,108.76,114.63z"/>
                                                                        </g>
                                                                        <g id="Polo_7_off">
                                                                            <path className="st10" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                                                        </g>
                                                                        <text transform="matrix(1 0 0 1 96.383 120.823)" class="st1 st7">Ω</text>
                                                                        <text transform="matrix(1 0 0 1 78.7228 104.1111)" className="st1 st7 st8">7</text>
                                                                        <g id="Tapa_4_">
                                                                            <path className="st9" d="M102.8,95.42c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                C129.58,93.28,115.7,95.42,102.8,95.42z"/>
                                                                            <path className="st1" d="M102.8,82.22c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                C76.52,84.4,89.74,82.22,102.8,82.22 M102.8,81.22c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                                                                s27.28-0.21,27.28-7.35C130.08,81.42,105.59,81.22,102.8,81.22L102.8,81.22z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Polo_8">
                                                                        <g id="Polo_8_negativo" className="st2">
                                                                            <g id="Fondo_negativo" className="st3">
                                                                                <path className="st0" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                                                                <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                                                            </g>
                                                                            <path id="Icono_negativo" className="st5" d="M109.81,61.08c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,59.21,109.81,60.05,109.81,61.08z"/>
                                                                        </g>
                                                                        <g id="Polo_8_positivo" className="st2">
                                                                            <g id="Fondo_positivo" className="st3">
                                                                                <path className="st4" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                                                                <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                                                            </g>
                                                                            <path id="Icono_positivo" className="st5" d="M108.76,59.59h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74
                                                                                h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04
                                                                                v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,60.31,109.34,59.59,108.76,59.59z"/>
                                                                        </g>
                                                                        <g id="Polo_8_off">
                                                                            <path className="st10" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                                                        </g>
                                                                        <text transform="matrix(1 0 0 1 96.3829 65.779)" class="st1 st7">Ω</text>
                                                                        <text transform="matrix(1 0 0 1 78.7228 49.0671)" className="st1 st7 st8">8</text>
                                                                        <g id="Tapa">
                                                                            <ellipse className="st9" cx="102.8" cy="33.52" rx="26.78" ry="6.85"/>
                                                                            <path className="st1" d="M102.8,27.17c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                S89.74,27.17,102.8,27.17 M102.8,26.17c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                                                                S105.59,26.17,102.8,26.17L102.8,26.17z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Carcasa">
                                                                        <line className="st11" x1="41.4" y1="10.25" x2="103.14" y2="10.25"/>
                                                                        <line className="st11" x1="102.8" y1="27.17" x2="102.8" y2="10.25"/>
                                                                        {this.state.leftG2.leads.lcase === 'positive' &&
                                                                            <>
                                                                                <path id="Carcasa_positivo" className="st12" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                                                                <path id="Icono_positivo_8_" className="st1" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                                                            </>
                                                                        }
                                                                        {this.state.leftG2.leads.lcase === 'neutral' &&
                                                                            <path id="Carcasa_off" className="st13" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                                                        }
                                                                        <path id="Tapa_2_" className="st14" d="M2.7,16.54c-1.22-20.84,39.46-20.84,39.45,0C23.01,16.54,2.7,16.54,2.7,16.54z"/>
                                                                        <text transform="matrix(1 0 0 1 14.4977 12.9934)" className="st1 st7 st8">IPG</text>
                                                                    </g>
                                                                </svg>
                                                            </Fragment>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-hiddenSm" style={{marginTop: '50px'}}>
                                                    <Separator></Separator>
                                                    <div className="ms-Grid-row ">
                                                        {
                                                            this.state.tipoProgramacionSel !== 'ma'
                                                            ?
                                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                                    <div className="ms-Grid-row">
                                                                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{t('stream.label')}:</Label></div>
                                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={this.state.resultadoL2} /></div>
                                                                    </div>
                                                                </div>
                                                            : <></>
                                                        }
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={(Math.round((this.state.leftG2.amplitude + Number.EPSILON) * 10) / 10)} /></div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm6 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.left.rateNew ? this.state.left.rateNew : this.state.leftG2.rate} /></div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                            <div className="ms-Grid-row">
                                                                <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.leftG2.pulse} /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ms-Grid-row ">
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                            <Separator></Separator>
                                                            <Toggle label={t('showmoredata.text')} inlineLabel onText={t('notshow.text')} offText={t('show.text')} name="toggleLeft2"  onChange={this.toggleOnChange2}/>
                                                        </div>
                                                        {this.state.mostrarFinalLeft2 &&
                                                            <>
                                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                                    <Label className="ms-fontSize-16 ms-fontWeight-bold">Amplitud equivalente: <span className="ms-fontWeight-regular">{this.state.resultadoL2} mA</span></Label>
                                                                    <Label>Fórmula aplicada: {this.state.formulaL2}</Label>
                                                                </div>
                                                                <div>
                                                                    <Fragment>
                                                                        <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                                                            <g id="Resistencias_positivas">
                                                                                { this.state.contPL2 === 4 &&
                                                                                    <g id="R5_positivo">
                                                                                        <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    </g>
                                                                                }
                                                                                { this.state.contPL2 >= 2 &&
                                                                                    <g id="R4_positivo">
                                                                                        <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43 " className="strokeBlanco"/>
                                                                                    </g>
                                                                                }
                                                                                { this.state.contPL2 === 1 || this.state.contPL2 === 3 ?
                                                                                    <g id="R3_positivo">
                                                                                        <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    </g>
                                                                                    : ''
                                                                                }
                                                                                
                                                                                { this.state.contPL2 >= 2 &&
                                                                                    <g id="R2_positivo">
                                                                                        <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    </g>
                                                                                }

                                                                                { this.state.contPL2 === 4 &&
                                                                                    <g id="R1_positivo">
                                                                                        <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    </g>
                                                                                }

                                                                                { this.state.contPL2 < 1 &&
                                                                                    <>
                                                                                        <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlanco"/>
                                                                                    </>
                                                                                }
                                                                                <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
		c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
		h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlanco"/>
                                                                                
                                                                            </g>
                                                                            <g id="Resistencias_negativas">
                                                                                {this.state.contNL2 === 4 &&
                                                                                    <g id="R5_negativo">
                                                                                    <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                </g>}
                                                                                {this.state.contNL2 >= 2 &&
                                                                                    <g id="R4_negativo">
                                                                                        <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                </g>}
                                                                                {this.state.contNL2 === 1 || this.state.contNL2 === 3 ?
                                                                                    <g id="R3_negativo">
                                                                                    <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                </g>:''}
                                                                                {this.state.contNL2 >= 2 &&
                                                                                    <g id="R2_negativo">
                                                                                        <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                </g>}
                                                                                {this.state.contNL2 === 4 &&
                                                                                    <g id="R1_negativo">
                                                                                        <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43"  className="strokeBlanco"/>
                                                                                </g>}
                                                                                { this.state.contNL2 < 1 &&
                                                                                    <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlanco"/>
                                                                                }
                                                                                <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
		c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlanco"/>
                                                                                
                                                                            </g>
                                                                            <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlanco"/>
                                                                            <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlanco"/>
                                                                            <g id="Carcasa">
                                                                                <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                                                    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                                                    h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                                                                <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlanco"/>
                                                                                <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlanco"/>
                                                                                <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlanco"/>
                                                                                <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
		                                                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                                                                <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                                                    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                                                    h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                                                            </g>
                                                                            <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlanco"/>
                                                                            <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlanco"/>
                                                                            <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlanco"/>
                                                                        </svg>
                                                                    </Fragment>
                                                                    <Label>{t('infoleyenda')}</Label>
                                                                    {
                                                                        i18next.language === 'pt' ? 
                                                                            <img src={mandoPT} alt="Mando" style={{width: '100%', height: '100%'}}/>
                                                                        :
                                                                            <img src={mando} alt="Mando" style={{width: '100%', height: '100%'}}/>
                                                                    }
                                                                </div>
                                                            </>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </PivotItem>
                                        :
                                        <></>
                                        }
                                    </Pivot>
                                    </div>
                                    : <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '3%', color:'#fff'}}>
                                    {t('sinmedidas.text')}
                                    </div>}
                                </div>
                                <div className="ms-Grid-row ms-hiddenMd ms-hiddenLg ms-hiddenXl ms-hiddenXxxl ms-hiddenXxlUp">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Separator></Separator>
                                        <Toggle label={t('showmoredata.text')} inlineLabel onText={t('notshow.text')} offText={t('show.text')} name="toggleLeft"  onChange={this.toggleOnChange}/>
                                    </div>
                                    {this.state.mostrarFinalLeft &&
                                        <>
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <Label className="ms-fontSize-16 ms-fontWeight-bold">{t('equivalentamplitude.text')}: <span className="ms-fontWeight-regular">{this.state.resultadoL} mA</span></Label>
                                                <Label>{t('appliedformula.text')}: {this.state.formulaL}</Label>
                                            </div>
                                            <div>
                                                <Fragment>
                                                    <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                                        <g id="Resistencias_positivas">
                                                            { this.state.contPL === 4 &&
                                                                <g id="R5_positivo">
                                                                    <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                    <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                    <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                    <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                    <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                </g>
                                                            }
                                                            { this.state.contPL >= 2 &&
                                                                <g id="R4_positivo">
                                                                    <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                    <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                    <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                    <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                    <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43 " className="strokeBlanco"/>
                                                                </g>
                                                            }
                                                            { this.state.contPL === 1 || this.state.contPL === 3 ?
                                                                <g id="R3_positivo">
                                                                    <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                    <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                    <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                </g>
                                                                : ''
                                                            }
                                                            
                                                            { this.state.contPL >= 2 &&
                                                                <g id="R2_positivo">
                                                                    <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                    <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                    <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                    <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                    <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                </g>
                                                            }

                                                            { this.state.contPL === 4 &&
                                                                <g id="R1_positivo">
                                                                    <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                    <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                    <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                    <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                    <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                </g>
                                                            }

                                                            { this.state.contPL < 1 &&
                                                                <>
                                                                    <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlanco"/>
                                                                </>
                                                            }
                                                            <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlanco"/>
                                                            
                                                        </g>
                                                        <g id="Resistencias_negativas">
                                                            {this.state.contNL === 4 &&
                                                                <g id="R5_negativo">
                                                                <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlanco"/>
                                                            </g>}
                                                            {this.state.contNL >= 2 &&
                                                                <g id="R4_negativo">
                                                                    <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlanco"/>
                                                            </g>}
                                                            {this.state.contNL === 1 || this.state.contNL === 3 ?
                                                                <g id="R3_negativo">
                                                                <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlanco"/>
                                                            </g>:''}
                                                            {this.state.contNL >= 2 &&
                                                                <g id="R2_negativo">
                                                                    <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlanco"/>
                                                            </g>}
                                                            {this.state.contNL === 4 &&
                                                                <g id="R1_negativo">
                                                                    <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43"  className="strokeBlanco"/>
                                                            </g>}
                                                            { this.state.contNL < 1 &&
                                                                <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlanco"/>
                                                            }
                                                            <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlanco"/>
                                                            
                                                        </g>
                                                        <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlanco"/>
                                                        <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlanco"/>
                                                        <g id="Carcasa">
                                                            <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                                c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                                h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                                            <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlanco"/>
                                                            <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlanco"/>
                                                            <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlanco"/>
                                                            <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
                                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                                            <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                                c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                                h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                                        </g>
                                                        <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlanco"/>
                                                        <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlanco"/>
                                                        <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlanco"/>
                                                    </svg>
                                                </Fragment>
                                                <Label>{t('infoleyenda')}</Label>
                                                {
                                                    i18next.language === 'pt' ? 
                                                        <img src={mandoPT} alt="Mando" style={{width: '100%', height: '100%'}}/>
                                                    :
                                                        <img src={mando} alt="Mando" style={{width: '100%', height: '100%'}}/>
                                                }
                                            </div>
                                        </>
                                    }

                                </div>
                                
                            </PivotItem>
                        }
                        {this.state.ladoImpUniSel.key !== 'left' &&
                            <PivotItem
                                headerText={t('rightsnt.header')}
                            >
                                <Label className="ms-fontWeight-regular ms-fontSize-28">{t('right.text')}</Label>
                                <div className="ms-Grid-row ms-hiddenMd ms-hiddenLg ms-hiddenXl ms-hiddenXxxl ms-hiddenXxlUp">
                                    {
                                        this.state.tipoProgramacionSel !== 'ma'
                                        ?
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginTop: '10px'}}>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('stream.label')}:</Label></div>
                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={this.state.resultadoR} /></div>
                                                </div>
                                            </div>
                                        : <></>
                                    }
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginTop: '10px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={this.state.right.amplitude} /></div>
                                        </div>
                                    </div>
                                    
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginTop: '10px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.right.rateNew ? this.state.right.rateNew : this.state.right.rate} /></div>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginTop: '10px'}}>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.right.pulse} /></div>
                                        </div>
                                    </div>
                                </div>
                                {/** 
                                <br className="ms-hiddenMd ms-hiddenLg ms-hiddenXl ms-hiddenXxxl ms-hiddenXxlUp"></br>
                                <div className="ms-Grid-row ms-hiddenMd ms-hiddenLg ms-hiddenXl ms-hiddenXxxl ms-hiddenXxlUp">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">Pulso:</Label></div>
                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.right.pulse} /></div>
                                        </div>
                                    </div>
                                </div>  
                                */}                        
                                <div className="ms-Grid-row" id="contentCenter">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '3%'}}>
                                        { this.state.resultadoR >= 20 
                                        ?
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <Label className="ms-fontWeight-regular ms-fontSize-16" style={{color: '#c80000'}}><FontIcon iconName="AlertSettings" style={{color: '#c80000'}} /> {t('resultr.alert')}</Label>
                                                </div>
                                            </div>
                                        :
                                            <></>
                                        }
                                        
                                        <Separator></Separator>
                                        <Label className="ms-fontSize-16 ms-fontWeight-regular">{t('contactsconf.text')}</Label>
                                    </div>
                                    {/*
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '3%'}}>
                                        <Dropdown
                                            label='Aplicar Tolerancia'
                                            options={this.state.tolerancias}
                                            name = 'tolerancia.right'
                                            defaultSelectedKey={'cero'}
                                            onChange={this.onInputchange}
                                            disabled={false}
                                        />
                                    </div>
                                    */}
                                    {this.state.configAnt && !this.state.configAnt.right.medirImpedancias ?
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '3%'}}>
                                        <Pivot aria-label="Basic Pivot Example">
                                            <PivotItem
                                            headerText={t('graphicsnt.header')}
                                            headerButtonProps={{
                                                'data-order': 1,
                                                'data-title': 'Left',
                                                }}
                                            >
                                                <div className="ms-Grid-row">
                                                    {this.props.hiddeFrame === 1 ? 
                                                                                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 btn-container P3-botton-p" style={{marginBottom: '20px'}}>
                                                                                                            <DefaultButton text={'mA'} style={this.state.graficoSelR === 'mA' ? {backgroundColor: '#d141f0'} : {}} name='l.ma' onClick={this.changeRateRigth} />
                                                                                                            <DefaultButton text={'%'}  style={this.state.graficoSelR === '%' ? {backgroundColor: '#d141f0'} : {}} name='l.%' onClick={this.changeRateRigth}/>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <Fragment>
                                                                                                                <svg version="1.1" id="Layer_1" width="100%" height="650px" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="25 0 130.49 471.64">
                                                                                                                    <g id="Polo_1">
                                                                                                                        { this.state.right.leads.r0 === 'negative' &&
                                                                                                                            <g id="Polo_1_negativo">
                                                                                                                                <g id="Fondo_negativo_7_">
                                                                                                                                    <path className="st0" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                                                                                                    <path className="st1" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                                                                                                        />
                                                                                                                                </g>
                                                                                                                                <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                                {this.state.graficoSelR === 'mA'
                                                                                                                                ? this.state.intensidadRight.r0
                                                                                                                                    ? (Math.round((this.state.intensidadRight.r0 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                                    : ''
                                                                                                                                : this.state.intensidadRightPorcent.r0
                                                                                                                                    ? (Math.round((this.state.intensidadRightPorcent.r0 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                                    : ''
                                                                                                                                }
                                                                                                                                </text>
                                                                                                                                <path id="Icono_negativo_7_" className="st1" d="M109.81,441.65c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86 c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,439.79,109.81,440.62,109.81,441.65z"/>
                                                                                                                            </g>
                                                                                                                        }
                                                                                                                        { this.state.right.leads.r0 === 'positive' &&
                                                                                                                            <g id="Polo_1_positivo" className="st2">
                                                                                                                                <g id="Fondo_positivo_7_" className="st3">
                                                                                                                                    <path className="st4" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                                                                                                    <path className="st1" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                                                                                                        />
                                                                                                                                </g>
                                                                                                                                <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                                {this.state.graficoSelR === 'mA'
                                                                                                                                ? this.state.intensidadRight.r0
                                                                                                                                    ? (Math.round((this.state.intensidadRight.r0 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                                    : ''
                                                                                                                                : this.state.intensidadRightPorcent.r0
                                                                                                                                    ? (Math.round((this.state.intensidadRightPorcent.r0 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                                    : ''
                                                                                                                                }
                                                                                                                                </text>
                                                                                                                                <path id="Icono_positivo_7_" className="st1" d="M108.76,440.16h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,440.89,109.34,440.16,108.76,440.16z"/>
                                                                                                                            </g>
                                                                                                                        }
                                                                                                                        { this.state.right.leads.r0 === 'neutral' &&
                                                                                                                            <g id="Polo_1_off" className="st2">
                                                                                                                                <path className="st6" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                                                                                                <path className="st5" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                                                                                                    />
                                                                                                                            </g>
                                                                                                                        }
                                                                                                                        <text transform="matrix(1 0 0 1 78.7228 434.3762)" className="st1 st7 st8">1</text>
                                                                                                                        <g id="Tapa_14_">
                                                                                                                            <ellipse className="st9" cx="102.8" cy="418.83" rx="26.78" ry="6.85"/>
                                                                                                                            <path className="st1" d="M102.8,412.48c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                                C76.52,414.66,89.74,412.48,102.8,412.48 M102.8,411.48c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                                                                                                                s27.28-0.21,27.28-7.35S105.59,411.48,102.8,411.48L102.8,411.48z"/>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                    <g id="Polo_2">
                                                                                                                        { this.state.right.leads.r1 === 'negative' &&
                                                                                                                            <g id="Polo_2_negativo">
                                                                                                                                <g id="Fondo_negativo_6_">
                                                                                                                                    <path className="st0" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                                                                                                    <path className="st1" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                                                                                                        "/>
                                                                                                                                </g>
                                                                                                                                <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                                    {this.state.graficoSelR === 'mA'
                                                                                                                                    ? this.state.intensidadRight.r1
                                                                                                                                        ? (Math.round((this.state.intensidadRight.r1 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                                        : ''
                                                                                                                                    : this.state.intensidadRightPorcent.r1
                                                                                                                                        ? (Math.round((this.state.intensidadRightPorcent.r1 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                                        : ''
                                                                                                                                    }
                                                                                                                                </text>
                                                                                                                                <path id="Icono_negativo_6_" className="st1" d="M109.81,386.6c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,384.74,109.81,385.58,109.81,386.6z"/>
                                                                                                                            </g>
                                                                                                                        }
                                                                                                                        { this.state.right.leads.r1 === 'positive' &&
                                                                                                                            <g id="Polo_2_positivo" className="st2">
                                                                                                                                <g id="Fondo_positivo_6_" className="st3">
                                                                                                                                    <path className="st4" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                                                                                                    <path className="st1" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                                                                                                        "/>
                                                                                                                                </g>
                                                                                                                                <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                                    {this.state.graficoSelR === 'mA'
                                                                                                                                    ? this.state.intensidadRight.r1
                                                                                                                                        ? (Math.round((this.state.intensidadRight.r1 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                                        : ''
                                                                                                                                    : this.state.intensidadRightPorcent.r1
                                                                                                                                        ? (Math.round((this.state.intensidadRightPorcent.r1 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                                        : ''
                                                                                                                                    }
                                                                                                                                </text>
                                                                                                                                <path id="Icono_positivo_6_" className="st1" d="M108.76,385.12h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,385.84,109.34,385.12,108.76,385.12z"/>
                                                                                                                            </g>
                                                                                                                        }
                                                                                                                        { this.state.right.leads.r1 === 'neutral' &&
                                                                                                                            <g id="Polo_2_off" className="st2">
                                                                                                                                <path className="st6" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                                                                                                <path className="st5" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z"
                                                                                                                                    />
                                                                                                                            </g>
                                                                                                                        }
                                                                                                                        <text transform="matrix(1 0 0 1 78.7228 379.3322)" className="st1 st7 st8">2</text>
                                                                                                                        <g id="Tapa_12_">
                                                                                                                            <path className="st9" d="M102.8,370.64c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                                                                C129.58,368.5,115.7,370.64,102.8,370.64z"/>
                                                                                                                            <path className="st1" d="M102.8,357.44c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                                S89.74,357.44,102.8,357.44 M102.8,356.44c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                                                                                                                S105.59,356.44,102.8,356.44L102.8,356.44z"/>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                    <g id="Polo_3">
                                                                                                                        { this.state.right.leads.r2 === 'negative' &&
                                                                                                                            <g id="Polo_3_negativo" className="st2">
                                                                                                                                <g id="Fondo_negativo_5_" className="st3">
                                                                                                                                    <path className="st0" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                                                                                                    <path className="st1" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                                                                                                        "/>
                                                                                                                                </g>
                                                                                                                                <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                                    {this.state.graficoSelR === 'mA'
                                                                                                                                    ? this.state.intensidadRight.r2
                                                                                                                                        ? (Math.round((this.state.intensidadRight.r2 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                                        : ''
                                                                                                                                    : this.state.intensidadRightPorcent.r2
                                                                                                                                        ? (Math.round((this.state.intensidadRightPorcent.r2 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                                        : ''
                                                                                                                                    }
                                                                                                                                </text>
                                                                                                                                <path id="Icono_negativo_5_" className="st1" d="M109.81,331.56c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,329.7,109.81,330.53,109.81,331.56z"/>
                                                                                                                            </g>
                                                                                                                        }
                                                                                                                        { this.state.right.leads.r2 === 'positive' &&
                                                                                                                            <g id="Polo_3_positivo">
                                                                                                                                <g id="Fondo_positivo_5_">
                                                                                                                                    <path className="st4" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                                                                                                    <path className="st1" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                                                                                                        "/>
                                                                                                                                </g>
                                                                                                                                <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                                    {this.state.graficoSelR === 'mA'
                                                                                                                                    ? this.state.intensidadRight.r2
                                                                                                                                        ? (Math.round((this.state.intensidadRight.r2 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                                        : ''
                                                                                                                                    : this.state.intensidadRightPorcent.r2
                                                                                                                                        ? (Math.round((this.state.intensidadRightPorcent.r2 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                                        : ''
                                                                                                                                    }
                                                                                                                                </text>
                                                                                                                                <path id="Icono_positivo_5_" className="st1" d="M108.76,330.07h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,330.8,109.34,330.07,108.76,330.07z"/>
                                                                                                                            </g>
                                                                                                                        }
                                                                                                                        { this.state.right.leads.r2 === 'neutral' &&
                                                                                                                            <g id="Polo_3_off" className="st2">
                                                                                                                                <path className="st6" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                                                                                                <path className="st5" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z"
                                                                                                                                    />
                                                                                                                            </g>
                                                                                                                        }
                                                                                                                        <text transform="matrix(1 0 0 1 78.7228 324.2883)" className="st1 st7 st8">3</text>
                                                                                                                        <g id="Tapa_10_">
                                                                                                                            <path className="st9" d="M102.8,315.6c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                                                                C129.58,313.45,115.7,315.6,102.8,315.6z"/>
                                                                                                                            <path className="st1" d="M102.8,302.39c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                                C76.52,304.57,89.74,302.39,102.8,302.39 M102.8,301.39c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                                                                                                                s27.28-0.21,27.28-7.35S105.59,301.39,102.8,301.39L102.8,301.39z"/>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                    <g id="Polo_4">
                                                                                                                        { this.state.right.leads.r3 === 'negative' &&
                                                                                                                            <g id="Polo_4_negativo" className="st2">
                                                                                                                                <g id="Fondo_negativo_4_" className="st3">
                                                                                                                                    <path className="st0" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                                                                                                    <path className="st1" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                                                                                                        />
                                                                                                                                </g>
                                                                                                                                <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                                    {this.state.graficoSelR === 'mA'
                                                                                                                                    ? this.state.intensidadRight.r3
                                                                                                                                        ? (Math.round((this.state.intensidadRight.r3 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                                        : ''
                                                                                                                                    : this.state.intensidadRightPorcent.r3
                                                                                                                                        ? (Math.round((this.state.intensidadRightPorcent.r3 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                                        : ''
                                                                                                                                    }
                                                                                                                                </text>
                                                                                                                                <path id="Icono_negativo_4_" className="st1" d="M109.81,276.52c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                                                                        c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,274.65,109.81,275.49,109.81,276.52z"/>
                                                                                                                            </g>
                                                                                                                        }
                                                                                                                        { this.state.right.leads.r3 === 'positive' &&
                                                                                                                            <g id="Polo_4_positivo" className="st2">
                                                                                                                                <g id="Fondo_positivo_4_" className="st3">
                                                                                                                                    <path className="st4" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                                                                                                    <path className="st1" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                                                                                                        />
                                                                                                                                </g>
                                                                                                                                <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                                                                    {this.state.graficoSelR === 'mA'
                                                                                                                                    ? this.state.intensidadRight.r3
                                                                                                                                        ? (Math.round((this.state.intensidadRight.r3 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                                                                        : ''
                                                                                                                                    : this.state.intensidadRightPorcent.r3
                                                                                                                                        ? (Math.round((this.state.intensidadRightPorcent.r3 + Number.EPSILON) * 1) / 1)+' %'
                                                                                                                                        : ''
                                                                                                                                    }
                                                                                                                                </text>
                                                                                                                                <path id="Icono_positivo_4_" className="st1" d="M108.76,275.03h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,275.75,109.34,275.03,108.76,275.03z"/>
                                                                                                                            </g>
                                                                                                                        }
                                                                                                                        { this.state.right.leads.r3 === 'neutral' &&
                                                                                                                            <g id="Polo_4_off">
                                                                                                                                <path className="st10" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                                                                                                <path className="st1" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"/>
                                                                                                                            </g>
                                                                                                                        }
                                                                                                                        <text transform="matrix(1 0 0 1 78.7228 269.2434)" className="st1 st7 st8">4</text>
                                                                                                                        <g id="Tapa_8_">
                                                                                                                            <path className="st9" d="M102.8,260.55c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                                                                C129.58,258.41,115.7,260.55,102.8,260.55z"/>
                                                                                                                            <path className="st1" d="M102.8,247.35c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                                C76.52,249.53,89.74,247.35,102.8,247.35 M102.8,246.35c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                                                                                                                s27.28-0.21,27.28-7.35S105.59,246.35,102.8,246.35L102.8,246.35z"/>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                    <g id="Polo_5">
                                                                                                                        <g id="Polo_5_negativo" className="st2">
                                                                                                                            <g id="Fondo_negativo_3_" className="st3">
                                                                                                                                <path className="st0" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                                                                                                                <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                                                                                                    "/>
                                                                                                                            </g>
                                                                                                                            <path id="Icono_negativo_3_" className="st5" d="M109.81,226.21c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,224.35,109.81,225.18,109.81,226.21z"/>
                                                                                                                        </g>
                                                                                                                        <g id="Polo_5_positivo" className="st2">
                                                                                                                            <g id="Fondo_positivo_3_" className="st3">
                                                                                                                                <path className="st4" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                                                                                                                <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                                                                                                    "/>
                                                                                                                            </g>
                                                                                                                            <path id="Icono_positivo_3_" className="st5" d="M108.76,224.72h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,225.45,109.34,224.72,108.76,224.72z"/>
                                                                                                                        </g>
                                                                                                                        <g id="Polo_5_off">
                                                                                                                            <path className="st10" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                                                                                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z"
                                                                                                                                />
                                                                                                                        </g>
                                                                                                                        <text transform="matrix(1 0 0 1 96.383 230.9116)" class="st1 st7">Ω</text>
                                                                                                                        <text transform="matrix(1 0 0 1 78.7228 214.1994)" className="st1 st7 st8">5</text>
                                                                                                                        <g id="Tapa_6_">
                                                                                                                            <path className="st9" d="M102.8,205.51c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                                                                C129.58,203.36,115.7,205.51,102.8,205.51z"/>
                                                                                                                            <path className="st1" d="M102.8,192.31c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                                S89.74,192.31,102.8,192.31 M102.8,191.31c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                                                                                                                S105.59,191.31,102.8,191.31L102.8,191.31z"/>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                    <g id="Polo_6">
                                                                                                                        <g id="Polo_6_negativo" className="st2">
                                                                                                                            <g id="Fondo_negativo_2_" className="st3">
                                                                                                                                <path className="st0" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                                                                                                                <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                                                                                                    "/>
                                                                                                                            </g>
                                                                                                                            <path id="Icono_negativo_2_" className="st5" d="M109.81,171.16c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,169.3,109.81,170.14,109.81,171.16z"/>
                                                                                                                        </g>
                                                                                                                        <g id="Polo_6_positivo" className="st2">
                                                                                                                            <g id="Fondo_positivo_2_" className="st3">
                                                                                                                                <path className="st4" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                                                                                                                <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                                                                                                    "/>
                                                                                                                            </g>
                                                                                                                            <path id="Icono_positivo_2_" className="st5" d="M108.76,169.68h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,170.4,109.34,169.68,108.76,169.68z"/>
                                                                                                                        </g>
                                                                                                                        <g id="Polo_6_off">
                                                                                                                            <path className="st10" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                                                                                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z"
                                                                                                                                />
                                                                                                                        </g>
                                                                                                                        <text transform="matrix(1 0 0 1 96.383 175.8672)" class="st1 st7">Ω</text>
                                                                                                                        <text transform="matrix(1 0 0 1 78.7228 159.156)" className="st1 st7 st8">6</text>
                                                                                                                        <g id="Tapa_1_">
                                                                                                                            <path className="st9" d="M102.8,150.46c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                                                                C129.58,148.32,115.7,150.46,102.8,150.46z"/>
                                                                                                                            <path className="st1" d="M102.8,137.26c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                                C76.52,139.44,89.74,137.26,102.8,137.26 M102.8,136.26c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                                                                                                                s27.28-0.21,27.28-7.35C130.08,136.47,105.59,136.26,102.8,136.26L102.8,136.26z"/>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                    <g id="Polo_7">
                                                                                                                        <g id="Polo_7_negativo" className="st2">
                                                                                                                            <g id="Fondo_negativo_1_" className="st3">
                                                                                                                                <path className="st0" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                                                                                                                <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                    S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                                                                                                            </g>
                                                                                                                            <path id="Icono_negativo_1_" className="st5" d="M109.81,116.12c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,114.26,109.81,115.09,109.81,116.12z"/>
                                                                                                                        </g>
                                                                                                                        <g id="Polo_7_positivo" className="st2">
                                                                                                                            <g id="Fondo_positivo_1_" className="st3">
                                                                                                                                <path className="st4" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                                                                                                                <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                    S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                                                                                                            </g>
                                                                                                                            <path id="Icono_positivo_1_" className="st5" d="M108.76,114.63h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,115.36,109.34,114.63,108.76,114.63z"/>
                                                                                                                        </g>
                                                                                                                        <g id="Polo_7_off">
                                                                                                                            <path className="st10" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                                                                                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                                                                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                                                                                                        </g>
                                                                                                                        <text transform="matrix(1 0 0 1 96.383 120.823)" class="st1 st7">Ω</text>
                                                                                                                        <text transform="matrix(1 0 0 1 78.7228 104.1111)" className="st1 st7 st8">7</text>
                                                                                                                        <g id="Tapa_4_">
                                                                                                                            <path className="st9" d="M102.8,95.42c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                                                                C129.58,93.28,115.7,95.42,102.8,95.42z"/>
                                                                                                                            <path className="st1" d="M102.8,82.22c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                                C76.52,84.4,89.74,82.22,102.8,82.22 M102.8,81.22c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                                                                                                                s27.28-0.21,27.28-7.35C130.08,81.42,105.59,81.22,102.8,81.22L102.8,81.22z"/>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                    <g id="Polo_8">
                                                                                                                        <g id="Polo_8_negativo" className="st2">
                                                                                                                            <g id="Fondo_negativo" className="st3">
                                                                                                                                <path className="st0" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                                                                                                                <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                    S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                                                                                                            </g>
                                                                                                                            <path id="Icono_negativo" className="st5" d="M109.81,61.08c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,59.21,109.81,60.05,109.81,61.08z"/>
                                                                                                                        </g>
                                                                                                                        <g id="Polo_8_positivo" className="st2">
                                                                                                                            <g id="Fondo_positivo" className="st3">
                                                                                                                                <path className="st4" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                                                                                                                <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                    S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                                                                                                            </g>
                                                                                                                            <path id="Icono_positivo" className="st5" d="M108.76,59.59h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74
                                                                                                                                h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04
                                                                                                                                v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,60.31,109.34,59.59,108.76,59.59z"/>
                                                                                                                        </g>
                                                                                                                        <g id="Polo_8_off">
                                                                                                                            <path className="st10" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                                                                                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                                                                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                                                                                                        </g>
                                                                                                                        <text transform="matrix(1 0 0 1 96.3829 65.779)" class="st1 st7">Ω</text>
                                                                                                                        <text transform="matrix(1 0 0 1 78.7228 49.0671)" className="st1 st7 st8">8</text>
                                                                                                                        <g id="Tapa">
                                                                                                                            <ellipse className="st9" cx="102.8" cy="33.52" rx="26.78" ry="6.85"/>
                                                                                                                            <path className="st1" d="M102.8,27.17c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                                                                S89.74,27.17,102.8,27.17 M102.8,26.17c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                                                                                                                S105.59,26.17,102.8,26.17L102.8,26.17z"/>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                    <g id="Carcasa">
                                                                                                                        <line className="st11" x1="41.4" y1="10.25" x2="103.14" y2="10.25"/>
                                                                                                                        <line className="st11" x1="102.8" y1="27.17" x2="102.8" y2="10.25"/>
                                                                                                                        {this.state.right.leads.rcase === 'positive' &&
                                                                                                                            <>
                                                                                                                                <path id="Carcasa_positivo" className="st12" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                                                                                                                <path id="Icono_positivo_8_" className="st1" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                        {this.state.right.leads.rcase === 'neutral' &&
                                                                                                                            <path id="Carcasa_off" className="st13" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                                                                                                        }
                                                                                                                        <path id="Tapa_2_" className="st14" d="M2.7,16.54c-1.22-20.84,39.46-20.84,39.45,0C23.01,16.54,2.7,16.54,2.7,16.54z"/>
                                                                                                                        <text transform="matrix(1 0 0 1 14.4977 12.9934)" className="st1 st7 st8">IPG</text>
                                                                                                                    </g>
                                                                                                                </svg>
                                                                                                            </Fragment>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : ''

                                                    }
                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-hiddenSm" style={{marginTop: '50px'}}>
                                                        <Separator></Separator>
                                                        <div className="ms-Grid-row">
                                                            {
                                                                this.state.tipoProgramacionSel !== 'ma' && this.props.hiddeFrame === 1
                                                                ?
                                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                                        <div className="ms-Grid-row">
                                                                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{t('stream.label')}:</Label></div>
                                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={this.state.resultadoR} /></div>
                                                                        </div>
                                                                    </div>
                                                                : <>
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{t('finalCorriente.text')}:</Label></div>
                                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={(Math.round((this.props.datos.configAnt.right.terapiaCorriente.r + Number.EPSILON) * 10) / 10)} /></div>
                                                                </div>
                                                            </div>

                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{t('finalImpedancia.text')}:</Label></div>
                                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'Ω'} ariaLabel="" disabled={true} value={this.props.datos.configAnt.right.terapiaImpedancia.r} /></div>
                                                                </div>
                                                            </div>
                                                                </>
                                                            }
                                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                                    <div className="ms-Grid-row">
                                                                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={(Math.round((this.state.right.amplitude + Number.EPSILON) * 10) / 10)} /></div>
                                                                    </div>
                                                                </div>
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.right.rateNew ? this.state.right.rateNew : this.state.right.rate} /></div>
                                                                </div>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.right.pulse} /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                                <Separator></Separator>
                                                                <Toggle label={t('showmoredata.text')} inlineLabel onText={t('notshow.text')} offText={t('show.text')} name="toggleRight"  onChange={this.toggleOnChange}/>
                                                            </div>
                                                            {this.state.mostrarFinalRigth &&
                                                                <>
                                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                                    <Label className="ms-fontSize-16 ms-fontWeight-bold">Amplitud equivalente: <span className="ms-fontWeight-regular">{this.state.resultadoR} mA</span></Label>
                                                                    <Label>Fórmula aplicada: {this.state.formulaR}</Label>
                                                                </div>
                                                                <div>
                                                                    <Fragment>
                                                                        <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                                                            <g id="Resistencias_positivas">
                                                                                { this.state.contPR === 4 &&
                                                                                    <g id="R5_positivo">
                                                                                        <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    </g>
                                                                                }
                                                                                { this.state.contPR >= 2 &&
                                                                                    <g id="R4_positivo">
                                                                                        <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    </g>
                                                                                }
                                                                                { this.state.contPR === 1 || this.state.contPR === 3 ?
                                                                                    <g id="R3_positivo">
                                                                                        <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    </g>
                                                                                    :''
                                                                                }
                                                                                
                                                                                { this.state.contPR >= 2 &&
                                                                                    <g id="R2_positivo">
                                                                                        <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    </g>
                                                                                }

                                                                                { this.state.contPR === 4 &&
                                                                                    <g id="R1_positivo">
                                                                                        <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    </g>
                                                                                }

                                                                                { this.state.contPR < 1 &&
                                                                                    <>
                                                                                        <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlanco"/>
                                                                                    </>
                                                                                }
                                                                                <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                                                c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                                                h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlanco"/>
                                                                                
                                                                            </g>
                                                                            <g id="Resistencias_negativas">
                                                                                {this.state.contNR === 4 &&
                                                                                    <g id="R5_negativo">
                                                                                        <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43"/>
                                                                                    <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                </g>}
                                                                                {this.state.contNR >= 2 &&
                                                                                    <g id="R4_negativo">
                                                                                        <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                </g>}
                                                                                {this.state.contNR === 1 || this.state.contNR === 3 ?
                                                                                    <g id="R3_negativo">
                                                                                    <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                </g>:''}
                                                                                {this.state.contNR >= 2 &&
                                                                                    <g id="R2_negativo">
                                                                                        <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                </g>}
                                                                                {this.state.contNR === 4 &&
                                                                                    <g id="R1_negativo">
                                                                                        <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                </g>}
                                                                                { this.state.contNR < 1 &&
                                                                                    <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlanco"/>
                                                                                }
                                                                                <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
            c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlanco"/>
                                                                            </g>
                                                                            <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlanco"/>
                                                                            <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlanco"/>
                                                                            <g id="Carcasa">
                                                                                <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlanco"/>
                                                                                <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlanco"/>
                                                                                <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlanco"/>
                                                                                <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
                                                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                                                                    <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                                                    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                                                    h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                                                            </g>
                                                                            <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlanco"/>
                                                                            <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlanco"/>
                                                                            <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlanco"/>
                                                                        </svg>
                                                                    </Fragment>
                                                                    <Label>{t('infoleyenda')}</Label>
                                                                    {
                                                                        i18next.language === 'pt' ? 
                                                                            <img src={mandoPT} alt="Mando" style={{width: '100%', height: '100%'}}/>
                                                                        :
                                                                            <img src={mando} alt="Mando" style={{width: '100%', height: '100%'}}/>
                                                                    }
                                                                </div>
                                                                </>
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </PivotItem>
                                            {this.state.right.programador === 'interleaving' && this.props.frameHidde === 1 ?
                                            <PivotItem
                                            headerText={t('graphicsnt.headerG2')}
                                            headerButtonProps={{
                                                'data-order': 1,
                                                'data-title': 'Left',
                                                }}
                                            >
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 btn-container P3-botton-p" style={{marginBottom: '20px'}}>
                                                            <DefaultButton text={'mA'} style={this.state.graficoSelR2 === 'mA' ? {backgroundColor: '#d141f0'} : {}} name='l.ma' onClick={this.changeRateRigth2} />
                                                            <DefaultButton text={'%'}  style={this.state.graficoSelR2 === '%' ? {backgroundColor: '#d141f0'} : {}} name='l.%' onClick={this.changeRateRigth2}/>
                                                        </div>
                                                        <div>
                                                            <Fragment>
                                                                <svg version="1.1" id="Layer_1" width="100%" height="650px" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="25 0 130.49 471.64">
                                                                    <g id="Polo_1">
                                                                        { this.state.rightG2.leads.r0 === 'negative' &&
                                                                            <g id="Polo_1_negativo">
                                                                                <g id="Fondo_negativo_7_">
                                                                                    <path className="st0" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                                                    <path className="st1" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                                                        />
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                {this.state.graficoSelR2 === 'mA'
                                                                                ? this.state.intensidadRight2.r0
                                                                                    ? (Math.round((this.state.intensidadRight2.r0 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                    : ''
                                                                                : this.state.intensidadRightPorcent2.r0
                                                                                    ? (Math.round((this.state.intensidadRightPorcent2.r0 + Number.EPSILON) * 1) / 1)+' %'
                                                                                    : ''
                                                                                }
                                                                                </text>
                                                                                <path id="Icono_negativo_7_" className="st1" d="M109.81,441.65c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86 c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,439.79,109.81,440.62,109.81,441.65z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.rightG2.leads.r0 === 'positive' &&
                                                                            <g id="Polo_1_positivo" className="st2">
                                                                                <g id="Fondo_positivo_7_" className="st3">
                                                                                    <path className="st4" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                                                    <path className="st1" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                                                        />
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 461.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                {this.state.graficoSelR2 === 'mA'
                                                                                ? this.state.intensidadRight2.r0
                                                                                    ? (Math.round((this.state.intensidadRight2.r0 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                    : ''
                                                                                : this.state.intensidadRightPorcent2.r0
                                                                                    ? (Math.round((this.state.intensidadRightPorcent2.r0 + Number.EPSILON) * 1) / 1)+' %'
                                                                                    : ''
                                                                                }
                                                                                </text>
                                                                                <path id="Icono_positivo_7_" className="st1" d="M108.76,440.16h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,440.89,109.34,440.16,108.76,440.16z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.rightG2.leads.r0 === 'neutral' &&
                                                                            <g id="Polo_1_off" className="st2">
                                                                                <path className="st6" d="M102.8,470.88c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,468.74,115.7,470.88,102.8,470.88z"/>
                                                                                <path className="st5" d="M129.08,421.5v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V421.5
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,425.53,129.08,421.5 M130.08,419.63l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V421.5V419.63L130.08,419.63z"
                                                                                    />
                                                                            </g>
                                                                        }
                                                                        <text transform="matrix(1 0 0 1 78.7228 434.3762)" className="st1 st7 st8">1</text>
                                                                        <g id="Tapa_14_">
                                                                            <ellipse className="st9" cx="102.8" cy="418.83" rx="26.78" ry="6.85"/>
                                                                            <path className="st1" d="M102.8,412.48c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                C76.52,414.66,89.74,412.48,102.8,412.48 M102.8,411.48c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                                                                s27.28-0.21,27.28-7.35S105.59,411.48,102.8,411.48L102.8,411.48z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Polo_2">
                                                                        { this.state.rightG2.leads.r1 === 'negative' &&
                                                                            <g id="Polo_2_negativo">
                                                                                <g id="Fondo_negativo_6_">
                                                                                    <path className="st0" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                                                    <path className="st1" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                                                        "/>
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                    {this.state.graficoSelR2 === 'mA'
                                                                                    ? this.state.intensidadRight2.r1
                                                                                        ? (Math.round((this.state.intensidadRight2.r1 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                        : ''
                                                                                    : this.state.intensidadRightPorcent2.r1
                                                                                        ? (Math.round((this.state.intensidadRightPorcent2.r1 + Number.EPSILON) * 1) / 1)+' %'
                                                                                        : ''
                                                                                    }
                                                                                </text>
                                                                                <path id="Icono_negativo_6_" className="st1" d="M109.81,386.6c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,384.74,109.81,385.58,109.81,386.6z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.rightG2.leads.r1 === 'positive' &&
                                                                            <g id="Polo_2_positivo" className="st2">
                                                                                <g id="Fondo_positivo_6_" className="st3">
                                                                                    <path className="st4" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                                                    <path className="st1" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z
                                                                                        "/>
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 406.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                    {this.state.graficoSelR2 === 'mA'
                                                                                    ? this.state.intensidadRight2.r1
                                                                                        ? (Math.round((this.state.intensidadRight2.r1 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                        : ''
                                                                                    : this.state.intensidadRightPorcent2.r1
                                                                                        ? (Math.round((this.state.intensidadRightPorcent2.r1 + Number.EPSILON) * 1) / 1)+' %'
                                                                                        : ''
                                                                                    }
                                                                                </text>
                                                                                <path id="Icono_positivo_6_" className="st1" d="M108.76,385.12h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,385.84,109.34,385.12,108.76,385.12z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.rightG2.leads.r1 === 'neutral' &&
                                                                            <g id="Polo_2_off" className="st2">
                                                                                <path className="st6" d="M102.8,415.84c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,413.7,115.7,415.84,102.8,415.84z"/>
                                                                                <path className="st5" d="M129.08,366.45v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,370.48,129.08,366.45 M130.08,364.58l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V364.58L130.08,364.58z"
                                                                                    />
                                                                            </g>
                                                                        }
                                                                        <text transform="matrix(1 0 0 1 78.7228 379.3322)" className="st1 st7 st8">2</text>
                                                                        <g id="Tapa_12_">
                                                                            <path className="st9" d="M102.8,370.64c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                C129.58,368.5,115.7,370.64,102.8,370.64z"/>
                                                                            <path className="st1" d="M102.8,357.44c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                S89.74,357.44,102.8,357.44 M102.8,356.44c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                                                                S105.59,356.44,102.8,356.44L102.8,356.44z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Polo_3">
                                                                        { this.state.rightG2.leads.r2 === 'negative' &&
                                                                            <g id="Polo_3_negativo" className="st2">
                                                                                <g id="Fondo_negativo_5_" className="st3">
                                                                                    <path className="st0" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                                                    <path className="st1" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                                                        "/>
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                    {this.state.graficoSelR2 === 'mA'
                                                                                    ? this.state.intensidadRight2.r2
                                                                                        ? (Math.round((this.state.intensidadRight2.r2 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                        : ''
                                                                                    : this.state.intensidadRightPorcent2.r2
                                                                                        ? (Math.round((this.state.intensidadRightPorcent2.r2 + Number.EPSILON) * 1) / 1)+' %'
                                                                                        : ''
                                                                                    }
                                                                                </text>
                                                                                <path id="Icono_negativo_5_" className="st1" d="M109.81,331.56c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,329.7,109.81,330.53,109.81,331.56z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.rightG2.leads.r2 === 'positive' &&
                                                                            <g id="Polo_3_positivo">
                                                                                <g id="Fondo_positivo_5_">
                                                                                    <path className="st4" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                                                    <path className="st1" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z
                                                                                        "/>
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 352.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                    {this.state.graficoSelR2 === 'mA'
                                                                                    ? this.state.intensidadRight2.r2
                                                                                        ? (Math.round((this.state.intensidadRight2.r2 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                        : ''
                                                                                    : this.state.intensidadRightPorcent2.r2
                                                                                        ? (Math.round((this.state.intensidadRightPorcent2.r2 + Number.EPSILON) * 1) / 1)+' %'
                                                                                        : ''
                                                                                    }
                                                                                </text>
                                                                                <path id="Icono_positivo_5_" className="st1" d="M108.76,330.07h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,330.8,109.34,330.07,108.76,330.07z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.rightG2.leads.r2 === 'neutral' &&
                                                                            <g id="Polo_3_off" className="st2">
                                                                                <path className="st6" d="M102.8,360.8c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,358.65,115.7,360.8,102.8,360.8z"/>
                                                                                <path className="st5" d="M129.08,311.41v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,315.44,129.08,311.41 M130.08,309.54l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V309.54L130.08,309.54z"
                                                                                    />
                                                                            </g>
                                                                        }
                                                                        <text transform="matrix(1 0 0 1 78.7228 324.2883)" className="st1 st7 st8">3</text>
                                                                        <g id="Tapa_10_">
                                                                            <path className="st9" d="M102.8,315.6c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                C129.58,313.45,115.7,315.6,102.8,315.6z"/>
                                                                            <path className="st1" d="M102.8,302.39c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                C76.52,304.57,89.74,302.39,102.8,302.39 M102.8,301.39c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                                                                s27.28-0.21,27.28-7.35S105.59,301.39,102.8,301.39L102.8,301.39z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Polo_4">
                                                                        { this.state.rightG2.leads.r3 === 'negative' &&
                                                                            <g id="Polo_4_negativo" className="st2">
                                                                                <g id="Fondo_negativo_4_" className="st3">
                                                                                    <path className="st0" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                                                    <path className="st1" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                                                        />
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                    {this.state.graficoSelR2 === 'mA'
                                                                                    ? this.state.intensidadRight2.r3
                                                                                        ? (Math.round((this.state.intensidadRight2.r3 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                        : ''
                                                                                    : this.state.intensidadRightPorcent2.r3
                                                                                        ? (Math.round((this.state.intensidadRightPorcent2.r3 + Number.EPSILON) * 1) / 1)+' %'
                                                                                        : ''
                                                                                    }
                                                                                </text>
                                                                                <path id="Icono_negativo_4_" className="st1" d="M109.81,276.52c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                        c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,274.65,109.81,275.49,109.81,276.52z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.rightG2.leads.r3 === 'positive' &&
                                                                            <g id="Polo_4_positivo" className="st2">
                                                                                <g id="Fondo_positivo_4_" className="st3">
                                                                                    <path className="st4" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                        s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                                                    <path className="st1" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                        c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                        s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"
                                                                                        />
                                                                                </g>
                                                                                <text transform="matrix(1 0 0 1 79.1828 297.766)" className="st1 st2 st3" style={{fontSize: '13px', fontWeight: 'bold'}}>
                                                                                    {this.state.graficoSelR2 === 'mA'
                                                                                    ? this.state.intensidadRight2.r3
                                                                                        ? (Math.round((this.state.intensidadRight2.r3 + Number.EPSILON) * 10) / 10)+' mA'
                                                                                        : ''
                                                                                    : this.state.intensidadRightPorcent2.r3
                                                                                        ? (Math.round((this.state.intensidadRightPorcent2.r3 + Number.EPSILON) * 1) / 1)+' %'
                                                                                        : ''
                                                                                    }
                                                                                </text>
                                                                                <path id="Icono_positivo_4_" className="st1" d="M108.76,275.03h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,275.75,109.34,275.03,108.76,275.03z"/>
                                                                            </g>
                                                                        }
                                                                        { this.state.rightG2.leads.r3 === 'neutral' &&
                                                                            <g id="Polo_4_off">
                                                                                <path className="st10" d="M102.8,305.75c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,304.31,112.75,305.75,102.8,305.75z"/>
                                                                                <path className="st1" d="M129.08,256.36v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,260.39,129.08,256.36 M130.08,254.5l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V254.5L130.08,254.5z"/>
                                                                            </g>
                                                                        }
                                                                        <text transform="matrix(1 0 0 1 78.7228 269.2434)" className="st1 st7 st8">4</text>
                                                                        <g id="Tapa_8_">
                                                                            <path className="st9" d="M102.8,260.55c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                C129.58,258.41,115.7,260.55,102.8,260.55z"/>
                                                                            <path className="st1" d="M102.8,247.35c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                C76.52,249.53,89.74,247.35,102.8,247.35 M102.8,246.35c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35
                                                                                s27.28-0.21,27.28-7.35S105.59,246.35,102.8,246.35L102.8,246.35z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Polo_5">
                                                                        <g id="Polo_5_negativo" className="st2">
                                                                            <g id="Fondo_negativo_3_" className="st3">
                                                                                <path className="st0" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                                                                <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                                                    "/>
                                                                            </g>
                                                                            <path id="Icono_negativo_3_" className="st5" d="M109.81,226.21c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,224.35,109.81,225.18,109.81,226.21z"/>
                                                                        </g>
                                                                        <g id="Polo_5_positivo" className="st2">
                                                                            <g id="Fondo_positivo_3_" className="st3">
                                                                                <path className="st4" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                                                                <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z
                                                                                    "/>
                                                                            </g>
                                                                            <path id="Icono_positivo_3_" className="st5" d="M108.76,224.72h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,225.45,109.34,224.72,108.76,224.72z"/>
                                                                        </g>
                                                                        <g id="Polo_5_off">
                                                                            <path className="st10" d="M102.8,250.71c-9.95,0-26.78-1.44-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,249.26,112.75,250.71,102.8,250.71z"/>
                                                                            <path className="st1" d="M129.08,201.32v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,205.35,129.08,201.32 M130.08,199.45l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V199.45L130.08,199.45z"
                                                                                />
                                                                        </g>
                                                                        <text transform="matrix(1 0 0 1 96.383 230.9116)" class="st1 st7">Ω</text>
                                                                        <text transform="matrix(1 0 0 1 78.7228 214.1994)" className="st1 st7 st8">5</text>
                                                                        <g id="Tapa_6_">
                                                                            <path className="st9" d="M102.8,205.51c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                C129.58,203.36,115.7,205.51,102.8,205.51z"/>
                                                                            <path className="st1" d="M102.8,192.31c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                S89.74,192.31,102.8,192.31 M102.8,191.31c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                                                                S105.59,191.31,102.8,191.31L102.8,191.31z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Polo_6">
                                                                        <g id="Polo_6_negativo" className="st2">
                                                                            <g id="Fondo_negativo_2_" className="st3">
                                                                                <path className="st0" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                                                                <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                                                    "/>
                                                                            </g>
                                                                            <path id="Icono_negativo_2_" className="st5" d="M109.81,171.16c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,169.3,109.81,170.14,109.81,171.16z"/>
                                                                        </g>
                                                                        <g id="Polo_6_positivo" className="st2">
                                                                            <g id="Fondo_positivo_2_" className="st3">
                                                                                <path className="st4" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                                                                <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z
                                                                                    "/>
                                                                            </g>
                                                                            <path id="Icono_positivo_2_" className="st5" d="M108.76,169.68h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,170.4,109.34,169.68,108.76,169.68z"/>
                                                                        </g>
                                                                        <g id="Polo_6_off">
                                                                            <path className="st10" d="M102.8,195.66c-12.9,0-26.78-2.14-26.78-6.85v-43.47l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,193.52,115.7,195.66,102.8,195.66z"/>
                                                                            <path className="st1" d="M129.08,146.28v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35v-42.54
                                                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,150.31,129.08,146.28 M130.08,144.41l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                s-19.8-0.31-25.72-4.26l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35v-42.54V144.41L130.08,144.41z"
                                                                                />
                                                                        </g>
                                                                        <text transform="matrix(1 0 0 1 96.383 175.8672)" class="st1 st7">Ω</text>
                                                                        <text transform="matrix(1 0 0 1 78.7228 159.156)" className="st1 st7 st8">6</text>
                                                                        <g id="Tapa_1_">
                                                                            <path className="st9" d="M102.8,150.46c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                C129.58,148.32,115.7,150.46,102.8,150.46z"/>
                                                                            <path className="st1" d="M102.8,137.26c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                C76.52,139.44,89.74,137.26,102.8,137.26 M102.8,136.26c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                                                                s27.28-0.21,27.28-7.35C130.08,136.47,105.59,136.26,102.8,136.26L102.8,136.26z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Polo_7">
                                                                        <g id="Polo_7_negativo" className="st2">
                                                                            <g id="Fondo_negativo_1_" className="st3">
                                                                                <path className="st0" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                                                                <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                                                            </g>
                                                                            <path id="Icono_negativo_1_" className="st5" d="M109.81,116.12c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,114.26,109.81,115.09,109.81,116.12z"/>
                                                                        </g>
                                                                        <g id="Polo_7_positivo" className="st2">
                                                                            <g id="Fondo_positivo_1_" className="st3">
                                                                                <path className="st4" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                                                                <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                                                            </g>
                                                                            <path id="Icono_positivo_1_" className="st5" d="M108.76,114.63h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,115.36,109.34,114.63,108.76,114.63z"/>
                                                                        </g>
                                                                        <g id="Polo_7_off">
                                                                            <path className="st10" d="M102.8,140.62c-12.9,0-26.78-2.14-26.78-6.85V90.3l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,138.48,115.7,140.62,102.8,140.62z"/>
                                                                            <path className="st1" d="M129.08,91.23v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V91.23
                                                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,95.26,129.08,91.23 M130.08,89.36l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                S83,94.35,77.07,90.4l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V91.23V89.36L130.08,89.36z"/>
                                                                        </g>
                                                                        <text transform="matrix(1 0 0 1 96.383 120.823)" class="st1 st7">Ω</text>
                                                                        <text transform="matrix(1 0 0 1 78.7228 104.1111)" className="st1 st7 st8">7</text>
                                                                        <g id="Tapa_4_">
                                                                            <path className="st9" d="M102.8,95.42c-12.9,0-26.78-2.14-26.78-6.85c0-4.71,13.88-6.85,26.78-6.85s26.78,2.14,26.78,6.85
                                                                                C129.58,93.28,115.7,95.42,102.8,95.42z"/>
                                                                            <path className="st1" d="M102.8,82.22c13.06,0,26.28,2.18,26.28,6.35c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                C76.52,84.4,89.74,82.22,102.8,82.22 M102.8,81.22c-2.79,0-27.28,0.21-27.28,7.35c0,7.15,24.49,7.35,27.28,7.35
                                                                                s27.28-0.21,27.28-7.35C130.08,81.42,105.59,81.22,102.8,81.22L102.8,81.22z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Polo_8">
                                                                        <g id="Polo_8_negativo" className="st2">
                                                                            <g id="Fondo_negativo" className="st3">
                                                                                <path className="st0" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                                                                <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                                                            </g>
                                                                            <path id="Icono_negativo" className="st5" d="M109.81,61.08c0,1.03-0.48,1.86-1.07,1.86H96.86c-0.59,0-1.07-0.83-1.07-1.86
                                                                                c0-1.03,0.48-1.86,1.07-1.86h11.87C109.33,59.21,109.81,60.05,109.81,61.08z"/>
                                                                        </g>
                                                                        <g id="Polo_8_positivo" className="st2">
                                                                            <g id="Fondo_positivo" className="st3">
                                                                                <path className="st4" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                    s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                                                                <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                                                    c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                    S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                                                            </g>
                                                                            <path id="Icono_positivo" className="st5" d="M108.76,59.59h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74
                                                                                h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04
                                                                                v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C109.81,60.31,109.34,59.59,108.76,59.59z"/>
                                                                        </g>
                                                                        <g id="Polo_8_off">
                                                                            <path className="st10" d="M102.8,85.58c-12.9,0-26.78-2.14-26.78-6.85V35.25l0.78,0.52c6.04,4.03,21.43,4.34,26,4.34
                                                                                s19.96-0.31,26-4.34l0.78-0.52v43.47C129.58,83.43,115.7,85.58,102.8,85.58z"/>
                                                                            <path className="st1" d="M129.08,36.19v42.54c0,4.17-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35V36.19
                                                                                c6.04,4.03,20.92,4.43,26.28,4.43S123.04,40.22,129.08,36.19 M130.08,34.32l-1.55,1.04c-5.92,3.95-21.19,4.26-25.72,4.26
                                                                                S83,39.31,77.07,35.36l-1.55-1.04v1.87v42.54c0,7.15,24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35V36.19V34.32L130.08,34.32z"/>
                                                                        </g>
                                                                        <text transform="matrix(1 0 0 1 96.3829 65.779)" class="st1 st7">Ω</text>
                                                                        <text transform="matrix(1 0 0 1 78.7228 49.0671)" className="st1 st7 st8">8</text>
                                                                        <g id="Tapa">
                                                                            <ellipse className="st9" cx="102.8" cy="33.52" rx="26.78" ry="6.85"/>
                                                                            <path className="st1" d="M102.8,27.17c13.06,0,26.28,2.18,26.28,6.35s-13.22,6.35-26.28,6.35c-13.06,0-26.28-2.18-26.28-6.35
                                                                                S89.74,27.17,102.8,27.17 M102.8,26.17c-2.79,0-27.28,0.21-27.28,7.35s24.49,7.35,27.28,7.35s27.28-0.21,27.28-7.35
                                                                                S105.59,26.17,102.8,26.17L102.8,26.17z"/>
                                                                        </g>
                                                                    </g>
                                                                    <g id="Carcasa">
                                                                        <line className="st11" x1="41.4" y1="10.25" x2="103.14" y2="10.25"/>
                                                                        <line className="st11" x1="102.8" y1="27.17" x2="102.8" y2="10.25"/>
                                                                        {this.state.rightG2.leads.rcase === 'positive' &&
                                                                            <>
                                                                                <path id="Carcasa_positivo" className="st12" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                                                                <path id="Icono_positivo_8_" className="st1" d="M28.38,32.95h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04
                                                                                    v4.74h-4.74c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04
                                                                                    c0.58,0,1.23-0.47,1.23-1.04v-4.4h4.74c0.58,0,1.04-0.55,1.04-1.13C29.43,33.68,28.96,32.95,28.38,32.95z"/>
                                                                            </>
                                                                        }
                                                                        {this.state.rightG2.leads.rcase === 'neutral' &&
                                                                            <path id="Carcasa_off" className="st13" d="M2.43,16.95c-12.84,51.23,53.9,51.34,39.59,0C19.89,16.95,2.43,16.95,2.43,16.95z"/>
                                                                        }
                                                                        <path id="Tapa_2_" className="st14" d="M2.7,16.54c-1.22-20.84,39.46-20.84,39.45,0C23.01,16.54,2.7,16.54,2.7,16.54z"/>
                                                                        <text transform="matrix(1 0 0 1 14.4977 12.9934)" className="st1 st7 st8">IPG</text>
                                                                    </g>
                                                                </svg>
                                                            </Fragment>
                                                        </div>
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 ms-hiddenSm" style={{marginTop: '50px'}}>
                                                        <Separator></Separator>
                                                        <div className="ms-Grid-row">
                                                            {
                                                                this.state.tipoProgramacionSel !== 'ma'
                                                                ?
                                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                                        <div className="ms-Grid-row">
                                                                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{t('stream.label')}:</Label></div>
                                                                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={'mA'} ariaLabel="" disabled={true} value={(Math.round((this.state.resultadoR2 + Number.EPSILON) * 10) / 10)} /></div>
                                                                        </div>
                                                                    </div>
                                                                : <></>
                                                            }
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{this.state.tipoProgramacionSel.key === 'ma' ? t('stream.label') : t('voltage.label')}:</Label></div>
                                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix={this.state.tipoProgramacionSel.key === 'ma' ? 'mA' : 'V'} ariaLabel="" disabled={true} value={(Math.round((this.state.rightG2.amplitude + Number.EPSILON) * 10) / 10)} /></div>
                                                                </div>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4"><Label className="ms-fontWeight-semibold">{t('frequency.label')}:</Label></div>
                                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="Hz" ariaLabel="" disabled={true} value={this.state.right.rateNew ? this.state.right.rateNew : this.state.rightG2.rate} /></div>
                                                                </div>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '5px'}}>
                                                                <div className="ms-Grid-row">
                                                                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4"><Label className="ms-fontWeight-semibold">{t('pulse.label')}:</Label></div>
                                                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"><TextField label="" suffix="μs" ariaLabel="" disabled={true} value={this.state.rightG2.pulse} /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                                <Separator></Separator>
                                                                <Toggle label={t('showmoredata.text')} inlineLabel onText={t('notshow.text')} offText={t('show.text')} name="toggleRight2"  onChange={this.toggleOnChange2}/>
                                                            </div>
                                                            {this.state.mostrarFinalRigth2 &&
                                                                <>
                                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                                    <Label className="ms-fontSize-16 ms-fontWeight-bold">Amplitud equivalente: <span className="ms-fontWeight-regular">{this.state.resultadoR2} mA</span></Label>
                                                                    <Label>Fórmula aplicada: {this.state.formulaR2}</Label>
                                                                </div>
                                                                <div>
                                                                    <Fragment>
                                                                        <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                                                            <g id="Resistencias_positivas">
                                                                                { this.state.contPR2 === 4 &&
                                                                                    <g id="R5_positivo">
                                                                                        <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    </g>
                                                                                }
                                                                                { this.state.contPR2 >= 2 &&
                                                                                    <g id="R4_positivo">
                                                                                        <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    </g>
                                                                                }
                                                                                { this.state.contPR2 === 1 || this.state.contPR2 === 3 ?
                                                                                    <g id="R3_positivo">
                                                                                        <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    </g>
                                                                                    :''
                                                                                }
                                                                                
                                                                                { this.state.contPR2 >= 2 &&
                                                                                    <g id="R2_positivo">
                                                                                        <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    </g>
                                                                                }

                                                                                { this.state.contPR2 === 4 &&
                                                                                    <g id="R1_positivo">
                                                                                        <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                        <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    </g>
                                                                                }

                                                                                { this.state.contPR2 < 1 &&
                                                                                    <>
                                                                                        <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlanco"/>
                                                                                    </>
                                                                                }
                                                                                <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                                                c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                                                h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlanco"/>
                                                                                
                                                                            </g>
                                                                            <g id="Resistencias_negativas">
                                                                                {this.state.contNR2 === 4 &&
                                                                                    <g id="R5_negativo">
                                                                                        <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43"/>
                                                                                    <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                </g>}
                                                                                {this.state.contNR2 >= 2 &&
                                                                                    <g id="R4_negativo">
                                                                                        <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                </g>}
                                                                                {this.state.contNR2 === 1 || this.state.contNR2 === 3 ?
                                                                                    <g id="R3_negativo">
                                                                                    <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                </g>:''}
                                                                                {this.state.contNR2 >= 2 &&
                                                                                    <g id="R2_negativo">
                                                                                        <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                </g>}
                                                                                {this.state.contNR2 === 4 &&
                                                                                    <g id="R1_negativo">
                                                                                        <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                                    <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                                </g>}
                                                                                { this.state.contNR2 < 1 &&
                                                                                    <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlanco"/>
                                                                                }
                                                                                <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
            c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlanco"/>
                                                                            </g>
                                                                            <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlanco"/>
                                                                            <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlanco"/>
                                                                            <g id="Carcasa">
                                                                                <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlanco"/>
                                                                                <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlanco"/>
                                                                                <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlanco"/>
                                                                                <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
                                                                                    c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                                                                    <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                                                    c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                                                    h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                                                            </g>
                                                                            <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlanco"/>
                                                                            <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlanco"/>
                                                                            <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlanco"/>
                                                                        </svg>
                                                                    </Fragment>
                                                                    <Label>{t('infoleyenda')}</Label>
                                                                    {
                                                                        i18next.language === 'pt' ? 
                                                                            <img src={mandoPT} alt="Mando" style={{width: '100%', height: '100%'}}/>
                                                                        :
                                                                            <img src={mando} alt="Mando" style={{width: '100%', height: '100%'}}/>
                                                                    }
                                                                </div>
                                                                </>
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </PivotItem>
                                            : <></>
                                            }
                                        </Pivot>
                                    </div>
                                    : <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{marginBottom: '3%', color:'#fff'}}>
                                    {t('sinmedidas.text')}
                                    </div>}
                                </div>
                                <div className="ms-Grid-row ms-hiddenMd ms-hiddenLg ms-hiddenXl ms-hiddenXxxl ms-hiddenXxlUp">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <Separator></Separator>
                                        <Toggle label={t('showmoredata.text')} inlineLabel onText={t('notshow.text')} offText={t('show.text')} name="toggleRight"  onChange={this.toggleOnChange}/>
                                    </div>
                                    {this.state.mostrarFinalRigth &&
                                        <>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <Label className="ms-fontSize-16 ms-fontWeight-bold">{t('equivalentamplitude.text')}: <span className="ms-fontWeight-regular">{this.state.resultadoR} mA</span></Label>
                                            <Label>{t('appliedformula.text')}: {this.state.formulaR}</Label>
                                        </div>
                                        <div>
                                            <Fragment>
                                                <svg version="1.1" id="Capa_2" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 324.59 333.89">
                                                    <g id="Resistencias_positivas">
                                                        { this.state.contPR === 4 &&
                                                            <g id="R5_positivo">
                                                                <rect id="Hilo_derecho_vert_7_" x="223.29" y="296.53" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                <rect id="Hilo_dcho_9_" x="203.91" y="321.8" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Resistencia_9_" x="155.92" y="311.93" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                <rect id="Hilo_izq_9_" x="135.48" y="321.8" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Hilo_izquierdo_vert_7_" x="134.9" y="296.53" width="1.27" height="26.43" className="strokeBlanco"/>
                                                            </g>
                                                        }
                                                        { this.state.contPR >= 2 &&
                                                            <g id="R4_positivo">
                                                                <rect id="Hilo_derecho_vert_6_" x="223.29" y="271.23" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                <rect id="Hilo_dcho_8_" x="203.91" y="296.5" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Resistencia_8_" x="155.92" y="286.63" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                <rect id="Hilo_izq_8_" x="135.48" y="296.5" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Hilo_izquierdo_vert_6_" x="134.9" y="271.23" width="1.27" height="26.43" className="strokeBlanco"/>
                                                            </g>
                                                        }
                                                        { this.state.contPR === 1 || this.state.contPR === 3 ?
                                                            <g id="R3_positivo">
                                                                <rect id="Hilo_dcho_7_" x="203.91" y="271.2" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Resistencia_7_" x="155.92" y="261.33" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                <rect id="Hilo_izq_7_" x="135.48" y="271.2" width="20.66" height="1.12" className="strokeBlanco"/>
                                                            </g>
                                                            :''
                                                        }
                                                        
                                                        { this.state.contPR >= 2 &&
                                                            <g id="R2_positivo">
                                                                <rect id="Hilo_derecho_vert_5_" x="223.29" y="245.92" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                <rect id="Hilo_dcho_6_" x="203.91" y="245.9" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Resistencia_6_" x="155.92" y="236.02" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                <rect id="Hilo_izq_6_" x="135.48" y="245.9" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Hilo_izquierdo_vert_5_" x="134.9" y="245.92" width="1.27" height="26.43" className="strokeBlanco"/>
                                                            </g>
                                                        }

                                                        { this.state.contPR === 4 &&
                                                            <g id="R1_positivo">
                                                                <rect id="Hilo_derecho_vert_4_" x="223.29" y="220.62" width="1.27" height="26.43" className="strokeBlanco"/>
                                                                <rect id="Hilo_dcho_5_" x="203.91" y="220.6" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Resistencia_5_" x="155.92" y="210.72" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                                <rect id="Hilo_izq_5_" x="135.48" y="220.6" width="20.66" height="1.12" className="strokeBlanco"/>
                                                                <rect id="Hilo_izquierdo_vert_4_" x="134.9" y="220.62" width="1.27" height="26.43" className="strokeBlanco"/>
                                                            </g>
                                                        }

                                                        { this.state.contPR < 1 &&
                                                            <>
                                                                <rect id="Hilo_central_1_" x="134.69" y="271.38" width="90.52" height="1.07" className="strokeBlanco"/>
                                                            </>
                                                        }
                                                        <path id="Icono_positivo_1_" d="M129.69,259.28h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                        c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                        h4.74c0.58,0,1.04-0.55,1.04-1.13C130.74,260.01,130.27,259.28,129.69,259.28z" className="strokeBlanco"/>
                                                        
                                                    </g>
                                                    <g id="Resistencias_negativas">
                                                        {this.state.contNR === 4 &&
                                                            <g id="R5_negativo">
                                                                <rect id="Hilo_derecho_vert_3_" x="223.29" y="87.34" width="1.27" height="26.43"/>
                                                            <rect id="Hilo_dcho_1_" x="203.91" y="112.64" width="20.66" height="1.12" className="strokeBlanco"/>
                                                            <rect id="Resistencia_1_" x="155.92" y="102.77" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                            <rect id="Hilo_izq_1_" x="135.48" y="112.64" width="20.66" height="1.12" className="strokeBlanco"/>
                                                            <rect id="Hilo_izquierdo_vert_3_" x="134.9" y="87.34" width="1.27" height="26.43" className="strokeBlanco"/>
                                                        </g>}
                                                        {this.state.contNR >= 2 &&
                                                            <g id="R4_negativo">
                                                                <rect id="Hilo_derecho_vert_2_" x="223.29" y="62.04" width="1.27" height="26.43" className="strokeBlanco"/>
                                                            <rect id="Hilo_dcho_2_" x="203.91" y="87.34" width="20.66" height="1.12" className="strokeBlanco"/>
                                                            <rect id="Resistencia_2_" x="155.92" y="77.47" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                            <rect id="Hilo_izq_2_" x="135.48" y="87.34" width="20.66" height="1.12" className="strokeBlanco"/>
                                                            <rect id="Hilo_izquierdo_vert_2_" x="134.9" y="62.04" width="1.27" height="26.43" className="strokeBlanco"/>
                                                        </g>}
                                                        {this.state.contNR === 1 || this.state.contNR === 3 ?
                                                            <g id="R3_negativo">
                                                            <rect id="Hilo_dcho_3_" x="203.91" y="62.04" width="20.66" height="1.12" className="strokeBlanco"/>
                                                            <rect id="Resistencia_3_" x="155.92" y="52.16" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                            <rect id="Hilo_izq_3_" x="135.48" y="62.04" width="20.66" height="1.12" className="strokeBlanco"/>
                                                        </g>:''}
                                                        {this.state.contNR >= 2 &&
                                                            <g id="R2_negativo">
                                                                <rect id="Hilo_derecho_vert_1_" x="223.29" y="36.73" width="1.27" height="26.43" className="strokeBlanco"/>
                                                            <rect id="Hilo_dcho_4_" x="203.91" y="36.73" width="20.66" height="1.12" className="strokeBlanco"/>
                                                            <rect id="Resistencia_4_" x="155.92" y="26.86" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                            <rect id="Hilo_izq_4_" x="135.48" y="36.73" width="20.66" height="1.12" className="strokeBlanco"/>
                                                            <rect id="Hilo_izquierdo_vert_1_" x="134.9" y="36.73" width="1.27" height="26.43" className="strokeBlanco"/>
                                                        </g>}
                                                        {this.state.contNR === 4 &&
                                                            <g id="R1_negativo">
                                                                <rect id="Hilo_derecho_vert" x="223.29" y="11.43" width="1.27" height="26.43" className="strokeBlanco"/>
                                                            <rect id="Hilo_dcho" x="203.91" y="11.43" width="20.66" height="1.12" className="strokeBlanco"/>
                                                            <rect id="Resistencia" x="155.92" y="1.56" class="stcir" width="47.98" height="20.87" className="strokeBlanco"/>
                                                            <rect id="Hilo_izq" x="135.48" y="11.43" width="20.66" height="1.12" className="strokeBlanco"/>
                                                            <rect id="Hilo_izquierdo_vert" x="134.9" y="11.43" width="1.27" height="26.43" className="strokeBlanco"/>
                                                        </g>}
                                                        { this.state.contNR < 1 &&
                                                            <rect id="Hilo_central" x="134.69" y="62.21" width="90.52" height="1.07" className="strokeBlanco"/>
                                                        }
                                                        <path id="Icono_negativo_1_" d="M130.74,54.93c0,1.03-0.48,1.86-1.07,1.86h-11.87c-0.59,0-1.07-0.83-1.07-1.86
c0-1.03,0.48-1.86,1.07-1.86h11.87C130.26,53.07,130.74,53.91,130.74,54.93z" className="strokeBlanco"/>
                                                    </g>
                                                    <rect id="Hilo_inferior_dcha" x="224.09" y="271.28" width="99.52" height="1.27" className="strokeBlanco"/>
                                                    <rect id="Hilo_inferior_izq" x="27.3" y="271.28" width="107.39" height="1.27" className="strokeBlanco"/>
                                                    <g id="Carcasa">
                                                        <rect id="Hilo_inferior" x="27.03" y="193.14" width="1.69" height="78.31" className="strokeBlanco"/>
                                                        <circle id="Circulo" class="stcir" cx="27.65" cy="167.03" r="26.35" className="strokeBlanco"/>
                                                        <rect id="Hilo_superior" x="27.03" y="62.85" width="1.69" height="77.91" className="strokeBlanco"/>
                                                        <path id="Icono_negativo" d="M34.89,153.48c0,1.03-0.48,1.86-1.07,1.86H21.94c-0.59,0-1.07-0.83-1.07-1.86
                                                            c0-1.03,0.48-1.86,1.07-1.86h11.87C34.41,151.62,34.89,152.45,34.89,153.48z" className="strokeBlanco"/>
                                                            <path id="Icono_positivo" d="M33.84,178.58h-4.74v-4.74c0-0.58-0.65-1.04-1.23-1.04c-0.58,0-1.23,0.47-1.23,1.04v4.74h-4.74
                                                            c-0.58,0-1.04,0.73-1.04,1.3c0,0.58,0.47,1.13,1.04,1.13h4.74v4.4c0,0.58,0.65,1.04,1.23,1.04c0.58,0,1.23-0.47,1.23-1.04v-4.4
                                                            h4.74c0.58,0,1.04-0.55,1.04-1.13C34.89,179.31,34.42,178.58,33.84,178.58z" className="strokeBlanco"/>
                                                    </g>
                                                    <rect id="Hilo_superior_dcha" x="224.09" y="62.11" width="99.52" height="1.27" className="strokeBlanco"/>
                                                    <rect id="Hilo_superior_izq_1_" x="27.3" y="62.11" width="107.39" height="1.27" className="strokeBlanco"/>
                                                    <rect id="Hilo_Derecho" x="322.68" y="62.28" width="1.3" height="209.73" className="strokeBlanco"/>
                                                </svg>
                                            </Fragment>
                                            <Label>{t('infoleyenda')}</Label>
                                            {
                                                i18next.language === 'pt' ? 
                                                    <img src={mandoPT} alt="Mando" style={{width: '100%', height: '100%'}}/>
                                                :
                                                    <img src={mando} alt="Mando" style={{width: '100%', height: '100%'}}/>
                                            }
                                        </div>
                                        </>
                                    }
                                    
                                </div>
                            </PivotItem>
                        }
                        </Pivot>
                        <br></br>
            </>
        );
    }
}

export default withTranslation()(ConversorMetronicRecuento);